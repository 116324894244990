/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useMemo, useRef} from "react";
import {useGLTF, useAnimations, Shadow} from "@react-three/drei";
import PropTypes from "prop-types";
import Helpers from "../models/Helpers";
import { useGraph } from "@react-three/fiber";
import { SkeletonUtils } from "three-stdlib";
import useTriggerAnimation from "../../../hooks/useTriggerAnimation";
import useSetFirstAnimationFrame from "../../../hooks/useSetFirstAnimationFrame";
import useNewMaterials from "../../../hooks/useNewMaterials";

export default function HelpersHolder({ ...props }) {
  const group = useRef();
  const { scene, materials, animations } = useGLTF(
    "/SceneAddition/helpers-transformed.glb"
  );
  const specificMaterials = useMemo(() => [
    { name: 'head', color: `${props.color}2` },
  ], [props.color])
  const newMaterials = useNewMaterials(materials, {
    baseMaterial: `${props.color}2`,
    specificMaterials: specificMaterials
  });

  const clone = useMemo(() => SkeletonUtils.clone(scene), [scene]);

  const { nodes } = useGraph(clone);

  const { actions } = useAnimations(animations, group);

  useSetFirstAnimationFrame("pops-up", actions);

  useTriggerAnimation(props.popsIn, "pops-up", actions, null, false, 1.2);
  useTriggerAnimation(props.lookDownAndUp, "look-down-and-up", actions, null, false, 1.6);
  useTriggerAnimation(props.lookDownAndUp2, "look-down-and-up", actions, null, false, 1.6);
  useTriggerAnimation(props.shrug, "shrug", actions, props.shrugEnd, false, 1.4);
  useTriggerAnimation(props.defaultPose, "default-pose", actions, null, false, 1.2);
  useTriggerAnimation(props.writing, "writing", actions, null, false, 1.2);

  return (
    <group ref={group} {...props} dispose={null} visible={props.show}>
      <Helpers nodes={nodes} materials={newMaterials} />
      <Shadow color="purple" position-y={props.position[1]} opacity={0.15} scale={0.7}/>
    </group>
  );
}

HelpersHolder.propTypes = {
  show: PropTypes.bool,
  popsIn: PropTypes.number,
  lookDownAndUp: PropTypes.number,
  lookDownAndUp2: PropTypes.number,
  shrug: PropTypes.number,
  defaultPose: PropTypes.number,
  writing: PropTypes.number,
  shrugEnd: PropTypes.number,
  color: PropTypes.string,
  position: PropTypes.array,
};

HelpersHolder.defaultProps = {
  show: true,
  popsIn: null,
  lookDownAndUp: null,
  lookDownAndUp2: null,
  shrug: null,
  shrugEnd: null,
  defaultPose: null,
  writing: null,
  color: "grey",
  position: [0, 0, 0],
};

useGLTF.preload("/SceneAddition/helpers-transformed.glb");

