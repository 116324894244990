import {useEffect, useRef, useState} from "react";
import * as THREE from "three";

import {
  grey1, grey2, grey3, grey4,
  blue1, blue2, blue3, blue4,
  green1, green2, green3, green4,
  purple1, purple2, purple3, purple4,
  yellow1, yellow2, yellow3, yellow4,
  pink1, pink2, pink3, pink4,
} from "../helpers/materials";

const colorMaterials = {
  grey1: grey1,
  grey2: grey2,
  grey3: grey3,
  grey4: grey4,
  blue1: blue1,
  blue2: blue2,
  blue3: blue3,
  blue4: blue4,
  green1: green1,
  green2: green2,
  green3: green3,
  green4: green4,
  purple1: purple1,
  purple2: purple2,
  purple3: purple3,
  purple4: purple4,
  yellow1: yellow1,
  yellow2: yellow2,
  yellow3: yellow3,
  yellow4: yellow4,
  pink1: pink1,
  pink2: pink2,
  pink3: pink3,
  pink4: pink4
}
const emptyArray = [];

export default function useNewMaterials(materials, options = {}) {
  // Set options for the hook
  const {
    baseMaterial = 'grey3',
    specificMaterials = emptyArray,
    debug = false,
    excludeMaterials = emptyArray
  } = options;

  const newMaterials = useRef(materials);

  const setNewMaterials = (updatedMaterials) => {
    newMaterials.current = updatedMaterials;
    // Trigger a re-render of the component using the hook
    // by calling setState with a new value
    setRerender({});
  }

  // State variable used to trigger a re-render
  const [, setRerender] = useState({});

  useEffect(() => {
    // Create a copy of materials
    const updatedMaterials = { ...materials };

    // Set base material
    for (const [key] of Object.entries(materials)) {
      if (!excludeMaterials.includes(key)) {
        updatedMaterials[key] = colorMaterials[baseMaterial];
      }
    }

    // If specificMaterials is not empty, then loop through the array
    // and set the materials to the specific materials.
    if (specificMaterials.length > 0) {
      specificMaterials.forEach((material) => {
        if (material.replaceWith) {
          updatedMaterials[material.name] = materials[material.replaceWith];
          if(material.setMaterialColor) {
            updatedMaterials[material.name].emissive = new THREE.Color(material.setMaterialColor)
            updatedMaterials[material.name].emissiveIntensity = 5
          }
        } else if (material.color) {
          updatedMaterials[material.name] = colorMaterials[material.color]
        }
      })
    }

    if (debug) { console.log(`updatedMaterials`, updatedMaterials) }

    // Update newMaterials ref
    setNewMaterials(updatedMaterials);
  }, [specificMaterials, baseMaterial, materials, debug, excludeMaterials])

  return newMaterials.current;
}

