import React from 'react'

const WebsiteUser = ({nodes, materials}) => {
  return (
    <group>
       <group>
        <group name="grp_char">
          <skinnedMesh name="char_legs" geometry={nodes.char_legs.geometry} material={materials.standardSurface2} skeleton={nodes.char_legs.skeleton} />
          <skinnedMesh name="char_head" geometry={nodes.char_head.geometry} material={materials.standardSurface2} skeleton={nodes.char_head.skeleton} />
          <skinnedMesh name="char_torso" geometry={nodes.char_torso.geometry} material={materials.standardSurface2} skeleton={nodes.char_torso.skeleton} />
          <skinnedMesh name="hat" geometry={nodes.hat.geometry} material={materials.standardSurface2} skeleton={nodes.hat.skeleton} position={[-0.000497, 0.011759, 0.005736]} rotation={[-0.004651, -0.000859, -0.000398]} />
          <skinnedMesh name="char_arms" geometry={nodes.char_arms.geometry} material={materials.standardSurface2} skeleton={nodes.char_arms.skeleton} />
        </group>
        <group name="lockbox_grp_1" position={[0, 0.905518, 0.332556]} scale={[27.34086, 25.36178, 27.34086]}>
          <group name="lockbox_grp" position={[0, 0.002908, 0]}>
            <group name="lock" position={[0, 0.000054, -0.000003]} rotation={[-0.215506, 0, 0]}>
              <mesh name="pCube1" geometry={nodes.pCube1.geometry} material={materials.pasted__phong_5} />
              <mesh name="pTorus2" geometry={nodes.pTorus2.geometry} material={materials.pasted__phong_5} />
            </group>
            <mesh name="box_lid" geometry={nodes.box_lid.geometry} material={materials.pasted__phong_5}>
              <mesh name="box_detail_1" geometry={nodes.box_detail_1.geometry} material={materials.pasted__phong_5} />
            </mesh>
            <mesh name="box_bottom" geometry={nodes.box_bottom.geometry} material={materials.pasted__phong_5} />
          </group>
        </group>
        <group name="lockbox_grp_2" position={[0, 0.745276, 0.332556]} scale={[27.34086, 25.36178, 27.34086]}>
          <group name="lockbox_grp_(1)" position={[0, 0.002908, 0]}>
            <group name="lock_(1)" position={[0, 0.000054, -0.000003]} rotation={[-0.215506, 0, 0]}>
              <mesh name="pCube1_(1)" geometry={nodes['pCube1_(1)'].geometry} material={materials.pasted__phong_5} />
              <mesh name="pTorus2_(1)" geometry={nodes['pTorus2_(1)'].geometry} material={materials.pasted__phong_5} />
            </group>
            <mesh name="box_lid_(1)" geometry={nodes['box_lid_(1)'].geometry} material={materials.pasted__phong_5}>
              <mesh name="box_detail_1_(1)" geometry={nodes['box_detail_1_(1)'].geometry} material={materials.pasted__phong_5} />
            </mesh>
            <mesh name="box_bottom_(1)" geometry={nodes['box_bottom_(1)'].geometry} material={materials.pasted__phong_5} />
          </group>
        </group>
        <group name="lockbox_grp_3" position={[0, 1.067725, 0.332556]} scale={[27.34086, 25.36178, 27.34086]}>
          <group name="lockbox_grp_(2)" position={[0, 0.002908, 0]}>
            <group name="lock_(2)" position={[0, 0.000054, -0.000003]} rotation={[-0.215506, 0, 0]}>
              <mesh name="pCube1_(2)" geometry={nodes['pCube1_(2)'].geometry} material={materials.pasted__phong_5} />
              <mesh name="pTorus2_(2)" geometry={nodes['pTorus2_(2)'].geometry} material={materials.pasted__phong_5} />
            </group>
            <mesh name="box_lid_(2)" geometry={nodes['box_lid_(2)'].geometry} material={materials.pasted__phong_5}>
              <mesh name="box_detail_1_(2)" geometry={nodes['box_detail_1_(2)'].geometry} material={materials.pasted__phong_5} />
            </mesh>
            <mesh name="box_bottom_(2)" geometry={nodes['box_bottom_(2)'].geometry} material={materials.pasted__phong_5} />
          </group>
        </group>
        <primitive object={nodes.Group} />
      </group>
      </group>
  )
}

export default WebsiteUser