import React, {useEffect} from 'react';
import PropTypes from "prop-types";
import {Box, useTexture} from "@react-three/drei";
import number0 from "../../../assets/textures/ao-numbers/number-0-ao.jpg";
import number1 from "../../../assets/textures/ao-numbers/number-1-ao.jpg";
import number2 from "../../../assets/textures/ao-numbers/number-2-ao.jpg";
import number3 from "../../../assets/textures/ao-numbers/number-3-ao.jpg";
import number4 from "../../../assets/textures/ao-numbers/number-4-ao.jpg";
import number5 from "../../../assets/textures/ao-numbers/number-5-ao.jpg";
import number6 from "../../../assets/textures/ao-numbers/number-6-ao.jpg";
import number7 from "../../../assets/textures/ao-numbers/number-7-ao.jpg";
import number8 from "../../../assets/textures/ao-numbers/number-8-ao.jpg";
import number9 from "../../../assets/textures/ao-numbers/number-9-ao.jpg";
import {config, useSpring, animated} from "@react-spring/three";

function SharedSecretNumbers(props) {
  const textures = useTexture([
    number0,
    number1,
    number2,
    number3,
    number4,
    number5,
    number6,
    number7,
    number8,
    number9,
  ])

  const springs = useSpring({
    scale: props.showNumbers ? 1 : 0,
    config: config.molasses
  })

  // For debug purposes only
  useEffect(() => {
    if (props.debug) {
      console.log('Show numbers: ', props.showNumbers);
    }
  }, [props.debug, props.showNumbers]);

  return (
    <group visible={props.showNumbers}>
      <group position={[-0.047, 0.052, 0.082]}>
        <animated.group scale={springs.scale}>
          <Box scale={0.02} args={[1, 1, 0.01]}>
            <meshPhysicalMaterial
              color={'white'}
              emissive={'white'}
              emissiveIntensity={10}
              transparent={true}
              alphaMap={textures[props.shares[0]]}
            />
          </Box>
        </animated.group>
      </group>

      <group position={[-0.024, -0.05, 0.082]}>
        <animated.group scale={springs.scale}>
          <Box scale={0.02} args={[1, 1, 0.01]}>
            <meshPhysicalMaterial
              color={'white'}
              emissive={'white'}
              emissiveIntensity={10}
              transparent={true}
              alphaMap={textures[props.shares[1]]}
            />
          </Box>
        </animated.group>
      </group>

      <group position={[0.057, 0.01, 0.082]}>
        <animated.group scale={springs.scale}>
          <Box scale={0.02} args={[1, 1, 0.01]}>
            <meshPhysicalMaterial
              color={'white'}
              emissive={'white'}
              emissiveIntensity={10}
              transparent={true}
              alphaMap={textures[props.shares[2]]}
            />
          </Box>
        </animated.group>
      </group>
    </group>
  )
}

SharedSecretNumbers.propTypes = {
  showNumbers: PropTypes.bool,
  shares: PropTypes.array.isRequired,
  debug: PropTypes.bool,
};

SharedSecretNumbers.defaultProps = {
  showNumbers: false,
  debug: false,
};

export default SharedSecretNumbers;