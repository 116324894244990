/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useMemo, useRef} from "react";

import useTrigger from "../../../hooks/useTrigger";
import { useAnimations, useGLTF } from "@react-three/drei";
import useTriggerAnimation from "../../../hooks/useTriggerAnimation";
import useSetFirstAnimationFrame from "../../../hooks/useSetFirstAnimationFrame";
import useNewMaterials from "../../../hooks/useNewMaterials";
import MultiplicationTotal from "../models/MultiplicationTotal";
import {text} from "../../../data/multiplication-data/text";
import { somethingVerySmall } from "../../../helpers/scrollHelpers";
import Label from "../../Label";

export default function MultiplicationTotalHolder() {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(
    "/SceneMultiplication/multiplication-total-transformed.glb"
  );
  const specificMaterials = useMemo(() =>[
    {name: 'number_material_6', replaceWith: `number_material_6`, setMaterialColor: 0xffffff},
    {name: 'number_material_8', replaceWith: `number_material_8`, setMaterialColor: 0xffffff},
    {name: 'number_material_10', replaceWith: `number_material_10`, setMaterialColor: 0xffffff},
    {name: 'number_material_11', replaceWith: `number_material_11`, setMaterialColor: 0xffffff},
    {name: 'number_material_12', replaceWith: `number_material_12`, setMaterialColor: 0xffffff},
    {name: 'typeBlinn', replaceWith: `typeBlinn`, setMaterialColor: 0xffffff},
  
  ], [])
  const newMaterials = useNewMaterials(materials, {
    baseMaterial: "pink2",
    specificMaterials: specificMaterials,
  })
  const { actions } = useAnimations(animations, group);

  const show = useTrigger(text[22].adjustedIn - somethingVerySmall);
  const showLabel = show;

  useSetFirstAnimationFrame('pops-up', actions)
  useTriggerAnimation(text[22].adjustedIn - somethingVerySmall, "pops-up", actions, null, false, 2);

  return (
    <>
      <group
        position={[0, 0, 0]}
        scale={0.2}
        ref={group}
        dispose={null}
        visible={show}
      >
        <MultiplicationTotal nodes={nodes} materials={newMaterials} />
        <Label
          show={showLabel}
          position={[12, -0.2, 0]}
          fullWidthLabel={true}
          targetWidth={70}
          targetHeight={70}
        />
      </group>
    </>
  );
}

useGLTF.preload("/SceneMultiplication/multiplication-total-transformed.glb");

