import create from "zustand";
import { createModalSlice } from "./modalSlice";
import { createTemplateSlice } from "./templateSlice";
import { createIntroSlice } from "./introSlice";
import { createSecretSlice } from "./secretSlice";
import { createAdditionSlice } from "./additionSlice";
import { createMultiplicationSlice } from "./multiplicationSlice";
import { createIpaSlice } from "./ipaSlice";
import { createNavigationSlice } from "./navigationSlice";
import { createAudioSlice } from "./audioSlice";

export const useBoundStore = create((...a) => ({
  ...createModalSlice(...a),
  ...createTemplateSlice(...a),
  ...createIntroSlice(...a),
  ...createSecretSlice(...a),
  ...createAdditionSlice(...a),
  ...createMultiplicationSlice(...a),
  ...createIpaSlice(...a),
  ...createNavigationSlice(...a),
  ...createAudioSlice(...a),
}));

