import React from "react";
import { useBoundStore } from "../stores/useBoundStore";
import { text } from "../data/multiplication-data/text";
import {audio} from "../data/multiplication-data/audio";
import { keyframes } from "../data/multiplication-data/camera";
import AnimatedPageHolder from "../components/common/AnimatedPageHolder";
import MultiplicationScene from "../components/multiplication-page/MultiplicationScene";

const timelineId = "multiplication-timeline";
const progressName = "multiplicationProgress";

function Multiplication() {
  const {
    multiplicationProgress,
    setMultiplicationProgress,
    multiplicationTextIndex,
    setMultiplicationTextIndex,
  } = useBoundStore();

  // useEffect(() => {
  //   console.log("multiplicationProgress", multiplicationProgress);
  //   console.log("multiplicationTextIndex", multiplicationTextIndex);
  // }, [multiplicationProgress, multiplicationTextIndex]);

  return (
    <AnimatedPageHolder
      setProgress={setMultiplicationProgress}
      setTextIndex={setMultiplicationTextIndex}
      text={text}
      keyframes={keyframes}
      progressName={progressName}
      progress={multiplicationProgress}
      currentTextIndex={multiplicationTextIndex}
      introText={
        <>
          <span className="intro-label">Explainer 5</span>
          <h1>Multiplication</h1>
          <p>
            There is more to IPA than just adding things up. For example:
            checking which purchases have corresponding ad impressions. To do
            this, we need to multiply secret-shared numbers.
          </p>
        </>
      }
      cameraKeyframes={keyframes}
      pageIndex={4}
      textContent={text}
      audioContent={audio}
      uniqueTimelineId={timelineId}
    >
      <MultiplicationScene />
    </AnimatedPageHolder>
  );
}

export default Multiplication;
