import React, { useRef } from "react";
import { useAnimations, useGLTF } from "@react-three/drei";
import PropTypes from "prop-types";
import useTriggerAnimation from "../../../hooks/useTriggerAnimation";
import MultiplicationIcon from "../models/MultiplicationIcon";
import useSetFirstAnimationFrame from "../../../hooks/useSetFirstAnimationFrame";
import useNewMaterials from "../../../hooks/useNewMaterials";

export default function MultiplicationIconHolder({ ...props }) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(
    "/SceneMultiplication/multiplication-icon-transformed.glb"
  );
  const newMaterials = useNewMaterials(materials, {
    baseMaterial: props.color,
  })
  const { actions } = useAnimations(animations, group);

  useSetFirstAnimationFrame('enter', actions);
  useTriggerAnimation(props.in, "enter", actions);

  return (
    <group
      ref={group}
      position={props.position}
      dispose={null}
      visible={props.show}
      scale={props.scale}
    >
      <MultiplicationIcon materials={newMaterials} nodes={nodes} />
    </group>
  );
}

MultiplicationIconHolder.propTypes = {
  show: PropTypes.bool,
  in: PropTypes.number,
  scale: PropTypes.number,
  position: PropTypes.array,
  color: PropTypes.string,
};

MultiplicationIconHolder.defaultProps = {
  show: true,
  in: null,
  scale: null,
  position: null,
  color: 'grey3'
};

useGLTF.preload("/SceneMultiplication/multiplication-icon-transformed.glb");

