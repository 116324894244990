import {useEffect} from "react";

export default function useSetFirstAnimationFrame(animationName, actions) {
  useEffect(() => {
    const animationIn = actions[animationName]
    animationIn.reset()
    animationIn.play()
    animationIn.paused = true
  }, [actions, animationName]);
  return null;
};
