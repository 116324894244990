import {useFrame} from "@react-three/fiber";
import {useBoundStore} from "../../stores/useBoundStore";
import * as THREE from "three";
import {scrollRange} from "../../helpers/scrollHelpers";
import {easeInOutQuad} from "../../helpers/eases";
import useIsMobile from "../../hooks/useIsMobile";
import PropTypes from "prop-types";

function Rig(props) {
  const {isMobile, isTablet} = useIsMobile();
  const {cameraKeyframes} = props;

  useFrame((state) => {
    const progress = useBoundStore.getState()[props.progressName];

    // console.log("progress", progress);

    // Set up consts for the camera shake
    const time = state.clock.getElapsedTime()
    const yShake = Math.sin(time * 0.5)
    const xShake = Math.sin(time * 0.25)

    // Loop through all keyframes to find the right keyframes to animate between
    for (let i = 0; i < cameraKeyframes.length; i++) {

      // The first keyframe where the timeline progress is less than or equal to the time
      // on it's following keyframe is where we want to do our animation.
      if (i === cameraKeyframes.length - 2 || progress <= cameraKeyframes[i + 1].time) {

        // Set up our lerp interval using this keyframe and the following keyframes time
        const r = scrollRange(
          cameraKeyframes[i].time,
          cameraKeyframes[i + 1].time - cameraKeyframes[i].time,
          progress
        );
        const rEased = easeInOutQuad(r);

        // Set the shake intensity that will be used to reduce or increase the camera shake that is adding to the camera position
        const shakeIntensity = THREE.MathUtils.lerp(
          cameraKeyframes[i].shakeIntensity || 1,
          cameraKeyframes[i + 1].shakeIntensity || 1,
          rEased
        );

        let from = cameraKeyframes[i].position
        let to = cameraKeyframes[i + 1].position
        if( isMobile || isTablet ) {
          if(cameraKeyframes[i].mobilePosition) {
            from = cameraKeyframes[i].mobilePosition
          }
          if(cameraKeyframes[i + 1].mobilePosition) {
            to = cameraKeyframes[i + 1].mobilePosition
          }
        }
        // Set the position of the camera based on this interval
        state.camera.position.x = THREE.MathUtils.lerp(
          from[0],
          to[0],
          rEased
        ) + xShake * shakeIntensity;
        state.camera.position.y = THREE.MathUtils.lerp(
          from[1],
          to[1],
          rEased
        ) + yShake * shakeIntensity;
        state.camera.position.z = THREE.MathUtils.lerp(
          from[2],
          to[2],
          rEased
        );

        // console.log(
        //   THREE.MathUtils.lerp(
        //     cameraKeyframes[i].position[0],
        //     cameraKeyframes[i + 1].position[0],
        //     rEased
        //   ),
        //   THREE.MathUtils.lerp(
        //     cameraKeyframes[i].position[1],
        //     cameraKeyframes[i + 1].position[1],
        //     rEased
        //   ),
        //   THREE.MathUtils.lerp(
        //     cameraKeyframes[i].position[2],
        //     cameraKeyframes[i + 1].position[2],
        //     rEased
        //   )
        // )

        // Set the lookAt points for the camera to lerp between.
        // If the lookAt is not defined for start or end keyframe, we just use the x and y position of the camera and set z to 0.
        // This is to make the camera look "straight ahead" when the lookAt is not defined.
        const currentLookAt = cameraKeyframes[i].lookAt;
        const nextLookAt = cameraKeyframes[i + 1].lookAt;

        // Now we lerp between the lookAt points
        state.camera.lookAt(
          THREE.MathUtils.lerp(currentLookAt[0], nextLookAt[0], rEased),
          THREE.MathUtils.lerp(currentLookAt[1], nextLookAt[1], rEased),
          THREE.MathUtils.lerp(currentLookAt[2], nextLookAt[2], rEased)
        );

        // break the loop here as we have found our keyframe and don't want to keep looking
        break;
      }
    }
  });

  return null;
}

Rig.propTypes = {
  cameraKeyframes: PropTypes.array.isRequired,
  progressName: PropTypes.string.isRequired,
};

export default Rig;

