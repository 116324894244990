import React from 'react'

const NewsSilo = ({nodes, materials}) => {
  return (
    <group>
    <group name="Icon_News_Site" position={[0, 0.874421, 0]} scale={[0.453523, 0.453523, 0.453605]}>
      <group name="Letter_ring_anim_grp" position={[0.979397, 0.203442, 0]} rotation={[0, 0, 0.409619]}>
        <group name="deform_group" position={[-0.017697, 0, 0]}>
          <mesh name="typeMesh1" geometry={nodes.typeMesh1.geometry} material={materials.phong_1} position={[0, 2.349393, 0.790049]} />
          <mesh name="typeMesh3" geometry={nodes.typeMesh3.geometry} material={materials.phong_1} position={[0.710748, 2.349393, -0.379698]} rotation={[-Math.PI, Math.PI / 3, -Math.PI]} />
          <mesh name="typeMesh4" geometry={nodes.typeMesh4.geometry} material={materials.phong_1} position={[-0.657656, 2.349393, -0.410351]} rotation={[-Math.PI, -Math.PI / 3, -Math.PI]} />
        </group>
        <mesh name="rings" geometry={nodes.rings.geometry} material={materials.phong_7} position={[0, 2.439613, 0]} scale={[81.50752, 13.771626, 81.50752]} />
      </group>
      <mesh name="sweep1" geometry={nodes.sweep1.geometry} material={materials.phong_3} position={[0, -0.673661, 0]} scale={[1.470855, 1.470855, 1.470588]} />
      <mesh name="pSphere10" geometry={nodes.pSphere10.geometry} material={materials.phong_3} position={[0, 2.459194, 0]} scale={65.460846} />
    </group>
    <group name="silo_grp">
      <group name="group3" rotation={[0, -0.744061, 0]}>
        <group name="lockboxes_grp_6" position={[0, 0.779152, 0]}>
          <mesh name="lockbox8" geometry={nodes.lockbox8.geometry} material={materials.phong_5} />
          <mesh name="lockbox9" geometry={nodes.lockbox9.geometry} material={materials.phong_5} />
          <mesh name="lockbox10" geometry={nodes.lockbox10.geometry} material={materials.phong_5} />
          <mesh name="lockbox11" geometry={nodes.lockbox11.geometry} material={materials.phong_5} />
          <mesh name="lockbox12" geometry={nodes.lockbox12.geometry} material={materials.phong_5} />
          <mesh name="lockbox13" geometry={nodes.lockbox13.geometry} material={materials.phong_5} />
          <mesh name="lockbox14" geometry={nodes.lockbox14.geometry} material={materials.phong_5} />
          <mesh name="lockbox15" geometry={nodes.lockbox15.geometry} material={materials.phong_5} />
        </group>
        <group name="silo_part1">
          <mesh name="silo_part1_1" geometry={nodes.silo_part1_1.geometry} material={materials.phong_5} />
          <mesh name="silo_part1_2" geometry={nodes.silo_part1_2.geometry} material={materials.phong_5} />
        </group>
      </group>
      <group name="group4" rotation={[0, -0.126924, 0]}>
        <group name="lockboxes_grp_5" position={[0, 0.387911, 0]}>
          <mesh name="lockbox16" geometry={nodes.lockbox16.geometry} material={materials.phong_5} />
          <mesh name="lockbox17" geometry={nodes.lockbox17.geometry} material={materials.phong_5} />
          <mesh name="lockbox18" geometry={nodes.lockbox18.geometry} material={materials.phong_5} />
          <mesh name="lockbox19" geometry={nodes.lockbox19.geometry} material={materials.phong_5} />
          <mesh name="lockbox20" geometry={nodes.lockbox20.geometry} material={materials.phong_5} />
          <mesh name="lockbox21" geometry={nodes.lockbox21.geometry} material={materials.phong_5} />
          <mesh name="lockbox22" geometry={nodes.lockbox22.geometry} material={materials.phong_5} />
          <mesh name="lockbox23" geometry={nodes.lockbox23.geometry} material={materials.phong_5} />
        </group>
        <group name="silo_part2">
          <mesh name="silo_part2_1" geometry={nodes.silo_part2_1.geometry} material={materials.phong_5} />
          <mesh name="silo_part2_2" geometry={nodes.silo_part2_2.geometry} material={materials.phong_5} />
        </group>
      </group>
      <group name="group5">
        <group name="lockboxes_grp_1">
          <mesh name="lockbox24" geometry={nodes.lockbox24.geometry} material={materials.phong_5} />
          <mesh name="lockbox25" geometry={nodes.lockbox25.geometry} material={materials.phong_5} />
          <mesh name="lockbox26" geometry={nodes.lockbox26.geometry} material={materials.phong_5} />
          <mesh name="lockbox27" geometry={nodes.lockbox27.geometry} material={materials.phong_5} />
          <mesh name="lockbox28" geometry={nodes.lockbox28.geometry} material={materials.phong_5} />
          <mesh name="lockbox29" geometry={nodes.lockbox29.geometry} material={materials.phong_5} />
          <mesh name="lockbox30" geometry={nodes.lockbox30.geometry} material={materials.phong_5} />
          <mesh name="lockbox31" geometry={nodes.lockbox31.geometry} material={materials.phong_5} />
        </group>
        <group name="silo_part3">
          <mesh name="silo_part3_1" geometry={nodes.silo_part3_1.geometry} material={materials.phong_5} />
          <mesh name="silo_part3_2" geometry={nodes.silo_part3_2.geometry} material={materials.phong_5} />
        </group>
      </group>
      <mesh name="pole" geometry={nodes.pole.geometry} material={materials.phong_5} position={[0, 1.492597, 0]} scale={[3.128761, 18.894241, 3.128761]} />
    </group>
    <group name="silo_part" rotation={[-Math.PI, -1.21767, -Math.PI]}>
      <mesh name="silo_part_1" geometry={nodes.silo_part_1.geometry} material={materials.phong_5} />
      <mesh name="silo_part_2" geometry={nodes.silo_part_2.geometry} material={materials.phong_5} />
      <group name="lockboxes_grp_7" position={[0, 1.158876, 0]} rotation={[0, -0.073964, 0]}>
        <mesh name="lockbox" geometry={nodes.lockbox.geometry} material={materials.phong_5} />
        <mesh name="lockbox1" geometry={nodes.lockbox1.geometry} material={materials.phong_5} />
        <mesh name="lockbox2" geometry={nodes.lockbox2.geometry} material={materials.phong_5} />
        <mesh name="lockbox3" geometry={nodes.lockbox3.geometry} material={materials.phong_5} />
        <mesh name="lockbox4" geometry={nodes.lockbox4.geometry} material={materials.phong_5} />
        <mesh name="lockbox5" geometry={nodes.lockbox5.geometry} material={materials.phong_5} />
        <mesh name="lockbox6" geometry={nodes.lockbox6.geometry} material={materials.phong_5} />
        <mesh name="lockbox7" geometry={nodes.lockbox7.geometry} material={materials.phong_5} />
      </group>
    </group>
  </group>
  )
}

export default NewsSilo