import React, {useMemo, useRef} from "react";
import { useAnimations, useGLTF } from "@react-three/drei";
import PropTypes from "prop-types";
import useTriggerAnimation from "../../../hooks/useTriggerAnimation";
import useSetFirstAnimationFrame from "../../../hooks/useSetFirstAnimationFrame";
import IpaEndIcon1 from "../models/IpaEndIcon1";
import useNewMaterials from "../../../hooks/useNewMaterials";

export default function IpaEndIconOneHolder({ ...props }) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(
    "/SceneIPA/ipa-end-icon-1-transformed.glb"
  );
  const specificMaterials = useMemo(() => [
    {name: 'phong_1', color: 'blue2' },
    {name: 'phong_6', color: 'blue2' },
    {name: 'phong_7', color: 'blue2' },
    {name: 'phong_5', color: 'blue1' },
    {name: 'number_material_0', replaceWith: 'number_material_9', setMaterialColor: 0xffffff },
  ], [])
  const newMaterials = useNewMaterials(materials, {
    baseMaterial: 'blue4',
    specificMaterials: specificMaterials,
  });
  const { actions } = useAnimations(animations, group);

  useSetFirstAnimationFrame('appear', actions);
  useTriggerAnimation(props.in, "appear", actions);

  return (
    <group
      ref={group}
      position={props.position}
      dispose={null}
      visible={props.show}
      scale={props.scale}
    >
      <IpaEndIcon1 materials={newMaterials} nodes={nodes} />
    </group>
  );
}

IpaEndIconOneHolder.propTypes = {
  show: PropTypes.bool,
  in: PropTypes.number,
  scale: PropTypes.number,
  position: PropTypes.array,
};

IpaEndIconOneHolder.defaultProps = {
  show: true,
  scale: null,
  position: null,
};

useGLTF.preload("/SceneIPA/ipa-end-icon-1-transformed.glb");

