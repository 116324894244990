/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useMemo, useRef} from "react";
import { useAnimations, useGLTF } from "@react-three/drei";
import PropTypes from "prop-types";
import ShareStack from "../models/ShareStack";
import useSetFirstAnimationFrame from "../../../hooks/useSetFirstAnimationFrame";
import useTriggerAnimation from "../../../hooks/useTriggerAnimation";
import useNewMaterials from "../../../hooks/useNewMaterials";

export default function ShareStackHolder({ ...props }) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(
    "/GlobalModels/share-stack-transformed.glb"
  );

  const specificMaterials = useMemo(() => [
    {name: 'top_number', replaceWith: `number_material_${props.number}`},
  ], [props.number])
  const excludeMaterials = useMemo(() => [
    'number_material_1', 'number_material_2', 'number_material_3', 'number_material_4', 'number_material_5', 'number_material_6', 'number_material_7', 'number_material_8', 'number_material_9'
  ], [])
  const newMaterials = useNewMaterials(materials, {
    baseMaterial: `${props.color}1`,
    specificMaterials: specificMaterials,
    excludeMaterials: excludeMaterials,
  });

  const { actions } = useAnimations(animations, group);

  useSetFirstAnimationFrame("stacks", actions);
  useTriggerAnimation(props.in, "appears", actions);
  useTriggerAnimation(props.stacks, "stacks", actions);
  useTriggerAnimation(props.removeTop, "remove-top", actions);
  useTriggerAnimation(props.removeAll, "remove-all", actions);

  // let newMaterials = {}
  //
  // for (const [key] of Object.entries(materials)) {
  //   if (key === 'number_material_0') {
  //     newMaterials[key] = materials[`number_material_${props.number}`];
  //   } else if (props.color !== "white" && key === 'standardSurface2') {
  //     newMaterials[key] = colorMaterials[props.color];
  //   } else {
  //     newMaterials[key] = materials[key];
  //   }
  // }

  return (
    <group ref={group} {...props} dispose={null} visible={props.show}>
      <ShareStack nodes={nodes} materials={newMaterials} />
    </group>
  );
}

ShareStackHolder.propTypes = {
  show: PropTypes.bool,
  in: PropTypes.number,
  stacks: PropTypes.number,
  removeTop: PropTypes.number,
  removeSecond: PropTypes.number,
  removeAll: PropTypes.number,
  number: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]),
  color: PropTypes.string,


};

ShareStackHolder.defaultProps = {
  show: true,
  in: null,
  stacks: null,
  removeTop: null,
  removeSecond: null,
  removeAll: null,
  number: 0,
  color: "grey",

};

useGLTF.preload("/GlobalModels/share-stack-transformed.glb");

