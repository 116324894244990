import React, {useMemo, useRef} from "react";
import {useAnimations, useGLTF} from "@react-three/drei";
import PropTypes from "prop-types";
import useTriggerAnimation from "../../../hooks/useTriggerAnimation";
import useSetFirstAnimationFrame from "../../../hooks/useSetFirstAnimationFrame";
import AdditionCube from "../models/AdditionCube";
import useNewMaterials from "../../../hooks/useNewMaterials";

export default function AdditionCubeHolder({...props}) {
  const group = useRef();
  const {nodes, materials, animations} = useGLTF(
    "/SceneIntro/addition-cube-transformed.glb"
  );
  const {actions} = useAnimations(animations, group);
  const specificMaterials = useMemo(() => [
    {name: 'phong_7', color: 'blue4'},
  ], []);
  const newMaterials = useNewMaterials(materials, {
    baseMaterial: 'blue1',
    specificMaterials: specificMaterials
  })

  useSetFirstAnimationFrame('p-to-cube', actions);
  useTriggerAnimation(props.pToCube, "p-to-cube", actions, null, false, 1.5);
  useTriggerAnimation(props.cubeToIcon, "cube-to-icon", actions, null, false, 1.5);
  useTriggerAnimation(props.iconToPineapple, "icon-to-pineapple", actions, null, false, 1.5);
  useTriggerAnimation(props.pineappleToCube, "pineapple-to-cube", actions, null, false, 1.5);


  return (
      <group
        ref={group}
        position={props.position}
        dispose={null}
        visible={props.show}
        scale={props.scale}
      >
        <AdditionCube materials={newMaterials} nodes={nodes} />
      </group>
  );
}

AdditionCubeHolder.propTypes = {
  show: PropTypes.bool,
  pToCube: PropTypes.number,
  cubeToIcon: PropTypes.number,
  iconToPineapple: PropTypes.number,
  pineappleToCube: PropTypes.number,
  scale: PropTypes.number,
  position: PropTypes.array,
};

AdditionCubeHolder.defaultProps = {
  show: true,
  pToCube: null,
  cubeToIcon: null,
  iconToPineapple: null,
  pineappleToCube: null,
  scale: null,
  position: null,
};

useGLTF.preload("/SceneIntro/addition-cube-transformed.glb");

