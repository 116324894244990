import React, { useMemo, useRef } from "react";
import { useAnimations, useGLTF } from "@react-three/drei";
import PropTypes from "prop-types";
import useTriggerAnimation from "../../../hooks/useTriggerAnimation";
import CafeUser from "../models/CafeUser";
import useSetFirstAnimationFrame from "../../../hooks/useSetFirstAnimationFrame";
import useNewMaterials from "../../../hooks/useNewMaterials";

export default function CafeUserHolder({ ...props }) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/SceneIPA/cafe-user.glb");

  const specificMaterials = useMemo(
    () => [
      { name: "blinn1_text", color: "pink3" },
      { name: "phong_pizza_base", color: "yellow1" },
      { name: "phong_pizza_cheese", color: "yellow1" },
      { name: "phong_pizza_pineapple", color: "green2" },
      { name: "phong_pizza_pepperoni", color: "pink2" },
      { name: "phong_14", color: "blue1" },
    ],
    []
  );
  const newMaterials = useNewMaterials(materials, {
    baseMaterial: "grey3",
    specificMaterials: specificMaterials,
  });
  const { actions } = useAnimations(animations, group);

  // loop animation
  useTriggerAnimation(props.idle, "idle", actions, props.idleEnd, true);
  useTriggerAnimation(props.toDevices, "fade-to-devices", actions);
  useTriggerAnimation(props.showPointer, "pointer-appear", actions);
  useTriggerAnimation(props.newsToPurchase, "news-to-purchase", actions);

  // Set in animation to its start position on initial render
  useSetFirstAnimationFrame("fade-to-devices", actions);

  return (
    <group
      ref={group}
      position={props.position}
      dispose={null}
      visible={props.show}
      scale={props.scale}
    >
      <CafeUser materials={newMaterials} nodes={nodes} />
    </group>
  );
}

CafeUserHolder.propTypes = {
  show: PropTypes.bool,
  idle: PropTypes.number,
  idleEnd: PropTypes.number,
  position: PropTypes.array,
  scale: PropTypes.number,
  toDevices: PropTypes.number,
  newsToPurchase: PropTypes.number,
};

CafeUserHolder.defaultProps = {
  show: true,
  idle: null,
  idleEnd: null,
  position: null,
  scale: null,
  toDevices: null,
  newsToPurchase: null,
};

useGLTF.preload("/SceneIPA/cafe-user.glb");

