import React, {useLayoutEffect, useRef} from "react";
import styled from "styled-components";
import {gsap} from "gsap";
import {textTimeline, textTimelineMobile} from "./Text";
import PropTypes from "prop-types";

const Holder = styled.div``;

const CanvasHolder = styled.div`
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    //background: linear-gradient(-135deg, #e2cffc 20%, #ccf5ff 50%, #fffee0 80%);
    //background: linear-gradient(-90deg, #ffe0fb 10%, #ecdffd 40%, #ecdffd 60%, #d7f6ff 90%);
    background: linear-gradient(-115deg, #e2cffc 30%, #cfecfc 70%);
    //background-color: #e2cffc;
`;

function GsapHolder(props) {
  const comp = useRef();
  const {
    id, setProgress, setTextIndex, textContent, children
  } = props

  useLayoutEffect(() => {

    let mm = gsap.matchMedia(),
      breakPoint = 992;

    mm.add({

      // set up any number of arbitrarily-named conditions. The function below will be called when ANY of them match.
      isDesktop: `(min-width: ${breakPoint}px)`,
      isMobile: `(max-width: ${breakPoint - 1}px)`,

    }, (context) => {

      // context.conditions has a boolean property for each condition defined above indicating if it's matched or not.
      let { isDesktop, isMobile } = context.conditions;

      let tl = gsap
        .timeline({
          id: id,
          paused: true,
          onUpdate() {
            // console.log("progress:", this.progress().toFixed(8));
            setProgress(this.progress());
          },
          onInterrupt() {
            console.log("interrupted!");
          },
          onStart() {
            console.log("started!");
          }
        });

      if(isDesktop) {
        // Add all the text popups.
        // This sets the duration of the whole animation to be equal to the number of text popups.
        textContent.forEach((textObject, i) => {
          const first = i === 0;
          const last = i === textContent.length - 1;
          const secondToLast = i === textContent.length - 2;
          let position = '-=2';
          if (first) {
            position = 0;
          } else if (last) {
            position = '-=1';
          }
          tl.add(
            textTimeline(
              `.text-pop-up-${i + 1}`,
              last,
              secondToLast
            ),
            position
          );
        });
        tl.to('#intro-panel', {
          duration: 1,
          opacity: 0,
        }, 0)
      } else if(isMobile) {
        // Add all the text popups.
        // This sets the duration of the whole animation to be equal to the number of text popups.
        textContent.forEach((textObject, i) => {
          const first = i === 0;
          const last = i === textContent.length - 1;
          let position = '-=1';
          if (first) {
            position = 0;
          }
          tl.add(
            textTimelineMobile(
              `.text-pop-up-${i + 1}`,
              last
            ),
            position
          );
        });
        tl.to('#intro-panel', {
          duration: 1,
          opacity: 0,
        }, 0)
      }

      return () => {
        // optionally return a cleanup function that will be called when none of the conditions match anymore (after having matched)
        // it'll automatically call context.revert() - do NOT do that here . Only put custom cleanup code here.
        console.log("cleanup");
        setTextIndex(0);
      }
    }, comp);
  }, [id, setProgress, setTextIndex, textContent]);

  

  return (
    <Holder ref={comp}>
      <CanvasHolder>{children}</CanvasHolder>
    </Holder>
  );
}

GsapHolder.propTypes = {
  id: PropTypes.string.isRequired,
  setProgress: PropTypes.func.isRequired,
  setTextIndex: PropTypes.func.isRequired,
  textContent: PropTypes.array.isRequired,
};

export default GsapHolder;

