import React from "react";
import styled from "styled-components";
import HomeMenu from "../components/navigation/HomeMenu";
import BackgroundImage from "../assets/images/homepage-bg.png";

const Holder = styled.div`
  position: relative;
  min-height: 100vh;
  padding: 2rem 1rem;
  background: #e2cffc;

  @media (${(props) => props.theme.breakpoints.lg}) {
    padding: 12vh 1.5rem 4vh;
    padding-left: calc(
      100vw - 100%
    ); // to avoid jumping scrollbar on sections open/close
  }

  .bg-image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    object-position: top center;
    filter: blur(15px);
  }
`;

const Inner = styled.div`
  position: relative;
  z-index: 1;
  max-width: 734px;
  margin: 0 auto;
  text-align: center;

  > p {
    margin-bottom: 1.5rem;

    @media (${(props) => props.theme.breakpoints.lg}) {
      margin-bottom: 2.5rem;
    }
  }

  strong {
    display: block;
  }
`;

function Home() {
  return (
    <Holder>
      <img className="bg-image" src={BackgroundImage} alt="" />
      <Inner>
        <h1>Explaining Interoperable Private Attribution</h1>
        <p>
          Interoperable Private Attribution (IPA) is a new way of measuring ads
          where companies don't need to learn which apps and websites you
          visited.
          <strong>
            Explore this set of 3D explainers to learn the foundations of IPA.
          </strong>
          <strong>Get started at the top or skip ahead:</strong>
        </p>
        <HomeMenu />
      </Inner>
    </Holder>
  );
}

export default Home;

