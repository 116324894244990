import {text} from "./text";

export const keyframes = [
  { time: 0, position: [10, 5, 25], lookAt: [0, 0.36, 5],shakeIntensity: 0.2 },

  // Zoom to bike
  { time: text[1].adjustedIn, position: [0, 0.36, 8], mobilePosition: [0, 0.36, 12], lookAt: [0, 0.36, 0], shakeIntensity: 0.02 },

  // Focus on lock
  { time: text[2].adjustedIn, position: [0.55, 0.4, 5.31], mobilePosition: [0.75, 0.43, 5.51],lookAt: [0.275, 0.36, 4.98], shakeIntensity: 0.005 },
  { time: text[22].adjustedIn, position: [0.55, 0.4, 5.31], mobilePosition: [0.75, 0.43, 5.51],lookAt: [0.275, 0.36, 4.98], shakeIntensity: 0.005 },
  // Up to share set
  { time: text[23].adjustedIn, position: [0.55, 0.43, 5.31], mobilePosition: [0.75, 0.45, 5.61], lookAt: [0.275, 0.43, 4.98], shakeIntensity: 0.005 },
  { time: text[25].adjustedIn, position: [0.55, 0.43, 5.31], mobilePosition: [0.75, 0.45, 5.61], lookAt: [0.275, 0.43, 4.98], shakeIntensity: 0.005 },
  // Back to lock
  { time: text[26].adjustedIn, position: [0.55, 0.4, 5.31], mobilePosition: [0.75, 0.43, 5.61], lookAt: [0.275, 0.36, 4.98], shakeIntensity: 0.005 },
  { time: text[27].adjustedIn, position: [0.55, 0.4, 5.31], mobilePosition: [0.75, 0.43, 5.61], lookAt: [0.275, 0.36, 4.98], shakeIntensity: 0.005 },
  // Up to accomodate 3 sets
  { time: text[28].adjustedIn, position: [0.55, 0.4, 5.31], mobilePosition: [0.75, 0.43, 5.61], lookAt: [0.275, 0.4, 4.98], shakeIntensity: 0.005 },
  { time: text[37].adjustedIn, position: [0.55, 0.4, 5.31], mobilePosition: [0.75, 0.43, 5.61], lookAt: [0.275, 0.4, 4.98], shakeIntensity: 0.005 },

  // Zoom out
  { time: text[38].adjustedIn, position: [0, 1, 14], lookAt: [0, 0.36, 5], shakeIntensity: 0.08 },
  { time: text[39].adjustedIn, position: [0, 3, 16], lookAt: [0, 0, 5], shakeIntensity: 0.1 },

  // End scene still zoomed out 
  {time: text[40].adjustedIn, position: [0, 3, 16], lookAt: [0, 0, 5], shakeIntensity: 0.1 }
];

