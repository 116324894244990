import React from 'react'

import useTrigger from '../../hooks/useTrigger';
import BrowsersHolder from './holders/BrowsersHolder';
import {text, interval} from "../../data/intro-data/text";
import {somethingVerySmall} from "../../helpers/scrollHelpers";

const BrowsersGroup = () => {
  const showBrowser = useTrigger(
    text[0].adjustedIn,
    text[4].adjustedIn
  );

  return (
    <group visible={showBrowser}>
      <BrowsersHolder
        position={[0, 10, 0]}
        in={text[0].adjustedIn}
        appsPile={text[0].adjustedIn + interval/2}
        appsFly={text[1].adjustedIn}
        appsLock={text[2].adjustedIn - somethingVerySmall}
        scale={1.7} />
    </group>
  )
}

export default BrowsersGroup