/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";

export default function BikeLock({nodes, materials}) {
  return (
    <group>
        <group name="Bike_Lock_2" position={[0.211235, 0.352342, 0.040343]} rotation={[-0.039419, 0.688, 0.038136]} scale={10}>
          <group name="mesh_lock">
            <group name="null1" position={[0.007493, 0.022124, -0.012718]} rotation={[0, 0, 0.015614]}>
              <group name="animated_grp_lock_R" position={[-0.00749, -0.022124, 0.012718]}>
                <mesh name="mesh_lock6" geometry={nodes.mesh_lock6.geometry} material={materials.material_lock_blue_1} />
                <mesh name="cable_R" geometry={nodes.cable_R.geometry} material={materials.material_lock_blue_1} position={[-0.000392, -0.000085, 0.001541]} />
                <mesh name="locky_bit" geometry={nodes.locky_bit.geometry} material={materials.material_lock_blue_1} scale={0.1} />
              </group>
            </group>
            <group name="animated_grp_lock_L">
              <group name="spiiners_Grp" position={[-0.000392, -0.000085, 0.001541]}>
                <mesh name="s1" geometry={nodes.s1.geometry} material={materials.material_lock_blue_1} rotation={[-1.884956, 0, 0]}>
                  <mesh name="s1_numbers" geometry={nodes.s1_numbers.geometry} material={materials.pasted__material_numbers} position={[0.000392, 0.000085, -0.001541]} scale={0.1} />
                </mesh>
                <mesh name="s2" geometry={nodes.s2.geometry} material={materials.material_lock_blue_1} position={[0.001733, 0.000001, 0.000003]} rotation={[1.884956, 0, 0]}>
                  <mesh name="s2_numbers" geometry={nodes.s2_numbers.geometry} material={materials.pasted__material_numbers} position={[-0.001341, 0.000085, -0.001541]} scale={0.1} />
                </mesh>
                <mesh name="s3" geometry={nodes.s3.geometry} material={materials.material_lock_blue_1} position={[0.003454, -0.000001, 0.000004]} rotation={[Math.PI, 0, 0]}>
                  <mesh name="s3_numbers" geometry={nodes.s3_numbers.geometry} material={materials.pasted__material_numbers} position={[-0.003063, 0.000085, -0.001541]} scale={0.1} />
                </mesh>
                <mesh name="s4" geometry={nodes.s4.geometry} material={materials.material_lock_blue_1} position={[0.005141, 0, 0]}>
                  <mesh name="s4_numbers" geometry={nodes.s4_numbers.geometry} material={materials.pasted__material_numbers} position={[-0.004749, 0.000085, -0.001541]} scale={0.1} />
                </mesh>
              </group>
              <mesh name="mesh_lock1" geometry={nodes.mesh_lock1.geometry} material={materials.material_lock_blue_1}>
                <mesh name="mesh_lock3" geometry={nodes.mesh_lock3.geometry} material={materials.lambert2} />
              </mesh>
              <mesh name="mesh_lock5" geometry={nodes.mesh_lock5.geometry} material={materials.material_lock_blue_1} />
              <mesh name="cable_L" geometry={nodes.cable_L.geometry} material={materials.material_lock_blue_1} position={[-0.000392, -0.000085, 0.001541]} />
              <mesh name="mesh_lock2" geometry={nodes.mesh_lock2.geometry} material={materials.material_lock_blue_1}>
                <mesh name="mesh_lock4" geometry={nodes.mesh_lock4.geometry} material={materials.lambert2} />
              </mesh>
            </group>
            <mesh name="cable_Static" geometry={nodes.cable_Static.geometry} material={materials.material_lock_blue_1} />
          </group>
        </group>
      </group>
  
  );
}

