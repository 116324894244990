import styled, { keyframes } from "styled-components";
import {useBoundStore} from "../stores/useBoundStore";
import {useEffect} from "react";
import useScrollTrigger from "../hooks/useScrollTrigger";

const slideIn = keyframes`
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
`;

const ModalContainer = styled.div`
  position: fixed;
  z-index: 10000000;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  animation: ${slideIn} 0.35s ease-in-out;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  background-color: white;
  @media( min-width: 768px ) {
    width: 50vw;
  }
  button {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
`;

const ModalContent = styled.div`
  width: 100%;
  padding: 1rem;
  @media( min-width: 768px ) {
    padding: 2rem;
  }
  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

const Modal = () => {
  const { st } = useScrollTrigger();
  const modalVisible = useBoundStore((state) => state.modalVisible);
  const hideModal = useBoundStore((state) => state.hideModal);
  const modalContent = useBoundStore((state) => state.modalContent);

  useEffect(() => {
    st.normalizeScroll(!modalVisible);
  }, [modalVisible, st]);

  if (!modalVisible) return null;
  return (
    <ModalContainer onScroll={() => console.log('scroll modal')}>
      <button className="smallGreen" onClick={() => {hideModal()}}>Close</button>
      <ModalContent>
        {modalContent}
      </ModalContent>
    </ModalContainer>
  );
};

export default Modal;

