/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useRef} from "react";
import {Shadow, useGLTF} from "@react-three/drei";
import PropTypes from "prop-types";
import Table from "../models/Table";
import useNewMaterials from "../../../hooks/useNewMaterials";

export default function TableHolder({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/GlobalModels/table-transformed.glb");
  const newMaterials = useNewMaterials(materials, {
    baseMaterial: `${props.color}3`
  });
  return (
    <group ref={group} {...props} dispose={null} visible={props.show}>
      <Table nodes={nodes} materials={newMaterials} />
      <Shadow color="purple" position-y={props.shadowPosition} opacity={0.15} scale={1.25}/>
    </group>
  );
}

TableHolder.propTypes = {
  show: PropTypes.bool,
  color: PropTypes.string,
  shadowPosition: PropTypes.number,
};

TableHolder.defaultProps = {
  show: true,
  color: "grey",
  shadowPosition: 0,
};

useGLTF.preload("/GlobalModels/table-transformed.glb");

