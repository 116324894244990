import React, {useMemo, useRef} from "react";
import { useAnimations, useGLTF } from "@react-three/drei";
import PropTypes from "prop-types";
import useTriggerAnimation from "../../../hooks/useTriggerAnimation";
import useSetFirstAnimationFrame from "../../../hooks/useSetFirstAnimationFrame";
import MultiplicationCube from "../models/MultiplicationCube";
import useNewMaterials from "../../../hooks/useNewMaterials";

export default function MultiplicationCubeHolder({ ...props }) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(
    "/SceneIntro/multiplication-cube-transformed.glb"
  );
  const { actions } = useAnimations(animations, group);
  const specificMaterials = useMemo(() => [
    {name: 'phong_7', color: 'blue4'},
  ], []);
  const newMaterials = useNewMaterials(materials, {
    baseMaterial: 'blue1',
    specificMaterials: specificMaterials
  })

  useSetFirstAnimationFrame('a-to-cube', actions, null, false, 1.5);
  useTriggerAnimation(props.aToCube, "a-to-cube", actions, null, false, 1.5);
  useTriggerAnimation(props.cubeToIcon, "cube-to-icon", actions, null, false, 1.5);
  useTriggerAnimation(props.leave, "leave", actions, null, false, 1.5);
  useTriggerAnimation(props.return, "return", actions, null, false, 1.5);


  return (
    <group
      ref={group}
      position={props.position}
      dispose={null}
      visible={props.show}
      scale={props.scale}
    >
      <MultiplicationCube materials={newMaterials} nodes={nodes} />
    </group>
  );
}

MultiplicationCubeHolder.propTypes = {
  show: PropTypes.bool,
  aToCube: PropTypes.number,
  cubeToIcon: PropTypes.number,
  leave: PropTypes.number,
  return: PropTypes.number,
  scale: PropTypes.number,
  position: PropTypes.array,
};

MultiplicationCubeHolder.defaultProps = {
  show: true,
  aToCube: null,
  cubeToIcon: null,
  leave: null,
  return: null,
  scale: null,
  position: null,
};

useGLTF.preload("/SceneIntro/multiplication-cube-transformed.glb");

