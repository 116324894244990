import React from "react";
import styled from "styled-components";
import {Outlet} from "react-router-dom";

const Holder = styled.div`
  position: relative;
`;

function Layout() {

  return (
    <Holder>
      <Outlet />
    </Holder>
  );
}

export default Layout;

