import React from 'react';
import ShareStackHolder from "../shared-models/holders/ShareStackHolder";
import useTrigger from "../../hooks/useTrigger";
import {text} from "../../data/multiplication-data/text";

function StackingSharesGroup() {
  const show = useTrigger(text[18].adjustedIn, text[20].adjustedIn)
  
  return (
    <group visible={show}>
      <ShareStackHolder
        position={[0.1, 1, 0.5]}
        stacks={text[18].adjustedIn}
        number={3}
        color="green"
      />
      <ShareStackHolder
        position={[-0.3, 1, -0.3]}
        stacks={text[18].adjustedIn}
        number={7}
        color="green"
      />
      <ShareStackHolder
        position={[0.3, 1, -0.25]}
        stacks={text[18].adjustedIn}
        number={4}
        color="green"
      />
    </group>
  )
}

export default StackingSharesGroup;