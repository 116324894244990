import React from "react";
import PropTypes from "prop-types";
import GlobalUserHolder from "../shared-models/holders/GlobalUserHolder";

// TODO: rename since it's no longer a pair
function UserPair(props) {
  return (
    <group position={props.position} rotation={props.rotation}>
      <GlobalUserHolder
        position={[0, 0, 0]}
        idleIn={props.idleIn}
        idleEnd={props.idleOut}
      />
    </group>
  );
}

UserPair.propTypes = {
  position: PropTypes.array,
  rotation: PropTypes.array,
};

UserPair.defaultProps = {
  position: [0, 0, 0],
  rotation: [0, 0, 0],
};

export default UserPair;

