/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useMemo, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

import useTriggerAnimation from "../../../hooks/useTriggerAnimation";
import PropTypes from "prop-types";
import BinarySpeechBubble2 from "../models/Binary-speech-bubble-2";
import useSetFirstAnimationFrame from "../../../hooks/useSetFirstAnimationFrame";
import useNewMaterials from "../../../hooks/useNewMaterials";

export default function SpeechBubbleHolder2({ ...props }) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(
    "/SceneIPA/binary-speech-bubble-2.glb"
  );
  const excludeMaterials = useMemo(
    () => [
      "shrdScrt_phong_1",
      "shrdScrt_number_material_1",
      "shrdScrt_number_material_0",
    ],
    []
  );
  const newMaterials = useNewMaterials(materials, {
    baseMaterial: "green1",
    excludeMaterials: excludeMaterials,
  });
  const { actions } = useAnimations(animations, group);

  //  Set in animation to its start position on initial render
  useSetFirstAnimationFrame("enter", actions);

  useTriggerAnimation(props.in, "enter", actions);
  useTriggerAnimation(props.numberToShares, "numbers-to-shares", actions);
  useTriggerAnimation(props.sharesDrop, "shares-drop", actions);

  return (
    <group
      ref={group}
      position={props.position}
      dispose={null}
      visible={props.show}
      scale={props.scale}
    >
      <BinarySpeechBubble2 nodes={nodes} materials={newMaterials} />
    </group>
  );
}

SpeechBubbleHolder2.propTypes = {
  in: PropTypes.number,
  sharesDrop: PropTypes.number,
  numberToShares: PropTypes.number,
  position: PropTypes.array,
  scale: PropTypes.number,
};

SpeechBubbleHolder2.defaultProps = {
  show: true,
  in: null,
  sharesDrop: null,
  numberToShares: null,
  position: null,
  scale: null,
};

useGLTF.preload("/SceneIPA/binary-speech-bubble-2.glb");

