/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";

export default function EyeIcon({ nodes, materials }) {
  return (
    <group>
      <group name="Grp_Eye">
        <group name="eye" rotation={[0, 0.746835, 0]} scale={0}>
          <mesh
            name="eye_mesh"
            geometry={nodes.eye_mesh.geometry}
            material={materials.lambert1}
          />
        </group>
        <group name="number_0">
          <mesh
            name="number_0_mesh"
            geometry={nodes.number_0_mesh.geometry}
            material={materials.numbers_shader}
          />
        </group>
        <group
          name="number_1"
          position={[0.073032, 0.517307, 0]}
          rotation={[0, -0.694551, 0]}
          scale={0}
        >
          <mesh
            name="number_1_mesh"
            geometry={nodes.number_1_mesh.geometry}
            material={materials.numbers_shader}
          />
        </group>
        <group name="eye_icon_top">
          <mesh
            name="eye_mesh3"
            geometry={nodes.eye_mesh3.geometry}
            material={materials.lambert1}
            position={[-2.330597, 3.2226, 0]}
            scale={0.346591}
          />
        </group>
      </group>
    </group>
  );
}

