/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import PropTypes from "prop-types";
import EqualsIcon from "../models/EqualsIcon";
import useNewMaterials from "../../../hooks/useNewMaterials";
export default function EqualsIconHolder({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF(
    "/GlobalModels/equals-icon-transformed.glb"
  );
  const newMaterials = useNewMaterials(materials, {baseMaterial: props.color});

  return (
    <group
      ref={group}
      position={props.position}
      rotation={props.rotation}
      dispose={null}
      visible={props.show}
      scale={props.scale}
    >
      <EqualsIcon nodes={nodes} materials={newMaterials} />
    </group>
  );
}

EqualsIconHolder.propTypes = {
  show: PropTypes.bool,
  scale: PropTypes.number,
  position: PropTypes.array,
  color: PropTypes.string,
};

EqualsIconHolder.defaultProps = {
  show: true,
  scale: null,
  position: [],
  color: "grey3",
};

useGLTF.preload("/GlobalModels/equals-icon-transformed.glb");

