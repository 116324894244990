/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";

export default function Helpers({ nodes, materials }) {
  return (
    <group>
        <group name="grp_char">
          <skinnedMesh name="char_legs" geometry={nodes.char_legs.geometry} material={materials.standardSurface2} skeleton={nodes.char_legs.skeleton} />
          <skinnedMesh name="char_torso" geometry={nodes.char_torso.geometry} material={materials.standardSurface2} skeleton={nodes.char_torso.skeleton} />
          <skinnedMesh name="char_head_cube" geometry={nodes.char_head_cube.geometry} material={materials.standardSurface2} skeleton={nodes.char_head_cube.skeleton} />
          <skinnedMesh name="typeMesh1_interogation1" geometry={nodes.typeMesh1_interogation1.geometry} material={materials.standardSurface3} skeleton={nodes.typeMesh1_interogation1.skeleton} />
          <skinnedMesh name="char_arms" geometry={nodes.char_arms.geometry} material={materials.standardSurface2} skeleton={nodes.char_arms.skeleton} />
        </group>
        <primitive object={nodes.Group} />
      </group>
  );
}

