import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useLocalStorage } from "react-use";

import { ReactComponent as EyeIcon } from "../../assets/svg/eye-icon.inline.svg";

const Holder = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  width: 100%;
  padding: 0.5em 1em;
  border-radius: 0.25em;
  border: 1px solid var(--blue);
  background: var(--blue);
  color: white;
  font-size: 0.875em;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  @media (${(props) => props.theme.breakpoints.md}) {
    padding: 0.75em 1em;
  }

  &.viewed {
    background: white;
    color: var(--blue);

    svg {
      width: 1.25rem;
    }
  }
`;

function NavigationLink({ path }) {
  const [viewedSections] = useLocalStorage("viewedSections", []);

  const wasViewed = (path) => {
    return viewedSections.some((section) => section === path.toString());
  };

  const wasLastViewed = (path) => {
    if (!wasViewed(path)) {
      return false;
    }

    // check if path is the last one added to viewedSections arr
    return viewedSections.length === 1
      ? viewedSections[0] === path
      : viewedSections[viewedSections.length - 1] === path;
  };

  return (
    <Holder to={path} className={wasViewed(path) && "viewed"}>
      {wasLastViewed(path) ? (
        <>Watch again</>
      ) : wasViewed(path) ? (
        <>
          <EyeIcon /> Viewed
        </>
      ) : (
        <>View explainer</>
      )}
    </Holder>
  );
}

export default NavigationLink;

