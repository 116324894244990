import React from 'react'

const IpaEndIcon2 = ({nodes, materials}) => {
  return (
    <group>
    <group name="null1">
      <group name="Icon_Ipa_2_Helper" position={[0, -1.073975, 0.141722]}>
        <group name="Base_1">
          <mesh name="pDisc3" geometry={nodes.pDisc3.geometry} material={materials.phong_3} position={[0, 1.073975, -0.141722]} />
        </group>
        <group name="group1" position={[0, 0, -0.128066]} scale={[1, 1, 0.501357]}>
          <mesh name="char_arms1" geometry={nodes.char_arms1.geometry} material={materials.phong_5} />
          <mesh name="char_head_cube1" geometry={nodes.char_head_cube1.geometry} material={materials.phong_5} position={[0.010429, 0.005187, 0]}>
            <mesh name="typeMesh1_interogation1" geometry={nodes.typeMesh1_interogation1.geometry} material={materials.phong_3} position={[-0.069011, 1.296128, 0.103874]} rotation={[0.023266, -0.033834, -0.051155]} />
          </mesh>
          <mesh name="char_arms3" geometry={nodes.char_arms3.geometry} material={materials.phong_5} />
          <mesh name="char_torso1" geometry={nodes.char_torso1.geometry} material={materials.phong_5} position={[0, 0, -0.005058]} />
        </group>
        <mesh name="polySurface1" geometry={nodes.polySurface1.geometry} material={materials.phong_5} position={[0, 0, -0.011864]} />
      </group>
    </group>
  </group>
  )
}

export default IpaEndIcon2