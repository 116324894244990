import React from "react";
import { useBoundStore } from "../stores/useBoundStore";
import { text } from "../data/secret-data/text";
import {audio } from "../data/secret-data/audio";
import { keyframes } from "../data/secret-data/camera";
import AnimatedPageHolder from "../components/common/AnimatedPageHolder";
import SecretScene from "../components/secret-page/SecretScene";

const timelineId = "secret-timeline";
const progressName = "secretProgress";

function Secret() {
  const {
    secretProgress,
    setSecretProgress,
    secretTextIndex,
    setSecretTextIndex,
  } = useBoundStore();

  // useEffect(() => {
  //   console.log("secretProgress", secretProgress);
  //   console.log("secretTextIndex", secretTextIndex);
  // }, [secretProgress, secretTextIndex]);

  return (
    <AnimatedPageHolder
      setProgress={setSecretProgress}
      setTextIndex={setSecretTextIndex}
      text={text}
      keyframes={keyframes}
      progressName={progressName}
      progress={secretProgress}
      currentTextIndex={secretTextIndex}
      introText={
        <>
          <span className="intro-label">Explainer 3</span>
          <h1>Secret Sharing</h1>
          <p>
            In IPA, information is first secret-shared before it ever leaves the
            browser or device. This helps keep it safe, and ensures that no
            party can learn which apps and websites an individual is visiting.
          </p>
        </>
      }
      cameraKeyframes={keyframes}
      pageIndex={2}
      textContent={text}
      audioContent={audio}
      uniqueTimelineId={timelineId}
    >
      <SecretScene />
    </AnimatedPageHolder>
  );
}

export default Secret;
