/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";

export default function Table({ nodes, materials }) {
  return (
    <group name="table">
        <mesh name="table_asset" geometry={nodes.table_asset.geometry} material={materials.phong_5} />
        <mesh name="table_asset1" geometry={nodes.table_asset1.geometry} material={materials.phong_3} />
        <mesh name="table_asset2" geometry={nodes.table_asset2.geometry} material={materials.phong_5} position={[0.002145, -0.054583, -0.00797]} scale={[1.290318, 1.040783, 1.290318]} />
        <mesh name="table_asset3" geometry={nodes.table_asset3.geometry} material={materials.phong_3} />
      </group>
  );
}

