import React from 'react'

const Browsers = ({nodes, materials}) => {
  return (
    <group>
        <group name="Browser_1">
          <group name="grp_laptop" position={[0, -1.003926, 0.969066]}>
            <group name="lock" position={[0.000831, 1.126651, -0.801241]} rotation={[0.019087, -0.000881, -0.002561]} scale={2.770914}>
              <group name="group14">
                <mesh name="lock2" geometry={nodes.lock2.geometry} material={materials.red_lock_phong_4} />
                <mesh name="lock1" geometry={nodes.lock1.geometry} material={materials.red_lock_phong_4}>
                  <mesh name="hole" geometry={nodes.hole.geometry} material={materials.red_lock_phong_8} />
                </mesh>
              </group>
            </group>
            <mesh name="hinge" geometry={nodes.hinge.geometry} material={materials.phong_5} rotation={[0.261799, 0, 0]}>
              <group name="browser_1" position={[0, 0.815748, -1.102239]} rotation={[-0.261799, 0, 0]} scale={0.016483}>
                <mesh name="browser_navbar_1" geometry={nodes.browser_navbar_1.geometry} material={materials.phong_1}>
                  <mesh name="browser_buttons_8" geometry={nodes.browser_buttons_8.geometry} material={materials.phong_3} position={[60.92415, 52.805443, -2.318395]} rotation={[Math.PI / 2, 0, 0]} scale={[295.338074, 109.9929, 295.338074]} />
                  <mesh name="browser_buttons_9" geometry={nodes.browser_buttons_9.geometry} material={materials.phong_3} position={[71.29816, 52.805443, -2.318395]} rotation={[Math.PI / 2, 0, 0]} scale={[295.338074, 109.9929, 295.338074]} />
                </mesh>
                <mesh name="browser_page_1" geometry={nodes.browser_page_1.geometry} material={materials.phong_3}>
                  <group name="app_page_1" position={[0, 2.331663, -6.469947]} scale={0.191862}>
                    <group name="mouse_pointer" position={[-26.301592, -6.089962, 6.097478]} rotation={[-0.120225, -0.208643, 1.042791]} scale={[927.9343, 927.9343, 199.513153]}>
                      <mesh name="mouse_pointer_1" geometry={nodes.mouse_pointer_1.geometry} material={materials.phong_1} />
                      <mesh name="mouse_pointer_2" geometry={nodes.mouse_pointer_2.geometry} material={materials.phong_3} />
                    </group>
                    <mesh name="app_page_bg_1" geometry={nodes.app_page_bg_1.geometry} material={materials.phong_1} position={[0, 0, -0.992694]} scale={[1, 1, 1.628135]} />
                    <mesh name="app_page_lines_1" geometry={nodes.app_page_lines_1.geometry} material={materials.phong_3} position={[0, 0, -5.560053]} scale={48.132366} />
                  </group>
                  <group name="app_page_2" position={[0.001091, 2.310881, -8.056879]} scale={0.191862}>
                    <mesh name="app_page_bg_2" geometry={nodes.app_page_bg_2.geometry} material={materials.phong_1} position={[0, 0, -0.992694]} scale={[1, 1, 1.628135]} />
                    <mesh name="app_page_lines_2" geometry={nodes.app_page_lines_2.geometry} material={materials.phong_3} position={[0, 0, -5.560053]} scale={48.132366} />
                  </group>
                  <group name="app_page_3" position={[-0.151402, 2.415035, -9.770845]} scale={0.191862}>
                    <mesh name="app_page_bg_3" geometry={nodes.app_page_bg_3.geometry} material={materials.phong_1} position={[0, 0, -0.992694]} scale={[1, 1, 1.628135]} />
                    <mesh name="app_page_lines_3" geometry={nodes.app_page_lines_3.geometry} material={materials.phong_3} position={[0, 0, -5.560053]} scale={48.132366} />
                  </group>
                  <group name="app_page_4" position={[0.053158, 2.146587, -5.910395]} scale={0.191862}>
                    <mesh name="app_page_bg_4" geometry={nodes.app_page_bg_4.geometry} material={materials.phong_1} position={[0, 0, -0.992694]} scale={[1, 1, 1.628135]} />
                    <mesh name="app_page_lines_4" geometry={nodes.app_page_lines_4.geometry} material={materials.phong_3} position={[0, 0, -5.560053]} scale={48.132366} />
                  </group>
                </mesh>
              </group>
              <mesh name="cover_laptop" geometry={nodes.cover_laptop.geometry} material={materials.phong_5} position={[0, -0.000049, -0.000182]} rotation={[1.308997, 0, 0]} />
            </mesh>
            <mesh name="laptop_bottom" geometry={nodes.laptop_bottom.geometry} material={materials.phong_5} position={[0, -0.154632, -0.032947]} rotation={[0.36553, 0, 0]}>
              <mesh name="keyboard" geometry={nodes.keyboard.geometry} material={materials.phong_3} position={[0, 0, -0.002072]} />
            </mesh>
          </group>
        </group>
      </group>
  )
}

export default Browsers