import React, { useEffect, useState } from "react";
import styled from "styled-components";
import GsapHolder from "./GsapHolder";
import Text from "./Text";
import Controls from "./Controls";
import Tips from "./Tips";
import Intro from "./Intro";
import Logo from "./Logo";
import CustomCanvas from "./CustomCanvas";
import ProgressHolder from "./ProgressHolder";
import ProgressIntervals from "./ProgressIntervals";
import PropTypes from "prop-types";
import { useProgress } from "@react-three/drei";
import AudioPlayer from "./AudioPlayer";
import Modal from "../Modal";
import EndMenu from "../navigation/EndMenu";
import BlockScreen from "./BlockScreen";

const Holder = styled.div`
  position: relative;
  height: 100vh;

  .hideForLoading {
    opacity: ${(props) => (props.loaded ? 1 : 0)};
    visibility: ${(props) =>
      props.loaded
        ? "visible"
        : "hidden"}; // to prevent clicking and cursor change
    transition: opacity 0.5s ease;
  }
`;

function AnimatedPageHolder(props) {
  const { progress } = useProgress();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (progress === 100) {
      setLoaded(true);
    }
  }, [progress]);

  useEffect(() => {
    props.setProgress(0);
    props.setTextIndex(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Holder loaded={loaded}>
      <BlockScreen />
      <GsapHolder
        id={props.uniqueTimelineId}
        setProgress={props.setProgress}
        setTextIndex={props.setTextIndex}
        textContent={props.text}
      >
        <CustomCanvas
          cameraKeyframes={props.keyframes}
          progressName={props.progressName}
          debug={props.debug}
        >
          {props.children}
        </CustomCanvas>
        <Text
          textContent={props.text}
          progress={props.progress}
          currentTextIndex={props.currentTextIndex}
        />
        <Intro>{props.introText}</Intro>
      </GsapHolder>

      {!props.debug && <Logo currentTextIndex={props.currentTextIndex} />}

      <Controls
        id={props.uniqueTimelineId}
        setCurrentTextIndex={props.setTextIndex}
        currentTextIndex={props.currentTextIndex}
        text={props.text}
      />

      <Tips currentTextIndex={props.currentTextIndex} />

      {props.audioContent?.length ? (
        <AudioPlayer
          tracks={props.audioContent}
          currentIndex={props.currentTextIndex - 1}
        />
      ) : null}

      <ProgressHolder pageIndex={props.pageIndex}>
        <ProgressIntervals
          length={props.text.length}
          gsapId={props.uniqueTimelineId}
          setTextIndex={props.setTextIndex}
          currentTextIndex={props.currentTextIndex}
        />
      </ProgressHolder>

      <Modal />
      <EndMenu />
    </Holder>
  );
}

AnimatedPageHolder.propTypes = {
  uniqueTimelineId: PropTypes.string.isRequired,
  textContent: PropTypes.array.isRequired,
  cameraKeyframes: PropTypes.array.isRequired,
  pageIndex: PropTypes.number.isRequired,
  setProgress: PropTypes.func.isRequired,
  setTextIndex: PropTypes.func.isRequired,
  progressName: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
  currentTextIndex: PropTypes.number.isRequired,
  introText: PropTypes.element.isRequired,
  audioContent: PropTypes.array,
  debug: PropTypes.bool,
};

AnimatedPageHolder.defaultProps = {
  debug: false,
};

export default AnimatedPageHolder;

