import React from "react";

export default function Calculator({ nodes, materials }) {
  return (
    <group>
        <group name="calculator">
          <mesh name="button4" geometry={nodes.button4.geometry} material={materials.phong_calculator_buttons} />
          <mesh name="button3" geometry={nodes.button3.geometry} material={materials.phong_calculator_buttons} />
          <mesh name="button2" geometry={nodes.button2.geometry} material={materials.phong_calculator_buttons} />
          <mesh name="button1" geometry={nodes.button1.geometry} material={materials.phong_calculator_buttons} />
          <mesh name="button7" geometry={nodes.button7.geometry} material={materials.phong_calculator_buttons} />
          <mesh name="button6" geometry={nodes.button6.geometry} material={materials.phong_calculator_buttons} />
          <mesh name="button8" geometry={nodes.button8.geometry} material={materials.phong_calculator_buttons} />
          <mesh name="button5" geometry={nodes.button5.geometry} material={materials.phong_calculator_buttons} />
          <mesh name="button" geometry={nodes.button.geometry} material={materials.phong_calculator_buttons} />
          <mesh name="screen" geometry={nodes.screen.geometry} material={materials.phong_calculator_screen} />
          <mesh name="screen_detail" geometry={nodes.screen_detail.geometry} material={materials.phong2_calculator} />
          <mesh name="calculator_(1)" geometry={nodes['calculator_(1)'].geometry} material={materials.phong2_calculator} />
        </group>
        <mesh name="char_arms1" geometry={nodes.char_arms1.geometry} material={materials.pasted__standardSurface2} position={[0.080299, -0.134497, 0.024583]} rotation={[-0.507286, 0, 0]} />
      </group>
  );
}
