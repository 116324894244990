import React from 'react';
import useTrigger from '../../hooks/useTrigger';
import WebsiteUserHolder from "./holders/WebsiteUserHolder";
import {text} from "../../data/ipa-data/text";
import { somethingVerySmall } from '../../helpers/scrollHelpers';

function WebsiteGuysGroup() {

  const show = useTrigger(text[6].adjustedIn - somethingVerySmall);
  return (
    <group visible={show} position={[0, 0.55, -0.1]}>
      <WebsiteUserHolder position={[-0.25, -1.03, 0]}/>
      <WebsiteUserHolder position={[0.25, -1.03, 0]}/>
    </group>
  )
}

export default WebsiteGuysGroup;