import React from "react";
import { useBoundStore } from "../stores/useBoundStore";
import { text } from "../data/intro-data/text";
import { audio } from "../data/intro-data/audio";
import { keyframes } from "../data/intro-data/camera";
import AnimatedPageHolder from "../components/common/AnimatedPageHolder";
import IntroScene from "../components/intro-page/IntroScene";

const timelineId = "intro-timeline";
const progressName = "introProgress";

function Intro() {
  const { introProgress, setIntroProgress, introTextIndex, setIntroTextIndex } =
    useBoundStore();

  return (
    <AnimatedPageHolder
      setProgress={setIntroProgress}
      setTextIndex={setIntroTextIndex}
      text={text}
      keyframes={keyframes}
      progressName={progressName}
      progress={introProgress}
      currentTextIndex={introTextIndex}
      introText={
        <>
          <span className="intro-label">Explainer 1</span>
          <h1>The Problem We Are Solving</h1>
          <p>
            The way ad measurement works involves multiple companies learning
            about which apps and websites you visited. We have a proposal for a
            new way of measuring ads in a more private way.
          </p>
        </>
      }
      cameraKeyframes={keyframes}
      pageIndex={0}
      textContent={text}
      audioContent={audio}
      uniqueTimelineId={timelineId}
    >
      <IntroScene />
    </AnimatedPageHolder>
  );
}

export default Intro;

