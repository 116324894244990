import React from 'react'

const PizzaSilo = ({nodes, materials}) => {
  return (
    <group>
    <group name="Icon_Pizza_Shop" position={[0, 0.469635, 0]} scale={0.72213}>
      <group name="null1">
        <group name="group1">
          <mesh name="pCylinder1" geometry={nodes.pCylinder1.geometry} material={materials.phong_4} />
          <mesh name="pCylinder2" geometry={nodes.pCylinder2.geometry} material={materials.phong_4} />
          <mesh name="pCylinder4" geometry={nodes.pCylinder4.geometry} material={materials.phong_4} />
          <mesh name="pCylinder3" geometry={nodes.pCylinder3.geometry} material={materials.phong_4} />
          <mesh name="polySurface3" geometry={nodes.polySurface3.geometry} material={materials.phong_10} />
          <mesh name="polySurface11" geometry={nodes.polySurface11.geometry} material={materials.phong_8} />
          <mesh name="pizaa_base" geometry={nodes.pizaa_base.geometry} material={materials.pasted__phong_9} />
        </group>
      </group>
      <mesh name="typeMesh2" geometry={nodes.typeMesh2.geometry} material={materials.typeBlinn2} />
    </group>
    <group name="silo_grp">
      <group name="group3" rotation={[-Math.PI, 0, -Math.PI]}>
        <group name="lockboxes_grp_6" position={[0, 0.779152, 0]}>
          <mesh name="lockbox8" geometry={nodes.lockbox8.geometry} material={materials.phong_5} />
          <mesh name="lockbox9" geometry={nodes.lockbox9.geometry} material={materials.phong_5} />
          <mesh name="lockbox10" geometry={nodes.lockbox10.geometry} material={materials.phong_5} />
          <mesh name="lockbox11" geometry={nodes.lockbox11.geometry} material={materials.phong_5} />
          <mesh name="lockbox12" geometry={nodes.lockbox12.geometry} material={materials.phong_5} />
          <mesh name="lockbox13" geometry={nodes.lockbox13.geometry} material={materials.phong_5} />
          <mesh name="lockbox14" geometry={nodes.lockbox14.geometry} material={materials.phong_5} />
          <mesh name="lockbox15" geometry={nodes.lockbox15.geometry} material={materials.phong_5} />
        </group>
        <group name="polySurface13">
          <mesh name="polySurface13_1" geometry={nodes.polySurface13_1.geometry} material={materials.phong_5} />
          <mesh name="polySurface13_2" geometry={nodes.polySurface13_2.geometry} material={materials.phong_3} />
        </group>
      </group>
      <group name="group4" rotation={[-Math.PI, 0, -Math.PI]}>
        <group name="lockboxes_grp_5" position={[0, 0.387911, 0]}>
          <mesh name="lockbox16" geometry={nodes.lockbox16.geometry} material={materials.phong_5} />
          <mesh name="lockbox17" geometry={nodes.lockbox17.geometry} material={materials.phong_5} />
          <mesh name="lockbox18" geometry={nodes.lockbox18.geometry} material={materials.phong_5} />
          <mesh name="lockbox19" geometry={nodes.lockbox19.geometry} material={materials.phong_5} />
          <mesh name="lockbox20" geometry={nodes.lockbox20.geometry} material={materials.phong_5} />
          <mesh name="lockbox21" geometry={nodes.lockbox21.geometry} material={materials.phong_5} />
          <mesh name="lockbox22" geometry={nodes.lockbox22.geometry} material={materials.phong_5} />
          <mesh name="lockbox23" geometry={nodes.lockbox23.geometry} material={materials.phong_5} />
        </group>
        <group name="polySurface14">
          <mesh name="polySurface14_1" geometry={nodes.polySurface14_1.geometry} material={materials.phong_5} />
          <mesh name="polySurface14_2" geometry={nodes.polySurface14_2.geometry} material={materials.phong_3} />
        </group>
      </group>
      <group name="group5" rotation={[-Math.PI, 0, -Math.PI]}>
        <group name="lockboxes_grp_1">
          <mesh name="lockbox24" geometry={nodes.lockbox24.geometry} material={materials.phong_5} />
          <mesh name="lockbox25" geometry={nodes.lockbox25.geometry} material={materials.phong_5} />
          <mesh name="lockbox26" geometry={nodes.lockbox26.geometry} material={materials.phong_5} />
          <mesh name="lockbox27" geometry={nodes.lockbox27.geometry} material={materials.phong_5} />
          <mesh name="lockbox28" geometry={nodes.lockbox28.geometry} material={materials.phong_5} />
          <mesh name="lockbox29" geometry={nodes.lockbox29.geometry} material={materials.phong_5} />
          <mesh name="lockbox30" geometry={nodes.lockbox30.geometry} material={materials.phong_5} />
          <mesh name="lockbox31" geometry={nodes.lockbox31.geometry} material={materials.phong_5} />
        </group>
        <group name="polySurface12">
          <mesh name="polySurface12_1" geometry={nodes.polySurface12_1.geometry} material={materials.phong_5} />
          <mesh name="polySurface12_2" geometry={nodes.polySurface12_2.geometry} material={materials.phong_3} />
        </group>
      </group>
      <mesh name="pole" geometry={nodes.pole.geometry} material={materials.phong_5} position={[0, 1.492597, 0]} scale={[3.128761, 18.894241, 3.128761]} />
    </group>
    <group name="polySurface15" rotation={[-Math.PI, 0, -Math.PI]}>
      <mesh name="polySurface15_1" geometry={nodes.polySurface15_1.geometry} material={materials.phong_5} />
      <mesh name="polySurface15_2" geometry={nodes.polySurface15_2.geometry} material={materials.phong_3} />
      <group name="lockboxes_grp_7" position={[0, 1.158876, 0]} rotation={[0, -0.073964, 0]}>
        <mesh name="lockbox" geometry={nodes.lockbox.geometry} material={materials.phong_5} />
        <mesh name="lockbox1" geometry={nodes.lockbox1.geometry} material={materials.phong_5} />
        <mesh name="lockbox2" geometry={nodes.lockbox2.geometry} material={materials.phong_5} />
        <mesh name="lockbox3" geometry={nodes.lockbox3.geometry} material={materials.phong_5} />
        <mesh name="lockbox4" geometry={nodes.lockbox4.geometry} material={materials.phong_5} />
        <mesh name="lockbox5" geometry={nodes.lockbox5.geometry} material={materials.phong_5} />
        <mesh name="lockbox6" geometry={nodes.lockbox6.geometry} material={materials.phong_5} />
        <mesh name="lockbox7" geometry={nodes.lockbox7.geometry} material={materials.phong_5} />
      </group>
    </group>
  </group>
  )
}

export default PizzaSilo