/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";

export default function HelpersTotal({ nodes, materials }) {
  return (
      <group>
        <group name="Total_helpers_grp" position={[0.223914, -7.314021, 0]}>
          <group name="card_4">
            <mesh name="speech_bubble_number_plane4" geometry={nodes.speech_bubble_number_plane4.geometry} material={materials.number_material_7} position={[-8.95026, 6.91773, 0.143315]} scale={1.004572} />
            <mesh name="card1" geometry={nodes.card1.geometry} material={materials.phong_cards} position={[-11.931136, 6.981863, 0]} scale={[313.38916, 313.38916, 46.837257]} />
          </group>
          <group name="card_9">
            <mesh name="speech_bubble_number_plane9" geometry={nodes.speech_bubble_number_plane9.geometry} material={materials.number_material_6} position={[-3.763133, 6.91773, 0.143315]} scale={1.004572} />
            <mesh name="card2" geometry={nodes.card2.geometry} material={materials.phong_cards} position={[-6.748762, 6.981863, 0]} scale={[313.38916, 313.38916, 46.837257]} />
          </group>
          <group name="card_5">
            <mesh name="speech_bubble_number_plane3" geometry={nodes.speech_bubble_number_plane3.geometry} material={materials.number_material_8} position={[1.843067, 6.91773, 0.143315]} scale={1.004572} />
            <mesh name="card3" geometry={nodes.card3.geometry} material={materials.phong_cards} position={[-1.176238, 6.981863, 0]} scale={[313.38916, 313.38916, 46.837257]} />
          </group>
          <group name="card_6">
            <mesh name="card4" geometry={nodes.card4.geometry} material={materials.phong_cards} position={[5.28655, 6.981863, 0]} scale={[313.38916, 313.38916, 46.837257]} />
            <mesh name="speech_bubble_number_plane6" geometry={nodes.speech_bubble_number_plane6.geometry} material={materials.number_material_10} position={[7.657447, 6.917058, 0.143315]} scale={1.004572} />
            <mesh name="speech_bubble_number_plane10" geometry={nodes.speech_bubble_number_plane10.geometry} material={materials.number_material_1} position={[8.951507, 6.917058, 0.143315]} scale={1.004572} />
          </group>
          <mesh name="plus_icon" geometry={nodes.plus_icon.geometry} material={materials.phong2_plus_and_equal_signs} />
          <mesh name="plus_icon_2" geometry={nodes.plus_icon_2.geometry} material={materials.phong2_plus_and_equal_signs} />
          <mesh name="equal_sign_mesh1" geometry={nodes.equal_sign_mesh1.geometry} material={materials.phong2_plus_and_equal_signs} />
          <mesh name="equal_sign_mesh2" geometry={nodes.equal_sign_mesh2.geometry} material={materials.phong2_plus_and_equal_signs} position={[6.352749, 0, 0]} />
        </group>
        <group name="Transforms_equation_spinner_grp" position={[11.545296, 0, 0]} scale={6.762186}>
          <group name="equation_spinner_grp" rotation={[-0.611679, 0, 0]}>
            <group name="group1">
              <group name="group12">
                <mesh name="typeMesh_0" geometry={nodes.typeMesh_0.geometry} material={materials.typeBlinn} position={[-0.008129, -0.079434, 0.335158]} />
              </group>
              <mesh name="pCylinder1" geometry={nodes.pCylinder1.geometry} material={materials.phong1} />
            </group>
          </group>
        </group>
      </group>
  );
}

