import React from 'react';
import HelpersHolder from "../shared-models/holders/HelpersHolder";
import useTrigger from "../../hooks/useTrigger";
import ShareHolder from "../shared-models/holders/ShareHolder";
import {text} from "../../data/multiplication-data/text";
import { somethingVerySmall } from '../../helpers/scrollHelpers';
import HelpersTotalHolder from './holders/HelpersTotalHolder';


function HelpersGroup() {
  const showHelpers = useTrigger(text[8].adjustedIn);
  const showShares = useTrigger(text[16].adjustedIn, text[17].adjustedIn - somethingVerySmall);
  
  return (
    <group>
      <group position={[1, 0, 0.6]}>
        <HelpersHolder
          rotation={[0, -Math.PI * 0.65, 0]}
          show={showHelpers}
          popsIn={text[8].adjustedIn}
          color="green"
        />
        <ShareHolder position={[0, 1.7, 0]} number={8} show={showShares} color="green2"/>
      </group>
      <group position={[0, 0, -1.1]}>
        <HelpersHolder
          rotation={[0, 0, 0]}
          show={showHelpers}
          popsIn={text[8].adjustedIn}
          color="blue"
        />
        <ShareHolder position={[0, 1.7, 0]} number={6} show={showShares} color="blue2"/>
      </group>
      <group position={[-0.9, 0, 0.7]}>
        <HelpersHolder
          rotation={[0, Math.PI * 0.75, 0]}
          show={showHelpers}
          popsIn={text[8].adjustedIn}
          color="pink"
        />
        <ShareHolder position={[0, 1.7, 0]} number={7} show={showShares} color="pink2"/>
        <HelpersTotalHolder />
      </group>
    </group>
  )
}

export default HelpersGroup;