import React from "react";
import BrowsersGroup from "./BrowsersGroup";
function TemplateScene() {
  return (
    <group>
      <BrowsersGroup />
    </group>
  );
}

export default TemplateScene;

