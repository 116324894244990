import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as ChevronRight } from "../../assets/svg/chevron-right.inline.svg";

const Holder = styled(Link)`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.75em 1.5em;
  border-radius: 3em;
  min-width: 20rem;
  background: white;
  font-size: 16px;
  text-decoration: none;

  .chevron {
    display: block;
    margin-left: 0.75rem;
    width: 0.75rem;
    flex-shrink: 0;
    transition: 0.3s ease;
  }

  &:hover {
    text-decoration: none;

    .chevron {
      transform: translateX(0.25rem);
    }
  }
`;

const IconWrapper = styled.span`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3em;
  height: 3em;
  padding: 0.5em;
  border-radius: 0.25rem;
  background: var(--green);

  svg {
    width: 100%;
    height: 100%;
  }
`;

const Text = styled.span`
  flex-grow: 1;
  font-size: 0.75em;
`;

const Heading = styled.span`
  display: block;
  font-size: 1.5em;
  font-weight: 700;
  letter-spacing: -0.025em;
`;

function UpNextLink({ link }) {
  return (
    <Holder to={link.path}>
      <IconWrapper>
        <link.Icon />
      </IconWrapper>
      <Text>
        Up next
        <Heading>{link.heading}</Heading>
      </Text>
      <ChevronRight className="chevron" />
    </Holder>
  );
}

export default UpNextLink;

