import React, { useMemo, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import ShareStackAppear from "../models/ShareStackAppear";
import useNewMaterials from "../../../hooks/useNewMaterials";
import useTriggerAnimation from "../../../hooks/useTriggerAnimation";

export default function ShareStackAppearHolder({ ...props }) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(
    "SceneAddition/share-stack-appear-transformed.glb"
  );
  const { actions } = useAnimations(animations, group);

  const specificMaterials = useMemo(
    () => [
      { name: "lambert3", color: "blue1" },
      { name: "lambert4", color: "green1" },
    ],
    []
  );
  const excludeMaterials = useMemo(
    () => [
      "number_material_0",
      "number_material_1",
      "number_material_2",
      "number_material_3",
      "number_material_4",
      "number_material_5",
      "number_material_6",
      "number_material_7",
      "number_material_8",
      "number_material_9",
    ],
    []
  );
  const newMaterials = useNewMaterials(materials, {
    baseMaterial: `${props.color}1`,
    specificMaterials: specificMaterials,
    excludeMaterials: excludeMaterials,
  });

  useTriggerAnimation(props.stacks, "appears", actions);

  return (
    <group ref={group} {...props} dispose={null} visible={props.show}>
      <ShareStackAppear nodes={nodes} materials={newMaterials} />
    </group>
  );
}

useGLTF.preload("SceneAddition/share-stack-appear-transformed.glb");

