import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import NavigationLink from "./NavigationLink";
import MobileMenuCard from "./MobileMenuCard";

import Logo from "../../assets/svg/logo-ipa.svg";

const Holder = styled.nav`
  padding: 0.5rem 1rem 4rem;
  font-size: 16px;

  ul {
    margin: 0;
    padding-bottom: 4rem;

    @media (${(props) => props.theme.breakpoints.lg}) {
      padding-bottom: 0;
    }
  }

  li {
    margin-bottom: 0.75rem;
  }

  .card-desktop {
    display: none;
  }

  @media (${(props) => props.theme.breakpoints.lg}) {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1.5rem 1.5rem 0;

    ul {
      padding-bottom: 1rem;
      flex-grow: 1;
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    li {
      margin: 0;
      padding: 1.5em 0;
      border-top: 1px solid var(--grey);
    }

    .card-mobile {
      display: none;
    }

    .card-desktop {
      display: block;
    }
  }
`;

const PlaylistHeader = styled.div`
  margin-bottom: 1em;

  h1 {
    font-size: 1.5em;
  }

  .logo {
    display: none;
  }

  @media (${(props) => props.theme.breakpoints.lg}) {
    display: flex;
    gap: 1em;

    h1 {
      font-size: 2em;
    }

    .logo {
      display: block;
      width: 100px;
    }
  }
`;

const PlaylistCard = styled.div`
  display: flex;
  gap: 1em;
`;

const PlaylistCardIcon = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.55em;
  height: 6.5em;
  padding: 1.75em;
  border-radius: 1rem;
  background: var(--green);

  svg {
    width: 100%;
    height: 100%;
  }
`;

const PlaylistCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;

  @media (min-width: 1400px) {
    flex-direction: row;
  }
`;

const PlaylistCardText = styled.div`
  padding-right: 0.5em;

  h2 {
    margin-bottom: 0.5em;
    font-size: 1.5em;
    font-weight: 700;
    line-height: 1.15;
  }

  p {
    margin: 0;
    font-size: 0.875em;
  }
`;

const PlaylistCardCTA = styled.div`
  flex-shrink: 0;
  width: 15em;

  @media (min-width: 1400px) {
    align-self: flex-end;
  }
`;

function Playlist({ items }) {
  return (
    <Holder>
      <PlaylistHeader>
        <img className="logo" src={Logo} alt="IPA Logo" />
        <h1>Playlist</h1>
      </PlaylistHeader>
      <ul>
        {items.map(({ description, heading, Icon, path, resources }) => (
          <li key={path}>
            <div className="card-desktop">
              <PlaylistCard>
                <PlaylistCardIcon>
                  <Icon />
                </PlaylistCardIcon>

                <PlaylistCardContent>
                  <PlaylistCardText>
                    <h2>{heading}</h2>
                    <p>{description}</p>
                  </PlaylistCardText>

                  <PlaylistCardCTA>
                    <NavigationLink path={path} />
                  </PlaylistCardCTA>
                </PlaylistCardContent>
              </PlaylistCard>
            </div>

            <div className="card-mobile">
              <MobileMenuCard
                description={description}
                heading={heading}
                Icon={Icon}
                path={path}
                resources={resources}
              />
            </div>
          </li>
        ))}
      </ul>
    </Holder>
  );
}

Playlist.propTypes = {
  items: PropTypes.array.isRequired,
};

export default Playlist;

