import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Holder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;

  div {
    border: none;
    color: #b9ffca;
    height: 100%;
    width: 100%;
    position: relative;
    &:after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #b9ffca;
      transform: scaleX(0);
      transform-origin: left;
      transition: transform 0.5s ease;
    }
    &.filled {
      &:after {
        transform: scaleX(1);
      }
    }
  }
`;

function ProgressIntervals(props) {
  const intervals = [];
  for (let i = 1; i < props.length + 1; i++) {
    intervals.push(
      <div key={i} className={i <= props.currentTextIndex ? "filled" : ""} />
    );
  }
  return <Holder>{intervals}</Holder>;
}

ProgressIntervals.propTypes = {
  length: PropTypes.number.isRequired,
  setTextIndex: PropTypes.func.isRequired,
  currentTextIndex: PropTypes.number.isRequired,
  gsapId: PropTypes.string.isRequired,
};

ProgressIntervals.defaultProps = {};

export default ProgressIntervals;

