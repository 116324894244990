import React from "react";
import { useBoundStore } from "../stores/useBoundStore";
import { audio } from "../data/ipa-data/audio";
import { text } from "../data/ipa-data/text";
import { keyframes } from "../data/ipa-data/camera";
import AnimatedPageHolder from "../components/common/AnimatedPageHolder";
import IpaScene from "../components/ipa-page/IpaScene";

const timelineId = "ipa-timeline";
const progressName = "ipaProgress";

function Ipa() {
  const { ipaProgress, setIpaProgress, ipaTextIndex, setIpaTextIndex } =
    useBoundStore();

  // useEffect(() => {
  //   console.log("ipaProgress", ipaProgress);
  //   console.log("ipaTextIndex", ipaTextIndex);
  // }, [ipaProgress, ipaTextIndex]);

  return (
    <AnimatedPageHolder
      setProgress={setIpaProgress}
      setTextIndex={setIpaTextIndex}
      text={text}
      keyframes={keyframes}
      progressName={progressName}
      progress={ipaProgress}
      currentTextIndex={ipaTextIndex}
      introText={
        <>
          <span className="intro-label">Explainer 2</span>
          <h1>An Overview of IPA</h1>
          <p>
            IPA is a proposal of a new way to measure ads, which doesn’t reveal
            which apps or websites individuals have interacted with. This
            section will provide a high-level overview of the IPA protocol.
          </p>
        </>
      }
      cameraKeyframes={keyframes}
      pageIndex={1}
      textContent={text}
      audioContent={audio}
      uniqueTimelineId={timelineId}
    >
      <IpaScene />
    </AnimatedPageHolder>
  );
}

export default Ipa;

