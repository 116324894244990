import React from 'react';
import SharedSecretHolder from "../shared-models/holders/SharedSecretHolder";
import {text} from "../../data/secret-data/text";
import useTrigger from "../../hooks/useTrigger";
import Label from "../Label";
import {somethingVerySmall} from "../../helpers/scrollHelpers";

function IconGroup() {
  const show = useTrigger(text[37].adjustedIn, text[40].adjustedIn);
  const showLabels = useTrigger(text[38].adjustedIn - somethingVerySmall, text[38].adjustedIn)

  return (
    <>
      <group position={[0, 0.8, 7]}>
        <SharedSecretHolder
          scale={4}
          split={text[38].adjustedIn - somethingVerySmall}
          leave={text[39].adjustedIn - somethingVerySmall}
          show={show}
          number={1}
          color={"pink"} />
      </group>

      <group position={[0.48, 1.275, 7]}>
        <Label show={showLabels}
               fullWidthLabel={true}
               targetWidth={130}
               targetHeight={130}
               mobileSize={0.7}
               tabletSize={1.1}
               text="This is the number being shared" />
      </group>
    </>
  )
}

export default IconGroup;