import React from 'react'

const IpaEndIcon1 = ({nodes, materials}) => {
  return (
    <group>
        <group name="null1">
          <group name="Icon_Ipa_1_Incognito" position={[0, -1.073975, 0.141722]}>
            <group name="Base_3">
              <mesh name="pDisc1" geometry={nodes.pDisc1.geometry} material={materials.phong_3} position={[0, 1.073975, -0.141722]} />
            </group>
            <group name="group9" position={[0.8138, -2.030204, -0.021847]} scale={2.308571}>
              <group name="group7" position={[-0.38053, 1.256806, 0.016696]} rotation={[0, 0, -0.332591]} scale={5.511916}>
                <mesh name="pCube3" geometry={nodes.pCube3.geometry} material={materials.phong_6} position={[0.010803, 0.007817, 0]} rotation={[0.964122, -0.155646, 0.808611]} />
                <mesh name="pCube4" geometry={nodes.pCube4.geometry} material={materials.phong_7} position={[-0.008986, 0.007746, 0]} rotation={[0.637443, 0.944459, 0.095896]} />
                <mesh name="pCube2" geometry={nodes.pCube2.geometry} material={materials.phong_1} position={[0.001226, -0.009529, 0]} rotation={[-0.509991, -0.993967, -0.025161]} />
              </group>
              <group name="group8" position={[-0.363844, 1.390933, 0.016872]} scale={7.67476}>
                <mesh name="speech_bubble_mesh" geometry={nodes.speech_bubble_mesh.geometry} material={materials.phong_5}>
                  <group name="numbers">
                    <mesh name="number_0_plane9" geometry={nodes.number_0_plane9.geometry} material={materials.number_material_9} />
                    <mesh name="number_0_plane7" geometry={nodes.number_0_plane7.geometry} material={materials.number_material_7} />
                    <mesh name="number_0_plane6" geometry={nodes.number_0_plane6.geometry} material={materials.number_material_6} />
                    <mesh name="number_0_plane5" geometry={nodes.number_0_plane5.geometry} material={materials.number_material_5} />
                    <mesh name="number_0_plane4" geometry={nodes.number_0_plane4.geometry} material={materials.number_material_4} />
                    <mesh name="number_0_plane3" geometry={nodes.number_0_plane3.geometry} material={materials.number_material_3} />
                    <mesh name="number_0_plane2" geometry={nodes.number_0_plane2.geometry} material={materials.number_material_2} />
                    <mesh name="number_0_plane1" geometry={nodes.number_0_plane1.geometry} material={materials.number_material_1} />
                    <mesh name="number_0_plane8" geometry={nodes.number_0_plane8.geometry} material={materials.number_material_8} />
                  </group>
                  <mesh name="speech_bubble_number_plane" geometry={nodes.speech_bubble_number_plane.geometry} material={materials.number_material_0} position={[0, -0.000013, 0]} scale={0.996824} />
                </mesh>
              </group>
            </group>
          </group>
        </group>
      </group>
  )
}

export default IpaEndIcon1