import React from 'react';
import useTrigger from "../../hooks/useTrigger";
import UserWithShares from "./UserWithShares";
import {secretShares} from '../../data/secretShares';
import {text} from "../../data/addition-data/text";
import {somethingVerySmall} from "../../helpers/scrollHelpers";
import PurchaseHolder from '../shared-models/holders/PurchaseHolder';


function UserPairGroup() {
  const hide = useTrigger(text[18].adjustedIn)
  const showPineappleIcons = useTrigger(text[3].adjustedIn, text[5].adjustedIn + somethingVerySmall)
  const showShares = useTrigger(text[6].adjustedIn, text[10].adjustedIn - somethingVerySmall)


  return (

    <group visible={!hide}>
      <UserWithShares
        position={[-1.6, 0, -1]}
        rotation={[0, Math.PI / 20, 0]}
        showShares={showShares}
        breakNumber={text[6].adjustedIn}
        returnToNormal={text[10].adjustedIn}
        secretShares={secretShares[0][1]}
        number={0}
        forPurchase={true}
      />
      <UserWithShares
        position={[-2.4, 0, -1]}
        rotation={[0, Math.PI / 20, 0]}
        showShares={showShares}
        breakNumber={text[6].adjustedIn}
        returnToNormal={text[10].adjustedIn}
        secretShares={secretShares[1][2]}
        number={1}
        forPurchase={true}
      />

      <group position={[-2, 0, -1]} rotation={[0, Math.PI / 20, 0]}>

        <group position={[0, 1.7, 0]}>
          <PurchaseHolder
            position={[-0.4, 0, 0]}
            scale={0.06}
            show={showPineappleIcons}
            in={text[3].adjustedIn}
            spin1={text[4].adjustedIn}
            color={"yellow4"}
          />
          <PurchaseHolder
            position={[0.4, 0, 0]}
            scale={0.06}
            show={showPineappleIcons}
            in={text[3].adjustedIn}
            spin0={text[5].adjustedIn}
            color={"yellow4"}
          />
        </group>
      </group>
    </group>
  )
}

export default UserPairGroup;