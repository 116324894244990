/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import {useFrame} from "@react-three/fiber";
import {pink3} from "../../../helpers/materials";

export function IconDiceHolder(props) {
  const { nodes } = useGLTF('/SceneIntro/intro-icon-dice-transformed.glb')
  const group = useRef();
  useFrame(({ clock }) => {
    group.current.rotation.y = -clock.getElapsedTime()/3
    group.current.rotation.x = clock.getElapsedTime()/5
  })
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh name="icon_dice" geometry={nodes.icon_dice.geometry} material={pink3} />
    </group>
  )
}

useGLTF.preload('/intro-icon-dice-transformed.glb')
