import React from "react";
import styled from "styled-components";

const StyledTable = styled.table`
  border-collapse: collapse;
  border: 1px solid black;
  text-align: center;
  margin: 0 auto;
  th,
  td {
    border: 1px solid black;
    padding: 5px;
  }
`;

function DeepDiveTwo() {
  return (
    <div>
      <p>
        A helper could try to guess what the 3rd share is but they won't know if
        their guess was right or wrong.
      </p>
      <p>Let’s put ourselves in the shoes of a helper.</p>
      <p>
        They can see two secret-shares: 5 and 6. They cannot see the third
        secret-share.
      </p>
      <p>There are 10 possible values of the 3rd secret-share:</p>
      <StyledTable>
        <thead>
          <tr>
            <th>
              Possible Values for <br />
              3rd secret-share
            </th>
          </tr>
        </thead>
        <tbody>
          {[...Array(10).keys()].map((value) => (
            <tr key={value}>
              <td>{value}</td>
            </tr>
          ))}
        </tbody>
      </StyledTable>
      <p>For all this helper knows, any of these numbers is possible.</p>
      <p>
        For each of these possibilities, let’s calculate what the secret-shared
        number would be:
      </p>
      <StyledTable>
        <thead>
          <tr>
            <th>Possible Value for 3rd secret-share</th>
            <th>Total sum (when added to 5 and 6)</th>
            <th>
              Secret-shared number (Found by turning the bike lock this many
              clicks up from 0)
            </th>
          </tr>
        </thead>
        <tbody>
          {[...Array(10).keys()].map((value) => (
            <tr key={value}>
              <td>{value}</td>
              <td>{5 + 6 + value}</td>
              <td>{(5 + 6 + value) % 10}</td>
            </tr>
          ))}
        </tbody>
      </StyledTable>
      <p>
        So the helper has learned nothing. The secret value could be any number
        from 0 through 9.
      </p>
      <p>
        Let’s say the helper guesses that the secret number is a 1. In that
        case, the third secret share would have to be a 0.
      </p>
      <p>
        …but the helper has no way of checking if this guess is correct! It
        might be correct, and it might be wrong, but they have no way of
        knowing!
      </p>
      <p>So the helper learns nothing.</p>
      <p>
        We made a YouTube video to show how even if one of the helpers is an
        imposter, they can still not learn the secret information. You can see
        it{" "}
        <a
          href="https://www.youtube.com/watch?v=hxQjn5UnJhY"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </p>
    </div>
  );
}

export default DeepDiveTwo;

