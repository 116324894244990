/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";

export default function UserHolding({ nodes, materials }) {
  return (
    <group>
      <group>
        <group name="Riggrp_char">
          <skinnedMesh name="Rigchar_legs" geometry={nodes.Rigchar_legs.geometry} material={materials['Rig:standardSurface2']} skeleton={nodes.Rigchar_legs.skeleton} />
          <skinnedMesh name="Rigchar_head" geometry={nodes.Rigchar_head.geometry} material={materials['Rig:standardSurface2']} skeleton={nodes.Rigchar_head.skeleton} />
          <skinnedMesh name="Rigchar_torso" geometry={nodes.Rigchar_torso.geometry} material={materials['Rig:standardSurface2']} skeleton={nodes.Rigchar_torso.skeleton} />
          <skinnedMesh name="Rigchar_arms" geometry={nodes.Rigchar_arms.geometry} material={materials['Rig:standardSurface2']} skeleton={nodes.Rigchar_arms.skeleton} />
        </group>
        <primitive object={nodes.RigGroup} />
      </group>
    </group>
  );
}

