import React from 'react';
import useTrigger from "../../hooks/useTrigger";
import Label from "../Label";
import HandsHolder from './holders/HandsHolder';
import SpinnerHolder from "../shared-models/holders/SpinnerHolder";
import {text} from "../../data/addition-data/text";
import {somethingVerySmall} from "../../helpers/scrollHelpers";
import {Html} from "@react-three/drei";

const labelProps = {
  labelPosition: 'top',
  targetHeight: 300,
  targetWidth: 180,
}

function AdditionSpinnerGroup() {

  const show = useTrigger(
    text[14].adjustedIn,
    text[18].adjustedIn + somethingVerySmall
  );

  const label1 = useTrigger(text[15].adjustedIn, text[16].adjustedIn);
  const label2 = useTrigger(text[16].adjustedIn, text[17].adjustedIn - somethingVerySmall);
  const label3 = useTrigger(text[17].adjustedIn - somethingVerySmall, text[18].adjustedIn);
  const label4 = useTrigger(text[18].adjustedIn - somethingVerySmall, text[18].adjustedIn + somethingVerySmall);

  const spin1 = useTrigger(text[15].adjustedIn);
  const spin2 = useTrigger(text[16].adjustedIn);
  const spin3 = useTrigger(text[17].adjustedIn - somethingVerySmall);

  return (
    <group
      position={[-0.1, 1.2, -0.65]}
      rotation={[0, Math.PI * 0.95, 0]}
    >
      <HandsHolder
        position={[0, -0.1, 0]}
        in={text[14].adjustedIn}
        out={text[18].adjustedIn}
        scale={1} />
      <group
        visible={show}
        rotation={[-Math.PI * 0.22, 0, 0]}>
        <SpinnerHolder
          scale={0.15}
          color="blue1"
          multipleMoves={[
            {start: spin1, numberOfTicks: 5, direction: true},
            {start: spin2, numberOfTicks: 6, direction: true},
            {start: spin3, numberOfTicks: 12, direction: true},
          ]}
        />
        <Html
          position={[-0.025, 0.005, 0]}
          style={{
            opacity: show ? 1 : 0,
          }}
        >
          <div className="addition-arrow-right"/>
        </Html>
        <Html
          position={[0.015, 0.005, 0]}
          style={{
            opacity: show ? 1 : 0,
          }}
        >
          <div className="addition-arrow-left"/>
        </Html>
      </group>
      <Label show={label1} {...labelProps} text={'Set to 5'} />
      <Label show={label2} {...labelProps} text={'Up 6 clicks to 1'} />
      <Label show={label3} {...labelProps} fullWidthLabel={true} text={'All shares are added...'} />
      <Label show={label4} {...labelProps} fullWidthLabel={true} text={'...leaving 3 on the dial'} />
    </group>
  )
}

export default AdditionSpinnerGroup;