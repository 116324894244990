import React, { useState } from "react";
import styled from "styled-components";
import AdditionalResources from "./AdditionalResources";
import NavigationLink from "./NavigationLink";
import { ReactComponent as ChevronDown } from "../../assets/svg/chevron-down.inline.svg";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  background: white;
`;

const CardHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;

  h2 {
    flex-grow: 1;
    font-size: 16px;

    @media (${(props) => props.theme.breakpoints.md}) {
      font-size: 18px;
    }
  }

  .description-toggle {
    position: relative;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 600;

    &:hover {
      background: transparent;
      text-decoration: underline;
    }

    svg {
      flex-shrink: 0;
      width: 16px;
      transition: 0.3s ease;
    }

    &.expanded {
      svg {
        transform: rotate(180deg);
      }
    }
  }
`;

const CardIcon = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 10px;
  border-radius: 12px;
  background: var(--green);

  svg {
    width: 100%;
    height: 100%;
  }

  @media (${(props) => props.theme.breakpoints.md}) {
    width: 52px;
    height: 52px;
    padding: 12px;
  }
`;

const CardBody = styled.div`
  display: none;
  overflow: hidden;
  font-size: 14px;

  > p {
    margin: 0;
    padding: 4px 0 8px;
  }

  &.expanded {
    display: block;
  }

  .resources-toggle {
    font-weight: 600;
    text-decoration: underline;
    margin-bottom: 1.25em;
    font-size: inherit;

    &:hover {
      background: transparent;
    }
  }
`;

function MobileMenuCard({ description, heading, Icon, path, resources }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showResources, setShowResources] = useState(false);

  return (
    <Card key={path}>
      <CardHeader onClick={() => setIsExpanded(!isExpanded)}>
        <CardIcon>
          <Icon />
        </CardIcon>
        <h2>{heading}</h2>
        <button
          aria-label="Toggle description"
          className={`description-toggle ${isExpanded && "expanded"}`}
        >
          <ChevronDown />
        </button>
      </CardHeader>

      <CardBody className={isExpanded && "expanded"}>
        <p>{description}</p>
        {resources?.length ? (
          <>
            <button
              className="resources-toggle"
              onClick={() => setShowResources(!showResources)}
            >
              {showResources ? "Hide resources" : "Show more resources"}
            </button>
            {showResources ? (
              <AdditionalResources
                isMobile={true}
                section={{ heading: heading, resources: resources }}
              />
            ) : null}
          </>
        ) : null}
      </CardBody>

      <NavigationLink path={path} />
    </Card>
  );
}

export default MobileMenuCard;

