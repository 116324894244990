import { useEffect } from "react";
import * as THREE from "three";
import useTrigger from "./useTrigger";
import { useBoundStore } from "../stores/useBoundStore";

const REVERSE_ANIMATION_SPEED = 10;

export default function useTriggerAnimation(
  trigger,
  animationName,
  actions,
  triggerEnd=null,
  loop=false,
  speed=1,
) {
  const triggerAnimation = useTrigger(trigger, triggerEnd);
  const setNavigationDisabled = useBoundStore((state) => state.setNavigationDisabled);

  useEffect(() => {

    const transferWeightToCurrentAnimation = (current) => {
      // Reset all animations except the current one
      Object.keys(actions).forEach((key) => {
        if (key !== current && actions[key]) {
          actions[key].weight = 0;
        }
      });
    }

    if(trigger && triggerAnimation && actions[animationName]) {

      // Here we need to:
      // 1. If the animation is a loop then play it when trigger is true and stop it when trigger is false
      // 2. If the animation is not a loop then play it when trigger is true and reverse it when trigger is false
      // We need to make sure we are on the start frame for the animation when we play it
      // If any other animations are playing on the same model then fade from that animation to this one


      if( loop ) {
        actions[animationName].reset()
        actions[animationName].setLoop(THREE.LoopRepeat)
        actions[animationName].play()
        return () => {
          actions[animationName].paused = true
        }
      } else {
        const animationTiming = 1 * speed;

        transferWeightToCurrentAnimation(animationName)
        actions[animationName].reset()
        actions[animationName].clampWhenFinished = true
        actions[animationName].timeScale = animationTiming
        actions[animationName].weight = 1
        actions[animationName].setLoop(THREE.LoopOnce, 1)
        actions[animationName].play()

        setNavigationDisabled(true);
      
        setTimeout(() => {
          setNavigationDisabled(false);
        }, animationTiming * 1000)
        return () => {
          // Force actions[animationName] to play in reverse
          transferWeightToCurrentAnimation(animationName)
          actions[animationName].reset()
          actions[animationName].time = actions[animationName].getClip().duration;
          actions[animationName].setLoop(THREE.LoopOnce);
          actions[animationName].timeScale = -1 * animationTiming * REVERSE_ANIMATION_SPEED;
          actions[animationName].weight = 1
          actions[animationName].play();
        }
      }
    }
  }, [triggerAnimation, animationName, actions, loop, trigger, speed, setNavigationDisabled])

  return null;
}
