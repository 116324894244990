import React, {useState} from 'react';
import styled from 'styled-components';
import classNames from "classnames";
import PropTypes from "prop-types";
import {ReactComponent as IpaLogo} from "../../assets/svg/logo-ipa.svg"

const Holder = styled.div`
    z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 1rem;
  height: 3rem;
  gap: 1rem;
  text-align: right;
  background-color: white;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    text-align: left;
    height: auto;
    padding: 1rem;
    background-color: transparent;
    justify-content: flex-start;
  }

  svg {
    width: auto;
    height: 2.2rem;
    @media ( ${props => props.theme.breakpoints.lg} ) {
      height: 3.2rem;
      cursor: pointer;
    }

    .cls-1 {
      fill: #003a71;
    }

    .cls-2 {
      fill: #7880fa;
      stroke: #7880fa;
      stroke-miterlimit: 2.09;
      stroke-width: .27px;
    }
  }

  h1 {
    ${(props) => props.theme.fluidType(-1)};
    color: var(--purple);
    @media ( ${props => props.theme.breakpoints.lg} ) {
      ${(props) => props.theme.fluidType(0)};
      display: none;
      &.is-visible {
        display: block;
      }
    }
  }
`;

function Logo(props) {
  const [isHovered, setIsHovered] = useState(false);
  const headingClasses = classNames({
    "is-visible": props.currentTextIndex > 0 && isHovered,
  });
  return (
    <Holder className="hideForLoading">
      <IpaLogo
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)} />
      <h1 className={headingClasses}>Explaining Interoperable Private Attribution</h1>
    </Holder>
  )
}

Logo.propTypes = {
  currentTextIndex: PropTypes.number.isRequired,
};

export default Logo;