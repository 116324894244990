import React, {useMemo, useRef} from "react";
import { useAnimations, useGLTF } from "@react-three/drei";
import PropTypes from "prop-types";
import useTriggerAnimation from "../../../hooks/useTriggerAnimation";
import useSetFirstAnimationFrame from "../../../hooks/useSetFirstAnimationFrame";
import PizzaSilo from "../models/PizzaSilo";
import useNewMaterials from "../../../hooks/useNewMaterials";

export default function PizzaSiloHolder({ ...props }) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(
    "/SceneIPA/website-data-silo-pizza-shop-transformed.glb"
  );
  const specificMaterials = useMemo(() => [
    {name: 'pasted__phong_9', color: 'yellow1' },
    {name: 'phong_4', color: 'pink1' },
    {name: 'phong_10', color: 'yellow1' },
    {name: 'phong_8', color: 'green1' },
    {name: 'typeBlinn2', color: 'pink1' },
  ], [])
  const newMaterials = useNewMaterials(materials, {
    specificMaterials: specificMaterials,
  });
  const { actions } = useAnimations(animations, group);


  useSetFirstAnimationFrame('silo-rotation', actions);
  useTriggerAnimation(props.spin, "silo-rotation", actions, null, false, 1.5);
  useTriggerAnimation(props.drop, "lock-boxes-drop", actions, null, false, 1.5);

  return (
    <group
      ref={group}
      position={props.position}
      dispose={null}
      visible={props.show}
      scale={props.scale}
    >
      <PizzaSilo materials={newMaterials} nodes={nodes} />
    </group>
  );
}

PizzaSiloHolder.propTypes = {
  show: PropTypes.bool,
  spinEnd: PropTypes.number,
  spin: PropTypes.number,
  drop: PropTypes.number,
  scale: PropTypes.number,
  position: PropTypes.array,
};

PizzaSiloHolder.defaultProps = {
  show: true,
  spin: null,
  spinEnd: null,
  drop: null,
  scale: null,
  position: null,
};

useGLTF.preload("/SceneIPA/website-data-silo-pizza-shop-transformed.glb");

