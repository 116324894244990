import React, { useRef } from "react";
import { useAnimations, useGLTF } from "@react-three/drei";
import Dice from "../models/Dice";
import PropTypes from "prop-types";
import useTriggerAnimation from "../../../hooks/useTriggerAnimation";
import useSetFirstAnimationFrame from "../../../hooks/useSetFirstAnimationFrame";
import useNewMaterials from "../../../hooks/useNewMaterials";

export default function DiceHolder(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(
    "/SceneSecret/global-dice-transformed.glb"
  );
  const newMaterials = useNewMaterials(materials, {
    baseMaterial: props.color,
    excludeMaterials: 'numbers_material',
  })
  const { actions } = useAnimations(animations, group);

  
  useTriggerAnimation(props.in, "pop-up-to-0", actions);
  useTriggerAnimation(props.roll1, "roll-to-1", actions);
  useTriggerAnimation(props.roll2, "roll-to-2", actions);
  useTriggerAnimation(props.roll3, "roll-to-3", actions);
  useTriggerAnimation(props.roll4, "roll-to-4", actions);
  useTriggerAnimation(props.roll5, "roll-to-5", actions);
  useTriggerAnimation(props.roll6, "roll-to-6", actions);
  useTriggerAnimation(props.roll7, "roll-to-7", actions);
  useTriggerAnimation(props.roll8, "roll-to-8", actions);
  useTriggerAnimation(props.roll9, "roll-to-9", actions);

  // Set in animation to its start position on initial render
  useSetFirstAnimationFrame("pop-up-to-0", actions);

  return (
    <group
      ref={group}
      position={props.position}
      dispose={null}
      visible={props.show}
      scale={props.scale}
    >
      <Dice materials={newMaterials} nodes={nodes} />
    </group>
  );
}

DiceHolder.propTypes = {
  show: PropTypes.bool,
  in: PropTypes.number,
  roll1: PropTypes.number,
  roll2: PropTypes.number,
  roll3: PropTypes.number,
  roll4: PropTypes.number,
  roll5: PropTypes.number,
  roll6: PropTypes.number,
  roll7: PropTypes.number,
  roll8: PropTypes.number,
  roll9: PropTypes.number,
  color: PropTypes.string,
};

DiceHolder.defaultProps = {
  show: true,
  in: 0,
  roll1: null,
  roll2: null,
  roll3: null,
  roll4: null,
  roll5: null,
  roll6: null,
  roll7: null,
  roll8: null,
  roll9: null,
  color: "green1",
};

useGLTF.preload("/SceneSecret/global-dice-transformed.glb");

