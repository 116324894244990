import React from "react";
import useTrigger from "../../hooks/useTrigger";
import SpeechBubbleHolder from "../shared-models/holders/SpeechBubbleHolder";
import { text } from "../../data/multiplication-data/text";
import { somethingVerySmall } from "../../helpers/scrollHelpers";

const MultiplicationSpeechBubblesGroup = () => {
  const bubblesIn = text[21].adjustedIn - somethingVerySmall;
  const showSpeechBubbles = useTrigger(bubblesIn);

  return (
    <group visible={showSpeechBubbles}>
      <SpeechBubbleHolder
        scale={14}
        position={[1, 1.7, 0.6]}
        number={6}
        in={bubblesIn}
        color="green2"
      />
      <SpeechBubbleHolder
        scale={14}
        position={[0, 1.7, -1.1]}
        number={9}
        in={bubblesIn}
        color="blue2"
      />
      <SpeechBubbleHolder
        scale={14}
        position={[-0.9, 1.7, 0.7]}
        number={8}
        in={bubblesIn}
        color="pink2"
      />
    </group>
  );
};

export default MultiplicationSpeechBubblesGroup;

