/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";

export default function SpeechBubble({ materials, nodes }) {
  return (
    <group>
      <mesh
        name="speech_bubble_mesh"
        geometry={nodes.speech_bubble_mesh.geometry}
        material={materials.material1}
      >
        <group name="numbers">
          <mesh
            name="number_0_plane9"
            geometry={nodes.number_0_plane9.geometry}
            material={materials.number_material_9}
          />
          <mesh
            name="number_0_plane7"
            geometry={nodes.number_0_plane7.geometry}
            material={materials.number_material_7}
          />
          <mesh
            name="number_0_plane6"
            geometry={nodes.number_0_plane6.geometry}
            material={materials.number_material_6}
          />
          <mesh
            name="number_0_plane5"
            geometry={nodes.number_0_plane5.geometry}
            material={materials.number_material_5}
          />
          <mesh
            name="number_0_plane4"
            geometry={nodes.number_0_plane4.geometry}
            material={materials.number_material_4}
          />
          <mesh
            name="number_0_plane3"
            geometry={nodes.number_0_plane3.geometry}
            material={materials.number_material_3}
          />
          <mesh
            name="number_0_plane2"
            geometry={nodes.number_0_plane2.geometry}
            material={materials.number_material_2}
          />
          <mesh
            name="number_0_plane1"
            geometry={nodes.number_0_plane1.geometry}
            material={materials.number_material_1}
          />
          <mesh
            name="number_0_plane8"
            geometry={nodes.number_0_plane8.geometry}
            material={materials.number_material_8}
          />
        </group>
        <mesh
          name="speech_bubble_number_plane"
          geometry={nodes.speech_bubble_number_plane.geometry}
          material={materials.number_material_0}
          position={[0, -0.000014, 0.0005]}
          scale={0.996824}
        />
      </mesh>
    </group>
  );
}

