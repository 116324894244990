import React from "react";
import useTrigger from "../../hooks/useTrigger";
import { useSpring, animated } from "@react-spring/three";
import MultiplicationIconHolder from "./holders/MultiplicationIconHolder";
import EqualsIconHolder from "../shared-models/holders/EqualsIconHolder";
import SharedSecretHolder from "../shared-models/holders/SharedSecretHolder";
import { text } from "../../data/multiplication-data/text";
import { somethingVerySmall } from "../../helpers/scrollHelpers";
import PurchaseHolder from "../shared-models/holders/PurchaseHolder";
import EyeHolder from "../shared-models/holders/EyeHolder";

const MatchEquationGroup = () => {
  const iconsIn = text[17].adjustedIn + somethingVerySmall;
  const iconsSpin = text[18].adjustedIn - somethingVerySmall;
  const iconsOut = text[19].adjustedIn - somethingVerySmall;
  const showIcons = useTrigger(iconsIn, iconsOut);
  const showShare = useTrigger(iconsIn, iconsOut);

  const moveEquation = useTrigger(iconsIn);

  const { equationPosition } = useSpring({
    equationPosition: moveEquation ? [-0.4, 1.8, 0] : [0, 1.8, 0],
    config: { tension: 280, friction: 240 },
  });

  return (
    <group>
      <animated.group position={equationPosition}>
        <EyeHolder
          position={[0, 0, 0]}
          scale={0.04}
          show={showIcons}
          in={iconsIn}
          spin1={iconsSpin}
        />
        <MultiplicationIconHolder
          position={[0.2, 0, 0]}
          scale={3}
          show={showIcons}
          in={iconsIn}
          color="yellow1"
        />
        <PurchaseHolder
          position={[0.4, 0, 0]}
          scale={0.04}
          show={showIcons}
          in={iconsIn}
          spin1={iconsSpin}
        />

        <EqualsIconHolder
          show={showIcons}
          position={[0.6, 0, 0]}
          scale={0.5}
          color="yellow1"
        />
        <animated.group scale={1} position={[0.85, 0, 0]}>
          <SharedSecretHolder
            show={showShare}
            scale={1}
            in={iconsIn}
            split={iconsIn}
            leave={iconsOut}
            number={1}
            color="green"
          />
        </animated.group>
      </animated.group>
    </group>
  );
};

export default MatchEquationGroup;

