import React from "react";
import styled from "styled-components";
import HomeMenuCard from "./HomeMenuCard";
import MobileMenuCard from "./MobileMenuCard";
import { PLAYLIST_LINKS } from "../../data/navigation-data/playlistLinks";

const Holder = styled.nav`
  text-align: left;

  ul {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .card-desktop {
    display: none;
  }

  @media (${(props) => props.theme.breakpoints.md}) {
    .card-mobile {
      display: none;
    }

    .card-desktop {
      display: block;
    }
  }
`;

function HomeMenu() {
  return (
    <Holder>
      <ul>
        {PLAYLIST_LINKS.map((link) => (
          <li key={link.path}>
            <div className="card-desktop">
              <HomeMenuCard {...link} />
            </div>
            <div className="card-mobile">
              <MobileMenuCard {...link} resources={null} />
            </div>
          </li>
        ))}
      </ul>
    </Holder>
  );
}

export default HomeMenu;

