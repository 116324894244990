import React from "react";
import BinarySpeechBubbleHolder from "./holders/BinarySpeechBubbleHolder";

import useTrigger from "../../hooks/useTrigger";
import { animated } from "@react-spring/three";
import { text } from "../../data/ipa-data/text";
import { somethingVerySmall } from "../../helpers/scrollHelpers";
import SpeechBubbleHolder2 from "./holders/BinarySpeechBubbleHolder2";

const positions = {
  overUser: [0, 0.9, 0],
  overPhone: [-0.25, 0.77, 0],
  overLaptop: [0.25, 0.77, 0],
};

function BinarySpeechBubblesGroup() {
  const inTime = text[0].adjustedIn;
  const turnToSharesTime = text[5].adjustedIn - somethingVerySmall;
  const dropTime = text[6].adjustedIn - somethingVerySmall;
  const showSplitBinaryBubbles = useTrigger(
    inTime,
    text[7].adjustedIn - somethingVerySmall
  );

  return (
    <group>
      <group visible={showSplitBinaryBubbles}>
        <animated.group position={positions.overPhone}>
          <BinarySpeechBubbleHolder
            in={inTime}
            numberToShares={turnToSharesTime}
            sharesDrop={dropTime}
            scale={11}
          />
        </animated.group>
        <animated.group position={positions.overLaptop}>
          <SpeechBubbleHolder2
            in={inTime}
            numberToShares={turnToSharesTime}
            sharesDrop={dropTime}
            scale={11}
          />
        </animated.group>
      </group>
    </group>
  );
}

export default BinarySpeechBubblesGroup;

