import React from "react";

export default function Share({ nodes, materials }) {
  return (
    <>
      <group name="numbers" position={[0.346291, 0, 0]}>
        <mesh
          name="number_0_plane9"
          geometry={nodes.number_0_plane9.geometry}
          material={materials.number_material_9}
          position={[0, 0, 0.159801]}
          scale={0}
        />
        <mesh
          name="number_0_plane8"
          geometry={nodes.number_0_plane8.geometry}
          material={materials.number_material_8}
          position={[0, 0, 0.159801]}
          scale={0}
        />
        <mesh
          name="number_0_plane7"
          geometry={nodes.number_0_plane7.geometry}
          material={materials.number_material_7}
          position={[0, 0, 0.159801]}
          scale={0}
        />
        <mesh
          name="number_0_plane6"
          geometry={nodes.number_0_plane6.geometry}
          material={materials.number_material_6}
          position={[0, 0, 0.159801]}
          scale={0}
        />
        <mesh
          name="number_0_plane5"
          geometry={nodes.number_0_plane5.geometry}
          material={materials.number_material_5}
          position={[0, 0, 0.159801]}
          scale={0}
        />
        <mesh
          name="number_0_plane4"
          geometry={nodes.number_0_plane4.geometry}
          material={materials.number_material_4}
          position={[0, 0, 0.159801]}
          scale={0}
        />
        <mesh
          name="number_0_plane3"
          geometry={nodes.number_0_plane3.geometry}
          material={materials.number_material_3}
          position={[0, 0, 0.159801]}
          scale={0}
        />
        <mesh
          name="number_0_plane2"
          geometry={nodes.number_0_plane2.geometry}
          material={materials.number_material_2}
          position={[0, 0, 0.159801]}
          scale={0}
        />
        <mesh
          name="number_0_plane1"
          geometry={nodes.number_0_plane1.geometry}
          material={materials.number_material_1}
          position={[0, 0, 0.159801]}
          scale={0}
        />
      </group>
      <mesh
        name="share_card"
        geometry={nodes.share_card.geometry}
        material={materials.standardSurface2}
      />
      <mesh
        name="shareCard_number_plane"
        position={[0, 0, 0.01]}
        geometry={nodes.shareCard_number_plane.geometry}
        material={materials.number_material_0}
      />
    </>
  );
}

