/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useMemo, useRef} from "react";
import {Shadow, useAnimations, useGLTF} from "@react-three/drei";
import PropTypes from "prop-types";
import UserHolding from "../models/User-holding";
import useTriggerAnimation from "../../../hooks/useTriggerAnimation";
import useSetFirstAnimationFrame from "../../../hooks/useSetFirstAnimationFrame";
import {SkeletonUtils} from "three-stdlib";
import {useGraph} from "@react-three/fiber";
import useNewMaterials from "../../../hooks/useNewMaterials";

export default function UserHoldingHolder(props) {
  const group = useRef();
  const { scene, materials, animations } = useGLTF(
    "/SceneAddition/user-holding-transformed.glb"
  );
  const newMaterials = useNewMaterials(materials, {
    baseMaterial: props.color,
  });

  const clone = useMemo(() => SkeletonUtils.clone(scene), [scene]);

  const { nodes } = useGraph(clone);

  const { actions } = useAnimations(animations, group);

  useSetFirstAnimationFrame("break-number-action", actions);
  useTriggerAnimation(
    props.breakNumber,
    "break-number-action",
    actions, null, false, 2
  );
  useTriggerAnimation(
    props.returnToNormal,
    "return-to-normal-pose",
    actions, null, false, 1.2
  );

  return (
    <group ref={group} position={props.position} scale={props.scale} rotation={props.rotation} dispose={null} visible={props.show}>
      <UserHolding nodes={nodes} materials={newMaterials} />
      <Shadow color="purple" position={[0,0,0.1]} opacity={0.1} scale={[0.7,0.25,1]}/>
    </group>
  );
}

UserHoldingHolder.propTypes = {
  show: PropTypes.bool,
  breakNumber: PropTypes.number,
  returnToNormal: PropTypes.number,
  position: PropTypes.array,
  rotation: PropTypes.array,
  scale: PropTypes.number,
  color: PropTypes.string
};

UserHoldingHolder.defaultProps = {
  show: true,
  breakNumber: null,
  returnToNormal: null,
  position: [0, 0, 0],
  rotation: [0, 0, 0],
  scale: 1,
  color: "grey3"
};

useGLTF.preload("/SceneAddition/user-holding-transformed.glb");

