import React from "react";
import UserPair from "./UserPair";
import useTrigger from "../../hooks/useTrigger";
import { secretShares } from "../../data/secretShares";
import SharedSecretHolder from "../shared-models/holders/SharedSecretHolder";
import MatchEquationGroup from "./MatchEquationGroup";
import MultiShareStackAppearHolder from "./holders/MultiShareStackAppearHolder";
import { text } from "../../data/multiplication-data/text";
import { somethingVerySmall } from "../../helpers/scrollHelpers";
import EyeHolder from "../shared-models/holders/EyeHolder";
import PurchaseHolder from "../shared-models/holders/PurchaseHolder";

const HeroUserPair = () => {
  const idleIn = text[0].adjustedIn;
  const idleOut = text[4].adjustedIn;

  const hide = useTrigger(text[18].adjustedIn);

  const numbersIn = text[2].adjustedIn - somethingVerySmall;
  const numbersSpin = text[4].adjustedIn - somethingVerySmall;
  const numbersOut = text[7].adjustedIn - somethingVerySmall;
  const showNumbers = useTrigger(numbersIn, numbersOut);

  const stackIn = text[8].adjustedIn - somethingVerySmall;
  const stackOut = text[16].adjustedIn - somethingVerySmall;
  const showStack = useTrigger(stackIn, stackOut);

  const sharesIn = numbersOut;
  const sharesOut = stackIn;
  const showShares = useTrigger(sharesIn, sharesOut);

  return (
    <group
      position={[-2, 0, -1]}
      rotation={[0, Math.PI / 20, 0]}
      visible={!hide}
    >
      <UserPair idleIn={idleIn} idleOut={idleOut} />

      <MatchEquationGroup />

      <group>
        <MultiShareStackAppearHolder
          position={[1.75, 1, 1.65]}
          rotation={[0, -0.25, 0]}
          stacks={stackIn}
          color="green"
          show={showStack}
        />
        <SharedSecretHolder
          scale={1}
          position={[-0.3, 1.7, 0]}
          rotation={[0, 0, 0]}
          shares={secretShares[1][0]}
          number={1}
          color="blue"
          split={sharesIn}
          // sharesLeave={text[8].adjustedIn}
          leave={sharesOut}
          show={showShares}
          forPurchase={true}
        />
        <SharedSecretHolder
          scale={1}
          position={[0.3, 1.7, 0]}
          rotation={[0, 0, 0]}
          shares={secretShares[1][1]}
          number={1}
          color="green"
          split={sharesIn}
          // sharesLeave={text[8].adjustedIn}
          leave={sharesOut}
          show={showShares}
          forImpression={true}
        />
      </group>

      <group position={[0, 1.7, 0]}>
        <EyeHolder
          position={[-0.3, 0, 0]}
          scale={0.06}
          show={showNumbers}
          in={numbersIn}
          spin1={numbersSpin}
        />
        <PurchaseHolder
          position={[0.3, 0, 0]}
          scale={0.06}
          show={showNumbers}
          in={numbersIn}
          spin1={numbersSpin}
        />
      </group>
    </group>
  );
};

export default HeroUserPair;

