import {Html} from '@react-three/drei';
import React from 'react'
import useTrigger from '../../hooks/useTrigger';
import AdditionCubeHolder from './holders/AdditionCubeHolder';
import MultiplicationCubeHolder from './holders/MultiplicationCubeHolder';
import SecretCubeHolder from './holders/SecretCubeHolder';
import {animated} from "@react-spring/three";
import {text as introText, interval as introInterval} from "../../data/intro-data/text";

const IpaCubesGroup = () => {
  const showSecretText = useTrigger(introText[4].adjustedIn);
  const showAdditionText = useTrigger(introText[4].adjustedIn);
  const showMultiplicationText = useTrigger(introText[4].adjustedIn);

  return (
    <group scale={0.4} position={[0, 0, 0]}>
      {/* I */}
      <group>
        <animated.group scale={0.8} position={[-6, 0, -8]}>
          <SecretCubeHolder
            iToCube={introText[4].adjustedIn - (introInterval/2)}
            cubeToIcon={introText[4].adjustedIn}
            scale={0.05}
          />
          <Html
            scale={1.2}
            transform
            position={[0, -3.8, 0]}
            style={{
              opacity: showSecretText ? 1 : 0,
            }}
          >
            <div className="introCube-text">
              <p>Secret Sharing</p>
            </div>
          </Html>
        </animated.group>
      </group>
      {/* P */}
      <group>
        <group scale={0.8} position={[0, 0, -8]}>
          <AdditionCubeHolder
            pToCube={introText[4].adjustedIn - (introInterval/2)}
            cubeToIcon={introText[4].adjustedIn}
            scale={0.05}
          />
          <Html
            scale={1.2}
            transform
            position={[0, -4.5, 0]}
            style={{
              opacity: showAdditionText ? 1 : 0,
            }}
          >
            <div className="introCube-text">
              <p>Adding <br/>Secret-Shared <br/>Numbers</p>
            </div>

          </Html>
        </group>
      </group>
      {/* A */}
      <group>
        <group scale={0.8} position={[6, 0, -8]}>
          <MultiplicationCubeHolder
            aToCube={introText[4].adjustedIn - (introInterval/2)}
            cubeToIcon={introText[4].adjustedIn}
            scale={0.05}
          />
          <Html
            scale={1.2}
            transform
            position={[0, -4.5, 0]}
            style={{
              opacity: showMultiplicationText ? 1 : 0,
            }}
          >
            <div className="introCube-text">
              <p>Multiplying Secret-Shared Numbers</p>
            </div>
          </Html>
        </group>
      </group>
    </group>

  )
}

export default IpaCubesGroup