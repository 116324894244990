import React from "react";
import PixelVoidGroup from "./PixelVoidGroup";
import BrowsersGroup from "./BrowsersGroup";
import IpaCubesGroup from "./IpaCubesGroup";


function IntroScene() {
  return (
    <group>
      <PixelVoidGroup />
      <BrowsersGroup />
      <IpaCubesGroup />
    </group>
  );
}

export default IntroScene;

