import React from 'react'

const SecretCube = ({nodes, materials}) => {
  return (
    <group>
      <group name="letter_i">
        <group name="icon_Secret_i" position={[0.834633, 0.532629, 31.833786]} scale={0}>
          <mesh name="ipa_building_block_secret_sharing3" geometry={nodes.ipa_building_block_secret_sharing3.geometry} material={materials.phong_7} position={[132.035431, -920.310669, -62.698757]} />
          <mesh name="ipa_building_block_secret_sharing2" geometry={nodes.ipa_building_block_secret_sharing2.geometry} material={materials.phong_7} position={[129.5211, -922.865662, -63.65926]} rotation={[0.263716, 0.037886, -0.111019]} />
          <mesh name="ipa_building_block_secret_sharing1" geometry={nodes.ipa_building_block_secret_sharing1.geometry} material={materials.phong_7} position={[143.333, -928.973938, -69.28142]} rotation={[-0.440883, 0.03054, 0.407089]} />
        </group>
        <group name="letter_i_pieces">
          <mesh name="expanding_pCube11" geometry={nodes.expanding_pCube11.geometry} material={materials.phong_5} scale={0.874559} />
          <mesh name="pCube4" geometry={nodes.pCube4.geometry} material={materials.phong_5} />
          <mesh name="pCube5" geometry={nodes.pCube5.geometry} material={materials.phong_5} />
          <mesh name="pCube6" geometry={nodes.pCube6.geometry} material={materials.phong_5} />
          <mesh name="pCube8" geometry={nodes.pCube8.geometry} material={materials.phong_5} />
          <mesh name="pCube14" geometry={nodes.pCube14.geometry} material={materials.phong_5} />
          <mesh name="pCube16" geometry={nodes.pCube16.geometry} material={materials.phong_5} />
          <mesh name="pCube17" geometry={nodes.pCube17.geometry} material={materials.phong_5} />
          <mesh name="pCube18" geometry={nodes.pCube18.geometry} material={materials.phong_5} />
          <mesh name="pCube78" geometry={nodes.pCube78.geometry} material={materials.phong_5} />
        </group>
        <group name="cube_i">
          <mesh name="pCube55" geometry={nodes.pCube55.geometry} material={materials.phong_5}>
            <mesh name="pCube68" geometry={nodes.pCube68.geometry} material={materials.phong_5} />
          </mesh>
          <mesh name="pCube57" geometry={nodes.pCube57.geometry} material={materials.phong_5}>
            <mesh name="pCube66" geometry={nodes.pCube66.geometry} material={materials.phong_5} />
          </mesh>
          <mesh name="pCube58" geometry={nodes.pCube58.geometry} material={materials.phong_5}>
            <mesh name="pCube61" geometry={nodes.pCube61.geometry} material={materials.phong_5} />
          </mesh>
          <mesh name="pCube59" geometry={nodes.pCube59.geometry} material={materials.phong_5}>
            <mesh name="pCube69" geometry={nodes.pCube69.geometry} material={materials.phong_5} />
          </mesh>
          <mesh name="pCube62" geometry={nodes.pCube62.geometry} material={materials.phong_5} />
          <mesh name="pCube63" geometry={nodes.pCube63.geometry} material={materials.phong_5} />
          <mesh name="pCube65" geometry={nodes.pCube65.geometry} material={materials.phong_5} />
          <mesh name="pCube67" geometry={nodes.pCube67.geometry} material={materials.phong_5} />
          <mesh name="pCube74" geometry={nodes.pCube74.geometry} material={materials.phong_5}>
            <mesh name="pCube64" geometry={nodes.pCube64.geometry} material={materials.phong_5} />
          </mesh>
          <mesh name="pCube77" geometry={nodes.pCube77.geometry} material={materials.phong_5}>
            <mesh name="pCube60" geometry={nodes.pCube60.geometry} material={materials.phong_5} />
          </mesh>
        </group>
      </group>
    </group>
  )
}

export default SecretCube