import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { useLocation } from "react-router-dom";

import { useBoundStore } from "../../stores/useBoundStore";
import useScrollTrigger from "../../hooks/useScrollTrigger";
import { PLAYLIST_LINKS } from "../../data/navigation-data/playlistLinks";

import Playlist from "./Playlist";
import UpNextLink from "./UpNextLink";
import { ReactComponent as CloseIcon } from "../../assets/svg/close-icon.inline.svg";
import Logo from "../../assets/svg/logo-ipa.svg";
import AdditionalResources from "./AdditionalResources";
import FinalCelebration from "./FinalCelebration";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const slideIn = keyframes`
  0% {
    transform: translateX(var(--slide-in-offset));
  }

  100% {
    transform: translateX(0);
  }
`;

const Holder = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000000;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background: #e5d8fd;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  animation: ${fadeIn} 0.3s ease;

  .close-button {
    --button-padding: 12px;

    position: absolute;
    top: calc(1.5rem - var(--button-padding));
    right: calc(1.5rem - var(--button-padding));
    padding: var(--button-padding);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 0;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  @media (${(props) => props.theme.breakpoints.lg}) {
    flex-direction: row-reverse;
    overflow: hidden;
    background-color: transparent;
    backdrop-filter: blur(15px);
  }
`;

const PlaylistHolder = styled.div`
  position: relative;

  .close-button {
    display: none;
  }

  @media (${(props) => props.theme.breakpoints.lg}) {
    --slide-in-offset: -100%;
    width: 60%;
    max-width: 960px;
    height: 100%;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    background: white;

    transform: translateX(var(--slide-in-offset));
    animation: ${slideIn} 0.5s cubic-bezier(0.16, 1, 0.3, 1) forwards;
    animation-delay: 0.25s;

    .close-button {
      display: block;
    }
  }
`;

const ResourcesHolder = styled.div`
  padding: 1.5rem 1rem;

  .resources {
    display: none;
  }

  @media (${(props) => props.theme.breakpoints.lg}) {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5rem;
    padding: 1.5rem;
    opacity: 0;
    animation: ${fadeIn} 0.3s ease forwards;
    animation-delay: 0.75s;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .resources {
      display: block;
      max-width: 480px;
    }
  }
`;

const MobileTopBar = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  padding: 1rem 1rem 0.25rem;
  background: inherit;

  .logo {
    width: 88px;
  }

  @media (${(props) => props.theme.breakpoints.lg}) {
    display: none;
  }
`;

const ResourcesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
`;

function EndMenu() {
  const [currentSection, setCurrentSection] = useState(null);
  const [upNextLink, setUpNextLink] = useState(null);
  const navigationModalVisible = useBoundStore(
    (state) => state.navigationModalVisible
  );
  const setNavigationModalVisible = useBoundStore(
    (state) => state.setNavigationModalVisible
  );

  const location = useLocation();

  const findCurrentSectionIndex = () => {
    return PLAYLIST_LINKS.findIndex((item) => {
      return item.path === location.pathname;
    });
  };

  const setCurrentAndNextSection = () => {
    const currentIndex = findCurrentSectionIndex();

    setCurrentSection(PLAYLIST_LINKS[currentIndex]);

    if (currentIndex === PLAYLIST_LINKS.length - 1) {
      setUpNextLink(null);
    } else {
      setUpNextLink(PLAYLIST_LINKS[currentIndex + 1]);
    }
  };

  useEffect(() => {
    setCurrentAndNextSection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setNavigationModalVisible(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const { st } = useScrollTrigger();

  useEffect(() => {
    st.normalizeScroll(!navigationModalVisible);
  }, [navigationModalVisible, st]);

  if (!navigationModalVisible) return <></>;

  return (
    <Holder>
      <MobileTopBar>
        <img className="logo" src={Logo} alt="IPA Logo" />
        <button
          onClick={() => setNavigationModalVisible(false)}
          className="close-button"
          aria-label="Close navigation"
        >
          <CloseIcon />
        </button>
      </MobileTopBar>
      <ResourcesHolder>
        {upNextLink ? <UpNextLink link={upNextLink} /> : <FinalCelebration />}
        <ResourcesWrapper>
          {currentSection?.resources?.length ? (
            <div className="resources">
              <AdditionalResources section={currentSection} />
            </div>
          ) : (
            <></>
          )}
        </ResourcesWrapper>
      </ResourcesHolder>
      <PlaylistHolder>
        <Playlist items={PLAYLIST_LINKS} />

        <button
          onClick={() => setNavigationModalVisible(false)}
          className="close-button"
          aria-label="Close navigation"
        >
          <CloseIcon />
        </button>
      </PlaylistHolder>
    </Holder>
  );
}

export default EndMenu;

