import React from 'react'

const Buildings = ({nodes, materials}) => {
  return (
    <group>
        <group name="City">
          <group name="blocks_animated_grp">
            <mesh name="animated_city_block1" geometry={nodes.animated_city_block1.geometry} material={materials.lambert1} position={[0, 4.514171, -1.577944]} />
            <mesh name="animated_city_block2" geometry={nodes.animated_city_block2.geometry} material={materials.lambert1} position={[2.466359, 8.4013, 0]} />
            <mesh name="animated_city_block3" geometry={nodes.animated_city_block3.geometry} material={materials.lambert1} position={[0, 4.514171, 0]} />
            <mesh name="animated_city_block4" geometry={nodes.animated_city_block4.geometry} material={materials.lambert1} position={[0, 6.537088, 0]} />
            <mesh name="animated_city_block5" geometry={nodes.animated_city_block5.geometry} material={materials.lambert1} position={[-1.329207, 4.59029, 0]} />
            <mesh name="animated_city_block6" geometry={nodes.animated_city_block6.geometry} material={materials.lambert1} position={[2.222823, 8.07349, 0]} />
            <mesh name="animated_city_block7" geometry={nodes.animated_city_block7.geometry} material={materials.lambert1} position={[2.9812, 1.809773, 0]} />
            <mesh name="animated_city_block8" geometry={nodes.animated_city_block8.geometry} material={materials.lambert1} position={[0, 5.910839, 0]} />
            <mesh name="animated_city_block9" geometry={nodes.animated_city_block9.geometry} material={materials.lambert1} position={[1.184665, 8.525187, 0]} />
            <mesh name="animated_city_block10" geometry={nodes.animated_city_block10.geometry} material={materials.lambert1} position={[0, 4.514171, 0]} />
            <mesh name="animated_city_block11" geometry={nodes.animated_city_block11.geometry} material={materials.lambert1} position={[5.069797, 11.165528, 0]} />
            <mesh name="animated_city_block12" geometry={nodes.animated_city_block12.geometry} material={materials.lambert1} position={[2.466359, 10.74153, 0]} />
            <mesh name="animated_city_block13" geometry={nodes.animated_city_block13.geometry} material={materials.lambert1} position={[0.306958, 4.59029, 0]} />
            <mesh name="animated_city_block14" geometry={nodes.animated_city_block14.geometry} material={materials.lambert1} position={[0, 5.799623, 0]} />
            <mesh name="animated_city_block15" geometry={nodes.animated_city_block15.geometry} material={materials.lambert1} position={[1.906191, 1.759401, 1.177355]} />
            <mesh name="animated_city_block16" geometry={nodes.animated_city_block16.geometry} material={materials.lambert1} position={[2.61975, 3.67394, 0]} />
            <mesh name="animated_city_block17" geometry={nodes.animated_city_block17.geometry} material={materials.lambert1} position={[0.306958, 0.998807, 0]} />
            <mesh name="animated_city_block18" geometry={nodes.animated_city_block18.geometry} material={materials.lambert1} position={[-1.033538, 3.337562, 0]} />
            <mesh name="animated_city_block19" geometry={nodes.animated_city_block19.geometry} material={materials.lambert1} position={[0, 8.209853, 0]} />
            <mesh name="animated_city_block20" geometry={nodes.animated_city_block20.geometry} material={materials.lambert1} position={[2.61975, 10.051557, 0]} />
            <mesh name="animated_city_block21" geometry={nodes.animated_city_block21.geometry} material={materials.lambert1} position={[-2.654001, 7.331667, 0]} />
            <mesh name="animated_city_block22" geometry={nodes.animated_city_block22.geometry} material={materials.lambert1} position={[2.9812, 4.000178, 0]} />
            <mesh name="animated_city_block23" geometry={nodes.animated_city_block23.geometry} material={materials.lambert1} position={[2.520448, 7.152048, 0]} />
            <mesh name="animated_city_block24" geometry={nodes.animated_city_block24.geometry} material={materials.lambert1} position={[0, 4.343062, 2.069507]} />
            <mesh name="animated_city_block25" geometry={nodes.animated_city_block25.geometry} material={materials.lambert1} position={[1.757524, 4.343062, -0.742718]} />
            <mesh name="animated_city_block26" geometry={nodes.animated_city_block26.geometry} material={materials.lambert1} position={[4.036746, 6.129208, 0]} />
            <mesh name="animated_city_block27" geometry={nodes.animated_city_block27.geometry} material={materials.lambert1} position={[0, 2.623985, 0]} />
            <mesh name="animated_city_block28" geometry={nodes.animated_city_block28.geometry} material={materials.lambert1} position={[-0.229111, 6.97878, 0]} />
            <mesh name="animated_city_block29" geometry={nodes.animated_city_block29.geometry} material={materials.lambert1} position={[4.877626, 9.501484, 0]} />
            <mesh name="animated_city_block30" geometry={nodes.animated_city_block30.geometry} material={materials.lambert1} position={[0, 3.830214, 0]} />
            <mesh name="animated_city_block31" geometry={nodes.animated_city_block31.geometry} material={materials.lambert1} position={[2.246578, 4.370347, 0]} />
            <mesh name="animated_city_block32" geometry={nodes.animated_city_block32.geometry} material={materials.lambert1} position={[1.229766, 6.487342, 0]} />
            <mesh name="animated_city_block33" geometry={nodes.animated_city_block33.geometry} material={materials.lambert1} position={[1.005173, 1.873914, 0]} />
            <mesh name="animated_city_block34" geometry={nodes.animated_city_block34.geometry} material={materials.lambert1} position={[-0.255438, 7.780705, 0]} />
            <mesh name="animated_city_block35" geometry={nodes.animated_city_block35.geometry} material={materials.lambert1} position={[0, 4.249858, 0]} />
            <mesh name="animated_city_block36" geometry={nodes.animated_city_block36.geometry} material={materials.lambert1} position={[0.220562, 5.774966, -1.102929]} />
            <mesh name="animated_city_block37" geometry={nodes.animated_city_block37.geometry} material={materials.lambert1} position={[-1.374036, 2.330646, 0]} />
            <mesh name="animated_city_block38" geometry={nodes.animated_city_block38.geometry} material={materials.lambert1} position={[0, 6.835256, 0]} />
            <mesh name="animated_city_block39" geometry={nodes.animated_city_block39.geometry} material={materials.lambert1} position={[0, 3.941634, 0]} />
            <mesh name="animated_city_block40" geometry={nodes.animated_city_block40.geometry} material={materials.lambert1} position={[2.9812, 0.752958, 0]} />
            <mesh name="animated_city_block41" geometry={nodes.animated_city_block41.geometry} material={materials.lambert1} position={[2.61975, 4.9617, 0]} />
            <mesh name="animated_city_block42" geometry={nodes.animated_city_block42.geometry} material={materials.lambert1} position={[1.5997, 3.635068, -0.431329]} />
            <mesh name="animated_city_block43" geometry={nodes.animated_city_block43.geometry} material={materials.lambert1} position={[2.115037, 5.041286, 0]} />
            <mesh name="animated_city_block44" geometry={nodes.animated_city_block44.geometry} material={materials.lambert1} position={[0.208714, 4.249858, 0]} />
            <mesh name="animated_city_block45" geometry={nodes.animated_city_block45.geometry} material={materials.lambert1} position={[4.083965, 11.165528, 0]} />
            <mesh name="animated_city_block46" geometry={nodes.animated_city_block46.geometry} material={materials.lambert1} position={[0.260449, 4.243023, 0]} />
            <mesh name="animated_city_block47" geometry={nodes.animated_city_block47.geometry} material={materials.lambert1} position={[0, 1.664134, 0]} />
            <mesh name="animated_city_block48" geometry={nodes.animated_city_block48.geometry} material={materials.lambert1} position={[2.130163, 3.315958, 0]} />
            <mesh name="animated_city_block49" geometry={nodes.animated_city_block49.geometry} material={materials.lambert1} position={[0.22782, 3.446381, 0]} />
            <mesh name="animated_city_block50" geometry={nodes.animated_city_block50.geometry} material={materials.lambert1} position={[-0.291721, 3.121541, 0]} />
            <mesh name="animated_city_block51" geometry={nodes.animated_city_block51.geometry} material={materials.lambert1} position={[2.254467, 3.830214, 0]} />
            <mesh name="animated_city_block52" geometry={nodes.animated_city_block52.geometry} material={materials.lambert1} position={[-0.302304, 0.974115, 0]} />
            <mesh name="animated_city_block53" geometry={nodes.animated_city_block53.geometry} material={materials.lambert1} position={[1.729428, 0.449256, 0]} />
            <mesh name="animated_city_block54" geometry={nodes.animated_city_block54.geometry} material={materials.lambert1} position={[2.222823, 3.780932, 0]} />
            <mesh name="animated_city_block55" geometry={nodes.animated_city_block55.geometry} material={materials.lambert1} position={[0, 2.727366, 0]} />
            <mesh name="animated_city_block56" geometry={nodes.animated_city_block56.geometry} material={materials.lambert1} position={[0, 6.835256, -1.356224]} />
            <mesh name="animated_city_block57" geometry={nodes.animated_city_block57.geometry} material={materials.lambert1} position={[2.61975, 8.923805, 0]} />
            <mesh name="animated_city_block58" geometry={nodes.animated_city_block58.geometry} material={materials.lambert1} position={[0, 5.438202, 0]} />
            <mesh name="animated_city_block59" geometry={nodes.animated_city_block59.geometry} material={materials.lambert1} position={[3.46519, 7.937935, -1.213604]} />
            <mesh name="animated_city_block60" geometry={nodes.animated_city_block60.geometry} material={materials.lambert1} position={[-2.818588, 3.305842, 0]} />
            <mesh name="animated_city_block61" geometry={nodes.animated_city_block61.geometry} material={materials.lambert1} position={[0, 2.909858, 0]} />
            <mesh name="animated_city_block62" geometry={nodes.animated_city_block62.geometry} material={materials.lambert1} position={[0, 4.249858, 0]} />
            <mesh name="animated_city_block63" geometry={nodes.animated_city_block63.geometry} material={materials.lambert1} position={[2.61975, 3.369076, -1.430316]} />
            <mesh name="animated_city_block64" geometry={nodes.animated_city_block64.geometry} material={materials.lambert1} position={[2.696674, 6.104709, -0.71673]} />
            <mesh name="animated_city_block65" geometry={nodes.animated_city_block65.geometry} material={materials.lambert1} position={[3.844842, 9.392734, 0]} />
            <mesh name="animated_city_block66" geometry={nodes.animated_city_block66.geometry} material={materials.lambert1} position={[2.61975, 10.194653, -1.430316]} />
            <mesh name="animated_city_block67" geometry={nodes.animated_city_block67.geometry} material={materials.lambert1} position={[4.036746, 6.129208, -1.430316]} />
            <mesh name="animated_city_block68" geometry={nodes.animated_city_block68.geometry} material={materials.lambert1} position={[1.085889, 3.830214, -1.27151]} />
            <mesh name="animated_city_block69" geometry={nodes.animated_city_block69.geometry} material={materials.lambert1} position={[1.595608, 5.783541, 0]} />
            <mesh name="animated_city_block70" geometry={nodes.animated_city_block70.geometry} material={materials.lambert1} position={[0, 6.24834, 0]} />
            <mesh name="animated_city_block71" geometry={nodes.animated_city_block71.geometry} material={materials.lambert1} position={[5.500961, 8.891353, 0]} />
            <mesh name="animated_city_block72" geometry={nodes.animated_city_block72.geometry} material={materials.lambert1} position={[0, 4.592647, 0]} />
            <mesh name="animated_city_block73" geometry={nodes.animated_city_block73.geometry} material={materials.lambert1} position={[2.61975, 11.044804, 0]} />
            <mesh name="animated_city_block74" geometry={nodes.animated_city_block74.geometry} material={materials.lambert1} position={[0, 6.576165, 0]} />
            <mesh name="animated_city_block75" geometry={nodes.animated_city_block75.geometry} material={materials.lambert1} position={[2.61975, 7.705041, -0.90335]} />
            <mesh name="animated_city_block76" geometry={nodes.animated_city_block76.geometry} material={materials.lambert1} position={[1.115301, 9.556841, 0]} />
            <mesh name="animated_city_block77" geometry={nodes.animated_city_block77.geometry} material={materials.lambert1} position={[2.958901, 2.399282, 0]} />
            <mesh name="animated_city_block78" geometry={nodes.animated_city_block78.geometry} material={materials.lambert1} position={[0, 3.126306, 0]} />
            <mesh name="animated_city_block79" geometry={nodes.animated_city_block79.geometry} material={materials.lambert1} position={[2.9812, 4.173553, 0]} />
            <mesh name="animated_city_block80" geometry={nodes.animated_city_block80.geometry} material={materials.lambert1} position={[0.167497, 4.514171, -0.745897]} />
            <mesh name="animated_city_block81" geometry={nodes.animated_city_block81.geometry} material={materials.lambert1} position={[0, 3.333989, 2.866775]} />
            <mesh name="animated_city_block82" geometry={nodes.animated_city_block82.geometry} material={materials.lambert1} position={[0.60706, 8.191112, 1.59215]} />
            <mesh name="animated_city_block83" geometry={nodes.animated_city_block83.geometry} material={materials.lambert1} position={[3.261327, 3.937607, 1.177355]} />
            <mesh name="animated_city_block84" geometry={nodes.animated_city_block84.geometry} material={materials.lambert1} position={[4.036746, 6.129208, 0]} />
            <mesh name="animated_city_block85" geometry={nodes.animated_city_block85.geometry} material={materials.lambert1} position={[0, 1.429346, 0]} />
            <mesh name="animated_city_block86" geometry={nodes.animated_city_block86.geometry} material={materials.lambert1} position={[2.9812, 1.910807, 0]} />
            <mesh name="animated_city_block87" geometry={nodes.animated_city_block87.geometry} material={materials.lambert1} position={[2.61975, 8.271569, 0]} />
            <mesh name="animated_city_block88" geometry={nodes.animated_city_block88.geometry} material={materials.lambert1} position={[-1.566563, 1.906016, 0]} />
            <mesh name="animated_city_block89" geometry={nodes.animated_city_block89.geometry} material={materials.lambert1} position={[-2.818588, 4.514171, 0]} />
            <mesh name="animated_city_block90" geometry={nodes.animated_city_block90.geometry} material={materials.lambert1} position={[1.184665, 7.780705, 0]} />
            <mesh name="animated_city_block91" geometry={nodes.animated_city_block91.geometry} material={materials.lambert1} position={[5.500961, 8.891353, 0]} />
            <mesh name="animated_city_block92" geometry={nodes.animated_city_block92.geometry} material={materials.lambert1} position={[1.179647, 8.241433, -3.600789]} />
            <mesh name="animated_city_block93" geometry={nodes.animated_city_block93.geometry} material={materials.lambert1} position={[2.61975, 4.605204, 1.387594]} />
            <mesh name="animated_city_block94" geometry={nodes.animated_city_block94.geometry} material={materials.lambert1} position={[-0.203509, 2.74445, 0]} />
            <mesh name="animated_city_block95" geometry={nodes.animated_city_block95.geometry} material={materials.lambert1} position={[1.184665, 9.509661, 0]} />
            <mesh name="animated_city_block96" geometry={nodes.animated_city_block96.geometry} material={materials.lambert1} position={[1.418708, 4.249858, 0.668645]} />
            <mesh name="animated_city_block97" geometry={nodes.animated_city_block97.geometry} material={materials.lambert1} position={[-1.127814, 2.21017, 0]} />
            <mesh name="animated_city_block98" geometry={nodes.animated_city_block98.geometry} material={materials.lambert1} position={[2.657044, 4.514171, 0]} />
            <mesh name="animated_city_block99" geometry={nodes.animated_city_block99.geometry} material={materials.lambert1} position={[0.737888, 3.830214, 0]} />
            <mesh name="animated_city_block100" geometry={nodes.animated_city_block100.geometry} material={materials.lambert1} position={[2.61975, 7.691635, 0]} />
            <mesh name="animated_city_block101" geometry={nodes.animated_city_block101.geometry} material={materials.lambert1} position={[3.007985, 4.514171, 0]} />
            <mesh name="animated_city_block102" geometry={nodes.animated_city_block102.geometry} material={materials.lambert1} position={[4.718519, 10.511766, 0.32165]} />
            <mesh name="animated_city_block103" geometry={nodes.animated_city_block103.geometry} material={materials.lambert1} position={[1.115301, 6.71657, 0]} />
            <mesh name="animated_city_block104" geometry={nodes.animated_city_block104.geometry} material={materials.lambert1} position={[2.529509, 3.937607, -0.782897]} />
            <mesh name="animated_city_block105" geometry={nodes.animated_city_block105.geometry} material={materials.lambert1} position={[2.927035, 1.76943, 0]} />
            <mesh name="animated_city_block106" geometry={nodes.animated_city_block106.geometry} material={materials.lambert1} position={[-0.255438, 2.208537, 0]} />
            <mesh name="animated_city_block107" geometry={nodes.animated_city_block107.geometry} material={materials.lambert1} position={[2.61975, 5.440562, 0]} />
            <mesh name="animated_city_block108" geometry={nodes.animated_city_block108.geometry} material={materials.lambert1} position={[-0.95773, 3.084306, 0]} />
            <mesh name="animated_city_block109" geometry={nodes.animated_city_block109.geometry} material={materials.lambert1} position={[2.466359, 8.958035, 0]} />
            <mesh name="animated_city_block110" geometry={nodes.animated_city_block110.geometry} material={materials.lambert1} position={[0, 4.514171, 0]} />
            <mesh name="animated_city_block111" geometry={nodes.animated_city_block111.geometry} material={materials.lambert1} position={[2.294945, 0.579631, 0]} />
            <mesh name="animated_city_block112" geometry={nodes.animated_city_block112.geometry} material={materials.lambert1} position={[2.222823, 5.690783, -1.996502]} />
            <mesh name="animated_city_block113" geometry={nodes.animated_city_block113.geometry} material={materials.lambert1} position={[-1.06266, 4.249858, 0]} />
            <mesh name="animated_city_block114" geometry={nodes.animated_city_block114.geometry} material={materials.lambert1} position={[2.9812, 1.809773, 0]} />
            <mesh name="animated_city_block115" geometry={nodes.animated_city_block115.geometry} material={materials.lambert1} position={[4.083965, 9.469125, -1.430316]} />
            <mesh name="animated_city_block116" geometry={nodes.animated_city_block116.geometry} material={materials.lambert1} position={[0.6974, 2.828845, 0.466869]} />
            <mesh name="animated_city_block117" geometry={nodes.animated_city_block117.geometry} material={materials.lambert1} position={[2.61975, 9.668287, 0]} />
            <mesh name="animated_city_block118" geometry={nodes.animated_city_block118.geometry} material={materials.lambert1} position={[0.270835, 4.343062, 1.740865]} />
            <mesh name="animated_city_block119" geometry={nodes.animated_city_block119.geometry} material={materials.lambert1} position={[1.334107, 6.435039, 0]} />
            <mesh name="animated_city_block120" geometry={nodes.animated_city_block120.geometry} material={materials.lambert1} position={[-2.981608, 5.032453, 1.476474]} />
            <mesh name="animated_city_block121" geometry={nodes.animated_city_block121.geometry} material={materials.lambert1} position={[0, 6.966331, -1.577944]} />
            <mesh name="animated_city_block122" geometry={nodes.animated_city_block122.geometry} material={materials.lambert1} position={[-2.114462, 5.295865, -1.436801]} />
            <mesh name="animated_city_block123" geometry={nodes.animated_city_block123.geometry} material={materials.lambert1} position={[-2.498605, 8.097431, 0]} />
            <mesh name="animated_city_block124" geometry={nodes.animated_city_block124.geometry} material={materials.lambert1} position={[2.520448, 7.413019, 0]} />
            <mesh name="animated_city_block125" geometry={nodes.animated_city_block125.geometry} material={materials.lambert1} position={[2.61975, 10.051557, 0]} />
            <mesh name="animated_city_block126" geometry={nodes.animated_city_block126.geometry} material={materials.lambert1} position={[2.9812, 4.000178, 0]} />
            <mesh name="animated_city_block127" geometry={nodes.animated_city_block127.geometry} material={materials.lambert1} position={[-4.051927, 4.894258, -0.185257]} />
            <mesh name="animated_city_block128" geometry={nodes.animated_city_block128.geometry} material={materials.lambert1} position={[0, 1.794067, 0]} />
            <mesh name="animated_city_block129" geometry={nodes.animated_city_block129.geometry} material={materials.lambert1} position={[0.515083, 3.737042, 1.350918]} />
            <mesh name="animated_city_block130" geometry={nodes.animated_city_block130.geometry} material={materials.lambert1} position={[1.13976, 4.859322, -2.088201]} />
            <mesh name="animated_city_block131" geometry={nodes.animated_city_block131.geometry} material={materials.lambert1} position={[-0.240482, 8.251962, 0]} />
            <mesh name="animated_city_block132" geometry={nodes.animated_city_block132.geometry} material={materials.lambert1} position={[2.918123, 6.353156, 0]} />
            <mesh name="animated_city_block133" geometry={nodes.animated_city_block133.geometry} material={materials.lambert1} position={[0, 6.835256, -1.356224]} />
            <mesh name="animated_city_block134" geometry={nodes.animated_city_block134.geometry} material={materials.lambert1} position={[2.61975, 4.049387, 0]} />
            <mesh name="animated_city_block135" geometry={nodes.animated_city_block135.geometry} material={materials.lambert1} position={[-0.165882, 5.495696, 0]} />
            <mesh name="animated_city_block136" geometry={nodes.animated_city_block136.geometry} material={materials.lambert1} position={[-1.168376, 1.794067, 0]} />
            <mesh name="animated_city_block137" geometry={nodes.animated_city_block137.geometry} material={materials.lambert1} position={[0, 1.467626, 0]} />
            <mesh name="animated_city_block138" geometry={nodes.animated_city_block138.geometry} material={materials.lambert1} position={[0, 1.794067, 0]} />
            <mesh name="animated_city_block139" geometry={nodes.animated_city_block139.geometry} material={materials.lambert1} position={[-1.128751, 4.514171, -1.803424]} />
            <mesh name="animated_city_block140" geometry={nodes.animated_city_block140.geometry} material={materials.lambert1} position={[-0.466709, 5.000695, 0]} />
            <mesh name="animated_city_block141" geometry={nodes.animated_city_block141.geometry} material={materials.lambert1} position={[1.871895, 4.379514, 0]} />
            <mesh name="animated_city_block142" geometry={nodes.animated_city_block142.geometry} material={materials.lambert1} position={[0, 0.982832, 0]} />
            <mesh name="animated_city_block143" geometry={nodes.animated_city_block143.geometry} material={materials.lambert1} position={[1.100982, 1.751428, 1.498927]} />
            <mesh name="animated_city_block144" geometry={nodes.animated_city_block144.geometry} material={materials.lambert1} position={[1.184665, 8.191112, 0]} />
            <mesh name="animated_city_block145" geometry={nodes.animated_city_block145.geometry} material={materials.lambert1} position={[0, 4.514171, -1.551826]} />
            <mesh name="animated_city_block146" geometry={nodes.animated_city_block146.geometry} material={materials.lambert1} position={[1.072723, 3.830214, -0.34361]} />
            <mesh name="animated_city_block147" geometry={nodes.animated_city_block147.geometry} material={materials.lambert1} position={[0.208714, 6.407681, 0]} />
          </group>
          <group name="city_blocks_static">
            <group name="building1">
              <mesh name="building1_1" geometry={nodes.building1_1.geometry} material={materials.lambert1} />
              <mesh name="building1_2" geometry={nodes.building1_2.geometry} material={materials.lambert1} />
            </group>
            <group name="building2">
              <mesh name="building2_1" geometry={nodes.building2_1.geometry} material={materials.lambert1} />
              <mesh name="building2_2" geometry={nodes.building2_2.geometry} material={materials.lambert1} />
            </group>
            <group name="building3">
              <mesh name="building3_1" geometry={nodes.building3_1.geometry} material={materials.lambert1} />
              <mesh name="building3_2" geometry={nodes.building3_2.geometry} material={materials.lambert1} />
            </group>
            <group name="building4">
              <mesh name="building4_1" geometry={nodes.building4_1.geometry} material={materials.lambert1} />
              <mesh name="building4_2" geometry={nodes.building4_2.geometry} material={materials.lambert1} />
            </group>
            <group name="building5">
              <mesh name="building5_1" geometry={nodes.building5_1.geometry} material={materials.lambert1} />
              <mesh name="building5_2" geometry={nodes.building5_2.geometry} material={materials.lambert1} />
            </group>
            <group name="building6">
              <mesh name="building6_1" geometry={nodes.building6_1.geometry} material={materials.lambert1} />
              <mesh name="building6_2" geometry={nodes.building6_2.geometry} material={materials.lambert1} />
            </group>
            <group name="building7">
              <mesh name="building7_1" geometry={nodes.building7_1.geometry} material={materials.lambert1} />
              <mesh name="building7_2" geometry={nodes.building7_2.geometry} material={materials.lambert1} />
            </group>
            <mesh name="building7_cafe_wall" geometry={nodes.building7_cafe_wall.geometry} material={materials.lambert1} />
          </group>
        </group>
      </group>
  )
}

export default Buildings