import React, { useCallback } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { useWindowSize } from "react-use";
import { useBoundStore } from "../../stores/useBoundStore";
import classNames from "classnames";
import { ReactComponent as Key } from "../../assets/svg/key-icon.svg";
import PropTypes from "prop-types";
import Fireworks from "./Fireworks";

gsap.registerPlugin(ScrollToPlugin);

const Holder = styled.div`
  position: fixed;
  z-index: 20000;
  bottom: 1rem;
  left: 1rem;
  width: calc(100% - 144px);
  @media (${(props) => props.theme.breakpoints.lg}) {
    top: 45%;
    bottom: auto;
    left: 75%;
    width: calc(25% - 1rem);
  }
`;

const PopUp = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media (${(props) => props.theme.breakpoints.lg}) {
    top: 0;
    bottom: auto;
  }

  .keyHeader {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .keyButton {
    border: 1px solid white;
    border-radius: 2rem;
    width: fit-content;
    padding: 0.4rem 1rem;
    font-weight: bold;
  }

  p {
    margin: 0;
    line-height: 1.4;
    ${(props) => props.theme.fluidType(-1)};
  }

  button {
    margin-top: 0.5rem;
  }

  .scene-anchor {
    display: none;
    font-size: 8px;
    margin: 0;
    position: absolute;
    bottom: 0.75rem;
    right: 0.75rem;
  }

  &.key-moment {
    color: white;
    background-color: var(--orange);

    p {
      ${(props) => props.theme.fluidType(0)};
    }
  }
`;

export const textTimeline = (target, last = false, secondLast = false) => {
  let tl = gsap.timeline();

  // Enters from below
  tl.fromTo(
    target,
    {
      yPercent: 100,
      y: 10,
      scale: 0.9,
      autoAlpha: 0,
    },
    {
      yPercent: 0,
      y: 0,
      scale: 1,
      autoAlpha: 1,
      duration: 1,
      ease: "power2.out",
    }
  );

  // Goes up by one position
  if (!last) {
    tl.to(target, {
      yPercent: -100,
      y: -10,
      scale: 0.9,
      autoAlpha: 0.4,
      duration: 1,
      ease: "power2.out",
    });
  }

  // Leaves entirely
  if (!last && !secondLast) {
    tl.to(target, {
      yPercent: -200,
      autoAlpha: 0,
      duration: 1,
      ease: "power2.out",
    });
  }

  return tl;
};

export const textTimelineMobile = (target, last = false) => {
  let tl = gsap.timeline();

  // Enters from below
  tl.fromTo(
    target,
    {
      scale: 0.9,
      autoAlpha: 0,
    },
    {
      scale: 1,
      autoAlpha: 1,
      duration: 1,
      ease: "power2.out",
    }
  );

  // Fades out
  if (!last) {
    tl.to(target, {
      scale: 0.9,
      autoAlpha: 0,
      duration: 1,
      ease: "power2.out",
    });
  }

  return tl;
};

function Text(props) {
  const { showModal, setModalContent } = useBoundStore();
  const { textContent, progress } = props;

  const size = useWindowSize();

  const scrollToPosition = useCallback(
    (position, animationSpeed = 1) => {
      gsap.to(window, {
        duration: 1.5 * animationSpeed,
        scrollTo: size.height * (170 - 1) * position + 1,
        ease: "none",
      });
    },
    [size.height]
  );

  const handleNext = useCallback(() => {
    // Find the index of the value after the current scroll
    const nextIndex = textContent.findIndex(
      (keyframe) => keyframe.in > progress
    );
    const index = Math.min(textContent.length - 1, nextIndex);
    const nextValue = textContent[index].in;
    const animationSpeed = textContent[nextIndex].animationSpeed;
    scrollToPosition(nextValue, animationSpeed);
  }, [textContent, progress, scrollToPosition]);

  const handleClick = (content) => {
    setModalContent(content);
    showModal();
  };
  return (
    <Holder>
      {props.textContent.map((textObject, i) => {
        const popUpClasses = classNames("text-pop-up", `text-pop-up-${i + 1}`, {
          "key-moment": textObject.keyMoment,
        });
        return (
          <PopUp key={i} className={popUpClasses}>
            {textObject.keyMoment && (
              <div className="keyHeader">
                <Key />
                <p>
                  <strong>Key Concept:</strong>
                </p>
              </div>
            )}
            {typeof textObject.text === "string" ? (
              <p>{textObject.text}</p>
            ) : (
              textObject.text
            )}
            {textObject.sceneAnchor !== undefined && (
              <p className="scene-anchor">{textObject.sceneAnchor}</p>
            )}
            {textObject.modal && (
              <button
                className="smallGreen"
                onClick={() => handleClick(textObject.modal)}
              >
                Dive deeper
              </button>
            )}
            {textObject.link && (
              <a
                className="button smallGreen"
                href={textObject.link}
                target="_blank"
                rel="noreferrer"
              >
                {textObject.linkTitle || "Visit Link"}
              </a>
            )}
            {textObject.keyMoment && (
              <button
                onClick={() => {
                  handleNext();
                }}
                className="keyButton"
              >
                Got it
              </button>
            )}
            {textObject.celebration && (
              <Fireworks show={props.currentTextIndex === i + 1} />
            )}
          </PopUp>
        );
      })}
    </Holder>
  );
}

Text.propTypes = {
  textContent: PropTypes.array.isRequired,
  progress: PropTypes.number.isRequired,
  currentTextIndex: PropTypes.number.isRequired,
};

export default Text;

