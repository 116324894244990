import Audio0 from "../../assets/audio/ipa/0.mp3";
import Audio1 from "../../assets/audio/ipa/1.mp3";
import Audio2 from "../../assets/audio/ipa/2.mp3";
import Audio3 from "../../assets/audio/ipa/3.mp3";
import Audio4 from "../../assets/audio/ipa/4.mp3";
import Audio5 from "../../assets/audio/ipa/5.mp3";
import Audio6 from "../../assets/audio/ipa/6.mp3";
import Audio7 from "../../assets/audio/ipa/7.mp3";
import Audio8 from "../../assets/audio/ipa/8.mp3";
import Audio9 from "../../assets/audio/ipa/9.mp3";
import Audio10 from "../../assets/audio/ipa/10.mp3";
import Audio11 from "../../assets/audio/ipa/11.mp3";
import Audio12 from "../../assets/audio/ipa/12.mp3";
import Audio13 from "../../assets/audio/ipa/13.mp3";
import Audio14 from "../../assets/audio/ipa/14.mp3";
import Audio15 from "../../assets/audio/ipa/15.mp3";
import Audio16 from "../../assets/audio/ipa/16.mp3";
import Audio17 from "../../assets/audio/ipa/17.mp3";
import Audio18 from "../../assets/audio/ipa/18.mp3";
import Audio19 from "../../assets/audio/ipa/19.mp3";

export const audio = [
  { file: Audio0 },
  { file: Audio1 },
  { file: Audio2 },
  { file: Audio3 },
  { file: Audio4 },
  { file: Audio5 },
  { file: Audio6 },
  { file: Audio7 },
  { file: Audio8 },
  { file: Audio9 },
  { file: Audio10 },
  { file: Audio11 },
  { file: Audio12 },
  { file: Audio13 },
  { file: Audio14 },
  { file: Audio15 },
  { file: Audio16 },
  { file: Audio17 },
  { file: Audio18 },
  { file: Audio19 },
];
