import Audio0 from "../../assets/audio/secret/0.mp3";
import Audio1 from "../../assets/audio/secret/1.mp3";
import Audio2 from "../../assets/audio/secret/2.mp3";
import Audio3 from "../../assets/audio/secret/3.mp3";
import Audio4 from "../../assets/audio/secret/4.mp3";
import Audio5 from "../../assets/audio/secret/5.mp3";
import Audio6 from "../../assets/audio/secret/6.mp3";
import Audio7 from "../../assets/audio/secret/7.mp3";
import Audio8 from "../../assets/audio/secret/8.mp3";
import Audio9 from "../../assets/audio/secret/9.mp3";
import Audio10 from "../../assets/audio/secret/10.mp3";
import Audio11 from "../../assets/audio/secret/11.mp3";
import Audio12 from "../../assets/audio/secret/12.mp3";
import Audio13 from "../../assets/audio/secret/13.mp3";
import Audio14 from "../../assets/audio/secret/14.mp3";
import Audio15 from "../../assets/audio/secret/15.mp3";
import Audio16 from "../../assets/audio/secret/16.mp3";
import Audio17 from "../../assets/audio/secret/17.mp3";
import Audio18 from "../../assets/audio/secret/18.mp3";
import Audio19 from "../../assets/audio/secret/19.mp3";
import Audio20 from "../../assets/audio/secret/20.mp3";
import Audio21 from "../../assets/audio/secret/21.mp3";
import Audio22 from "../../assets/audio/secret/22.mp3";
import Audio23 from "../../assets/audio/secret/23.mp3";
import Audio24 from "../../assets/audio/secret/24.mp3";
import Audio25 from "../../assets/audio/secret/25.mp3";
import Audio26 from "../../assets/audio/secret/26.mp3";
import Audio27 from "../../assets/audio/secret/27.mp3";
import Audio28 from "../../assets/audio/secret/28.mp3";
import Audio29 from "../../assets/audio/secret/29.mp3";
import Audio30 from "../../assets/audio/secret/30.mp3";
import Audio31 from "../../assets/audio/secret/31.mp3";
import Audio32 from "../../assets/audio/secret/32.mp3";
import Audio33 from "../../assets/audio/secret/33.mp3";
import Audio34 from "../../assets/audio/secret/34.mp3";
import Audio35 from "../../assets/audio/secret/35.mp3";
import Audio36 from "../../assets/audio/secret/36.mp3";
import Audio37 from "../../assets/audio/secret/37.mp3";
import Audio38 from "../../assets/audio/secret/38.mp3";
import Audio39 from "../../assets/audio/secret/39.mp3";
import Audio40 from "../../assets/audio/secret/40.mp3";

export const audio = [
  { file: Audio0 },
  { file: Audio1 },
  { file: Audio2 },
  { file: Audio3 },
  { file: Audio4 },
  { file: Audio5 },
  { file: Audio6 },
  { file: Audio7 },
  { file: Audio8 },
  { file: Audio9 },
  { file: Audio10 },
  { file: Audio11 },
  { file: Audio12 },
  { file: Audio13 },
  { file: Audio14 },
  { file: Audio15 },
  { file: Audio16 },
  { file: Audio17 },
  { file: Audio18 },
  { file: Audio19 },
  { file: Audio20 },
  { file: Audio21 },
  { file: Audio22 },
  { file: Audio23 },
  { file: Audio24 },
  { file: Audio25 },
  { file: Audio26 },
  { file: Audio27 },
  { file: Audio28 },
  { file: Audio29 },
  { file: Audio30 },
  { file: Audio31 },
  { file: Audio32 },
  { file: Audio33 },
  { file: Audio34 },
  { file: Audio35 },
  { file: Audio36 },
  { file: Audio37 },
  { file: Audio38 },
  { file: Audio39 },
  { file: Audio40 },
];

