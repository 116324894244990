import { useBoundStore } from "../stores/useBoundStore";
import { useEffect, useRef, useState } from "react";
import {useLocation} from "react-router-dom";

export default function useTrigger(triggerStart, triggerEnd = null) {
  const [active, setActive] = useState(false);
  const location = useLocation();
  const id = location.pathname === '/' ? 'home' : location.pathname.split("/")[1]
  const progressName = `${id}Progress`

  // Rapidly changing state: https://github.com/pmndrs/zustand#transient-updates-for-often-occurring-state-changes
  const progressRef = useRef(useBoundStore.getState()[progressName]);
  useEffect(() =>
    useBoundStore.subscribe((state) => {

      progressRef.current = state[progressName];
      // Set state to active if progress is after trigger start and, if trigger end exists, before trigger end
      triggerStart && setActive(
        progressRef.current >= triggerStart &&
        (triggerEnd === null || progressRef.current <= triggerEnd)
      );
    })
  );

  if(!triggerStart) return null;
  return active;
}