/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useMemo, useRef} from "react";
import {useAnimations, useGLTF} from "@react-three/drei";
import BikeLock from "../models/Bike-lock";
import useTriggerAnimation from "../../../hooks/useTriggerAnimation";
import useNewMaterials from "../../../hooks/useNewMaterials";
import {text} from "../../../data/secret-data/text";
import useTrigger from "../../../hooks/useTrigger";
import {somethingVerySmall} from "../../../helpers/scrollHelpers";

export default function BikeLockHolder() {
  const hide = useTrigger(text[23].adjustedIn - somethingVerySmall, text[26].adjustedIn - somethingVerySmall);
  const group = useRef();

  const lockTriggers = {
    randomToOpen: text[2].adjustedIn - somethingVerySmall,
    closeToRandom: text[4].adjustedIn,
    randomToCode: text[7].adjustedIn,
    spin4BackTo8: text[11].adjustedIn - somethingVerySmall,
    spin8ForwardsTo4: text[16].adjustedIn -somethingVerySmall,
    scrambleOne: text[20].adjustedIn,
    scrambleTwo: text[30].adjustedIn,
  }

  const {nodes, materials, animations} = useGLTF('/SceneSecret/bike-lock-transformed.glb')
  const {actions} = useAnimations(animations, group)


  // Set all the meshes to our blue highlight material except for the pasted__material_numbers
  const specificMaterials = useMemo(() => [
    {name: 'lambert2', color: 'blue4'},
  ], [])
  const newMaterials = useNewMaterials(materials, {
    baseMaterial: 'blue1',
    excludeMaterials: 'pasted__material_numbers',
    specificMaterials: specificMaterials
  })

  //Random to open
  useTriggerAnimation(lockTriggers.randomToOpen, 'randomToOpen', actions, null, false, 1.6)

  // 4206 to 6710 / close to random
  useTriggerAnimation(lockTriggers.closeToRandom, 'closteToRandom', actions, null, false, 1.8)

  // 6710 to 4206 / random to code
  useTriggerAnimation(lockTriggers.randomToCode, 'randomToCode', actions, null, false, 1.6)

  // 4 to 8 back
  useTriggerAnimation(lockTriggers.spin4BackTo8, "dial1Spin4BackTo8",
    actions, null, false, 0.5)

  // 8 to 4 forwards
  useTriggerAnimation(lockTriggers.spin8ForwardsTo4, "dial1Spin8ForwardsTo4",
    actions, null, false, 1)

  //4 to 5 back
  useTriggerAnimation(lockTriggers.scrambleOne, "scrambleOne",
    actions, null, false, 1)

  // 5 to 8 back
  useTriggerAnimation(lockTriggers.scrambleTwo, "scrambleTwo",
    actions, null, false, 1)


  return (
    <group ref={group} position={[0, 0, 4.9]} dispose={null} visible={!hide}>
      <BikeLock nodes={nodes} materials={newMaterials} />
    </group>
  );
}

useGLTF.preload("/SceneSecret/bike-lock-transformed.glb");

