/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useMemo, useRef } from "react";

import useTrigger from "../../../hooks/useTrigger";
import { useAnimations, useGLTF } from "@react-three/drei";
import PineappleTotal from "../models/PineappleTotal";
import useTriggerAnimation from "../../../hooks/useTriggerAnimation";
import useSetFirstAnimationFrame from "../../../hooks/useSetFirstAnimationFrame";
import useNewMaterials from "../../../hooks/useNewMaterials";
import { text } from "../../../data/addition-data/text";
import Label from "../../Label";

export default function PineappleTotalHolder() {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(
    "/SceneAddition/pineapple-total-transformed.glb"
  );
  const specificMaterials = useMemo(
    () => [
      {
        name: "number_material_1",
        replaceWith: `number_material_1`,
        setMaterialColor: 0xffffff,
      },
      {
        name: "number_material_6",
        replaceWith: `number_material_6`,
        setMaterialColor: 0xffffff,
      },
      {
        name: "number_material_4",
        replaceWith: `number_material_4`,
        setMaterialColor: 0xffffff,
      },
      {
        name: "number_material_3",
        replaceWith: `number_material_3`,
        setMaterialColor: 0xffffff,
      },
      {
        name: "number_material_9",
        replaceWith: `number_material_9`,
        setMaterialColor: 0xffffff,
      },
      {
        name: "typeBlinn",
        replaceWith: `typeBlinn`,
        setMaterialColor: 0xffffff,
      },
    ],
    []
  );
  const newMaterials = useNewMaterials(materials, {
    baseMaterial: "pink1",
    specificMaterials: specificMaterials,
  });
  const { actions } = useAnimations(animations, group);

  const show = useTrigger(text[21].adjustedIn);
  const showLabel = show;

  useSetFirstAnimationFrame("pops-up", actions);
  useTriggerAnimation(text[21].adjustedIn, "pops-up", actions, null, false, 2);

  return (
    <>
      <group
        position={[0, 3.5, 0]}
        scale={0.2}
        ref={group}
        dispose={null}
        visible={show}
      >
        <PineappleTotal nodes={nodes} materials={newMaterials} />
        <Label
          show={showLabel}
          position={[12, -0.2, 0]}
          fullWidthLabel={true}
          targetWidth={70}
          targetHeight={70}
        />
      </group>
    </>
  );
}

useGLTF.preload("/SceneAddition/pineapple-total-transformed.glb");

