import React, { useRef } from "react";
import { useAnimations, useGLTF } from "@react-three/drei";
import PropTypes from "prop-types";
import useTriggerAnimation from "../../../hooks/useTriggerAnimation";
import useSetFirstAnimationFrame from "../../../hooks/useSetFirstAnimationFrame";
import Hands from "../models/Hands";
import useNewMaterials from "../../../hooks/useNewMaterials";

export default function HandsHolder({ ...props }) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(
    "/SceneAddition/hands-transformed.glb"
  );
  const newMaterials = useNewMaterials(materials, {
    baseMaterial: `blue3`,
  })
  const { actions } = useAnimations(animations, group);

  useSetFirstAnimationFrame('enter', actions);
  
  useTriggerAnimation(props.in, "enter", actions);
  useTriggerAnimation(props.out, "leave", actions);

  return (
    <group
      ref={group}
      position={props.position}
      dispose={null}
      visible={props.show}
      scale={props.scale}
    >
      <Hands materials={newMaterials} nodes={nodes} />
    </group>
  );
}

HandsHolder.propTypes = {
  show: PropTypes.bool,
  in: PropTypes.number,
  out: PropTypes.number,
  scale: PropTypes.number,
  position: PropTypes.array,
};

HandsHolder.defaultProps = {
  show: true,
  in: null,
  out: null,
  scale: null,
  position: null,
};

useGLTF.preload("/SceneAddition/hands-transformed.glb");

