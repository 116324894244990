import React from "react";
import PropTypes from "prop-types";
import {Html} from "@react-three/drei";
import classNames from "classnames";
import {ReactComponent as TickIcon} from "../assets/svg/tick-icon.inline.svg";
import {ReactComponent as CrossIcon} from "../assets/svg/cross-icon.inline.svg";
import {ReactComponent as LockIcon} from "../assets/svg/lock-icon.inline.svg";
import useIsMobile from "../hooks/useIsMobile";

function Label(props) {
  const {isMobile, isTablet, isDesktop} = useIsMobile();

  const holderClasses = classNames({
    defaultLabel: true,
    [props.labelPosition]: true,
    fullWidthLabel: props.fullWidthLabel,
    [props.color]: true,
    noLabel: !props.text,
    lock: props.lock,
  })

  const labelClasses = classNames({
    label: true,
  })

  if (props.hideOnMobileAndTablet && !isDesktop) {
    return null;
  }

  return (
    <group visible={props.show}>
      <Html
        className={holderClasses}
        position={props.position}
        scale={props.scale}
        style={{
          opacity: props.show ? 1 : 0,
          transform: 'translate(-50%, -50%)',
          fontSize: isMobile ? '10px' : '16px',
        }}
      >
        <div className="target"
            style={{
              width: isMobile ? `${props.targetWidth*props.mobileSize}px` : isTablet ? `${props.targetWidth*props.tabletSize}px` : `${props.targetWidth}px`,
              height: isMobile ? `${props.targetHeight*props.mobileSize}px` : isTablet ? `${props.targetHeight*props.tabletSize}px` : `${props.targetHeight}px`,
            }}
        >
          {props.text &&
            <div className={labelClasses}>
              <p>{props.text}</p>
            </div>
          }
          {props.tick && <div className="tick"><TickIcon /></div>}
          {props.cross && <div className="cross"><CrossIcon /></div>}
          {props.lock && <div className="lock"><LockIcon /></div>}
        </div>
      </Html>
    </group>
  );
}

Label.propTypes = {
  position: PropTypes.array,
  scale: PropTypes.number,
  targetWidth: PropTypes.number,
  targetHeight: PropTypes.number,
  labelWidth: PropTypes.number,
  labelPosition: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  text: PropTypes.string,
  show: PropTypes.bool.isRequired,
  tick: PropTypes.bool,
  cross: PropTypes.bool,
  fullWidthLabel: PropTypes.bool,
  color: PropTypes.oneOf(['white', 'pink']),
  hideOnMobileAndTablet: PropTypes.bool,
  mobileSize: PropTypes.number,
  tabletSize: PropTypes.number,
};

Label.defaultProps = {
  position: [0, 0, 0],
  scale: 0.01,
  targetWidth: 50,
  targetHeight: 50,
  labelWidth: 100,
  labelPosition: 'top',
  tick: false,
  cross: false,
  fullWidthLabel: false,
  color: 'white',
  hideOnMobileAndTablet: false,
  mobileSize: 0.5,
  tabletSize: 0.7,
}

export default Label;
