import GlobalStyle from "./components/GlobalStyles";
import { ThemeProvider } from "styled-components";
import { theme } from "./helpers/styling";
import "./helpers/fontface.css";
import Layout from "./components/Layout";
import { Route, Routes } from "react-router";
import Template from "./pages/Template";
import Home from "./pages/Home";
import Intro from "./pages/Intro";
import Secret from "./pages/Secret";
import Addition from "./pages/Addition";
import Multiplication from "./pages/Multiplication";
import Ipa from "./pages/Ipa";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="template" element={<Template />} />
          <Route path="intro" element={<Intro />} />
          <Route path="secret" element={<Secret />} />
          <Route path="addition" element={<Addition />} />
          <Route path="multiplication" element={<Multiplication />} />
          <Route path="ipa" element={<Ipa />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;

