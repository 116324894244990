/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useMemo, useRef} from "react";

import useTrigger from "../../../hooks/useTrigger";
import { useAnimations, useGLTF } from "@react-three/drei";
import useTriggerAnimation from "../../../hooks/useTriggerAnimation";
import useSetFirstAnimationFrame from "../../../hooks/useSetFirstAnimationFrame";
import useNewMaterials from "../../../hooks/useNewMaterials";
import {text} from "../../../data/multiplication-data/text";
import { somethingVerySmall } from "../../../helpers/scrollHelpers";
import Label from "../../Label";
import HelpersTotal from "../models/HelpersTotal";

export default function HelpersTotalHolder() {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(
    "/SceneMultiplication/total-helpers-transformed.glb"
  );

  const specificMaterials = useMemo(() =>[
    {name: 'number_material_7', replaceWith: `number_material_7`, setMaterialColor: 0xffffff},
    {name: 'number_material_6', replaceWith: `number_material_6`, setMaterialColor: 0xffffff},
    {name: 'number_material_8', replaceWith: `number_material_8`, setMaterialColor: 0xffffff},
    {name: 'number_material_1', replaceWith: `number_material_1`, setMaterialColor: 0xffffff},
    {name: 'number_material_10', replaceWith: `number_material_10`, setMaterialColor: 0xffffff},
    {name: 'typeBlinn', replaceWith: `typeBlinn`, setMaterialColor: 0xffffff},
  
  ], [])
  const newMaterials = useNewMaterials(materials, {
    baseMaterial: "pink1",
    specificMaterials: specificMaterials,
  })
  const { actions } = useAnimations(animations, group);

  const show = useTrigger(text[16].adjustedIn - somethingVerySmall, text[17].adjustedIn - somethingVerySmall);
  const showLabel = show;

  useSetFirstAnimationFrame('pops-up', actions)
  useTriggerAnimation(text[16].adjustedIn - somethingVerySmall, "pops-up", actions, null, false, 2);

  return (
    <>
      <group
        position={[0.95, 0.8, 0.3]}
        rotation={[-Math.PI * 0.17, 0, 0]}
        scale={0.06}
        ref={group}
        dispose={null}
        visible={show}
      >
        <HelpersTotal nodes={nodes} materials={newMaterials} />
        <Label
          show={showLabel}
          position={[12, -0.35, 0]}
          fullWidthLabel={true}
          targetWidth={50}
          targetHeight={40}
        />
      </group>
    </>
  );
}

useGLTF.preload("/SceneMultiplication/total-helpers-transformed.glb");

