import React from "react";
import useTrigger from "../../hooks/useTrigger";
import NewsSiloHolder from "./holders/NewsSiloHolder";
import PizzaSiloHolder from "./holders/PizzaSiloHolder";
import Label from "../Label";
import {text} from "../../data/ipa-data/text";
import {somethingVerySmall} from "../../helpers/scrollHelpers";
import {Shadow} from "@react-three/drei";

const positions = {
  silo: [0, 0.55, 0.1],
  silo1: [-0.27, -0.99, -0.5],
  silo2: [0.27, -0.99, -0.5],
  label1: [-0.08, 1.2, -0.4],
  label2: [0.08, 1.2, -0.4],
}

const SiloGroup = () => {
  const show = useTrigger(text[5].adjustedIn, text[14].adjustedIn);
  const showTextNews = useTrigger(text[9].adjustedIn, text[12].adjustedIn);
  const showTextPizza = useTrigger(text[9].adjustedIn, text[12].adjustedIn);


  return (
    <group visible={show}>

      <group position={positions.silo1}>
        <NewsSiloHolder
          spin={text[9].adjustedIn - somethingVerySmall}
          drop={text[10].adjustedIn}
          position={positions.silo}
          scale={0.3}
        />
        <Shadow color="purple" position-y={0.55} opacity={0.15} scale={0.8}/>
        <Label
          position={positions.label1}
          labelPosition="bottom"
          show={showTextNews}
          targetWidth={300}
          targetHeight={250}
          fullWidthLabel={true}
          text="Pizza shop bought 1000 ad impressions on the News website" />
      </group>

      <group position={positions.silo2}>
        <PizzaSiloHolder
          spin={text[9].adjustedIn - somethingVerySmall}
          drop={text[11].adjustedIn - somethingVerySmall}
          position={positions.silo}
          scale={0.3}
        />
        <Shadow color="purple" position-y={0.55} opacity={0.15} scale={0.8}/>
        <Label
          position={positions.label2}
          labelPosition="bottom"
          show={showTextPizza}
          targetWidth={300}
          targetHeight={250}
          fullWidthLabel={true}
          text="Pizza shop sold 100 pizzas, but how many because of the ads?" />
      </group>

    </group>
  );
};

export default SiloGroup;

