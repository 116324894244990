import React from "react";
import useTrigger from "../../hooks/useTrigger";
import ShareStackHolder from "../shared-models/holders/ShareStackHolder";
import ShareStackAppearHolder from "../shared-models/holders/ShareStackAppearHolder";
import {useSpring, animated} from "@react-spring/three";
import {text} from "../../data/addition-data/text";
import {somethingVerySmall} from "../../helpers/scrollHelpers";

const ShareStackingGroup = () => {
  const showShareStacks = useTrigger(text[10].adjustedIn, text[18].adjustedIn + somethingVerySmall);
  const showAppearStacks = useTrigger(text[10].adjustedIn, text[12].adjustedIn - somethingVerySmall);
  const moveHeroStack = useTrigger(text[14].adjustedIn);

  const springs = useSpring({
    rotation: moveHeroStack ? [0, -Math.PI * 1.05, 0] : [0, -Math.PI * 0.75, 0],
  })
  return (
    <>
    <group visible={showAppearStacks}>
      <ShareStackAppearHolder
          position={[0.06, 1, 0.5]}
          stacks={text[10].adjustedIn}
          number={9}
          color="pink"
        />
    </group>
     <group visible={showShareStacks}>
         
        <ShareStackHolder
          position={[0.3, 1, -0.25]}
          rotation={[0, Math.PI * 0.75, 0]}
          stacks={text[10].adjustedIn}
          number={6}
          color="green"
        />
        <animated.group
          position={[-0.3, 1, -0.3]}
          rotation={springs.rotation}>
          <ShareStackHolder
            stacks={text[10].adjustedIn}
            number={5}
            removeTop={text[16].adjustedIn}
            removeAll={text[17].adjustedIn - somethingVerySmall}
            color="blue"
          />
        </animated.group>
      </group>

    </>
  );
};

export default ShareStackingGroup;

