const myText = [
  {
    text: "Secret-sharing is a way of breaking up data into pieces which on their own are just random numbers.",
  },
  { text: "To see how this works, we can visualize a bike lock:" },
  {
    text: "The lock will only open if you turn each dial to the correct number.",
  },
  {
    text: "These numbers are information you want to keep secret. If someone knows your combination, they could unlock your bike.",
  },
  {
    text: "When it’s time to lock your bike, you spin the dials to random numbers.",
  },
  {
    text: "You don’t mind leaving these numbers exposed because you know they don’t reveal anything about the secret combination.",
  },
  { text: "Secret sharing is similar to this. Here's how..." },
  { text: "Let's unlock the bike lock again" },
  {
    text: "and to simplify things even more we'll talk about one number at a time.",
  },
  { text: "We will need a dice to decide how many clicks to spin the dial." },
  {
    text: "The person who owns the bike rolls the dice where only they can see it...",
  },
  { text: "...and turns the dial down that many clicks." },
  {
    text: "We now have a random number from the dice and a random number shown on the lock.",
  },
  {
    text: "We've just made a two part secret sharing of the number 4; the first number in the secret-combination!",
  },
  {
    text: "As long as you keep these two numbers separate, they will never reveal the secret number.",
  },
  {
    text: "If you have both numbers, it's really easy to get back to the secret number.",
  },
  { text: "Just turn the dial back the number of clicks shown on the dice." },
  {
    text: "...And we're back at the number where we started.",
    celebration: true,
  },
  { text: "Let's do it for all four numbers now." },
  {
    text: "We will roll 4 dice, to get 4 random numbers. Let's call it Set 1.",
  },
  {
    text: "And spin each dial back by the  number of clicks shown on the dice above it.",
  },
  {
    text: "The bike is securely locked. You can't guess the combination from these scrambled values.",
  },
  { text: "Imagine you wrote the 4 numbers from the dice on a slip of paper" },
  { text: "...and handed it to someone." },
  {
    text: "On their own these numbers would tell them nothing about your secret combination.",
  },
  {
    text: "After all, Set 1 is just four random numbers, that we generated by rolling the dice.",
  },
  {
    text: "You'd need BOTH the slip of paper, AND the scrambled bike lock to get back to the secret combination.",
  },
  {
    text: "At this point, we have split our secret bike code into 2 sets of numbers.",
  },
  {
    text: "We can split it into 3 sets, so that more pieces are required to find the secret combination.",
  },
  {
    text: "To do this, we roll another 4 dice to make another set of random numbers...",
  },
  { text: "...then use this set to scramble the lock a second time." },
  { text: "Now we have 3 sets of numbers;" },
  { text: "The first 2 sets are random numbers from the dice rolls," },
  {
    text: "and the 3rd set is the numbers shown on the doubly-scrambled lock.",
  },
  { text: "If someone had just two sets..." },
  { text: "...They would not have enough information to unlock the bike" },
  {
    text: "You need all three sets to get back to the secret combination",
    celebration: true,
  },
  {
    text: 'There is a name for these "Sets" of numbers. They are called "Secret-shares"',
  },
  {
    text: "We will use this icon throughout the rest of the explainer to represent a secret-sharing of a single number.",
  },
  {
    text: "In IPA, information is first secret-shared before it ever leaves the browser or device.",
  },
  {
    text: "Next up, lets see how IPA adds up secret-shared numbers from a lot of people, to calculate total counts.",
  },
];

export const interval = 1 / myText.length;

export const text = myText.map((item, index) => {
  return {
    in: index * interval,
    adjustedIn: (index + 1) * interval,
    sceneAnchor: index,
    ...item,
  };
});

