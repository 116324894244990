import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

const Tooltip = styled.div`
  display: none;
  position: absolute;
  bottom: calc(100% + 1.5rem);
  left: 50%;
  background-color: white;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  transform: translateX(-50%);
  color: var(--purple);
  p {
    white-space: nowrap;
    font-weight: bold;
    margin: 0;
    line-height: 1.2;
    ${(props) => props.theme.fluidType(-2)};;
  }
  
  &.labelLeft {
    left: 0;
    transform: translateX(0);
    &:after {
      left: 1rem;
    }
  }

  &.labelRight {
    left: auto;
    right: 0;
    transform: translateX(0);
    &:after {
      left: auto;
      right: 1rem;
    }
  }
  
  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: calc(50% - 0.5rem);
    border-top: 0.5rem solid white;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
  }
`;


const Holder = styled.div`
    margin-left: 0.25rem;
  order: ${(props) => props.order};
  display: flex;
  justify-content: start;
  align-items: center;
  position: relative;

  .button {
    background-color: #ffffff;
    border-radius: 0.25rem;
    overflow: hidden;
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    @media( ${props => props.theme.breakpoints.lg} ) {
      height: 3rem;
      width: 3rem;
    }
    
    &:hover {
      background-color: var(--green);
    }

    svg {
      height: 1rem;
      width: 1rem;

      @media( ${props => props.theme.breakpoints.lg} ) {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }
  
  &:hover ${Tooltip} {
    @media( ${props => props.theme.breakpoints.lg} ) {
      display: block;
    }
  }
`;

function ProgressLink(props) {
  return (
    <Holder>
      <Tooltip className={ props.isPostLink ? 'labelRight': 'labelLeft'}><p>{props.page.title}</p></Tooltip>
      <Link className="button" to={props.page.url}>{props.page.icon}</Link>
    </Holder>
  )
}

ProgressLink.propTypes = {
  page: PropTypes.object.isRequired,
};

export default ProgressLink;