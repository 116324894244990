// /*
// Auto-generated by: https://github.com/pmndrs/gltfjsx
// */

import React from "react";

export default function ShareStackAppear({ nodes, materials }) {
  return (
    <group>
        <group name="grp_stack_1">
          <group name="grp_share_stack_card_A_01" position={[0, 0.02754, 0]}>
            <mesh name="share_card" geometry={nodes.share_card.geometry} material={materials.lambert2} />
            <mesh name="shareCard_number_plane" geometry={nodes.shareCard_number_plane.geometry} material={materials.number_material_8} />
          </group>
          <group name="grp_share_stack_card_A_02" position={[0.265679, 0.02754, -0.460137]}>
            <mesh name="share_card_(1)" geometry={nodes['share_card_(1)'].geometry} material={materials.lambert4} />
            <mesh name="shareCard_number_plane_(1)" geometry={nodes['shareCard_number_plane_(1)'].geometry} material={materials.number_material_9} />
          </group>
          <group name="grp_share_stack_card_A_03" position={[-0.29655, 0.02754, -0.461897]}>
            <mesh name="share_card_(2)" geometry={nodes['share_card_(2)'].geometry} material={materials.lambert3} />
            <mesh name="shareCard_number_plane_(2)" geometry={nodes['shareCard_number_plane_(2)'].geometry} material={materials.number_material_4} />
          </group>
          <group name="grp_share_stack_card_B_01" position={[0, 0.013758, 0]}>
            <mesh name="share_card_(3)" geometry={nodes['share_card_(3)'].geometry} material={materials.lambert2} />
            <mesh name="shareCard_number_plane_(3)" geometry={nodes['shareCard_number_plane_(3)'].geometry} material={materials.number_material_8} />
          </group>
          <group name="grp_share_stack_card_B_02" position={[0.262951, 0.011388, -0.460918]}>
            <mesh name="share_card_(4)" geometry={nodes['share_card_(4)'].geometry} material={materials.lambert4} />
            <mesh name="shareCard_number_plane_(4)" geometry={nodes['shareCard_number_plane_(4)'].geometry} material={materials.number_material_9} />
          </group>
          <group name="grp_share_stack_card_B_03" position={[-0.297393, 0.013562, -0.462203]}>
            <mesh name="share_card_(5)" geometry={nodes['share_card_(5)'].geometry} material={materials.lambert3} />
            <mesh name="shareCard_number_plane_(5)" geometry={nodes['shareCard_number_plane_(5)'].geometry} material={materials.number_material_4} />
          </group>
          <group name="grp_share_stack_card_C_01">
            <mesh name="share_card_(6)" geometry={nodes['share_card_(6)'].geometry} material={materials.lambert2} />
            <mesh name="shareCard_number_plane_(6)" geometry={nodes['shareCard_number_plane_(6)'].geometry} material={materials.number_material_5} />
          </group>
          <group name="grp_share_stack_card_C_02" position={[0.261656, 0, -0.461169]}>
            <mesh name="share_card_(7)" geometry={nodes['share_card_(7)'].geometry} material={materials.lambert4} />
            <mesh name="shareCard_number_plane_(7)" geometry={nodes['shareCard_number_plane_(7)'].geometry} material={materials.number_material_1} />
          </group>
          <group name="grp_share_stack_card_C_03" position={[-0.296646, 0, -0.462915]}>
            <mesh name="share_card_(8)" geometry={nodes['share_card_(8)'].geometry} material={materials.lambert3} />
            <mesh name="shareCard_number_plane_(8)" geometry={nodes['shareCard_number_plane_(8)'].geometry} material={materials.number_material_4} />
          </group>
          <group name="grp_share_stack_card_D_01" position={[0, 0.04186, 0]}>
            <mesh name="share_card_(9)" geometry={nodes['share_card_(9)'].geometry} material={materials.lambert2} />
            <mesh name="shareCard_number_plane_(9)" geometry={nodes['shareCard_number_plane_(9)'].geometry} material={materials.number_material_3} />
          </group>
          <group name="grp_share_stack_card_D_02" position={[0.263896, 0.040035, -0.45992]}>
            <mesh name="share_card_(10)" geometry={nodes['share_card_(10)'].geometry} material={materials.lambert4} />
            <mesh name="shareCard_number_plane_(10)" geometry={nodes['shareCard_number_plane_(10)'].geometry} material={materials.number_material_9} />
          </group>
          <group name="grp_share_stack_card_D_03" position={[-0.297159, 0.041349, -0.462614]}>
            <mesh name="share_card_(11)" geometry={nodes['share_card_(11)'].geometry} material={materials.lambert3} />
            <mesh name="shareCard_number_plane_(11)" geometry={nodes['shareCard_number_plane_(11)'].geometry} material={materials.number_material_8} />
          </group>
          <group name="grp_share_stack_card_E_01" position={[0.00439, 0.167124, 0.123848]}>
            <group name="group7" position={[0, 0, 0.006249]}>
              <mesh name="share_card_(12)" geometry={nodes['share_card_(12)'].geometry} material={materials.lambert2} />
              <mesh name="shareCard_number_plane_(12)" geometry={nodes['shareCard_number_plane_(12)'].geometry} material={materials.number_material_0} />
            </group>
          </group>
          <group name="grp_share_stack_card_E_02" position={[0.036132, 0.199811, 0.21315]}>
            <group name="group9" position={[-0.000018, 0, 0.007606]} rotation={[0, 0.026313, 0]}>
              <mesh name="share_card_(13)" geometry={nodes['share_card_(13)'].geometry} material={materials.lambert2} />
              <mesh name="shareCard_number_plane_(13)" geometry={nodes['shareCard_number_plane_(13)'].geometry} material={materials.number_material_4} />
            </group>
          </group>
          <group name="grp_share_stack_card_E_03" position={[-0.023439, 0.228944, 0.277992]}>
            <group name="group10" position={[0, 0, 0.007744]} rotation={[0, -0.01341, 0]}>
              <mesh name="share_card_(14)" geometry={nodes['share_card_(14)'].geometry} material={materials.lambert2} />
              <mesh name="shareCard_number_plane_(14)" geometry={nodes['shareCard_number_plane_(14)'].geometry} material={materials.number_material_9} />
            </group>
          </group>
          <group name="grp_share_stack_card_E_04" position={[0.149268, 0.265801, 0.450241]}>
            <group name="group11" position={[0.000038, 0, 0.004274]} rotation={[0, 0.031572, 0]}>
              <mesh name="share_card_(15)" geometry={nodes['share_card_(15)'].geometry} material={materials.lambert2} />
              <mesh name="shareCard_number_plane_(15)" geometry={nodes['shareCard_number_plane_(15)'].geometry} material={materials.number_material_6} />
            </group>
          </group>
          <group name="grp_share_stack_card_E_05" position={[-0.110283, 0.298018, 0.639766]}>
            <mesh name="share_card_(16)" geometry={nodes['share_card_(16)'].geometry} material={materials.lambert2} />
            <mesh name="shareCard_number_plane_(16)" geometry={nodes['shareCard_number_plane_(16)'].geometry} material={materials.number_material_5} />
          </group>
        </group>
      </group>
  );
}

