import DeepDiveThree from "../ModalContent/DeepDiveThree";
import React from "react";
export const myText = [
  {
    text: (
      <>
        <p>
          Now we will talk about how to <b>multiply</b> secret-shared numbers.
        </p>
      </>
    ),
  },
  {
    text: "Most complex problems can be broken down into addition and multiplications",
  },
  {
    text: "In IPA, we need to count the number of purchases that came from people who previously saw an ad. Let’s work through a simple example showing how multiplication can help with problems like this.",
  },
  { text: "Imagine that for each person, we have two secret-shared numbers;" },
  {
    text: "A sharing of a one/zero that indicates if they saw an ad, and a sharing of a one/zero that indicates if they made a purchase.",
  },
  {
    text: "To find each person’s contribution to the total, we just need to multiply these two numbers together.",
  },
  {
    text: "That way, only those people who both saw an ad, and made a purchase will contribute ‘1’.",
  },
  { text: "Each person will secret-share their number." },
  { text: "They put their shares on the table" },
  {
    text: "...but each helper can only see two out of three shares per secret-sharing.",
  },
  {
    text: "Each helper then runs a very simple computation on the 4 numbers it can see.",
  },
  { text: "Blue left multiplied by green left" },
  { text: "...plus green left multiplied by blue right..." },
  { text: "...plus blue left multiplied by green right" },
  { text: "The answer is 108" },
  {
    text: "And spinning a dial 108 clicks, like we have previously, results in 8, this helper's share of the answer.",
  },
  { text: "At this moment, the helpers have a three-way secret-sharing of 1." },
  {
    text: "We just did something amazing, we multiplied two secret-shared numbers!",
    celebration: true,
  },
  {
    text: "We got a secret sharing of 1 because this person both saw an ad and made a purchase.",
  },
  {
    text: "If they hadn’t seen an ad, or if they didn’t make a purchase, their contribution will be ‘0’.",
  },
  {
    text: "By multiplying these numbers together, each person will produce a secret-sharing of either 1 or 0.",
  },
  { text: "And the helpers can add up all these shares." },
  {
    text: "We did it! The total is 3 when added on a dial, that means there were 3 people who saw an ad and made a purchase.",
  },
  {
    text: "Now you know how to multiply two secret-shared numbers, to compute a secret-sharing of their product.",
    celebration: true,
  },
  {
    text: "Since multiplication is a bit involved we skipped one last detail for simplicity.",
    modal: <DeepDiveThree />,
  },
  {
    text: "This was an over-simplification of how attribution really works in IPA. We need to do more things, like check that the ad was seen before the purchase, and decide which ad gets credit if the person saw multiple ads. Multiplications are used in all of these things.",
  },
];

export const interval = 1 / myText.length;

export const text = myText.map((item, index) => {
  return {
    in: index * interval,
    adjustedIn: (index + 1) * interval, // adjustedIn accounts for the opening screen having no text pop-up.
    sceneAnchor: index,
    ...item,
  };
});

