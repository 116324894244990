/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";

export default function ShareStack({ nodes, materials }) {
  return (
    <group>
        <group name="grp_stack">
          <group name="grp_share_stack_card1">
            <group name="group1" position={[0, 0, 0.007356]}>
              <group name="numbers">
                <mesh name="number_0_plane9" geometry={nodes.number_0_plane9.geometry} material={materials.number_material_9} />
                <mesh name="number_0_plane7" geometry={nodes.number_0_plane7.geometry} material={materials.number_material_7} />
                <mesh name="number_0_plane6" geometry={nodes.number_0_plane6.geometry} material={materials.number_material_6} />
                <mesh name="number_0_plane5" geometry={nodes.number_0_plane5.geometry} material={materials.number_material_5} />
                <mesh name="number_0_plane4" geometry={nodes.number_0_plane4.geometry} material={materials.number_material_4} />
                <mesh name="number_0_plane2" geometry={nodes.number_0_plane2.geometry} material={materials.number_material_2} />
                <mesh name="number_0_plane1" geometry={nodes.number_0_plane1.geometry} material={materials.number_material_1} />
                <mesh name="number_0_plane3" geometry={nodes.number_0_plane3.geometry} material={materials.number_material_3} />
              </group>
              <mesh name="number_0_plane8" geometry={nodes.number_0_plane8.geometry} material={materials.number_material_8} />
              <mesh name="share_card" geometry={nodes.share_card.geometry} material={materials.standardSurface2} />
              <mesh name="shareCard_number_plane" geometry={nodes.shareCard_number_plane.geometry} material={materials.number_material_0} />
            </group>
          </group>
          <group name="grp_share_stack_card2" position={[-0.000536, 0.009783, 0.007541]}>
            <group name="group2" position={[0, 0, 0.00722]} rotation={[0, -0.029108, 0]}>
              <mesh name="share_card_(1)" geometry={nodes['share_card_(1)'].geometry} material={materials.standardSurface2} />
              <mesh name="shareCard_number_plane_(1)" geometry={nodes['shareCard_number_plane_(1)'].geometry} material={materials.number_material_2} />
            </group>
          </group>
          <group name="grp_share_stack_card3" position={[0, 0.019825, 0]}>
            <group name="group3" position={[0, 0, 0.007745]}>
              <mesh name="share_card_(2)" geometry={nodes['share_card_(2)'].geometry} material={materials.standardSurface2} />
              <mesh name="shareCard_number_plane_(2)" geometry={nodes['shareCard_number_plane_(2)'].geometry} material={materials.number_material_6} />
            </group>
          </group>
          <group name="grp_share_stack_card4" position={[0, 0.030456, 0]}>
            <group name="group4" position={[-0.000784, 0, 0.008756]} rotation={[0, -0.042113, 0]}>
              <mesh name="share_card_(3)" geometry={nodes['share_card_(3)'].geometry} material={materials.standardSurface2} />
              <mesh name="shareCard_number_plane_(3)" geometry={nodes['shareCard_number_plane_(3)'].geometry} material={materials.number_material_9} />
            </group>
          </group>
          <group name="grp_share_stack_card5" position={[0, 0.040467, 0]}>
            <group name="group5" position={[-0.000826, 0, 0.006706]}>
              <mesh name="share_card_(4)" geometry={nodes['share_card_(4)'].geometry} material={materials.standardSurface2} />
              <mesh name="shareCard_number_plane_(4)" geometry={nodes['shareCard_number_plane_(4)'].geometry} material={materials.number_material_8} />
            </group>
          </group>
          <group name="grp_share_stack_card6" position={[0.00001, 0.049761, -0.000019]} rotation={[0.001709, 0, 0]} scale={0.999002}>
            <group name="group6" position={[-0.000016, 0, 0.007345]} rotation={[0, 0.028832, 0]}>
              <mesh name="share_card_(5)" geometry={nodes['share_card_(5)'].geometry} material={materials.standardSurface2} />
              <mesh name="shareCard_number_plane_(5)" geometry={nodes['shareCard_number_plane_(5)'].geometry} material={materials.number_material_1} />
            </group>
          </group>
          <group name="grp_share_stack_card7" position={[0.000174, 0.066, -0.000342]} rotation={[0.031198, 0, 0]} scale={0.981828}>
            <group name="group7" position={[0, 0, 0.006249]}>
              <mesh name="share_card_(6)" geometry={nodes['share_card_(6)'].geometry} material={materials.standardSurface2} />
              <mesh name="shareCard_number_plane_(6)" geometry={nodes['shareCard_number_plane_(6)'].geometry} material={materials.number_material_0} />
            </group>
          </group>
          <group name="grp_share_stack_card8" position={[0.000626, 0.088609, -0.001111]} rotation={[0.090287, 0, 0]} scale={0.934883}>
            <group name="group8" position={[-0.001241, 0, 0.008879]} rotation={[0, -0.064371, 0]}>
              <mesh name="share_card_(7)" geometry={nodes['share_card_(7)'].geometry} material={materials.standardSurface2} />
              <mesh name="shareCard_number_plane_(7)" geometry={nodes['shareCard_number_plane_(7)'].geometry} material={materials.number_material_7} />
            </group>
          </group>
          <group name="grp_share_stack_card9" position={[0.001177, 0.112505, -0.002075]} rotation={[0.16978, 0, 0]} scale={0.877826}>
            <group name="group9" position={[-0.000018, 0, 0.007606]} rotation={[0, 0.026313, 0]}>
              <mesh name="share_card_(8)" geometry={nodes['share_card_(8)'].geometry} material={materials.standardSurface2} />
              <mesh name="shareCard_number_plane_(8)" geometry={nodes['shareCard_number_plane_(8)'].geometry} material={materials.number_material_4} />
            </group>
          </group>
          <group name="grp_share_stack_card10" position={[0.002668, 0.16305, -0.004545]} rotation={[0.385029, 0, 0]} scale={0.724364}>
            <group name="group10" position={[0, 0, 0.007744]} rotation={[0, -0.01341, 0]}>
              <mesh name="share_card_(9)" geometry={nodes['share_card_(9)'].geometry} material={materials.standardSurface2} />
              <mesh name="shareCard_number_plane_(9)" geometry={nodes['shareCard_number_plane_(9)'].geometry} material={materials.number_material_9} />
            </group>
          </group>
          <group name="grp_share_stack_card11" position={[0.003449, 0.190986, -0.005713]} rotation={[0.497741, 0, 0]} scale={0.644608}>
            <group name="group11" position={[0.000038, 0, 0.004274]} rotation={[0, 0.031572, 0]}>
              <mesh name="share_card_(10)" geometry={nodes['share_card_(10)'].geometry} material={materials.standardSurface2} />
              <mesh name="shareCard_number_plane_(10)" geometry={nodes['shareCard_number_plane_(10)'].geometry} material={materials.number_material_6} />
            </group>
          </group>
          <group name="grp_share_stack_card12" position={[0.004421, 0.222566, -0.007006]} rotation={[0.638166, 0, 0]} scale={0.545875}>
            <mesh name="share_card_(11)" geometry={nodes['share_card_(11)'].geometry} material={materials.standardSurface2} />
            <mesh name="shareCard_number_plane_(11)" geometry={nodes['shareCard_number_plane_(11)'].geometry} material={materials.top_number} />
          </group>
        </group>
      </group>
  );
}

