import React from "react";

const CafeUser = ({ nodes, materials }) => {
  return (
    <group>
      <group name="Riggrp_char">
        <mesh
          name="Rigchar_legs"
          geometry={nodes.Rigchar_legs.geometry}
          material={materials["Rig:standardSurface2"]}
          position={[-2.733785, 0.764651, 0]}
          scale={0}
        >
          <mesh
            name="Rigchar_torso"
            geometry={nodes.Rigchar_torso.geometry}
            material={materials["Rig:standardSurface2"]}
          >
            <mesh
              name="polySurface14"
              geometry={nodes.polySurface14.geometry}
              material={materials["Rig:standardSurface2"]}
            />
            <mesh
              name="polySurface13"
              geometry={nodes.polySurface13.geometry}
              material={materials["Rig:standardSurface2"]}
            >
              <group
                name="phone_mesh2"
                position={[-0.290793, 0.602339, 0.001338]}
                rotation={[1.356727, 0.550892, -1.177149]}
                scale={0}
              >
                <mesh
                  name="phone_mesh2_1"
                  geometry={nodes.phone_mesh2_1.geometry}
                  material={materials.phong_phone_buttons}
                />
                <mesh
                  name="phone_mesh2_2"
                  geometry={nodes.phone_mesh2_2.geometry}
                  material={materials.phong_phone}
                />
                <group
                  name="phone_website_2"
                  position={[-0.108214, -0.050342, 0.623219]}
                  rotation={[-1.847969, -1.112734, -0.883748]}
                >
                  <mesh
                    name="phone_website_2_1"
                    geometry={nodes.phone_website_2_1.geometry}
                    material={materials["asset_2:phong_1"]}
                  />
                  <mesh
                    name="phone_website_2_2"
                    geometry={nodes.phone_website_2_2.geometry}
                    material={materials["asset_2:pasted__phong_phone_buttons"]}
                  />
                  <mesh
                    name="phone_website_2_3"
                    geometry={nodes.phone_website_2_3.geometry}
                    material={materials["asset_2:phong_3"]}
                  />
                </group>
                <group
                  name="pizza_phone_add_2"
                  position={[-0.0761, -0.07631, 0.64225]}
                  rotation={[-1.74483, -1.176574, -0.783776]}
                >
                  <mesh
                    name="pizza_phone_add_2_1"
                    geometry={nodes.pizza_phone_add_2_1.geometry}
                    material={materials.blinn1_text}
                  />
                  <mesh
                    name="pizza_phone_add_2_2"
                    geometry={nodes.pizza_phone_add_2_2.geometry}
                    material={materials.phong_pizza_pepperoni}
                  />
                  <mesh
                    name="pizza_phone_add_2_3"
                    geometry={nodes.pizza_phone_add_2_3.geometry}
                    material={materials.phong_pizza_cheese}
                  />
                  <mesh
                    name="pizza_phone_add_2_4"
                    geometry={nodes.pizza_phone_add_2_4.geometry}
                    material={materials.phong_pizza_pineapple}
                  />
                  <mesh
                    name="pizza_phone_add_2_5"
                    geometry={nodes.pizza_phone_add_2_5.geometry}
                    material={materials.phong_pizza_base}
                  />
                </group>
              </group>
            </mesh>
            <mesh
              name="Rigchar_head"
              geometry={nodes.Rigchar_head.geometry}
              material={materials["Rig:standardSurface2"]}
            >
              <mesh
                name="baseball_hat"
                geometry={nodes.baseball_hat.geometry}
                material={materials["Rig:standardSurface2"]}
                position={[-0.441559, -0.135221, -0.332983]}
                rotation={[0.099204, -0.810198, -0.12901]}
                scale={0.734667}
              />
            </mesh>
          </mesh>
        </mesh>
      </group>
      <group name="cafe_grp">
        <group
          name="stool3"
          position={[-2.756162, 0.226294, -0.008666]}
          scale={0}
        >
          <mesh
            name="stool3_1"
            geometry={nodes.stool3_1.geometry}
            material={materials.phong_1}
          />
          <mesh
            name="stool3_2"
            geometry={nodes.stool3_2.geometry}
            material={materials.phong8_table_top}
          />
          <mesh
            name="stool3_3"
            geometry={nodes.stool3_3.geometry}
            material={materials.phong9_table_base}
          />
          <mesh
            name="stool3_4"
            geometry={nodes.stool3_4.geometry}
            material={materials.phong10_stool_top}
          />
          <mesh
            name="stool3_5"
            geometry={nodes.stool3_5.geometry}
            material={materials.phong11_stool_legs}
          />
          <mesh
            name="stool3_6"
            geometry={nodes.stool3_6.geometry}
            material={materials.phong12_table_base}
          />
        </group>
        <group
          name="stool4"
          position={[2.707369, 0.226294, -0.008666]}
          scale={0}
        >
          <mesh
            name="stool4_1"
            geometry={nodes.stool4_1.geometry}
            material={materials.phong_1}
          />
          <mesh
            name="stool4_2"
            geometry={nodes.stool4_2.geometry}
            material={materials.phong8_table_top}
          />
          <mesh
            name="stool4_3"
            geometry={nodes.stool4_3.geometry}
            material={materials.phong9_table_base}
          />
          <mesh
            name="stool4_4"
            geometry={nodes.stool4_4.geometry}
            material={materials.phong10_stool_top}
          />
          <mesh
            name="stool4_5"
            geometry={nodes.stool4_5.geometry}
            material={materials.phong11_stool_legs}
          />
          <mesh
            name="stool4_6"
            geometry={nodes.stool4_6.geometry}
            material={materials.phong12_table_base}
          />
        </group>
        <group
          name="stool"
          position={[-2.991656, 0.226294, -0.008666]}
          scale={0}
        >
          <mesh
            name="stool_1"
            geometry={nodes.stool_1.geometry}
            material={materials.phong_1}
          />
          <mesh
            name="stool_2"
            geometry={nodes.stool_2.geometry}
            material={materials.phong8_table_top}
          />
          <mesh
            name="stool_3"
            geometry={nodes.stool_3.geometry}
            material={materials.phong9_table_base}
          />
          <mesh
            name="stool_4"
            geometry={nodes.stool_4.geometry}
            material={materials.phong10_stool_top}
          />
          <mesh
            name="stool_5"
            geometry={nodes.stool_5.geometry}
            material={materials.phong11_stool_legs}
          />
          <mesh
            name="stool_6"
            geometry={nodes.stool_6.geometry}
            material={materials.phong12_table_base}
          />
        </group>
        <mesh
          name="polySurface11"
          geometry={nodes.polySurface11.geometry}
          material={materials.phong6_door}
          position={[2.876779, 0, 0]}
          scale={0}
        >
          <mesh
            name="polySurface8"
            geometry={nodes.polySurface8.geometry}
            material={materials.phong7_door_detail}
          />
        </mesh>
        <mesh
          name="polySurface12"
          geometry={nodes.polySurface12.geometry}
          material={materials.phong5_window}
          position={[-2.110925, 0, 0]}
          scale={0}
        >
          <mesh
            name="polySurface10"
            geometry={nodes.polySurface10.geometry}
            material={materials.phong4_coffee_mug}
          />
        </mesh>
      </group>
      <group
        name="grp_laptop"
        position={[0.174558, 0.452315, 0.113254]}
        rotation={[0.20458, -0.582673, -0.001765]}
      >
        <group
          name="Ad_Laptop"
          position={[-0.532787, 0.129663, -0.100108]}
          rotation={[-0.261141, 0, 0]}
          scale={0}
        >
          <group
            name="icon_pizza_laptop"
            position={[-0.089336, 0.053361, 0.003642]}
            rotation={[0, 0, -0.216575]}
            scale={0.922168}
          >
            <mesh
              name="pizza_laptop1"
              geometry={nodes.pizza_laptop1.geometry}
              material={materials.phong_pizza_pepperoni}
            />
            <mesh
              name="pizza_laptop2"
              geometry={nodes.pizza_laptop2.geometry}
              material={materials.phong_pizza_pepperoni}
            />
            <mesh
              name="pizza_laptop3"
              geometry={nodes.pizza_laptop3.geometry}
              material={materials.phong_pizza_pepperoni}
            />
            <mesh
              name="pizza_laptop4"
              geometry={nodes.pizza_laptop4.geometry}
              material={materials.phong_pizza_pepperoni}
            />
            <mesh
              name="pizza_laptop5"
              geometry={nodes.pizza_laptop5.geometry}
              material={materials.phong_pizza_cheese}
            />
            <mesh
              name="pizza_laptop6"
              geometry={nodes.pizza_laptop6.geometry}
              material={materials.phong_pizza_pineapple}
            />
            <mesh
              name="pizza_laptop7"
              geometry={nodes.pizza_laptop7.geometry}
              material={materials.phong_pizza_base}
            />
          </group>
          <mesh
            name="GreatPizza_text2"
            geometry={nodes.GreatPizza_text2.geometry}
            material={materials.blinn1_text}
            position={[0.174983, 0.628296, 0.036438]}
            rotation={[-0.002441, 0, 0]}
            scale={0.346924}
          />
          <mesh
            name="app_page_bg_4"
            geometry={nodes.app_page_bg_4.geometry}
            material={materials["asset_2:phong_1"]}
            position={[0.170433, 0.594365, 0.035814]}
            rotation={[-0.002441, 0, 0]}
            scale={[0.65905, 1.166312, 2.077556]}
          />
        </group>
        <group
          name="pizza_page"
          position={[-0.160525, -0.400065, 0.002785]}
          rotation={[-0.261141, 0, 0]}
          scale={[0.908, 0.907948, 0.907948]}
        >
          <group name="grp_buy_button" position={[0.175092, 0.50949, 0.038081]}>
            <mesh
              name="buynow_text"
              geometry={nodes.buynow_text.geometry}
              material={materials.blinn1_text}
            />
            <mesh
              name="buynow_button"
              geometry={nodes.buynow_button.geometry}
              material={materials.phong1_button}
              position={[-0.55818, -1.624214, -0.13316]}
            />
          </group>
          <group
            name="icon_pizza_laptop_(1)"
            position={[0.048179, 0.013332, 0.001386]}
            scale={0.970374}
          >
            <mesh
              name="pizza_laptop1_(1)"
              geometry={nodes["pizza_laptop1_(1)"].geometry}
              material={materials.phong_pizza_pepperoni}
            />
            <mesh
              name="pizza_laptop2_(1)"
              geometry={nodes["pizza_laptop2_(1)"].geometry}
              material={materials.phong_pizza_pepperoni}
            />
            <mesh
              name="pizza_laptop3_(1)"
              geometry={nodes["pizza_laptop3_(1)"].geometry}
              material={materials.phong_pizza_pepperoni}
            />
            <mesh
              name="pizza_laptop4_(1)"
              geometry={nodes["pizza_laptop4_(1)"].geometry}
              material={materials.phong_pizza_pepperoni}
            />
            <mesh
              name="pizza_laptop5_(1)"
              geometry={nodes["pizza_laptop5_(1)"].geometry}
              material={materials.phong_pizza_cheese}
            />
            <mesh
              name="pizza_laptop6_(1)"
              geometry={nodes["pizza_laptop6_(1)"].geometry}
              material={materials.phong_pizza_pineapple}
            />
            <mesh
              name="pizza_laptop7_(1)"
              geometry={nodes["pizza_laptop7_(1)"].geometry}
              material={materials.phong_pizza_base}
            />
          </group>
          <mesh
            name="GreatPizza_text2_(1)"
            geometry={nodes["GreatPizza_text2_(1)"].geometry}
            material={materials.blinn1_text}
            position={[0.130243, 0.595618, 0.036518]}
            rotation={[-0.002441, 0, 0]}
            scale={0.346924}
          />
          <mesh
            name="app_page_bg_5"
            geometry={nodes.app_page_bg_5.geometry}
            material={materials["asset_2:phong_1"]}
            position={[0.157919, 0.579112, 0.03625]}
            rotation={[-0.002441, 0, 0]}
            scale={1.101384}
          />
        </group>
        <group name="null2">
          <group
            name="null1"
            position={[0, 0.092225, -0.101719]}
            rotation={[-0.263582, 0, 0]}
            scale={0.100493}
          >
            <group
              name="asset_2browser_1"
              position={[0, 0.069307, 0.115516]}
              scale={0.016483}
            >
              <group name="asset_2app_page_1">
                <mesh
                  name="asset_2app_page_bg_1"
                  geometry={nodes.asset_2app_page_bg_1.geometry}
                  material={materials["asset_2:phong_1"]}
                  position={[248.007019, 0, -2.885033]}
                  scale={0}
                >
                  <mesh
                    name="asset_2app_page_lines_1"
                    geometry={nodes.asset_2app_page_lines_1.geometry}
                    material={materials["asset_2:pasted__phong_phone_buttons"]}
                    position={[9.644209, 3.07771, -3.720817]}
                    scale={[48.132366, 48.132366, 29.562881]}
                  />
                  <mesh
                    name="app_page_lines_1"
                    geometry={nodes.app_page_lines_1.geometry}
                    material={materials["asset_2:pasted__phong_phone_buttons"]}
                    position={[9.644209, -44.325245, -3.720817]}
                    scale={[48.132366, 48.132366, 29.562881]}
                  />
                </mesh>
                <mesh
                  name="app_page_bg_2"
                  geometry={nodes.app_page_bg_2.geometry}
                  material={materials["asset_2:phong_1"]}
                  position={[301.8354, 14.011728, -2.885033]}
                  scale={0}
                >
                  <mesh
                    name="app_page_lines_2"
                    geometry={nodes.app_page_lines_2.geometry}
                    material={materials["asset_2:pasted__phong_phone_buttons"]}
                    position={[25.47428, -23.890148, -3.720817]}
                    scale={[48.132366, 48.132366, 29.562881]}
                  />
                  <mesh
                    name="app_page_lines_3"
                    geometry={nodes.app_page_lines_3.geometry}
                    material={materials["asset_2:pasted__phong_phone_buttons"]}
                    position={[25.47428, -46.855785, -3.720817]}
                    scale={[48.132366, 48.132366, 29.562881]}
                  />
                </mesh>
                <mesh
                  name="app_page_bg_3"
                  geometry={nodes.app_page_bg_3.geometry}
                  material={materials["asset_2:phong_1"]}
                  position={[-335.823578, -23.438696, -2.885033]}
                  scale={0}
                />
              </group>
              <mesh
                name="asset_2browser_navbar_1"
                geometry={nodes.asset_2browser_navbar_1.geometry}
                material={materials["asset_2:phong_1"]}
              >
                <mesh
                  name="asset_2browser_buttons_8"
                  geometry={nodes.asset_2browser_buttons_8.geometry}
                  material={materials["asset_2:phong_3"]}
                  position={[60.92415, 52.805443, -2.318395]}
                  rotation={[Math.PI / 2, 0, 0]}
                  scale={[295.338074, 109.9929, 295.338074]}
                />
                <mesh
                  name="asset_2browser_buttons_9"
                  geometry={nodes.asset_2browser_buttons_9.geometry}
                  material={materials["asset_2:phong_3"]}
                  position={[71.29816, 52.805443, -2.318395]}
                  rotation={[Math.PI / 2, 0, 0]}
                  scale={[295.338074, 109.9929, 295.338074]}
                />
              </mesh>
              <mesh
                name="asset_2browser_page_1"
                geometry={nodes.asset_2browser_page_1.geometry}
                material={materials["asset_2:phong_3"]}
              />
            </group>
          </group>
        </group>
        <mesh
          name="keys"
          geometry={nodes.keys.geometry}
          material={materials.phong_laptop_buttons}
        />
        <mesh
          name="cover_laptop"
          geometry={nodes.cover_laptop.geometry}
          material={materials.phong_laptop}
          rotation={[1.308997, 0, 0]}
        />
        <mesh
          name="hinge"
          geometry={nodes.hinge.geometry}
          material={materials.phong_laptop}
        />
        <mesh
          name="laptop_bottom"
          geometry={nodes.laptop_bottom.geometry}
          material={materials.phong_laptop}
        />
      </group>
      <group
        name="pointer_grp"
        position={[-0.104591, 0.537629, 0.23093]}
        rotation={[-0.171476, -0.760339, -0.118786]}
        scale={0}
      >
        <mesh
          name="pointer_fill"
          geometry={nodes.pointer_fill.geometry}
          material={materials.phong_13}
        />
        <mesh
          name="pointer_stoke"
          geometry={nodes.pointer_stoke.geometry}
          material={materials.phong_14}
        />
      </group>
      <group
        name="phone_mesh1"
        position={[-0.238179, 0.522528, 0.006624]}
        rotation={[1.531212, 0.382367, -0.683434]}
        scale={1.498635}
      >
        <mesh
          name="phone_mesh1_1"
          geometry={nodes.phone_mesh1_1.geometry}
          material={materials.phong_phone_buttons}
        />
        <mesh
          name="phone_mesh1_2"
          geometry={nodes.phone_mesh1_2.geometry}
          material={materials.phong_phone}
        />
        <group
          name="phone_website_1"
          position={[0.025091, -0.003057, 0.382374]}
          rotation={[-1.522868, -0.697644, -0.351834]}
          scale={0.667274}
        >
          <mesh
            name="phone_website_1_1"
            geometry={nodes.phone_website_1_1.geometry}
            material={materials["asset_2:phong_1"]}
          />
          <mesh
            name="phone_website_1_2"
            geometry={nodes.phone_website_1_2.geometry}
            material={materials["asset_2:pasted__phong_phone_buttons"]}
          />
          <mesh
            name="phone_website_1_3"
            geometry={nodes.phone_website_1_3.geometry}
            material={materials["asset_2:phong_3"]}
          />
        </group>
        <group
          name="pizza_phone_add_1"
          position={[0.025091, -0.003057, 0.382374]}
          rotation={[-1.522868, -0.697644, -0.351834]}
          scale={0.667274}
        >
          <mesh
            name="pizza_phone_add_1_1"
            geometry={nodes.pizza_phone_add_1_1.geometry}
            material={materials.blinn1_text}
          />
          <mesh
            name="pizza_phone_add_1_2"
            geometry={nodes.pizza_phone_add_1_2.geometry}
            material={materials.phong_pizza_pepperoni}
          />
          <mesh
            name="pizza_phone_add_1_3"
            geometry={nodes.pizza_phone_add_1_3.geometry}
            material={materials.phong_pizza_cheese}
          />
          <mesh
            name="pizza_phone_add_1_4"
            geometry={nodes.pizza_phone_add_1_4.geometry}
            material={materials.phong_pizza_pineapple}
          />
          <mesh
            name="pizza_phone_add_1_5"
            geometry={nodes.pizza_phone_add_1_5.geometry}
            material={materials.phong_pizza_base}
          />
        </group>
      </group>
    </group>
  );
};

export default CafeUser;

