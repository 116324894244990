import { somethingVerySmall } from "../../helpers/scrollHelpers";
import { text } from "./text";

export const keyframes = [
  // Enter city
  { time: 0, position: [2, 3, 8], lookAt: [0, 0.4, 0], shakeIntensity: 0.05 },

  // Resolve on cafe
  // { time: text[0].adjustedIn - halfInterval, position: [0, 1, 6], lookAt: [0, 0.4, -5], shakeIntensity: 0.02 },
  {
    time: text[0].adjustedIn,
    position: [0, 1, 2],
    mobilePosition: [0, 1, 4],
    lookAt: [0, -0.1, -5],
    shakeIntensity: 0.02,
  },

  // two copies of speech bubble
  {
    time: text[1].adjustedIn,
    position: [0, 1, 2],
    mobilePosition: [0, 1, 4],
    lookAt: [0, -0.1, -5],
    shakeIntensity: 0.02,
  },
  {
    time: text[2].adjustedIn,
    position: [0, 1, 2],
    mobilePosition: [0, 1, 4],
    lookAt: [0, -0.1, -5],
    shakeIntensity: 0.02,
  },

  // zoom to pizza ad
  {
    time: text[3].adjustedIn,
    position: [0.1, 0.6, 0.7],
    mobilePosition: [-0.13, 0.6, 1.2],
    lookAt: [0.24, 0.55, 0],
    shakeIntensity: 0.02,
  },
  {
    time: text[4].adjustedIn,
    position: [0.1, 0.6, 0.7],
    mobilePosition: [-0.13, 0.6, 1.2],
    lookAt: [0.24, 0.55, 0],
    shakeIntensity: 0.02,
  },

  // zoom up to binary number
  {
    time: text[5].adjustedIn,
    position: [0.25, 0.8, 0.7],
    mobilePosition: [0.25, 0.1, 3],
    lookAt: [0.25, 0.7, 0],
    shakeIntensity: 0.02,
  },

  // Pan down to lock boxes
  {
    time: text[6].adjustedIn,
    position: [0.25, 0.7, 0.8],
    mobilePosition: [0.25, 0.8, 2],
    lookAt: [0.25, 0.7, 0],
    shakeIntensity: 0.05,
  },

  // Down to user receiving boxes
  {
    time: text[7].adjustedIn,
    position: [0.25, -0.2, 0.8],
    mobilePosition: [0.25, -0.2, 1.8],
    lookAt: [0.25, -0.2, 0],
    shakeIntensity: 0.04,
  },
  {
    time: text[8].adjustedIn,
    position: [0, -0.1, 2],
    mobilePosition: [0, -0.1, 4.4],
    lookAt: [0, -0.15, 0],
    shakeIntensity: 0.04,
  },
  {
    time: text[11].adjustedIn,
    position: [0, -0.1, 2],
    mobilePosition: [0, -0.1, 4.4],
    lookAt: [0, -0.15, 0],
    shakeIntensity: 0.04,
  },

  //show helper table
  {
    time: text[12].adjustedIn,
    position: [0, -1.8, 1.6],
    mobilePosition: [0, -1.8, 2],
    lookAt: [0, -2.2, 0],
    shakeIntensity: 0.04,
  },
  {
    time: text[15].adjustedIn,
    position: [0, -1.8, 1.6],
    mobilePosition: [0, -1.8, 2],
    lookAt: [0, -2.2, 0],
    shakeIntensity: 0.04,
  },

  //show conclusion ipa
  {
    time: text[16].adjustedIn - somethingVerySmall,
    position: [1, -2, 2],
    mobilePosition: [1, -2, 3],
    lookAt: [1, -2, 0],
    shakeIntensity: 0.01,
  },
  {
    time: text[18].adjustedIn,
    position: [1, -2, 2],
    mobilePosition: [1, -2, 3],
    lookAt: [1, -2, 0],
    shakeIntensity: 0.01,
  },
];

