import React from 'react';
import useTrigger from "../../hooks/useTrigger";
import {text} from "../../data/secret-data/text";
import ShareHolder from "../shared-models/holders/ShareHolder";
import {Html} from "@react-three/drei";
import {somethingVerySmall} from "../../helpers/scrollHelpers";
import useIsMobile from "../../hooks/useIsMobile";

function SharesGroupTwo() {
  const show = useTrigger(text[31].adjustedIn, text[38].adjustedIn);
  const hideShares = useTrigger(text[34].adjustedIn - somethingVerySmall, text[36].adjustedIn - somethingVerySmall);
  const {isMobile, isTablet} = useIsMobile();

  return (
      <group
        position={[0.256, 0.425, 4.95]}
        rotation={[0, Math.PI / 5, 0]}
        visible={show}>
        <ShareHolder scale={0.2} position={[-0.06, 0, 0]} number={5} color={'green1'}/>
        <ShareHolder scale={0.2} position={[-0.02, 0, 0]} number={2} color={'green1'}/>
        <ShareHolder scale={0.2} position={[0.02, 0, 0]} number={8} color={'green1'}/>
        <ShareHolder scale={0.2} position={[0.06, 0, 0]} number={5} color={'green1'}/>
        <Html
          className={`hiddenShares`}
          style={{
            opacity: hideShares ? 1 : 0,
            transform: 'translate(-50%, -50%)',
            fontSize: isMobile ? '9px' : isTablet ? '12px' : '16px',
            width: isMobile ? '275px' : isTablet ? '400px' : '600px',
            height: isMobile ? '55px' : isTablet ? '80px' : '120px',
          }}
        >
          <p>?</p>
          <p>?</p>
          <p>?</p>
          <p>?</p>
        </Html>
      </group>
  )
}

export default SharesGroupTwo;