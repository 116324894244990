/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import {useFrame} from "@react-three/fiber";
import {blue4} from "../../../helpers/materials";

export function IconSpinnerHolder(props) {
  const { nodes } = useGLTF('/SceneIntro/intro-icon-spinner-transformed.glb')
  const group = useRef();
  useFrame(({ clock }) => {
    group.current.rotation.x = -clock.getElapsedTime()/5
  })
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh name="icon_spinner" geometry={nodes.icon_spinner.geometry} material={blue4} />
    </group>
  )
}

useGLTF.preload('/intro-icon-spinner-transformed.glb')
