/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";

export default function Cross({ nodes, materials }) {
  return  <mesh name="cross" geometry={nodes.cross.geometry} material={materials.lambert1} />;
}

