import React from "react";
import UserPair from "./UserPair";
import useTrigger from "../../hooks/useTrigger";
import EqualsIconHolder from "../shared-models/holders/EqualsIconHolder";
import { useSpring, animated } from "@react-spring/three";
import MultiplicationIconHolder from "./holders/MultiplicationIconHolder";
import SharedSecretHolder from "../shared-models/holders/SharedSecretHolder";
import { text } from "../../data/multiplication-data/text";
import { somethingVerySmall } from "../../helpers/scrollHelpers";
import EyeHolder from "../shared-models/holders/EyeHolder";
import PurchaseHolder from "../shared-models/holders/PurchaseHolder";

const NonMatchingUserPair = () => {
  const final = text[21].adjustedIn - somethingVerySmall;
  const hide = useTrigger(final);

  const iconsIn = text[18].adjustedIn + somethingVerySmall;
  const iconsSpin = text[19].adjustedIn - somethingVerySmall;
  const iconsOut = text[20].adjustedIn - somethingVerySmall;
  const showIcons = useTrigger(iconsIn, iconsOut);
  const showShare = useTrigger(iconsIn, final);
  const scale = useTrigger(iconsOut);

  const moveEquation = useTrigger(text[19].adjustedIn - somethingVerySmall);

  const { equationPosition, shareScale } = useSpring({
    shareScale: scale ? 2 : 1,
    equationPosition: moveEquation ? [-0.4, 1.8, 0] : [0, 1.8, 0],
    config: { tension: 280, friction: 240 },
  });

  const idleIn = text[0].adjustedIn;
  const idleOut = text[8].adjustedIn;

  return (
    <group position={[0, 0, -5]} visible={!hide}>
      <UserPair idleIn={idleIn} idleOut={idleOut} />

      <animated.group position={equationPosition}>
        <EyeHolder
          position={[-0.025, 0, 0]} // optically centering the multiplication sign
          scale={0.04}
          show={showIcons}
          in={iconsIn}
          spin0={iconsSpin}
        />
        <MultiplicationIconHolder
          position={[0.2, 0, 0]}
          scale={3}
          show={showIcons}
          in={iconsIn}
          color="yellow1"
        />
        <PurchaseHolder
          position={[0.4, 0, 0]}
          scale={0.04}
          in={iconsIn}
          show={showIcons}
          spin1={iconsSpin}
        />
        <EqualsIconHolder
          show={showIcons}
          position={[0.6, 0, 0]}
          scale={0.5}
          color="yellow1"
        />
        <animated.group scale={shareScale} position={[0.85, 0, 0]}>
          <SharedSecretHolder
            show={showShare}
            scale={1}
            number={0}
            color="pink"
            in={iconsIn}
            split={iconsIn}
            leave={final}
          />
        </animated.group>
      </animated.group>
    </group>
  );
};

export default NonMatchingUserPair;

