import React from 'react'

const SharedSecret = ({nodes, materials}) => {
  return (
    <group>
        <group name="shared_secret_grp">
          <group name="cc_dice_1" position={[-0.047744, 0.050856, 0.034773]} rotation={[0.402104, 0, 0]} scale={2.71371}>
            <mesh name="dice1_2" geometry={nodes.dice1_2.geometry} material={materials.phong_2} rotation={[0, -1.24621, 0]} />
          </group>
          <group name="cc_dice_2" position={[-0.024995, -0.051167, 0.034773]} rotation={[0.402104, 0, 0]} scale={2.71371}>
            <mesh name="dice2_2" geometry={nodes.dice2_2.geometry} material={materials.phong_2} rotation={[0, 1.309157, 0]} />
          </group>
          <group name="share_card" position={[0.119553, 0.117223, 0.005333]} scale={0.649257}>
            <group name="numbers">
              <mesh name="shareCard_number_plane1" geometry={nodes.shareCard_number_plane1.geometry} material={materials.number_material_1} position={[0, 0, -0.004118]} />
              <mesh name="shareCard_number_plane2" geometry={nodes.shareCard_number_plane2.geometry} material={materials.number_material_2} position={[0, 0, -0.004118]} />
              <mesh name="shareCard_number_plane3" geometry={nodes.shareCard_number_plane3.geometry} material={materials.number_material_3} position={[0, 0, -0.004118]} />
              <mesh name="shareCard_number_plane4" geometry={nodes.shareCard_number_plane4.geometry} material={materials.number_material_4} position={[0, 0, -0.004118]} />
              <mesh name="shareCard_number_plane5" geometry={nodes.shareCard_number_plane5.geometry} material={materials.number_material_5} position={[0, 0, -0.004118]} />
              <mesh name="shareCard_number_plane6" geometry={nodes.shareCard_number_plane6.geometry} material={materials.number_material_6} position={[0, 0, -0.004118]} />
              <mesh name="shareCard_number_plane7" geometry={nodes.shareCard_number_plane7.geometry} material={materials.number_material_7} position={[0, 0, -0.004118]} />
              <mesh name="shareCard_number_plane8" geometry={nodes.shareCard_number_plane8.geometry} material={materials.number_material_8} position={[0, 0, -0.004118]} />
              <mesh name="shareCard_number_plane9" geometry={nodes.shareCard_number_plane9.geometry} material={materials.number_material_9} position={[0, 0, -0.004118]} />
            </group>
            <mesh name="share_card_(1)" position={[0,0, -0.001]} geometry={nodes['share_card_(1)'].geometry} material={materials.phong_7} />
            <mesh name="shareCard_number_plane" position={[0,0, 0.01]} geometry={nodes.shareCard_number_plane.geometry} material={materials.number_material_0} />
          </group>

          <group name="share_card1" position={[-0.044099, 1.004499, 0.078779]} rotation={[0, -0.297206, 0]} scale={0}>
            <group name="numbers_(1)">
              <mesh name="shareCard_number_plane1_(1)" geometry={nodes['shareCard_number_plane1_(1)'].geometry} material={materials.number_material_1} />
            </group>
            <mesh name="share_card_(2)" position={[0,0,0]}geometry={nodes['share_card_(2)'].geometry} material={materials.phong_7} />
          </group>

          <group name="share_card2" position={[0.058513, 0.963576, 0.078779]} rotation={[0, -0.297206, 0]} scale={0}>
            <group name="numbers_(2)">
              <mesh name="shareCard_number_plane1_(2)" geometry={nodes['shareCard_number_plane1_(2)'].geometry} material={materials.number_material_1} />
            </group>
            <mesh name="share_card_(3)" geometry={nodes['share_card_(3)'].geometry} material={materials.phong_7} />
          </group>

          <group name="share_card3" position={[-0.021706, 0.896242, 0.078779]} rotation={[0, -0.297206, 0]} scale={0}>
            <group name="numbers_(3)">
              <mesh name="shareCard_number_plane1_(3)" geometry={nodes['shareCard_number_plane1_(3)'].geometry} material={materials.number_material_1} />
            </group>
            <mesh name="share_card_(4)" geometry={nodes['share_card_(4)'].geometry} material={materials.phong_7} />
          </group>

          <group name="offset_transforms_equation_spinner_grp" position={[0.057702, 0.002389, 0.025788]} scale={0.151222}>
            <group name="equation_spinner_grp">
              <group name="group1" position={[0, 0.03846, 0]} rotation={[-1.928532, 0, 0]}>
                <mesh name="pCylinder1" geometry={nodes.pCylinder1.geometry} material={materials.phong_2} />
                <mesh name="pCylinder2" geometry={nodes.pCylinder2.geometry} material={materials.phong_2} rotation={[0, 0, Math.PI / 2]} scale={[32.340168, 13.574957, 32.340168]} />
              </group>
            </group>
          </group>
          <mesh name="base_secret_share1" geometry={nodes.base_secret_share1.geometry} material={materials.phong_5} position={[0, 0, 0.000794]} />
        </group>
      </group>
  )
}

export default SharedSecret