import React from "react";
import useTrigger from "../../hooks/useTrigger";

import SpeechBubbleHolder from "../shared-models/holders/SpeechBubbleHolder";
import {text} from "../../data/addition-data/text";
import {somethingVerySmall} from "../../helpers/scrollHelpers";

const SpeechBubblesGroup = () => {
  const showSpeechBubbles = useTrigger(
    text[20].adjustedIn - somethingVerySmall,
    text[22].adjustedIn
  );

  return (
    <>
      {/* addition-speech-bubble */}
      <group position={[0, 1.7, 0]} visible={showSpeechBubbles}>
        <SpeechBubbleHolder
          in={text[20].adjustedIn - somethingVerySmall}
          position={[-0.8, 0, 0.69]}
          scale={14}
          number={4}
          color='pink1'
        />
        <SpeechBubbleHolder
          in={text[20].adjustedIn - somethingVerySmall}
          position={[0, 0, -1]}
          scale={14}
          number={3}
          color='blue1'
        />
        <SpeechBubbleHolder
          in={text[20].adjustedIn - somethingVerySmall}
          position={[0.8, 0, 0.69]}
          scale={14}
          number={9}
          color='green1'
        />
      </group>
    </>
  );
};

export default SpeechBubblesGroup;

