import React, {useEffect, useState} from 'react';
import useTrigger from "../../hooks/useTrigger";
import {text} from "../../data/secret-data/text";
import ShareHolder from "../shared-models/holders/ShareHolder";
import Label from "../Label";
import {somethingVerySmall} from "../../helpers/scrollHelpers";
import useIsMobile from "../../hooks/useIsMobile";

function ReferenceNumbersGroup() {
  const showSingle = useTrigger(text[11].adjustedIn - somethingVerySmall, text[18].adjustedIn);
  const showSingleTick = useTrigger(text[17].adjustedIn - somethingVerySmall, text[18].adjustedIn);
  const showMulti = useTrigger(text[19].adjustedIn + somethingVerySmall, text[37].adjustedIn);
  const hideMulti = useTrigger(text[23].adjustedIn - somethingVerySmall, text[26].adjustedIn - somethingVerySmall);
  const crossMulti = useTrigger(text[35].adjustedIn, text[36].adjustedIn - somethingVerySmall);
  const tickMulti = useTrigger(text[36].adjustedIn - somethingVerySmall, text[37].adjustedIn);
  const {isDesktop} = useIsMobile();
  const [ position, setPosition ] = useState( [0.28, 0.376, 5.1] );

  useEffect(() => {
    if(isDesktop) {
      setPosition([0.28, 0.376, 5.1]);
    } else {
      setPosition([0.3, 0.30, 4.93]);
    }
  }, [isDesktop, setPosition]);

  return (
    <>

      <group position={position} rotation={[0, Math.PI / 5, 0]} visible={showSingle}>
        <ShareHolder scale={0.14} number={4} color={'pink1'} />
        <Label
          show={showSingle}
          labelPosition="bottom"
          targetHeight={130}
          targetWidth={130}
          text="Secret number"
          fullWidthLabel={true}
          tick={showSingleTick}
          color={'pink'}
        />
      </group>

      <group position={position} scale={0.6} rotation={[0, Math.PI / 5, 0]} visible={showMulti && !hideMulti}>
        <ShareHolder position={[-0.03, 0, 0]} scale={0.12} number={4} color={'pink1'} />
        <ShareHolder position={[-0.01, 0, 0]} scale={0.12} number={2} color={'pink1'} />
        <ShareHolder position={[0.01, 0, 0]} scale={0.12} number={0} color={'pink1'} />
        <ShareHolder position={[0.03, 0, 0]} scale={0.12} number={6} color={'pink1'} />
        <Label
          show={showMulti && !hideMulti}
          labelPosition="bottom"
          labelWidth={150}
          targetHeight={80}
          targetWidth={270}
          cross={crossMulti}
          tick={tickMulti}
          text="Secret combination"
          fullWidthLabel={true}
          color={'pink'}
        />
      </group>

    </>
  )
}

export default ReferenceNumbersGroup;