import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useLocalStorage } from "react-use";

import { useBoundStore } from "../../stores/useBoundStore";

import { ReactComponent as Arrow } from "../../assets/svg/arrow-icon.svg";

gsap.registerPlugin(ScrollToPlugin);

const Holder = styled.div`
  z-index: 1000;
  position: fixed;
  bottom: 1rem;
  right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  @media (${(props) => props.theme.breakpoints.lg}) {
    top: 1rem;
    right: 0.5rem;
    bottom: unset;
    flex-direction: column;
  }
`;

const StyledButton = styled.button`
  text-align: center;
  position: relative;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.25s ease;

  &:hover {
    background-color: #b9ffca;
    color: white;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background-color: white;
  }

  svg {
    align-self: center;
    justify-self: center;
    width: 28px;
    height: 28px;
  }

  &:last-child {
    svg {
      transform: rotate(180deg);
    }
  }
`;

const Controls = (props) => {
  const location = useLocation();
  const [viewedSections, setViewedSections] = useLocalStorage(
    "viewedSections",
    []
  );

  const [timelinePlaying, setTimelinePlaying] = useState(false);
  const navigationDisabled = useBoundStore((state) => state.navigationDisabled);
  const navigationModalVisible = useBoundStore(
    (state) => state.navigationModalVisible
  );
  const setNavigationModalVisible = useBoundStore(
    (state) => state.setNavigationModalVisible
  );

  const { id, currentTextIndex, setCurrentTextIndex, text } = props;

  const handlePrevious = useCallback(async () => {
    if (navigationModalVisible) {
      return;
    }

    if (currentTextIndex === 0) {
      setNavigationModalVisible(true);
    } else {
      setTimelinePlaying(true);

      await gsap.getById(id).tweenTo(currentTextIndex - 1);

      setCurrentTextIndex(currentTextIndex - 1);
      setTimelinePlaying(false);
    }
  }, [
    currentTextIndex,
    setCurrentTextIndex,
    setTimelinePlaying,
    id,
    setNavigationModalVisible,
    navigationModalVisible,
  ]);

  const handleNext = useCallback(async () => {
    if (navigationModalVisible) {
      return;
    }

    if (currentTextIndex === text.length) {
      setViewedSections([...viewedSections, location.pathname]);
      setNavigationModalVisible(true);
    } else {
      setTimelinePlaying(true);

      await gsap.getById(id).tweenTo(currentTextIndex + 1);

      setCurrentTextIndex(currentTextIndex + 1);
      setTimelinePlaying(false);
    }
  }, [
    currentTextIndex,
    setCurrentTextIndex,
    setTimelinePlaying,
    id,
    text,
    location,
    setViewedSections,
    viewedSections,
    setNavigationModalVisible,
    navigationModalVisible,
  ]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (navigationDisabled || timelinePlaying) {
        return;
      }

      if (event.key === "ArrowDown" || event.key === "ArrowRight") {
        // Go forwards when down or right keys are pressed
        handleNext();
      } else if (event.key === "ArrowUp" || event.key === "ArrowLeft") {
        // Go back when up or left keys are pressed
        handlePrevious();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleNext, handlePrevious, navigationDisabled, timelinePlaying]);

  return (
    <Holder className="hideForLoading">
      <StyledButton
        onClick={() => {
          handlePrevious();
        }}
        disabled={navigationDisabled || timelinePlaying}
      >
        <Arrow />
      </StyledButton>
      <StyledButton
        onClick={() => {
          handleNext();
        }}
        disabled={navigationDisabled || timelinePlaying}
      >
        <Arrow />
      </StyledButton>
    </Holder>
  );
};

Controls.propTypes = {
  currentTextIndex: PropTypes.number.isRequired,
  setCurrentTextIndex: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  text: PropTypes.array.isRequired,
};

export default Controls;

