/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 -p=6 --transform --keepnames ./intro-icon-bikelock.glb
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber';
import {green3} from "../../../helpers/materials";


export function IconBikeLockHolder(props) {
  const { nodes } = useGLTF('/SceneIntro/intro-icon-bikelock-transformed.glb')
    const group = useRef();
    useFrame(({ clock }) => {
      group.current.rotation.y = clock.getElapsedTime()/3
    })
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh name="icon_bikelock1" geometry={nodes.icon_bikelock1.geometry} material={green3} />
    </group>
  )
}

useGLTF.preload('/SceneIntro/intro-icon-bikelock-transformed.glb')
