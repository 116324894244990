import * as THREE from "three";
const textureLoader = new THREE.TextureLoader();

const base = textureLoader.load('./textures/matcaps/new/multi-fade-bright-60-sat-neg20.png')

const matcapBlue1 = textureLoader.load('./textures/matcaps/new/toon-neg40-sat-40.png')
const matcapBlue2 = textureLoader.load('./textures/matcaps/new/toon-neg40-sat-40-bright-30.png')
const matcapBlue3 = textureLoader.load('./textures/matcaps/new/toon-neg40-sat-40-bright-60.png')
const matcapBlue4 = textureLoader.load('./textures/matcaps/new/toon-neg40-sat-40-bright-90.png')

const matcapGreen1 = textureLoader.load('./textures/matcaps/new/toon-neg100-sat-40.png')
const matcapGreen2 = textureLoader.load('./textures/matcaps/new/toon-neg100-sat-40-bright-30.png')
const matcapGreen3 = textureLoader.load('./textures/matcaps/new/toon-neg100-sat-40-bright-60.png')
const matcapGreen4 = textureLoader.load('./textures/matcaps/new/toon-neg100-sat-40-bright-90.png')

const matcapPurple1 = textureLoader.load('./textures/matcaps/new/toon-0.png')
const matcapPurple2 = textureLoader.load('./textures/matcaps/new/toon-0-bright-30.png')
const matcapPurple3 = textureLoader.load('./textures/matcaps/new/toon-0-bright-60.png')
const matcapPurple4 = textureLoader.load('./textures/matcaps/new/toon-0-bright-90.png')

const matcapYellow1 = textureLoader.load('./textures/matcaps/new/toon-160-sat-40.png')
const matcapYellow2 = textureLoader.load('./textures/matcaps/new/toon-160-sat-40-bright-30.png')
const matcapYellow3 = textureLoader.load('./textures/matcaps/new/toon-160-sat-40-bright-60.png')
const matcapYellow4 = textureLoader.load('./textures/matcaps/new/toon-160-sat-40-bright-90.png')

const matcapPink1 = textureLoader.load('./textures/matcaps/new/toon-60-sat-40.png')
const matcapPink2 = textureLoader.load('./textures/matcaps/new/toon-60-sat-40-bright-30.png')
const matcapPink3 = textureLoader.load('./textures/matcaps/new/toon-60-sat-40-bright-60.png')
const matcapPink4 = textureLoader.load('./textures/matcaps/new/toon-60-sat-40-bright-90.png')

export const grey1 = new THREE.MeshMatcapMaterial({
  matcap: base,
});

export const grey2 = new THREE.MeshMatcapMaterial({
  matcap: base,
});

export const grey3 = new THREE.MeshMatcapMaterial({
  matcap: base,
});

export const grey4 = new THREE.MeshMatcapMaterial({
  matcap: base,
});

export const blue1 = new THREE.MeshMatcapMaterial({
  matcap: matcapBlue1,
  //color: new THREE.Color('#b8b8ff'),
});

export const blue2 = new THREE.MeshMatcapMaterial({
  matcap: matcapBlue2,
  //color: new THREE.Color('#b8b8ff'),
});

export const blue3 = new THREE.MeshMatcapMaterial({
  matcap: matcapBlue3,
  //color: new THREE.Color('#b8b8ff'),
});

export const blue4 = new THREE.MeshMatcapMaterial({
  matcap: matcapBlue4,
  //color: new THREE.Color('#b8b8ff'),
});

export const green1 = new THREE.MeshMatcapMaterial({
  matcap: matcapGreen1,
  //color: new THREE.Color('#68ffa4'),
});

export const green2 = new THREE.MeshMatcapMaterial({

  matcap: matcapGreen2,
  //color: new THREE.Color('#68ffa4'),
});

export const green3 = new THREE.MeshMatcapMaterial({

  matcap: matcapGreen3,
  //color: new THREE.Color('#68ffa4'),
});

export const green4 = new THREE.MeshMatcapMaterial({

  matcap: matcapGreen4,
  //color: new THREE.Color('#68ffa4'),
});

export const purple1 = new THREE.MeshMatcapMaterial({

  matcap: matcapPurple1,
  //color: new THREE.Color('#dfb8ff'),
});

export const purple2 = new THREE.MeshMatcapMaterial({

  matcap: matcapPurple2,
  //color: new THREE.Color('#dfb8ff'),
});

export const purple3 = new THREE.MeshMatcapMaterial({

  matcap: matcapPurple3,
  //color: new THREE.Color('#dfb8ff'),
});

export const purple4 = new THREE.MeshMatcapMaterial({

  matcap: matcapPurple4,
  //color: new THREE.Color('#dfb8ff'),
});

export const yellow1 = new THREE.MeshMatcapMaterial({

  matcap: matcapYellow1,
  //color: new THREE.Color('#ffee84'),
});

export const yellow2 = new THREE.MeshMatcapMaterial({

  matcap: matcapYellow2,
  //color: new THREE.Color('#ffee84'),
});

export const yellow3 = new THREE.MeshMatcapMaterial({

  matcap: matcapYellow3,
  //color: new THREE.Color('#ffee84'),
});

export const yellow4 = new THREE.MeshMatcapMaterial({

  matcap: matcapYellow4,
  //color: new THREE.Color('#ffee84'),
});

export const pink1 = new THREE.MeshMatcapMaterial({
  matcap: matcapPink1,
  //color: new THREE.Color('#ff799d'),
});

export const pink2 = new THREE.MeshMatcapMaterial({
  matcap: matcapPink2,
  //color: new THREE.Color('#ff799d'),
});

export const pink3 = new THREE.MeshMatcapMaterial({
  matcap: matcapPink3,
  //color: new THREE.Color('#ff799d'),
});

export const pink4 = new THREE.MeshMatcapMaterial({
  matcap: matcapPink4,
  //color: new THREE.Color('#ff799d'),
});

