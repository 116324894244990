/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useRef} from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import Buildings from "../models/Buildings"
import useSetFirstAnimationFrame from '../../../hooks/useSetFirstAnimationFrame';
import useNewMaterials from "../../../hooks/useNewMaterials";
import PropTypes from "prop-types";

export default function BuildingsHolder( {...props} ) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/GlobalModels/city-buildings-transformed.glb')
  const { actions } = useAnimations(animations, group)
  const newMaterials = useNewMaterials(materials)

  useSetFirstAnimationFrame('static', actions);

  return (
    <group 
      ref={group} 
      dispose={null}
      {...props}  
      visible={!(props.hide)}
      position={props.position}
    >
      <Buildings materials={newMaterials} nodes={nodes}/>
    </group>
  )
}

BuildingsHolder.propTypes = {
  hide: PropTypes.bool,
  position: PropTypes.array,
};

BuildingsHolder.defaultProps = {
  hide: false,
  position: [],
};

useGLTF.preload('/GlobalModels/city-buildings-transformed.glb')