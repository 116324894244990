/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useMemo, useRef } from "react";
import {useGLTF, useAnimations, Shadow} from "@react-three/drei";
import { SkeletonUtils } from "three-stdlib";
import PropTypes from "prop-types";
import { useGraph } from "@react-three/fiber";
import WebsiteUser from "../models/WebsiteUser";
import useSetFirstAnimationFrame from "../../../hooks/useSetFirstAnimationFrame";
import useTriggerAnimation from "../../../hooks/useTriggerAnimation";
import useNewMaterials from "../../../hooks/useNewMaterials";
import {text} from "../../../data/ipa-data/text";
import {somethingVerySmall} from "../../../helpers/scrollHelpers";

export default function WebsiteUserHolder({ ...props }) {
  const group = useRef();
  const { scene, materials, animations } = useGLTF(
    "/SceneIPA/website-user-transformed.glb"
  );
  const newMaterials = useNewMaterials(materials);

  const clone = useMemo(() => SkeletonUtils.clone(scene), [scene]);

  const { nodes } = useGraph(clone);

  const { actions } = useAnimations(animations, group);

   // Set in animation to its start position on initial render
  useSetFirstAnimationFrame("open", actions);

  useTriggerAnimation(text[6].adjustedIn - somethingVerySmall, "open", actions);
  useTriggerAnimation(text[6].adjustedIn, "shut", actions);
  
  useTriggerAnimation(text[7].adjustedIn, "into-hands", actions, null, false, 1);
  useTriggerAnimation(text[8].adjustedIn, "shrug", actions);
  useTriggerAnimation(text[9].adjustedIn - somethingVerySmall, "boxes-float-into-silo", actions);


  return (
    <group
      ref={group}
      position={props.position}
      dispose={null}
      visible={props.show}
      scale={0.2}
    >
      <WebsiteUser nodes={nodes} materials={newMaterials} />
      <Shadow color="purple" position-y={0} opacity={0.15} scale={1.25}/>
      <Shadow color="purple" position={[-0.45,5.2,1]} opacity={0.15} scale={0.5}/>
      <Shadow color="purple" position={[0.45,5.2,1]} opacity={0.15} scale={0.5}/>
      <Shadow color="purple" position={[0,5.2,1]} opacity={0.15} scale={0.5}/>
    </group>
  );
}

WebsiteUserHolder.propTypes = {
  show: PropTypes.bool,
  position: PropTypes.array,
  scale: PropTypes.number,
};

WebsiteUserHolder.defaultProps = {
  show: true,
  position: null,
  scale: null,
};

useGLTF.preload("/SceneIPA/website-user-transformed.glb");

