import React from "react";
import styled, { keyframes } from "styled-components";
import PropTypes from "prop-types";

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const Tip = styled.div`
  position: fixed;
  z-index: 999;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: var(--purple);
  border-radius: 0.25rem;
  padding: 1rem;
  width: 10rem;
  animation: ${fadeOut} 0.25s linear 8s 1 forwards;

  &.audio {
    top: 7rem;
    left: 5rem;
    &:after {
      top: 30%;
      left: 0;
      transform: rotate(90deg);
      transform-origin: top left;
    }
    @media (${(props) => props.theme.breakpoints.lg}) {
      top: auto;
      bottom: 4.5rem;
      left: auto;
      right: 5rem;
      &:after {
        top: calc(50% + 1rem);
        left: calc(100% - 1px);
        transform: rotate(-90deg);
        transform-origin: top left;
      }
    }
  }

  &.nav {
    bottom: 1rem;
    right: 136px;
    &:after {
      top: 85%;
      left: calc(100% - 1px);
      transform: rotate(-90deg);
      transform-origin: top left;
    }
    @media (${(props) => props.theme.breakpoints.lg}) {
      top: 1rem;
      bottom: auto;
      right: 5rem;
      &:after {
        top: calc(50% + 1rem);
        left: calc(100% - 1px);
        transform: rotate(-90deg);
        transform-origin: top left;
      }
    }
  }

  &.nav .arrow-keys {
    display: none;
    @media (${(props) => props.theme.breakpoints.lg}) {
      display: inline;
    }
  }

  &.progress {
    width: 14rem;
    top: 7rem;
    right: 1rem;
    &:after {
      top: auto;
      transform: rotate(180deg);
      bottom: calc(100% - 1px);
      left: calc(100% - 3rem);
      right: auto;
    }
    @media (${(props) => props.theme.breakpoints.lg}) {
      top: auto;
      bottom: 6rem;
      left: 2rem;
      &:after {
        top: calc(100% - 1px);
        transform: rotate(0deg);
        bottom: auto;
        left: calc(50% - 1rem);
      }
    }
  }

  p {
    margin: 0;
    ${(props) => props.theme.fluidType(-2)};
  }

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    border-top: 0.5rem solid var(--purple);
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    @media (${(props) => props.theme.breakpoints.lg}) {
      border-top: 1rem solid var(--purple);
      border-left: 1rem solid transparent;
      border-right: 1rem solid transparent;
    }
  }
`;

const Tips = (props) => {
  if (props.currentTextIndex > 0) return null;

  return (
    <>
      <Tip className="nav hideForLoading">
        <p>
          Navigate through the explainer with these buttons.
          <span className="arrow-keys"> You can also use your arrow keys.</span>
        </p>
      </Tip>
      <Tip className="progress hideForLoading">
        <p>Skip to different sections by clicking on the icons.</p>
      </Tip>
      <Tip className="audio">
        <p>Toggle audio on and off by clicking on this icon.</p>
      </Tip>
    </>
  );
};

Tips.propTypes = {
  currentTextIndex: PropTypes.number.isRequired,
};

export default Tips;

