import Audio0 from "../../assets/audio/addition/0.mp3";
import Audio1 from "../../assets/audio/addition/1.mp3";
import Audio2 from "../../assets/audio/addition/2.mp3";
import Audio3 from "../../assets/audio/addition/3.mp3";
import Audio4 from "../../assets/audio/addition/4.mp3";
import Audio5 from "../../assets/audio/addition/5.mp3";
import Audio6 from "../../assets/audio/addition/6.mp3";
import Audio7 from "../../assets/audio/addition/7.mp3";
import Audio8 from "../../assets/audio/addition/8.mp3";
import Audio9 from "../../assets/audio/addition/9.mp3";
import Audio10 from "../../assets/audio/addition/10.mp3";
import Audio11 from "../../assets/audio/addition/11.mp3";
import Audio12 from "../../assets/audio/addition/12.mp3";
import Audio13 from "../../assets/audio/addition/13.mp3";
import Audio14 from "../../assets/audio/addition/14.mp3";
import Audio15 from "../../assets/audio/addition/15.mp3";
import Audio16 from "../../assets/audio/addition/16.mp3";
import Audio17 from "../../assets/audio/addition/17.mp3";
import Audio18 from "../../assets/audio/addition/18.mp3";
import Audio19 from "../../assets/audio/addition/19.mp3";
import Audio20 from "../../assets/audio/addition/20.mp3";
import Audio21 from "../../assets/audio/addition/21.mp3";
import Audio22 from "../../assets/audio/addition/22.mp3";
import Audio23 from "../../assets/audio/addition/23.mp3";
import Audio24 from "../../assets/audio/addition/24.mp3";



export const audio = [
  { file: Audio0 },
  { file: Audio1 },
  { file: Audio2 },
  { file: Audio3 },
  { file: Audio4 },
  { file: Audio5 },
  { file: Audio6 },
  { file: Audio7 },
  { file: Audio8 },
  { file: Audio9 },
  { file: Audio10 },
  { file: Audio11 },
  { file: Audio12 },
  { file: Audio13 },
  { file: Audio14 },
  { file: Audio15 },
  { file: Audio16 },
  { file: Audio17 },
  { file: Audio18 },
  { file: Audio19 },
  { file: Audio20 },
  { file: Audio21 },
  { file: Audio22 },
  { file: Audio23 },
  { file: Audio24 },
];
