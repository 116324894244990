import React from 'react';
import useTrigger from "../../hooks/useTrigger";
import DiceHolder from "../shared-models/holders/DiceHolder";
import {text} from "../../data/secret-data/text";
import {somethingVerySmall} from "../../helpers/scrollHelpers";
// import {Box} from "@react-three/drei";

function DiceGroupOne() {
  const show = useTrigger(text[17].adjustedIn, text[22].adjustedIn - somethingVerySmall);
  const showDice1 = useTrigger(text[17].adjustedIn);

  return (
    <>
      {/*<Box scale={0.02} args={[0.25,3,0.25]} position={[0.256, 0.4, 4.95]} />*/}
      <group
        position={[0.256, 0.425, 4.95]}
        rotation={[0, Math.PI / 5, 0]}
        visible={show}>

        <DiceHolder
          position={[-0.06, 0, 0]}
          show={showDice1}
          in={text[18].adjustedIn + somethingVerySmall}
          roll6={text[19].adjustedIn + somethingVerySmall}
        />
        <DiceHolder
          position={[-0.02, 0, 0]}
          show={showDice1}
          in={text[18].adjustedIn + somethingVerySmall}
          roll3={text[19].adjustedIn + somethingVerySmall}
        />
        <DiceHolder
          position={[0.02, 0, 0]}
          show={showDice1}
          in={text[18].adjustedIn + somethingVerySmall}
          roll7={text[19].adjustedIn + somethingVerySmall}
        />
        <DiceHolder
          position={[0.06, 0, 0]}
          show={showDice1}
          in={text[18].adjustedIn + somethingVerySmall}
          roll1={text[19].adjustedIn + somethingVerySmall}
        />

      </group>
    </>
  )
}

export default DiceGroupOne;