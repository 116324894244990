import React from 'react'

const EqualsIcon = ({nodes, materials}) => {
  return (
    <group>
        <mesh name="equalsign_mesh" geometry={nodes.equalsign_mesh.geometry} material={materials.phong_5} />
    </group>
  )
}

export default EqualsIcon