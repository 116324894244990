/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import PropTypes from "prop-types";
import Tick from "../models/Tick";
import useNewMaterials from "../../../hooks/useNewMaterials";

export default function TickHolder({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/GlobalModels/tick-transformed.glb");
  const newMaterials = useNewMaterials(materials, {baseMaterial: props.color});
  return (
    <group ref={group} {...props} dispose={null} visible={props.show}>
      <Tick nodes={nodes} materials={newMaterials} />
    </group>
  );
}

TickHolder.propTypes = {
  show: PropTypes.bool,
  color: PropTypes.string,
};

TickHolder.defaultProps = {
  show: true,
  color: "green1",
};

useGLTF.preload("/GlobalModels/tick-transformed.glb");

