import React from 'react'

const LockboxPile = ({nodes, materials}) => {
  return (
    <group>
        <group name="boxes_stack" position={[-0.004142, 2.641877, -0.506148]} rotation={[-Math.PI, 0, -Math.PI]}>
          <group name="lockboxes_grp_1">
            <mesh name="lockbox" geometry={nodes.lockbox.geometry} material={materials.pasted__phong_5} position={[0.02296, -2.721967, -0.204187]} rotation={[0, 0.074122, 0]} />
            <mesh name="lockbox2" geometry={nodes.lockbox2.geometry} material={materials.pasted__phong_5} position={[0.223122, -2.69598, -0.163992]} rotation={[0, 0.305245, 0]} />
            <mesh name="lockbox3" geometry={nodes.lockbox3.geometry} material={materials.pasted__phong_5} position={[0.016794, -2.69598, -0.231737]} rotation={[0, 0.039055, 0]} />
            <mesh name="lockbox4" geometry={nodes.lockbox4.geometry} material={materials.pasted__phong_5} position={[-0.030743, -2.69598, -0.204802]} rotation={[0, -0.079549, 0]} />
            <mesh name="lockbox5" geometry={nodes.lockbox5.geometry} material={materials.pasted__phong_5} position={[-0.196716, -2.69598, -0.030765]} rotation={[0, 0.03836, 0]} />
            <mesh name="lockbox6" geometry={nodes.lockbox6.geometry} material={materials.pasted__phong_5} position={[-0.250179, -2.721877, -0.074009]} rotation={[0, -0.043003, 0]} />
            <mesh name="lockbox7" geometry={nodes.lockbox7.geometry} material={materials.pasted__phong_5} position={[-0.126778, -2.598396, -0.129323]} rotation={[0, -0.083253, 0]} />
          </group>
        </group>
        <group name="grp_stack_cards" rotation={[0, -0.145386, 0]} scale={0.7}>
          <group name="grp_share_stack_card1" position={[0.000536, 0.005192, -0.007541]} scale={0}>
            <group name="group1" position={[0, 0, 0.007356]}>
              <group name="numbers">
                <mesh name="number_0_plane9" geometry={nodes.number_0_plane9.geometry} material={materials.number_material_9} />
                <mesh name="number_0_plane7" geometry={nodes.number_0_plane7.geometry} material={materials.number_material_7} />
                <mesh name="number_0_plane6" geometry={nodes.number_0_plane6.geometry} material={materials.number_material_6} />
                <mesh name="number_0_plane4" geometry={nodes.number_0_plane4.geometry} material={materials.number_material_4} />
                <mesh name="number_0_plane2" geometry={nodes.number_0_plane2.geometry} material={materials.number_material_2} />
                <mesh name="number_0_plane1" geometry={nodes.number_0_plane1.geometry} material={materials.number_material_1} />
                <mesh name="number_0_plane5" geometry={nodes.number_0_plane5.geometry} material={materials.number_material_5} />
                <mesh name="number_0_plane3" geometry={nodes.number_0_plane3.geometry} material={materials.number_material_3} />
              </group>
              <mesh name="number_0_plane8" geometry={nodes.number_0_plane8.geometry} material={materials.number_material_8} />
              <mesh name="share_card1" geometry={nodes.share_card1.geometry} material={materials.standardSurface2} />
              <mesh name="shareCard_number_plane13" geometry={nodes.shareCard_number_plane13.geometry} material={materials.number_material_0} />
            </group>
          </group>
          <group name="grp_share_stack_card2" position={[0, 0.013147, -0.005591]} scale={0}>
            <group name="group2" position={[0, 0, 0.00722]} rotation={[0, -0.029108, 0]}>
              <mesh name="share_card2" geometry={nodes.share_card2.geometry} material={materials.standardSurface2} />
              <mesh name="shareCard_number_plane14" geometry={nodes.shareCard_number_plane14.geometry} material={materials.number_material_2} />
            </group>
          </group>
          <group name="grp_share_stack_card3" position={[0, 0.022393, -0.005591]} scale={0}>
            <group name="group3" position={[0, 0, 0.007745]}>
              <mesh name="share_card3" geometry={nodes.share_card3.geometry} material={materials.standardSurface2} />
              <mesh name="shareCard_number_plane15" geometry={nodes.shareCard_number_plane15.geometry} material={materials.number_material_6} />
            </group>
          </group>
          <group name="grp_share_stack_card4" position={[0, 0.032123, -0.005591]} scale={0}>
            <group name="group4" position={[-0.000784, 0, 0.008756]} rotation={[0, -0.042113, 0]}>
              <mesh name="share_card4" geometry={nodes.share_card4.geometry} material={materials.standardSurface2} />
              <mesh name="shareCard_number_plane16" geometry={nodes.shareCard_number_plane16.geometry} material={materials.number_material_9} />
            </group>
          </group>
          <group name="grp_share_stack_card5" position={[0, 0.04072, -0.005591]} scale={0}>
            <group name="group5" position={[-0.000826, 0, 0.006706]}>
              <mesh name="share_card5" geometry={nodes.share_card5.geometry} material={materials.standardSurface2} />
              <mesh name="shareCard_number_plane17" geometry={nodes.shareCard_number_plane17.geometry} material={materials.number_material_8} />
            </group>
          </group>
          <group name="grp_share_stack_card6" position={[0, 0.050291, -0.005591]} scale={0}>
            <group name="group6" position={[-0.000016, 0, 0.007345]} rotation={[0, 0.028832, 0]}>
              <mesh name="share_card6" geometry={nodes.share_card6.geometry} material={materials.standardSurface2} />
              <mesh name="shareCard_number_plane18" geometry={nodes.shareCard_number_plane18.geometry} material={materials.number_material_1} />
            </group>
          </group>
          <group name="grp_share_stack_card7" position={[0, 0.058623, -0.005591]} scale={0}>
            <group name="group7" position={[0, 0, 0.006249]}>
              <mesh name="share_card7" geometry={nodes.share_card7.geometry} material={materials.standardSurface2} />
              <mesh name="shareCard_number_plane19" geometry={nodes.shareCard_number_plane19.geometry} material={materials.number_material_0} />
            </group>
          </group>
          <group name="grp_share_stack_card8" position={[0, 0.06775, -0.005591]} scale={0}>
            <group name="group8" position={[-0.001241, 0, 0.008879]} rotation={[0, -0.064371, 0]}>
              <mesh name="share_card8" geometry={nodes.share_card8.geometry} material={materials.standardSurface2} />
              <mesh name="shareCard_number_plane20" geometry={nodes.shareCard_number_plane20.geometry} material={materials.number_material_7} />
            </group>
          </group>
          <group name="grp_share_stack_card9" position={[0, 0.077085, -0.005591]} scale={0}>
            <group name="group9" position={[-0.000018, 0, 0.007606]} rotation={[0, 0.026313, 0]}>
              <mesh name="share_card9" geometry={nodes.share_card9.geometry} material={materials.standardSurface2} />
              <mesh name="shareCard_number_plane21" geometry={nodes.shareCard_number_plane21.geometry} material={materials.number_material_4} />
            </group>
          </group>
          <group name="grp_share_stack_card10" position={[0, 0.086711, -0.005591]} scale={0}>
            <group name="group10" position={[0, 0, 0.007744]} rotation={[0, -0.01341, 0]}>
              <mesh name="share_card10" geometry={nodes.share_card10.geometry} material={materials.standardSurface2} />
              <mesh name="shareCard_number_plane22" geometry={nodes.shareCard_number_plane22.geometry} material={materials.number_material_9} />
            </group>
          </group>
          <group name="grp_share_stack_card11" position={[0, 0.095005, -0.005591]} scale={0}>
            <group name="group11" position={[0.000038, 0, 0.004274]} rotation={[0, 0.031572, 0]}>
              <mesh name="share_card11" geometry={nodes.share_card11.geometry} material={materials.standardSurface2} />
              <mesh name="shareCard_number_plane23" geometry={nodes.shareCard_number_plane23.geometry} material={materials.number_material_6} />
            </group>
          </group>
          <group name="grp_share_stack_card12" position={[0, 0.103113, -0.005591]} scale={0}>
            <mesh name="share_card12" geometry={nodes.share_card12.geometry} material={materials.standardSurface2} />
            <mesh name="shareCard_number_plane24" geometry={nodes.shareCard_number_plane24.geometry} material={materials.number_material_7} />
          </group>
          <group name="grp_share_stack_card13" position={[0.000536, 0.112794, -0.007541]} scale={0}>
            <group name="group1_(1)" position={[0, 0, 0.007356]}>
              <group name="numbers_(1)">
                <mesh name="number_0_plane9_(1)" geometry={nodes['number_0_plane9_(1)'].geometry} material={materials.number_material_9} />
                <mesh name="number_0_plane7_(1)" geometry={nodes['number_0_plane7_(1)'].geometry} material={materials.number_material_7} />
                <mesh name="number_0_plane6_(1)" geometry={nodes['number_0_plane6_(1)'].geometry} material={materials.number_material_6} />
                <mesh name="number_0_plane4_(1)" geometry={nodes['number_0_plane4_(1)'].geometry} material={materials.number_material_4} />
                <mesh name="number_0_plane2_(1)" geometry={nodes['number_0_plane2_(1)'].geometry} material={materials.number_material_2} />
                <mesh name="number_0_plane1_(1)" geometry={nodes['number_0_plane1_(1)'].geometry} material={materials.number_material_1} />
                <mesh name="number_0_plane5_(1)" geometry={nodes['number_0_plane5_(1)'].geometry} material={materials.number_material_5} />
                <mesh name="number_0_plane3_(1)" geometry={nodes['number_0_plane3_(1)'].geometry} material={materials.number_material_3} />
              </group>
              <mesh name="number_0_plane8_(1)" geometry={nodes['number_0_plane8_(1)'].geometry} material={materials.number_material_8} />
              <mesh name="share_card13" geometry={nodes.share_card13.geometry} material={materials.standardSurface2} />
              <mesh name="shareCard_number_plane12" geometry={nodes.shareCard_number_plane12.geometry} material={materials.number_material_0} />
            </group>
          </group>
          <group name="grp_share_stack_card14" position={[0, 0.120748, -0.005591]} scale={0}>
            <group name="group2_(1)" position={[0, 0, 0.00722]} rotation={[0, -0.029108, 0]}>
              <mesh name="share_card14" geometry={nodes.share_card14.geometry} material={materials.standardSurface2} />
              <mesh name="shareCard_number_plane11" geometry={nodes.shareCard_number_plane11.geometry} material={materials.number_material_2} />
            </group>
          </group>
          <group name="grp_share_stack_card15" position={[0, 0.129995, -0.005591]} scale={0}>
            <group name="group3_(1)" position={[0, 0, 0.007745]}>
              <mesh name="share_card15" geometry={nodes.share_card15.geometry} material={materials.standardSurface2} />
              <mesh name="shareCard_number_plane10" geometry={nodes.shareCard_number_plane10.geometry} material={materials.number_material_6} />
            </group>
          </group>
          <group name="grp_share_stack_card16" position={[0, 0.139725, -0.005591]} scale={0}>
            <group name="group4_(1)" position={[-0.000784, 0, 0.008756]} rotation={[0, -0.042113, 0]}>
              <mesh name="share_card16" geometry={nodes.share_card16.geometry} material={materials.standardSurface2} />
              <mesh name="shareCard_number_plane9" geometry={nodes.shareCard_number_plane9.geometry} material={materials.number_material_9} />
            </group>
          </group>
          <group name="grp_share_stack_card17" position={[0, 0.148322, -0.005591]} scale={0}>
            <group name="group5_(1)" position={[-0.000826, 0, 0.006706]}>
              <mesh name="share_card18" geometry={nodes.share_card18.geometry} material={materials.standardSurface2} />
              <mesh name="shareCard_number_plane8" geometry={nodes.shareCard_number_plane8.geometry} material={materials.number_material_8} />
            </group>
          </group>
          <group name="grp_share_stack_card18" position={[0, 0.157893, -0.005591]} scale={0}>
            <group name="group6_(1)" position={[-0.000016, 0, 0.007345]} rotation={[0, 0.028832, 0]}>
              <mesh name="share_card17" geometry={nodes.share_card17.geometry} material={materials.standardSurface2} />
              <mesh name="shareCard_number_plane7" geometry={nodes.shareCard_number_plane7.geometry} material={materials.number_material_1} />
            </group>
          </group>
          <group name="grp_share_stack_card19" position={[0, 0.166225, -0.005591]} scale={0}>
            <group name="group7_(1)" position={[0, 0, 0.006249]}>
              <mesh name="share_card19" geometry={nodes.share_card19.geometry} material={materials.standardSurface2} />
              <mesh name="shareCard_number_plane6" geometry={nodes.shareCard_number_plane6.geometry} material={materials.number_material_0} />
            </group>
          </group>
          <group name="grp_share_stack_card20" position={[0, 0.175351, -0.005591]} scale={0}>
            <group name="group8_(1)" position={[-0.001241, 0, 0.008879]} rotation={[0, -0.064371, 0]}>
              <mesh name="share_card20" geometry={nodes.share_card20.geometry} material={materials.standardSurface2} />
              <mesh name="shareCard_number_plane5" geometry={nodes.shareCard_number_plane5.geometry} material={materials.number_material_7} />
            </group>
          </group>
          <group name="grp_share_stack_card21" position={[0, 0.184687, -0.005591]} scale={0}>
            <group name="group9_(1)" position={[-0.000018, 0, 0.007606]} rotation={[0, 0.026313, 0]}>
              <mesh name="share_card21" geometry={nodes.share_card21.geometry} material={materials.standardSurface2} />
              <mesh name="shareCard_number_plane4" geometry={nodes.shareCard_number_plane4.geometry} material={materials.number_material_4} />
            </group>
          </group>
          <group name="grp_share_stack_card22" position={[0, 0.194313, -0.005591]} scale={0}>
            <group name="group10_(1)" position={[0, 0, 0.007744]} rotation={[0, -0.01341, 0]}>
              <mesh name="share_card22" geometry={nodes.share_card22.geometry} material={materials.standardSurface2} />
              <mesh name="shareCard_number_plane3" geometry={nodes.shareCard_number_plane3.geometry} material={materials.number_material_9} />
            </group>
          </group>
          <group name="grp_share_stack_card23" position={[0, 0.202606, -0.005591]} scale={0}>
            <group name="group11_(1)" position={[0.000038, 0, 0.004274]} rotation={[0, 0.031572, 0]}>
              <mesh name="share_card23" geometry={nodes.share_card23.geometry} material={materials.standardSurface2} />
              <mesh name="shareCard_number_plane2" geometry={nodes.shareCard_number_plane2.geometry} material={materials.number_material_6} />
            </group>
          </group>
          <group name="grp_share_stack_card24" position={[0, 0.210715, -0.005591]} scale={0}>
            <mesh name="share_card24" geometry={nodes.share_card24.geometry} material={materials.standardSurface2} />
            <mesh name="shareCard_number_plane1" geometry={nodes.shareCard_number_plane1.geometry} material={materials.number_material_7} />
          </group>
        </group>
      </group>
  )
}

export default LockboxPile