/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";

export default function Tick({ nodes, materials }) {
  return (
    <>
      <mesh name="tick" geometry={nodes.tick.geometry} material={materials.lambert1} />
    </>
  );
}

