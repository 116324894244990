import React from "react";
import IpaCafeGroup from "./IpaCafeGroup";
import SiloGroup from "./SiloGroup";
import BinarySpeechBubblesGroup from "./BinarySpeechBubblesGroup";
import WebsiteGuysGroup from "./WebsiteGuysGroup";
import IpaHelperGroup from "./IpaHelperGroup";
import IpaConclusionGroup from "./IpaConclusionGroup";
import BuildingsHolder from "../shared-models/holders/BuildingsHolder";
import CityHolder from "../shared-models/holders/CityHolder";
import useTrigger from "../../hooks/useTrigger";
import {text} from "../../data/ipa-data/text";
import {somethingVerySmall} from "../../helpers/scrollHelpers";



function IpaScene() {
  const hide = useTrigger((text[1].adjustedIn - somethingVerySmall), text[19].adjustedIn);

  return (
    <group>
      <IpaCafeGroup />
      <BuildingsHolder hide={hide} position={[-10,0,9.3]}/>
      <CityHolder hide={hide} position={[-10,0,9.3]}/>
      <BinarySpeechBubblesGroup />
      <WebsiteGuysGroup />
      <SiloGroup />
      <IpaHelperGroup />
      <IpaConclusionGroup />
    </group>
  );
}

export default IpaScene;

