const myText = [
  { text: "Template text 1"},
  { text: "Template text 2"},
  { text: "Template text 3"},
  { text: "Template text 4"},
];

export const interval = 1 / myText.length

export const text = myText.map((item, index) => {
  return {
    in: index * interval,
    adjustedIn: (index + 1) * interval,  // adjustedIn accounts for the opening screen having no text pop-up.
    sceneAnchor: index,
    ...item
  }
});