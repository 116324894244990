import React from "react";
import HelpersImage from "../../assets/images/deep-dive-three-helpers.png";
import NumbersImage from "../../assets/images/deep-dive-three-initial-helpers.png";
import styled from "styled-components";

const StyledList = styled.ul`
  padding: 0 2rem;
`;

const StyledP = styled.p`
  margin-top: 2rem;
`;

function DeepDiveThree() {
  return (
    <div>
      <StyledP>
        Earlier, we showed the steps each helper follows to multiply two
        secret-shared numbers. Here’s what each helper got in the example we
        showed:
      </StyledP>
      <img
        src={NumbersImage}
        alt="Three helpers, with the secret shares of 6, 8, and 7."
      />
      <StyledP>
        Each helper has to mask their secret-share before putting it on the
        table to their right. If they did not, we might accidentally reveal too
        much information.
      </StyledP>
      <h2>How is masking done?</h2>

      <p>
        Each of the three helpers roll a ten-sided dice to their right side,
        where both they and their neighbor to the right can see it.
      </p>
      <p>Now each helper can see two dice:</p>
      <StyledList>
        <li>- The one they rolled to their right</li>
        <li>- The one rolled by their neighbor to their left</li>
      </StyledList>
      <p>
        Each helper uses these two random dice rolls to mask their total. They:
      </p>
      <StyledList>
        <li>- Add the number to their right</li>
        <li>- Subtract the number to their left</li>
      </StyledList>
      <p>
        All of this “addition” and “subtraction” is done on the bike locks of
        course, so everything wraps around and the answer is always a number
        from 0 to 9.
      </p>
      <img src={HelpersImage} alt="" />
      <p>
        Let’s work through an example using the helper in the bottom right
        corner of this picture:
      </p>
      <StyledList>
        <li>- Unmasked secret-share: 8</li>
        <li>- Dice to left: 7</li>
        <li>- Dice to right: 4</li>
        <li>- Masked secret-share: 8 + 4 - 7 = 5</li>
      </StyledList>
      <p>
        Once each helper has masked their secret-share, it is safe to share with
        the helper to the right.
      </p>
      <h2>Why doesn't masking make us get the wrong answer?</h2>
      <p>Look at this diagram again:</p>
      <img src={HelpersImage} alt="" />
      <p>
        Notice that each dice roll is added by one helper, and subtracted by
        another. That way, when the three helpers all add up their masked
        totals, the dice rolls all cancel out.
      </p>
      <StyledP>Adding unmasked secret-shares:</StyledP>
      <StyledList>
        <li>7 + 6 + 8</li>
        <li>= 21 (1 on a bike lock)</li>
      </StyledList>
      <StyledP>Adding masked secret-shares:</StyledP>
      <StyledList>
        <li>7 + 6 + 8 + (4 - 4) + (7 - 7) + (5 - 5)</li>
        <li>= 7 + 6 + 8</li>
        <li>= 21 (1 on a bike lock)</li>
      </StyledList>
      <p>
        We made a YouTube video to explain the multiplication protocol. You can
        see it{" "}
        <a
          href="https://www.youtube.com/watch?v=A9XiROfR0w8"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </p>
    </div>
  );
}

export default DeepDiveThree;
