import React from 'react';
import IpaEndIconOneHolder from "./holders/IpaEndIconOneHolder";
import IpaEndIconTwoHolder from "./holders/IpaEndIconTwoHolder";
import IpaEndIconThreeHolder from "./holders/IpaEndIconThreeHolder";
import {text} from "../../data/ipa-data/text";
import { somethingVerySmall } from '../../helpers/scrollHelpers';

function IpaConclusionGroup() {

  return (
    <group position={[1, -1.95, 0]}>
      <IpaEndIconOneHolder position={[0, 0.14, 0]} in={text[16].adjustedIn - somethingVerySmall} scale={0.27} />
      <IpaEndIconTwoHolder position={[-0.16, -0.14, 0]} in={text[17].adjustedIn - somethingVerySmall} scale={0.27} />
      <IpaEndIconThreeHolder position={[0.16, -0.14, 0]} in={text[18].adjustedIn - somethingVerySmall} scale={0.27} />
    </group>
  )
}

export default IpaConclusionGroup;