import * as THREE from 'three'

export const scrollRange = (start, duration, progress) => {
  return THREE.MathUtils.clamp(THREE.MathUtils.mapLinear(progress, start, start + duration, 0, 1), 0, 1)
}

export const scrollSection = (progress, sections) => {
  let section = 0;
  for (let i = 0; i < sections.length; i++) {
    if (i + 1 === sections.length || progress < sections[i + 1]) {
      section = i
      break;
    }
  }
  return section
}

export const somethingVerySmall = 0.00000001