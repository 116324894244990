import React from 'react'

const MultiplicationCube = ({nodes, materials}) => {
  return (
    <group>
      <group name="letter_a">
        <group name="icon_multiplication_a" position={[-106.646812, 590.9441, -17.692257]} rotation={[-Math.PI, 0, -Math.PI]} scale={0.641779}>
          <mesh name="ipa_building_block_secret_multiplying1" geometry={nodes.ipa_building_block_secret_multiplying1.geometry} material={materials.phong_7} position={[-166.731476, -920.9381, -91.26264]} />
          <mesh name="ipa_building_block_secret_multiplying5" geometry={nodes.ipa_building_block_secret_multiplying5.geometry} material={materials.phong_7} position={[-165.581055, -922.0885, -91.26264]} />
          <mesh name="ipa_building_block_secret_multiplying4" geometry={nodes.ipa_building_block_secret_multiplying4.geometry} material={materials.phong_7} position={[-167.977768, -922.1843, -91.26264]} />
          <mesh name="ipa_building_block_secret_multiplying3" geometry={nodes.ipa_building_block_secret_multiplying3.geometry} material={materials.phong_7} position={[-167.5943, -920.0753, -91.26264]} />
          <mesh name="ipa_building_block_secret_multiplying2" geometry={nodes.ipa_building_block_secret_multiplying2.geometry} material={materials.phong_7} position={[-165.1976, -919.4042, -91.26264]} />
        </group>
        <group name="letter_a_pieces">
          <mesh name="Expanding_pCube11" geometry={nodes.Expanding_pCube11.geometry} material={materials.phong_5} scale={[5.219898, 5.219898, 1.135496]} />
          <mesh name="pCube5" geometry={nodes.pCube5.geometry} material={materials.phong_5} />
          <mesh name="pCube6" geometry={nodes.pCube6.geometry} material={materials.phong_5} />
          <mesh name="pCube7" geometry={nodes.pCube7.geometry} material={materials.phong_5} />
          <mesh name="pCube9" geometry={nodes.pCube9.geometry} material={materials.phong_5} />
          <mesh name="pCube10" geometry={nodes.pCube10.geometry} material={materials.phong_5} />
          <mesh name="pCube12" geometry={nodes.pCube12.geometry} material={materials.phong_5} />
          <mesh name="pCube13" geometry={nodes.pCube13.geometry} material={materials.phong_5} />
          <mesh name="pCube15" geometry={nodes.pCube15.geometry} material={materials.phong_5} />
          <mesh name="pCube16" geometry={nodes.pCube16.geometry} material={materials.phong_5} />
          <mesh name="pCube17" geometry={nodes.pCube17.geometry} material={materials.phong_5} />
          <mesh name="pCube18" geometry={nodes.pCube18.geometry} material={materials.phong_5} />
          <mesh name="pCube77" geometry={nodes.pCube77.geometry} material={materials.phong_5} />
        </group>
        <group name="cube_a">
          <mesh name="pCube60" geometry={nodes.pCube60.geometry} material={materials.phong_5} />
          <mesh name="pCube61" geometry={nodes.pCube61.geometry} material={materials.phong_5} />
          <mesh name="pCube62" geometry={nodes.pCube62.geometry} material={materials.phong_5} />
          <mesh name="pCube63" geometry={nodes.pCube63.geometry} material={materials.phong_5} />
          <mesh name="pCube64" geometry={nodes.pCube64.geometry} material={materials.phong_5} />
          <mesh name="pCube65" geometry={nodes.pCube65.geometry} material={materials.phong_5} />
          <mesh name="pCube66" geometry={nodes.pCube66.geometry} material={materials.phong_5} />
          <mesh name="pCube67" geometry={nodes.pCube67.geometry} material={materials.phong_5} />
          <mesh name="pCube68" geometry={nodes.pCube68.geometry} material={materials.phong_5} />
          <mesh name="pCube69" geometry={nodes.pCube69.geometry} material={materials.phong_5} />
          <mesh name="pCube75" geometry={nodes.pCube75.geometry} material={materials.phong_5}>
            <mesh name="pCube56" geometry={nodes.pCube56.geometry} material={materials.phong_5} />
          </mesh>
          <mesh name="pCube76" geometry={nodes.pCube76.geometry} material={materials.phong_5} />
        </group>
      </group>
    </group>
  )
}

export default MultiplicationCube