import {createGlobalStyle} from "styled-components";

const GlobalStyle = createGlobalStyle`

    :root {
        --white: #FAF7FF;
        --green: #b9ffca;
        --pink: #ff6daa;
        --darkGreen: #94ffaf;
        --blue: #0d81f5;
        --purple: #7880fa;
        --orange: #FF9900;
        --darkBlue: #003a71;
        --navy: #365E95;
        --grey: #D9D9D9;
    }

    html,
    body,
    #root {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }

    html {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        ${(props) => props.theme.fluidType(0)};

        * { box-sizing: border-box; }
    }

    body {
        margin: 0;
        // Use system fonts: https://css-tricks.com/snippets/css/system-font-stack/
        font-family: 'Optimistic Text', sans-serif;
        color: var(--darkBlue);
        background-color: white;
    }

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
        line-height: 1.2;
        margin: 0;
        font-family: 'Optimistic Display', sans-serif;
    }

    p,
    ol, ul, li,
    code, kbd, pre, samp {
        line-height: 1.5;
        margin-top: 0.8rem;
        margin-bottom: 0.8rem;
    }

    h1, .h1 {
        ${(props) => props.theme.fluidType(5)};
        letter-spacing: -0.02em;
        font-weight: 700;
    }

    h2, .h2 { ${(props) => props.theme.fluidType(0)}; }

    h3, .h3 { ${(props) => props.theme.fluidType(0)}; }

    h4, .h4 { ${(props) => props.theme.fluidType(0)}; }

    h5, .h5 { ${(props) => props.theme.fluidType(0)}; }

    h6, .h6 { ${(props) => props.theme.fluidType(0)}; }

    li {
        margin-top: 0;
        margin-bottom: 0;
    }

    small, p.small { ${(props) => props.theme.fluidType(0)}; }

    code, kbd, pre, samp {
        font-family: monospace;
        white-space: normal;
    }

    ul {
        padding-left: 0;
        list-style: none;
    }

    em, i { font-style: italic; }

    strong, b { font-weight: bold; }

    a {
        color: inherit;

        &:hover {
            text-decoration: underline;
        }
    }

    sup, sub {
        vertical-align: baseline;
        position: relative;
        top: -0.4em;
    }

    sub { top: 0.4em; }

    .button,
    button,
    input[type="submit"],
    input[type="button"],
    input[type="reset"] {
        display: inline-block;
        font-family: inherit;
        ${(props) => props.theme.fluidType(0)};
        text-decoration: none;
        border: none;
        padding: 0;
        background-color: transparent;
        white-space: normal;
        line-height: normal;
        color: inherit;
        cursor: pointer;
        transition: background-color 0.25s ease;

        &:hover {
            text-decoration: none;
            background-color: var(--green);
        }

        &.smallGreen {
            padding: 0.2rem 0.5rem;
            cursor: pointer;
            align-self: flex-start;
            background-color: var(--green);
            ${(props) => props.theme.fluidType(-3)};
            border: 1px solid var(--green);

            &:hover {
                background-color: var(--darkGreen);
            }
        }
    }

    .introCube-text {
        width: 210px;
        height: 60px;
        outline: none;
        border: none;
        font-size: 18px;
        color: var(--purple);
        font-weight: bold;
        padding: 10px 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .calculatorEquation {
        padding: 10px;
        border-radius: 0.6em;
        font-size: 16px;
        text-align: center;
        font-weight: bold;
        color: #645fe8;

        p {
            margin: 0;
            line-height: 1.2;
        }

        p:last-child {
            font-size: 1.8em;
        }
    }

    .defaultLabel {
        position: relative;

        p { margin: 0; }

        .target {
            color: #ffffff;
            border-radius: 0.6em;
            border-top-left-radius: 0;
            border: 0.3em solid;
        }

        .label {
            background: #ffffff;
            border-top-left-radius: 0.6em;
            border-top-right-radius: 0.6em;
            padding: 0.6em 0.8em 0.8em;
            font-size: 0.8em;
            text-align: left;
            font-weight: bold;
            color: #645fe8;
            position: absolute;
            bottom: 100%;
            left: 0;
        }

        &.noLabel {
            .target {
                border-radius: 0.6em !important;
            }
        }

        &.fullWidthLabel {
            .label {
                width: 100%;
                text-align: center;
            }

            .target {
                border-top-right-radius: 0;
            }
        }

        &.bottom {
            .label {
                top: 100%;
                bottom: auto;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                border-bottom-left-radius: 0.6em;
                border-bottom-right-radius: 0.6em;
            }

            .target {
                border-bottom-left-radius: 0;
                border-top-left-radius: 0.6em;
            }

            &.fullWidthLabel {
                .target {
                    border-top-right-radius: 0.6em;
                    border-bottom-right-radius: 0;
                }
            }
        }

        &.lock {
            .target {
                color: #ff1f8f;
            }
        }

        &.pink {
            .target {
                color: #ff5ce6;
            }

            .label {
                background: #ff5ce6;
                color: #ffffff;
            }
        }

        .cross, .tick, .lock {
            width: 2.5em;
            height: 2.5em;
            border-radius: 50%;
            position: absolute;
            top: -0.6em;
            right: -0.6em;

            svg {
                width: 0.75em;
                height: 0.75em;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .cross, .lock {
            background: #ff1f8f;
        }

        .tick {
            background: #64ff89;
        }
    }

    .hiddenShares {
        border-radius: 0.6em;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2em;
        //background: linear-gradient(268.45deg, #DDCDFB 0%, #7880FA 101.47%);
        //background: linear-gradient(-115deg, #e2cffc 30%, #cfecfc 70%);
        background: white;

        p {
            margin: 0;
            width: 2.4em;
            height: 2.4em;
            font-weight: 700;
            font-size: 1.8em;
            color: var(--purple);
            border-radius: 5px;
            border: 0.1em solid;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .addition-arrow-right {
        width: 20px;
        height: 15px;
        color: white;
        border-left: 20px solid;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
    }

    .addition-arrow-left {
        width: 20px;
        height: 15px;
        color: white;
        border-right: 20px solid;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
    }



`;

export default GlobalStyle;

