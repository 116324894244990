import React from "react";
import GlobalUserGroup from "./GlobalUserGroup";
import TableHolder from "../shared-models/holders/TableHolder";
import HelperTableGroup from "./HelperTableGroup";
import ShareStackingGroup from "./ShareStackingGroup";
import SpeechBubblesGroup from "./SpeechBubblesGroup";
import PineappleTotalHolder from "./holders/PineappleTotalHolder";
import UserPairGroup from "./UserPairGroup";
import AdditionSpinnerGroup from "./AdditionSpinnerGroup";

function AdditionScene() {
  return (
    <group>
      <GlobalUserGroup />
      <UserPairGroup />
      <HelperTableGroup />
      <ShareStackingGroup />
      <AdditionSpinnerGroup />
      <TableHolder position={[0, -0.1, 0]}/>
      <SpeechBubblesGroup />
      <PineappleTotalHolder />
    </group>
  );
}

export default AdditionScene;

