import React from "react";
import UserPair from "./UserPair";
import SharedSecretHolder from "../shared-models/holders/SharedSecretHolder";
import useTrigger from "../../hooks/useTrigger";
import {text, interval} from "../../data/multiplication-data/text";
import {somethingVerySmall} from "../../helpers/scrollHelpers";

const pairs = [
  {position: [-3, 0, 3], rotation: [0, Math.PI / 3, 0]},
  {position: [4, 0, -2.5], rotation: [0, -Math.PI / 4, 0]},
  {position: [3, 0, 2], rotation: [0, -Math.PI / 3, 0]},
  {position: [-5, 0, -3], rotation: [0, Math.PI / 4, 0]},

  {position: [-7, 0, 0], rotation: [0, Math.PI / 3, 0]},
  {position: [7, 0, -1], rotation: [0, -Math.PI / 3, 0]},
  {position: [-4, 0, 6], rotation: [0, -Math.PI / 4, 0]},
  {position: [5, 0, 5], rotation: [0, Math.PI / 3, 0]},
  {position: [0, 0, 6], rotation: [0, Math.PI / 4, 0]},
  {position: [-4, 0, -7], rotation: [0, -Math.PI / 4, 0]},
  {position: [5, 0, -7], rotation: [0, Math.PI / 3, 0]},
]

const PairsWithSharesGroup = () => {
  const hide = useTrigger(text[21].adjustedIn - somethingVerySmall)
  const showShare = useTrigger(text[20].adjustedIn - somethingVerySmall, text[21].adjustedIn - somethingVerySmall)

  const idleIn = text[0].adjustedIn;
  const idleOut = text[2].adjustedIn;

  return (
    <group visible={!hide}>
      {pairs.map((pair, i) => (
        <group
          key={i}
        >
          <UserPair
            position={pair.position}
            rotation={pair.rotation}
            idleIn={idleIn}
            idleOut={idleOut}
          />
          <group position={pair.position}>
            <SharedSecretHolder
              show={showShare}
              scale={2}
              position={[0, 1.8, 0]}
              split={text[20].adjustedIn}
              turnToShares={text[20].adjustedIn + interval / 3}
              sharesLeave={text[21].adjustedIn - interval / 3}
              leave={text[21].adjustedIn}
              number={i % 2}
              color={i % 2 === 0 ? "pink" : "green"}
            />
          </group>
        </group>
      ))}
    </group>
  );
};

export default PairsWithSharesGroup;
