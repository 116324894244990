import React from "react";
import useTrigger from "../../hooks/useTrigger";
import CafeUserHolder from "./holders/CafeUserHolder";
import {text} from "../../data/ipa-data/text";
import {somethingVerySmall} from "../../helpers/scrollHelpers";

const IpaCafeGroup = () => {
  const hideBackground = useTrigger(text[5].adjustedIn);

  return (
    <group visible={!hideBackground}>
      <CafeUserHolder
        show={!hideBackground}
        toDevices={text[1].adjustedIn - somethingVerySmall} 
        showPointer={text[3].adjustedIn - somethingVerySmall}
        newsToPurchase={text[4].adjustedIn - somethingVerySmall}
        scale={1}
        position={[0, 0, 0]}
      />
    </group>
  );
};

export default IpaCafeGroup;

