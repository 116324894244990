import { ReactComponent as IntroIcon } from "../../assets/svg/intro-icon.svg";
import { ReactComponent as SecretIcon } from "../../assets/svg/secret-icon.svg";
import { ReactComponent as IpaIcon } from "../../assets/svg/ipa-icon.svg";
import { ReactComponent as AdditionIcon } from "../../assets/svg/addition-icon.svg";
import { ReactComponent as MultiplicationIcon } from "../../assets/svg/multiplication-icon.svg";

import SecretSharingAndAdditionVideoCover from "../../assets/images/additional-resources/secret-share-and-addition-video-cover.png";
import AdditionVideoCover from "../../assets/images/additional-resources/addition-video-cover.png";
import MultiplicationVideoCoverOne from "../../assets/images/additional-resources/multiplication-video-cover-one.png";
import MultiplicationVideoCoverTwo from "../../assets/images/additional-resources/multiplication-video-cover-two.png";

export const PLAYLIST_LINKS = [
  {
    path: "/intro",
    Icon: IntroIcon,
    heading: "The problem we are solving",
    description:
      "The way ad measurement works involves multiple companies learning about which apps and websites you visited. We have a proposal for a new way of measuring ads in a more private way.",
    resources: [
      {
        heading:
          "Mobile Dev Memo Podcast: Understanding Interoperable Private Attribution (with Ben Savage)",
        link: {
          url: "https://mobiledevmemo.com/podcast-understanding-interoperable-private-attribution-with-ben-savage/",
          label: "Listen to the podcast",
        },
      },
    ]
  },
  {
    path: "/ipa",
    Icon: IpaIcon,
    heading: "An Overview of IPA",
    description:
      "IPA is a proposal of a new way to measure ads, which doesn’t reveal which apps or websites individuals have interacted with. This section will provide a high-level overview of the IPA protocol.",
    resources: [
      {
        heading:
          "YouTube Channel MPC Explained",
        link: {
          url: "https://www.youtube.com/@MPCExplained",
          label: "Watch the videos",
          image: SecretSharingAndAdditionVideoCover,
        },
      },
      {
        heading: "Open source code on GitHub implementing the IPA protocol",
        link: {
          url: "https://github.com/private-attribution/ipa/",
          label: "See on GitHub",
        },
      },
      {
        heading: "A detailed technical version of the proposal on GitHub",
        link: {
          url: "https://github.com/patcg-individual-drafts/ipa/blob/main/IPA-End-to-End.md",
          label: "See on GitHub",
        },
      },
      {
        heading: "A formal analysis of IPA posted on ePrint",
        link: {
          url: "https://eprint.iacr.org/2023/437",
          label: "Read on ePrint",
        },
      },
    ],
  },
  {
    path: "/secret",
    Icon: SecretIcon,
    heading: "Secret Sharing Basics",
    description:
      "In IPA, information is first secret-shared before it ever leaves the browser or device. This helps keep it safe, and ensures that no party can learn which apps and websites an individual is visiting.",
    resources: [
      {
        heading:
          "Introduction to secret-sharing and adding secret-shared numbers in MPC",
        link: {
          url: "https://www.youtube.com/watch?v=zrmMmq9N9FY",
          label: "Watch the video",
          image: SecretSharingAndAdditionVideoCover,
        },
      },
    ],
  },
  {
    path: "/addition",
    Icon: AdditionIcon,
    heading: "Adding Secret Shared Numbers",
    description:
      "IPA is designed to calculate statistics like total counts and sums across large groups of people. These contributions can be added up to calculate the total, while keeping each person’s contribution a secret.",
    resources: [
      {
        heading: "Introduction to secret-sharing and adding secret-shared numbers in MPC",
        link: {
          url: "https://www.youtube.com/watch?v=zrmMmq9N9FY",
          label: "Watch the video",
          image: SecretSharingAndAdditionVideoCover,
        },
      },
      {
        heading: "Why does addition in MPC work?",
        link: {
          url: "https://www.youtube.com/watch?v=F2yTh0QL1UU",
          label: "Watch the video",
          image: AdditionVideoCover,
        },
      },
    ],
  },
  {
    path: "/multiplication",
    Icon: MultiplicationIcon,
    heading: "Multiplying Secret Shared Numbers",
    description:
      "There is more to IPA than just adding things up. For example: checking which purchases have corresponding ad impressions. To do this, we need to multiply secret-shared numbers.",
    resources: [
      {
        heading: "Introducing multiplication in MPC",
        link: {
          url: "https://www.youtube.com/watch?v=A9XiROfR0w8&t=158s",
          label: "Watch the video",
          image: MultiplicationVideoCoverOne,
        },
      },
      {
        heading: "How multiplication in MPC works",
        link: {
          url: "https://www.youtube.com/watch?v=DA50CZirucY",
          label: "Watch the video",
          image: MultiplicationVideoCoverTwo,
        },
      },
    ],
  },
];

