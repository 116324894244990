/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";

export default function City({nodes, materials}) {
  return (
    <group>
        <group name="Cars">
          <group name="car_6" position={[-2.45834, -0.048012, -49.06032]} rotation={[0, 0.028928, 0]} scale={10}>
            <mesh name="car_mesh1" geometry={nodes.car_mesh1.geometry} material={materials.lambert1} position={[-0.27588, 0.065939, -0.823713]} />
            <mesh name="car_wheels1" geometry={nodes.car_wheels1.geometry} material={materials.lambert1} position={[-0.23191, 0.030494, -0.825293]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="car_wheels2" geometry={nodes.car_wheels2.geometry} material={materials.lambert1} position={[-0.328463, 0.030494, -0.825293]} rotation={[Math.PI / 2, 0, 0]} />
          </group>
          <group name="car_12" position={[-6.132537, -0.048012, -36.263542]} rotation={[-Math.PI, -1.570142, -Math.PI]} scale={10}>
            <mesh name="car_mesh2" geometry={nodes.car_mesh2.geometry} material={materials.lambert1} position={[-0.27588, 0.065939, -0.823713]} />
            <mesh name="car_wheels3" geometry={nodes.car_wheels3.geometry} material={materials.lambert1} position={[-0.330004, 0.030494, -0.825293]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="car_wheels4" geometry={nodes.car_wheels4.geometry} material={materials.lambert1} position={[-0.233451, 0.030494, -0.825293]} rotation={[Math.PI / 2, 0, 0]} />
          </group>
          <group name="car_7" position={[-29.21858, -0.04755, -37.070267]} rotation={[-Math.PI, -1.570307, -Math.PI]} scale={10}>
            <mesh name="car_mesh3" geometry={nodes.car_mesh3.geometry} material={materials.lambert1} position={[-0.27588, 0.065939, -0.823713]} />
            <mesh name="car_wheels5" geometry={nodes.car_wheels5.geometry} material={materials.lambert1} position={[-0.330004, 0.030494, -0.825293]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="car_wheels6" geometry={nodes.car_wheels6.geometry} material={materials.lambert1} position={[-0.233451, 0.030494, -0.825293]} rotation={[Math.PI / 2, 0, 0]} />
          </group>
          <group name="car_11" position={[-28.391035, -0.04755, -52.239788]} rotation={[-Math.PI, -0.768556, -Math.PI]} scale={10}>
            <group name="car_11_grp" position={[0.525134, 0, -0.521978]} rotation={[-Math.PI, 0, -Math.PI]}>
              <mesh name="car_mesh12" geometry={nodes.car_mesh12.geometry} material={materials.lambert1} position={[-0.27588, 0.065939, -0.823713]} />
              <mesh name="car_wheels112" geometry={nodes.car_wheels112.geometry} material={materials.lambert1} position={[-0.23191, 0.030494, -0.825293]} rotation={[Math.PI / 2, 0, 0]} />
              <mesh name="car_wheels212" geometry={nodes.car_wheels212.geometry} material={materials.lambert1} position={[-0.328463, 0.030494, -0.825293]} rotation={[Math.PI / 2, 0, 0]} />
            </group>
          </group>
          <group name="car_8" position={[5.335705, -0.04755, -52.100956]} rotation={[-Math.PI, 1.211865, -Math.PI]}>
            <mesh name="car_mesh5" geometry={nodes.car_mesh5.geometry} material={materials.lambert1} />
            <mesh name="car_wheels9" geometry={nodes.car_wheels9.geometry} material={materials.lambert1} position={[-0.329104, 0.030494, -0.825293]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="car_wheels10" geometry={nodes.car_wheels10.geometry} material={materials.lambert1} position={[-0.232551, 0.030494, -0.825293]} rotation={[Math.PI / 2, 0, 0]} />
          </group>
          <group name="car_13" position={[-1.931576, -0.048012, -2.452756]} rotation={[-Math.PI, 1.568427, -Math.PI]} scale={10}>
            <group name="car_13_grp">
              <mesh name="car_mesh16" geometry={nodes.car_mesh16.geometry} material={materials.lambert1} position={[-0.27588, 0.065939, -0.823713]} />
              <mesh name="car_wheels12" geometry={nodes.car_wheels12.geometry} material={materials.lambert1} position={[-0.23191, 0.030494, -0.825293]} rotation={[Math.PI / 2, 0, 0]} />
              <mesh name="car_wheels22" geometry={nodes.car_wheels22.geometry} material={materials.lambert1} position={[-0.328463, 0.030494, -0.825293]} rotation={[Math.PI / 2, 0, 0]} />
            </group>
          </group>
          <group name="car_14" position={[28.02677, -0.048012, -12.817209]} rotation={[-Math.PI, -1.431584, -Math.PI]} scale={10}>
            <mesh name="car_mesh18" geometry={nodes.car_mesh18.geometry} material={materials.lambert1} position={[-0.27588, 0.065939, -0.823713]} />
            <mesh name="car_wheels13" geometry={nodes.car_wheels13.geometry} material={materials.lambert1} position={[-0.23191, 0.030494, -0.825293]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="car_wheels23" geometry={nodes.car_wheels23.geometry} material={materials.lambert1} position={[-0.328463, 0.030494, -0.825293]} rotation={[Math.PI / 2, 0, 0]} />
          </group>
          <group name="car_15" position={[18.298971, -0.048012, -52.809315]} rotation={[0, -0.733616, 0]} scale={10}>
            <group name="car_15_grp" position={[0.53179, 0, -0.52181]} rotation={[-Math.PI, 0, -Math.PI]} scale={0.1}>
              <mesh name="car_mesh66" geometry={nodes.car_mesh66.geometry} material={materials.lambert1} />
              <mesh name="car_wheels114" geometry={nodes.car_wheels114.geometry} material={materials.lambert1} position={[-0.329104, 0.030494, -0.825293]} rotation={[Math.PI / 2, 0, 0]} />
              <mesh name="car_wheels124" geometry={nodes.car_wheels124.geometry} material={materials.lambert1} position={[-0.232551, 0.030494, -0.825293]} rotation={[Math.PI / 2, 0, 0]} />
            </group>
          </group>
          <group name="car_16" position={[-29.221918, -0.04755, -25.849836]} rotation={[0, -1.570211, 0]} scale={10}>
            <mesh name="car_mesh36" geometry={nodes.car_mesh36.geometry} material={materials.lambert1} position={[-0.27588, 0.065939, -0.823713]} />
            <mesh name="car_wheels51" geometry={nodes.car_wheels51.geometry} material={materials.lambert1} position={[-0.330004, 0.030494, -0.825293]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="car_wheels61" geometry={nodes.car_wheels61.geometry} material={materials.lambert1} position={[-0.233451, 0.030494, -0.825293]} rotation={[Math.PI / 2, 0, 0]} />
          </group>
          <group name="car_17" position={[-13.048188, -0.04755, 2.032018]} rotation={[0, -0.00768, 0]} scale={10}>
            <mesh name="car_mesh34" geometry={nodes.car_mesh34.geometry} material={materials.lambert1} position={[-0.27588, 0.065939, -0.823713]} />
            <mesh name="car_wheels54" geometry={nodes.car_wheels54.geometry} material={materials.lambert1} position={[-0.330004, 0.030494, -0.825293]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="car_wheels64" geometry={nodes.car_wheels64.geometry} material={materials.lambert1} position={[-0.233451, 0.030494, -0.825293]} rotation={[Math.PI / 2, 0, 0]} />
          </group>
          <group name="car_18" position={[6.035025, -0.04755, -13.457169]} rotation={[0, 1.570113, 0]} scale={10}>
            <mesh name="car_mesh37" geometry={nodes.car_mesh37.geometry} material={materials.lambert1} position={[-0.27588, 0.065939, -0.823713]} />
            <mesh name="car_wheels57" geometry={nodes.car_wheels57.geometry} material={materials.lambert1} position={[-0.330004, 0.030494, -0.825293]} rotation={[Math.PI / 2, 0, 0]} />
            <mesh name="car_wheels67" geometry={nodes.car_wheels67.geometry} material={materials.lambert1} position={[-0.233451, 0.030494, -0.825293]} rotation={[Math.PI / 2, 0, 0]} />
          </group>
        </group>
        <group name="City">
          <group name="FG_elements">
            <group name="bird2" position={[1.02102, 0.631308, 1.554414]} rotation={[0.272422, 0.906898, -0.170212]} scale={1.047139}>
              <mesh name="pCube1" geometry={nodes.pCube1.geometry} material={materials.lambert1} position={[0, 0.009026, 0.014743]} rotation={[-0.394726, 0, 0]}>
                <mesh name="pCube5" geometry={nodes.pCube5.geometry} material={materials.lambert1} />
                <mesh name="pCube4" geometry={nodes.pCube4.geometry} material={materials.lambert1} position={[-0.013385, -0.00038, 0.025206]} rotation={[-0.239644, 0.047427, -0.123063]} />
                <mesh name="pCube6" geometry={nodes.pCube6.geometry} material={materials.lambert1} position={[0.027096, -0.004685, 0.004463]} rotation={[-0.042903, -0.05727, 0.24658]} />
                <mesh name="pCube2" geometry={nodes.pCube2.geometry} material={materials.lambert1} position={[0, 0.006234, 0.011906]} rotation={[-0.099257, 0, 0]} />
                <mesh name="pCube3" geometry={nodes.pCube3.geometry} material={materials.lambert1} position={[0.000171, 0.006109, 0.013713]} rotation={[-0.051958, 0, 0]} />
              </mesh>
            </group>
            <mesh name="bench" geometry={nodes.bench.geometry} material={materials.lambert1} />
            <mesh name="fence" geometry={nodes.fence.geometry} material={materials.lambert1} />
            <mesh name="fence2" geometry={nodes.fence2.geometry} material={materials.lambert1} position={[2.666121, 0, 0.442275]} rotation={[0, -0.016745, 0]} />
            <mesh name="hotDogStand" geometry={nodes.hotDogStand.geometry} material={materials.lambert1} position={[-1.439082, 0, 3.395317]} rotation={[-Math.PI, -0.738327, -Math.PI]} />
            <mesh name="fence1" geometry={nodes.fence1.geometry} material={materials.lambert1} position={[1.931877, 0, -0.138291]} rotation={[0, -0.343527, 0]} />
          </group>
          <mesh name="street_racks" geometry={nodes.street_racks.geometry} material={materials.lambert1} />
          <mesh name="road_mesh" geometry={nodes.road_mesh.geometry} material={materials.lambert1} />
        </group>
        <group name="a_City">
          <group name="FG_elements_(1)">
            <mesh name="tree_1" geometry={nodes.tree_1.geometry} material={materials.lambert1} />
            <mesh name="tree_2" geometry={nodes.tree_2.geometry} material={materials.lambert1} />
          </group>
          <mesh name="lamps" geometry={nodes.lamps.geometry} material={materials.lambert1} />
        </group>
      </group>
  );
}

