/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useEffect, useMemo, useRef, useState} from "react";
import {useGLTF, useAnimations, Html, Plane} from "@react-three/drei";
import useTriggerAnimation from "../../../hooks/useTriggerAnimation";
import Calculator from "../models/Calculator";
import useSetFirstAnimationFrame from "../../../hooks/useSetFirstAnimationFrame";
import useTrigger from "../../../hooks/useTrigger";
import useNewMaterials from "../../../hooks/useNewMaterials";
import {text} from "../../../data/multiplication-data/text";
import {somethingVerySmall} from "../../../helpers/scrollHelpers";
import useIsMobile from "../../../hooks/useIsMobile";

export default function CalculatorHolder() {
  const group = useRef();
  const [labelPosition , setLabelPosition] = useState([0, 0.01, -0.0101]);
  const {isDesktop} = useIsMobile();
  const {nodes, materials, animations} = useGLTF(
    "/SceneMultiplication/calculator-transformed.glb"
  );
  const specificMaterials = useMemo(() => [
    {name: 'pasted__standardSurface2', color: 'green2'},
    {name: 'phong_calculator_screen', color: 'purple4'},
    {name: 'phong_calculator_buttons', color: 'purple1'},
  ], []);
  const newMaterials = useNewMaterials(materials, {
    baseMaterial: 'purple3',
    specificMaterials: specificMaterials
  });

  const {actions} = useAnimations(animations, group);

  const show = useTrigger(text[10].adjustedIn - somethingVerySmall, text[17].adjustedIn);

  const [currentEquation, setCurrentEquation] = useState(null);
  const line1Text = [
    "",
    "(7 x 4)",
    "(7 x 4) + (4 x 6)",
    "(7 x 4) + (4 x 6) + (7 x 8)"
  ]
  const line2Text = [
    "(7 x 4)",
    "+ (4 x 6)",
    "+ (7 x 8)",
    "= 108"
  ]
  const trigger1 = useTrigger(text[11].adjustedIn);
  const trigger2 = useTrigger(text[12].adjustedIn - somethingVerySmall);
  const trigger3 = useTrigger(text[13].adjustedIn - somethingVerySmall);
  const trigger4 = useTrigger(text[14].adjustedIn - somethingVerySmall);
  const hideHtml = useTrigger(text[15].adjustedIn + somethingVerySmall);

  useEffect(() => {
    if (trigger4) {
      setCurrentEquation(3);
    } else if (trigger3) {
      setCurrentEquation(2);
    } else if (trigger2) {
      setCurrentEquation(1);
    } else if (trigger1) {
      setCurrentEquation(0);
    } else {
      setCurrentEquation(null);
    }

  }, [trigger1, trigger2, trigger3, trigger4]);

  useEffect(() => {
    if(isDesktop) {
      setLabelPosition([0, 0.015, -0.0101]);
    } else {
      setLabelPosition([0.015, 0.2, -0.04]);
    }
  }, [isDesktop]);


  useSetFirstAnimationFrame('enters', actions);
  useTriggerAnimation(text[9].adjustedIn, "enters", actions);
  useTriggerAnimation(text[11].adjustedIn - somethingVerySmall, "finger-punching", actions);
  useTriggerAnimation(text[12].adjustedIn - somethingVerySmall, "finger-punching", actions);
  useTriggerAnimation(text[13].adjustedIn - somethingVerySmall, "finger-punching", actions);
  useTriggerAnimation(text[15].adjustedIn + somethingVerySmall, "leaves", actions);

  return (
    <group
      ref={group}
      dispose={null}
      visible={show}
      scale={6}
      position={[0.71, 1.4, 0.39]}
      rotation={[0, Math.PI * 0.34, 0]}
    >
      <group rotation={[Math.PI * 0.2, 0, 0]}>
        <Calculator nodes={nodes} materials={newMaterials} />
        <Plane args={[0.1, 0.1]} scale={[0.15, 0.05, 1]} position={labelPosition} rotation={[-Math.PI * 0.6, 0, 0]}>
          <meshStandardMaterial
            transparent={true}
            opacity={0}
            // color={'red'}
          />
          <Html
            scale={0.1}
            className="calculatorEquation"
            style={{
              opacity: currentEquation === null || hideHtml ? 0 : 1,
              transform: isDesktop ? "translate(-50%, -50%) rotateZ(-2deg)" : "translate(-50%, -50%)",
              backgroundColor: isDesktop ? 'transparent' : "white",
              width: isDesktop ? '300px' : "150px",
            }}
          >
            <p>{line1Text[currentEquation]}</p>
            <p>{line2Text[currentEquation]}</p>
          </Html>
        </Plane>
      </group>

    </group>
  );
}

useGLTF.preload("/SceneMultiplication/calculator-transformed.glb");

