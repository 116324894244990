import React, {useMemo, useRef} from "react";
import { useAnimations, useGLTF } from "@react-three/drei";
import PropTypes from "prop-types";
import Browsers from "../models/Browsers"
import useTriggerAnimation from "../../../hooks/useTriggerAnimation";
import useSetFirstAnimationFrame from "../../../hooks/useSetFirstAnimationFrame";
import useNewMaterials from "../../../hooks/useNewMaterials";

export default function BrowsersHolder({ ...props }) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(
    "/SceneIntro/browsers-transformed.glb"
  );
  const { actions } = useAnimations(animations, group);

  const specificMaterials = useMemo(() => [
    {name: 'phong_3', color: 'grey2'},
    {name: 'phong_7', color: 'green3'},
    {name: 'red_lock_phong_4', color: 'pink1'},
  ], []);
  const newMaterials = useNewMaterials(materials, { specificMaterials: specificMaterials })

  useSetFirstAnimationFrame('enter', actions);
  useTriggerAnimation(props.in, "enter", actions, null, false, 1);
  useTriggerAnimation(props.appsFly, "apps_fly_off", actions, null, false, 0.5);
  useTriggerAnimation(props.appsPile, "apps_pile_up", actions, null, false, 1);
  useTriggerAnimation(props.appsLock, "apps_fly_back_then_locks", actions, null, false, 1);

  return (
    <group
      ref={group}
      position={props.position}
      dispose={null}
      visible={props.show}
      scale={props.scale}
    >
      <Browsers materials={newMaterials} nodes={nodes} />
    </group>
  );
}

BrowsersHolder.propTypes = {
  show: PropTypes.bool,
  in: PropTypes.number,
  appsFly: PropTypes.number,
  appsPile: PropTypes.number,
  appsLock: PropTypes.number,
  scale: PropTypes.number,
  position: PropTypes.array,
};

BrowsersHolder.defaultProps = {
  show: true,
  in: null,
  appsFly: null,
  appsPile: null,
  appsLock: null,
  scale: null,
  position: null,
};

useGLTF.preload("/SceneIntro/browsers-transformed.glb");

