import React from "react";
import { useBoundStore } from "../stores/useBoundStore";
import { text } from "../data/addition-data/text";
import { audio } from "../data/addition-data/audio";
import { keyframes } from "../data/addition-data/camera";
import AnimatedPageHolder from "../components/common/AnimatedPageHolder";
import AdditionScene from "../components/addition-page/AdditionScene";

const timelineId = "addition-timeline";
const progressName = "additionProgress";

function Addition() {
  const {
    additionProgress,
    setAdditionProgress,
    additionTextIndex,
    setAdditionTextIndex,
  } = useBoundStore();

  // useEffect(() => {
  //   console.log("additionProgress", additionProgress);
  //   console.log("additionTextIndex", additionTextIndex);
  // }, [additionProgress, additionTextIndex]);

  return (
    <AnimatedPageHolder
      setProgress={setAdditionProgress}
      setTextIndex={setAdditionTextIndex}
      text={text}
      keyframes={keyframes}
      progressName={progressName}
      progress={additionProgress}
      currentTextIndex={additionTextIndex}
      introText={
        <>
          <span className="intro-label">Explainer 4</span>
          <h1>Addition</h1>
          <p>
            IPA is designed to calculate statistics like total counts and sums
            across large groups of people. These contributions can be added up
            to calculate the total, while keeping each person’s contribution a
            secret.
          </p>
        </>
      }
      cameraKeyframes={keyframes}
      pageIndex={3}
      textContent={text}
      audioContent={audio}
      uniqueTimelineId={timelineId}
    >
      <AdditionScene />
    </AnimatedPageHolder>
  );
}

export default Addition;
