/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useEffect, useMemo, useRef} from "react";
import {useGLTF, useAnimations} from "@react-three/drei";
import SharedSecret from "../models/SharedSecret";
import useTriggerAnimation from "../../../hooks/useTriggerAnimation";
import useSetFirstAnimationFrame from "../../../hooks/useSetFirstAnimationFrame";
import PropTypes from "prop-types";
import {secretShares} from "../../../data/secretShares";
import useTrigger from "../../../hooks/useTrigger";
import useNewMaterials from "../../../hooks/useNewMaterials";
import SharedSecretNumbers from "./SecretShareNumbers";
import {somethingVerySmall} from "../../../helpers/scrollHelpers";
import EyeHolder from "./EyeHolder";
import PurchaseHolder from "./PurchaseHolder";

export default function SharedSecretHolder({...props}) {
  const group = useRef();

  // Set the shares
  const randomValue = useRef(Math.floor(Math.random() * secretShares[props.number].length));
  const shares = props.shares ? props.shares : secretShares[props.number][randomValue.current];

  // Load the model
  const {nodes, materials, animations} = useGLTF(
    "/GlobalModels/shared-secret-transformed.glb"
  );
  const {actions} = useAnimations(animations, group);

  // Set up the materials
  const specificMaterials = useMemo(() => [
    { name: 'number_material_0', replaceWith: `number_material_${props.number}`, setMaterialColor: 0xffffff },
    { name: 'phong_7', color: `${props.color}2` },
  ], [props.number, props.color])
  const newMaterials = useNewMaterials(materials, {
    baseMaterial: `${props.color}4`,
    specificMaterials: specificMaterials
  });

  // Set the animation triggers
  useSetFirstAnimationFrame('enter', actions)
  useTriggerAnimation(props.in, "enter", actions, null, false, 1.5);
  useTriggerAnimation(props.split, "split", actions, null, false, 1.5);
  useTriggerAnimation(props.turnToShares, "shares-appear", actions);
  useTriggerAnimation(props.sharesLeave, "shares-fly-away", actions, null, false, 0.5);
  useTriggerAnimation(props.leave, "leave", actions);
  let showNumbersEnd = props.sharesLeave ? props.sharesLeave : props.leave;
  const showNumbers = useTrigger(props.split, showNumbersEnd - somethingVerySmall);

  // For debug purposes only
  useEffect(() => {
    if(props.debug) {
      console.log('Show numbers: ', showNumbers);
      console.log('In: ', props.in);
      console.log('Split: ', props.split);
      console.log('Turn to shares: ', props.turnToShares);
      console.log('Shares leave: ', props.sharesLeave);
      console.log('Leave: ', props.leave);
    }
  }, [props.debug, showNumbers, props.in, props.split, props.turnToShares, props.sharesLeave, props.leave]);

  return (
    <group
      ref={group}
      position={props.position}
      rotation={props.rotation}
      dispose={null}
      visible={props.show}
      scale={props.scale}
    >
      <SharedSecret nodes={nodes} materials={newMaterials} />
      <SharedSecretNumbers shares={shares} showNumbers={showNumbers} debug={props.debug}/>
      <group visible={props.show && ( props.forImpression || props.forPurchase )} position={[-0.125, 0.125, 0.05]}>
        <EyeHolder show={props.forImpression} in={props.split} position={[0, 0, 0]} scale={0.02}/>
        <PurchaseHolder show={props.forPurchase} in={props.split} position={[0, 0, 0]} scale={0.02}/>
      </group>
    </group>
  );
}

SharedSecretHolder.propTypes = {
  show: PropTypes.bool,
  scale: PropTypes.number,
  position: PropTypes.array,
  rotation: PropTypes.array,
  in: PropTypes.number,
  split: PropTypes.number,
  turnToShares: PropTypes.number,
  sharesLeave: PropTypes.number,
  leave: PropTypes.number,
  number: PropTypes.number,
  shares: PropTypes.array,
  color: PropTypes.string,
  debug: PropTypes.bool,
  forImpression: PropTypes.bool,
  forPurchase: PropTypes.bool,
}

SharedSecretHolder.defaultProps = {
  show: true,
  scale: 1,
  position: [0, 0, 0],
  rotation: [0, 0, 0],
  in: null,
  split: null,
  sharesLeave: null,
  leave: null,
  number: 0,
  color: 'pink',
  debug: false,
  forImpression: false,
  forPurchase: false,
}

useGLTF.preload("/GlobalModels/shared-secret-transformed.glb");

