import React, { useRef } from "react";
import {Shadow, useGLTF} from "@react-three/drei";
import Bike from "../models/Bike";
import useNewMaterials from "../../../hooks/useNewMaterials";
import useTrigger from "../../../hooks/useTrigger";
import {text} from "../../../data/secret-data/text";
import {somethingVerySmall} from "../../../helpers/scrollHelpers";

export default function BikeHolder() {
  const hide = useTrigger(text[23].adjustedIn - somethingVerySmall, text[26].adjustedIn - somethingVerySmall);
  const group = useRef();
  const { nodes, materials } = useGLTF("/SceneSecret/bike-transformed.glb");
  const newMaterials = useNewMaterials(materials)
  return (
    <group ref={group} position={[0, 0, 4.9]} dispose={null} visible={!hide}>
      <Bike materials={newMaterials} nodes={nodes} />
      <Shadow color="purple" position={[-0.4,0,0]} opacity={0.1} scale={[0.75, 0.5, 0]}/>
      <Shadow color="purple" position={[0.4,0,0]} opacity={0.1} scale={[0.75, 0.5, 0]}/>
    </group>
  );
}

useGLTF.preload("/SceneSecret/bike-transformed.glb");