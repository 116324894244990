/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";

export default function MultiplicationIcon({ materials, nodes }) {
  return (
    <group>
      <mesh
        name="multiplication_icon"
        geometry={nodes.multiplication_icon.geometry}
        material={materials.lambert1}
      />
    </group>
  );
}

