import React from "react";
import styled from "styled-components";
import {ReactComponent as IntroIcon} from "../../assets/svg/intro-icon.svg";
import {ReactComponent as SecretIcon} from "../../assets/svg/secret-icon.svg";
import {ReactComponent as IpaIcon} from "../../assets/svg/ipa-icon.svg";
import {ReactComponent as AdditionIcon} from "../../assets/svg/addition-icon.svg";
import {ReactComponent as MultiplicationIcon} from "../../assets/svg/multiplication-icon.svg";
import PropTypes from "prop-types";
import ProgressLink from "./ProgressLink";

const Holder = styled.div`
  z-index: 1000;
  display: flex;
  position: fixed;
  width: 100%;
  top: 3rem;
  left: 0;
  border-top: none;
  padding: 0.5rem 0.25rem;
  ${(props) => props.theme.fluidType(-1)};
  @media ( ${props => props.theme.breakpoints.lg} ) {
    ${(props) => props.theme.fluidType(0)};
    bottom: 0;
    top: auto;
    border-top: 1px solid #ffffff;
    border-bottom: none;
  }
`;

const BarHolder = styled.div`
  background-color: #ffffff;
  border-radius: 0.2rem;
  overflow: hidden;
  width: calc(100% - ${2 * 5}rem);
  margin-left: 0.25rem;
  position: relative;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    width: calc(100% - ${3 * 5}rem);
    border-radius: 0.5rem;
  }
`;

const ProgressTitle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 0 0 0.5rem;
  line-height: 2rem;
  height: 100%;
  width: 100%;
  z-index: 10;
  pointer-events: none;
  color: var(--purple);
  font-weight: bold;
  ${(props) => props.theme.fluidType(-2)};
  @media ( ${props => props.theme.breakpoints.lg} ) {
    padding: 0 0 0 1rem;
    line-height: 3rem;
  }
`;

const pages = [
  {title: "Introduction", url: "/intro", icon: <IntroIcon />},
  {title: "Interoperable Private Attribution", url: "/ipa", icon: <IpaIcon />},
  {title: "Secret sharing", url: "/secret", icon: <SecretIcon />},
  {title: "Adding secret-shared numbers", url: "/addition", icon: <AdditionIcon />},
  {title: "Multiplying secret-shared numbers", url: "/multiplication", icon: <MultiplicationIcon />},
];

function ProgressHolder(props) {

  const preLinks = [];
  for (let i = 0; i < props.pageIndex + 1; i++) {
    // note: we are adding a key prop here to allow react to uniquely identify each
    // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
    preLinks.push(<ProgressLink key={i} page={pages[i]} />);
  }

  const postLinks = [];
  for (let i = props.pageIndex + 1; i < pages.length; i++) {
    // note: we are adding a key prop here to allow react to uniquely identify each
    // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
    postLinks.push(<ProgressLink key={i} page={pages[i]} isPostLink={true} />);
  }

  return (
    <Holder className="hideForLoading">
      {preLinks}
      <BarHolder>
        <ProgressTitle>{pages[props.pageIndex].title}</ProgressTitle>
        {props.children}
      </BarHolder>
      {postLinks}
    </Holder>
  );
}

ProgressHolder.propTypes = {
  pageIndex: PropTypes.number.isRequired,
};

export default ProgressHolder;

