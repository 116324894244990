import React from 'react';
import UserHoldingHolder from "./holders/UserHoldingHolder";
import PropTypes from "prop-types";
import SharedSecretHolder from "../shared-models/holders/SharedSecretHolder";
import useTrigger from "../../hooks/useTrigger";

function UserWithShares(props) {
  const showIcon = useTrigger(props.breakNumber, props.returnToNormal)

  return (
      <group position={props.position} rotation={props.rotation}>
        <UserHoldingHolder
          color="grey3"
          breakNumber={props.breakNumber}
          returnToNormal={props.returnToNormal} />
        <SharedSecretHolder
          show={showIcon && props.showShares}
          position={[0, 1.8, 0]}
          split={props.breakNumber}
          leave={props.returnToNormal}
          number={props.number}
          forImpression={props.forImpression}
          forPurchase={props.forPurchase}
          color={props.number === 1 ? "green" : "pink"}
        />
      </group>
  )
}

UserWithShares.propTypes = {
  numberIn: PropTypes.number,
  breakNumber: PropTypes.number.isRequired,
  returnToNormal: PropTypes.number.isRequired,
  position: PropTypes.array,
  rotation: PropTypes.array,
  secretShares: PropTypes.array.isRequired,
  number: PropTypes.number,
  forImpression: PropTypes.bool,
  forPurchase: PropTypes.bool,
  showShares: PropTypes.bool,
};

UserWithShares.defaultProps = {
  position: [0, 0, 0],
  rotation: [0, 0, 0],
};

export default UserWithShares;