/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF, Shadow } from "@react-three/drei";
import PropTypes from "prop-types";

import GlobalUser from "../models/Global-user";
import useNewMaterials from "../../../hooks/useNewMaterials";

export default function GlobalUserHolder({ ...props }) {
  const { nodes, materials } = useGLTF(
    "/GlobalModels/global-user-no-anim-transformed.glb"
  );
  const group = useRef();

  const newMaterials = useNewMaterials(materials, {
    baseMaterial: props.color,
  });


  return (
    <group ref={group} {...props} dispose={null} visible={props.show}>
      <GlobalUser nodes={nodes} materials={newMaterials} />
      <Shadow
        color="purple"
        position={[0, 0, 0.1]}
        opacity={0.1}
        scale={[0.7, 0.25, 1]}
      />
    </group>
  );
}

GlobalUserHolder.propTypes = {
  show: PropTypes.bool,
  color: PropTypes.string,
};

GlobalUserHolder.defaultProps = {
  show: true,
  color: "grey3",
};

useGLTF.preload("/GlobalModels/global-user-no-anim-transformed.glb");

