import React from "react";
import DeepDiveOne from "../ModalContent/DeepDiveOne";

const myText = [
  {
    text: "Your browsing history is information that you want to keep private",
  },
  {
    text: "But right now, the way ad measurement works involves multiple companies learning about which apps and websites you visited.",
    modal: <DeepDiveOne />,
  },
  {
    text: "We have a proposal for a new way of measuring ads where companies don't need to learn which apps and websites you visited.",
  },
  { text: "We call it: Interoperable Private Attribution [IPA]" },
  {
    text: "IPA relies on some powerful cryptography concepts. Up next is a high level overview of the IPA proposal followed by explanations that dive into the cryptographic concepts which IPA uses.",
  },
];

export const interval = 1 / myText.length;

export const text = myText.map((item, index) => {
  return {
    in: index * interval,
    adjustedIn: (index + 1) * interval, // adjustedIn accounts for the opening screen having no text pop-up.
    sceneAnchor: index,
    ...item,
  };
});

