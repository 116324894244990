/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";

export default function Bike({ materials, nodes }) {
  return (
      <mesh geometry={nodes.Bike.geometry} material={materials.lambert1} castShadow/>
  );
}

