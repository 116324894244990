import React from "react";
import GlobalUserHolder from "../shared-models/holders/GlobalUserHolder";
import UserWithShares from "./UserWithShares";
import useTrigger from "../../hooks/useTrigger";
import { secretShares } from "../../data/secretShares";
import { text } from "../../data/addition-data/text";
import {somethingVerySmall} from "../../helpers/scrollHelpers";

const positions = [
  { position: [3, 0, -3], rotation: [0, 0, 0] },
  { position: [4, 0, 3.5], rotation: [0, 0, 0] },
  { position: [0, 0, 5], rotation: [0, 0, 0] },
  { position: [2, 0, 2], rotation: [0, 0, 0] },
  { position: [4.5, 0, -0.5], rotation: [0, 0, 0] },
  { position: [-3.5, 0, 1.5], rotation: [0, 0, 0] },
  { position: [-6, 0, 0], rotation: [0, 0, 0] },
  // {position: [-1.5, 0, -4], rotation: [0, 0, 0]},
  // {position: [-4.5, 0, -3], rotation: [0, 0, 0]},
  { position: [-4, 0, 4], rotation: [0, 0, 0] },
];

const shareUserProps = {
  numberIn: text[7].adjustedIn,
  breakNumber: text[8].adjustedIn,
  returnToNormal: text[10].adjustedIn,
};

const GlobalUserGroup = () => {
  const hide = useTrigger(text[18].adjustedIn);
  const showShares = useTrigger(text[7].adjustedIn, text[10].adjustedIn - somethingVerySmall);
  const idleIn = text[0].adjustedIn;
  const idleOut = text[4].adjustedIn;

  return (
    <group visible={!hide}>
      <UserWithShares
        position={[-1.5, 0, -4]}
        {...shareUserProps}
        showShares={showShares}
        secretShares={secretShares[0][1]}
        forPurchase={true}
      />

      <UserWithShares
        position={[-4.5, 0, -3]}
        {...shareUserProps}
        showShares={showShares}
        secretShares={secretShares[1][1]}
        number={1}
        forPurchase={true}
      />

      {positions.map((position, index) => (
        <GlobalUserHolder
          key={index}
          idleIn={idleIn}
          idleEnd={idleOut}
          position={position.position}
          rotation={position.rotation}
          color="grey3"
        />
      ))}
    </group>
  );
};

export default GlobalUserGroup;

