import React from "react";

export default function MultiShareStackAppear({ nodes, materials }) {
  return (
    <group name="grp_stack_1">
      <group
        name="grp_share_stack_card_C_01"
        position={[0.231662, 0, -0.633303]}
        rotation={[-Math.PI, 1.079054, -Math.PI]}
      >
        <mesh
          name="share_card"
          geometry={nodes.share_card.geometry}
          material={materials.standardSurface3}
        />
        <mesh
          name="shareCard_number_plane"
          geometry={nodes.shareCard_number_plane.geometry}
          material={materials.number_material_8}
        />
      </group>
      <group
        name="grp_share_stack_card_C_02"
        position={[0.10554, 0, -0.003842]}
        rotation={[0, 0.145156, 0]}
      >
        <mesh
          name="share_card_(1)"
          geometry={nodes["share_card_(1)"].geometry}
          material={materials.standardSurface3}
        />
        <mesh
          name="shareCard_number_plane_(1)"
          geometry={nodes["shareCard_number_plane_(1)"].geometry}
          material={materials.number_material_4}
        />
      </group>
      <group
        name="grp_share_stack_card_C_03"
        position={[-0.330534, 0, -0.405029]}
        rotation={[-Math.PI, -0.9333, -Math.PI]}
      >
        <mesh
          name="share_card_(2)"
          geometry={nodes["share_card_(2)"].geometry}
          material={materials.standardSurface3}
        />
        <mesh
          name="shareCard_number_plane_(2)"
          geometry={nodes["shareCard_number_plane_(2)"].geometry}
          material={materials.number_material_9}
        />
      </group>
      <group
        name="grp_share_stack_card_B_01"
        position={[0.322934, 0.000687, -0.454624]}
        rotation={[-Math.PI, 1.118643, -Math.PI]}
      >
        <mesh
          name="share_card_(3)"
          geometry={nodes["share_card_(3)"].geometry}
          material={materials.standardSurface2}
        />
        <mesh
          name="shareCard_number_plane_(3)"
          geometry={nodes["shareCard_number_plane_(3)"].geometry}
          material={materials.number_material_6}
        />
      </group>
      <group
        name="grp_share_stack_card_B_02"
        position={[-0.089218, 0.000687, 0.021248]}
        rotation={[0, 0.115842, 0]}
      >
        <mesh
          name="share_card_(4)"
          geometry={nodes["share_card_(4)"].geometry}
          material={materials.standardSurface2}
        />
        <mesh
          name="shareCard_number_plane_(4)"
          geometry={nodes["shareCard_number_plane_(4)"].geometry}
          material={materials.number_material_7}
        />
      </group>
      <group
        name="grp_share_stack_card_B_03"
        position={[-0.197492, 0.000687, -0.584188]}
        rotation={[-Math.PI, -0.91174, -Math.PI]}
      >
        <mesh
          name="share_card_(5)"
          geometry={nodes["share_card_(5)"].geometry}
          material={materials.standardSurface2}
        />
        <mesh
          name="shareCard_number_plane_(5)"
          geometry={nodes["shareCard_number_plane_(5)"].geometry}
          material={materials.number_material_8}
        />
      </group>
    </group>
  );
}

