import React from 'react'
import useTrigger from '../../hooks/useTrigger';
import BrowsersHolder from './holders/BrowsersHolder';
import {text, interval} from "../../data/template-data/text";

const BrowsersGroup = () => {
  const showBrowser = useTrigger(
    text[0].adjustedIn,
    text[3].adjustedIn
  );

  return (
    <group visible={showBrowser}>
      <BrowsersHolder
        position={[0, 0, 0]}
        in={text[0].adjustedIn}
        appsPile={text[0].adjustedIn + interval/2}
        appsFly={text[1].adjustedIn}
        appsLock={text[2].adjustedIn}
        scale={0.5} />
    </group>
  )
}

export default BrowsersGroup