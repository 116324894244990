/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";

export default function BinarySpeechBubble2({ nodes, materials }) {
  return (
    <group>
      <mesh
        name="speech_bubble_mesh"
        geometry={nodes.speech_bubble_mesh.geometry}
        material={materials.phong_5}
        position={[0, 0.003085, 0.001854]}
        scale={0.5}
      >
        <group
          name="binary_group"
          position={[0, 0.005235, -0.001601]}
          scale={0.02698}
        >
          <group name="shared_secret_grp4" position={[-1.2, 0, 0]}>
            <group
              name="cc_dice_1"
              position={[-0.047744, 0.050856, 0.034773]}
              rotation={[0.402104, 0, 0]}
              scale={2.71371}
            >
              <group
                name="dice1"
                position={[-0.000001, 0, -0.000001]}
                rotation={[0, -1.24621, 0]}
              >
                <mesh
                  name="dice1_1"
                  geometry={nodes.dice1_1.geometry}
                  material={materials.shrdScrt_phong_1}
                />
                <mesh
                  name="dice1_2"
                  geometry={nodes.dice1_2.geometry}
                  material={materials.shrdScrt_phong_2}
                />
              </group>
            </group>
            <group
              name="cc_dice_2"
              position={[-0.024995, -0.051167, 0.034773]}
              rotation={[0.402104, 0, 0]}
              scale={2.71371}
            >
              <group
                name="dice1_(1)"
                position={[0.000001, 0, -0.000001]}
                rotation={[0, 1.309157, 0]}
              >
                <mesh
                  name="dice1_3"
                  geometry={nodes.dice1_3.geometry}
                  material={materials.shrdScrt_phong_1}
                />
                <mesh
                  name="dice1_4"
                  geometry={nodes.dice1_4.geometry}
                  material={materials.shrdScrt_phong_2}
                />
              </group>
            </group>
            <mesh
              name="base_secret_share1"
              geometry={nodes.base_secret_share1.geometry}
              material={materials.shrdScrt_phong_5}
              position={[0, 0, 0.000794]}
            />
            <group
              name="share_card"
              position={[0.119553, 0.117223, 0.005333]}
              scale={0.649257}
            >
              <group name="numbers">
                <mesh
                  name="shareCard_number_plane1"
                  geometry={nodes.shareCard_number_plane1.geometry}
                  material={materials.shrdScrt_number_material_1}
                  position={[0, 0, -0.004118]}
                />
              </group>
              <mesh
                name="share_card_(1)"
                geometry={nodes["share_card_(1)"].geometry}
                material={materials.shrdScrt_phong_7}
              />
              <mesh
                name="shareCard_number_plane"
                geometry={nodes.shareCard_number_plane.geometry}
                material={materials.shrdScrt_number_material_0}
              />
            </group>
            <group
              name="offset_transforms_equation_spinner_grp"
              position={[0.057702, 0.002389, 0.042131]}
              scale={0.151222}
            >
              <group name="equation_spinner_grp">
                <group
                  name="group1"
                  position={[0, 0.03846, 0]}
                  rotation={[-1.928532, 0, 0]}
                >
                  <group name="group12">
                    <mesh
                      name="typeMesh_0"
                      geometry={nodes.typeMesh_0.geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004615, -0.054547, 0.335158]}
                      scale={0.674685}
                    />

                    <mesh
                      name="typeMesh_2"
                      geometry={nodes.typeMesh_2.geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.005421, -0.335588, 0.051762]}
                      rotation={[1.256637, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_3"
                      geometry={nodes.typeMesh_3.geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.008129, -0.294208, -0.179115]}
                      rotation={[1.884956, 0, 0]}
                    />
                    <mesh
                      name="typeMesh_4"
                      geometry={nodes.typeMesh_4.geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.00564, -0.152872, -0.303211]}
                      rotation={[2.513274, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_5"
                      geometry={nodes.typeMesh_5.geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004981, 0.05462, -0.335158]}
                      rotation={[Math.PI, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_6"
                      geometry={nodes.typeMesh_6.geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004762, 0.241545, -0.238786]}
                      rotation={[-2.513274, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_7"
                      geometry={nodes.typeMesh_7.geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.006885, 0.33561, -0.051692]}
                      rotation={[-1.884956, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_8"
                      geometry={nodes.typeMesh_8.geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004689, 0.301898, 0.155447]}
                      rotation={[-1.256637, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_9"
                      geometry={nodes.typeMesh_9.geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.005201, 0.153286, 0.302909]}
                      rotation={[-Math.PI / 5, 0, 0]}
                      scale={0.674685}
                    />
                  </group>
                  <mesh
                    name="pCylinder1"
                    geometry={nodes.pCylinder1.geometry}
                    material={materials.shrdScrt_phong_2}
                  />
                  <mesh
                    name="pCylinder2"
                    geometry={nodes.pCylinder2.geometry}
                    material={materials.shrdScrt_phong_2}
                    rotation={[0, 0, Math.PI / 2]}
                    scale={[32.340168, 13.574957, 32.340168]}
                  />
                </group>
              </group>
            </group>
          </group>
          <group name="shared_secret_grp3" position={[-0.9, 0, 0]}>
            <group
              name="cc_dice_1_(1)"
              position={[-0.047744, 0.050856, 0.034773]}
              rotation={[0.402104, 0, 0]}
              scale={2.71371}
            >
              <group
                name="dice1_(2)"
                position={[-0.000001, 0, -0.000001]}
                rotation={[0, -1.24621, 0]}
              >
                <mesh
                  name="dice1_5"
                  geometry={nodes.dice1_5.geometry}
                  material={materials.shrdScrt_phong_1}
                />
                <mesh
                  name="dice1_6"
                  geometry={nodes.dice1_6.geometry}
                  material={materials.shrdScrt_phong_2}
                />
              </group>
            </group>
            <group
              name="cc_dice_2_(1)"
              position={[-0.024995, -0.051167, 0.034773]}
              rotation={[0.402104, 0, 0]}
              scale={2.71371}
            >
              <group
                name="dice1_(3)"
                position={[0.000001, 0, -0.000001]}
                rotation={[0, 1.309157, 0]}
              >
                <mesh
                  name="dice1_7"
                  geometry={nodes.dice1_7.geometry}
                  material={materials.shrdScrt_phong_1}
                />
                <mesh
                  name="dice1_8"
                  geometry={nodes.dice1_8.geometry}
                  material={materials.shrdScrt_phong_2}
                />
              </group>
            </group>
            <mesh
              name="base_secret_share1_(1)"
              geometry={nodes["base_secret_share1_(1)"].geometry}
              material={materials.shrdScrt_phong_5}
              position={[0, 0, 0.000794]}
            />
            <group
              name="share_card_(2)"
              position={[0.119553, 0.117223, 0.005333]}
              scale={0.649257}
            >
              <group name="numbers_(1)">
                <mesh
                  name="shareCard_number_plane1_(1)"
                  geometry={nodes["shareCard_number_plane1_(1)"].geometry}
                  material={materials.shrdScrt_number_material_1}
                  position={[0, 0, -0.004118]}
                />
              </group>
              <mesh
                name="share_card_(3)"
                geometry={nodes["share_card_(3)"].geometry}
                material={materials.shrdScrt_phong_7}
              />
              <mesh
                name="shareCard_number_plane_(1)"
                geometry={nodes["shareCard_number_plane_(1)"].geometry}
                material={materials.shrdScrt_number_material_1}
              />
            </group>
            <group
              name="offset_transforms_equation_spinner_grp_(1)"
              position={[0.057702, 0.002389, 0.042131]}
              scale={0.151222}
            >
              <group name="equation_spinner_grp_(1)">
                <group
                  name="group1_(1)"
                  position={[0, 0.03846, 0]}
                  rotation={[-1.928532, 0, 0]}
                >
                  <group name="group12_(1)">
                    <mesh
                      name="typeMesh_0_(1)"
                      geometry={nodes["typeMesh_0_(1)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004615, -0.054547, 0.335158]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_1_(1)"
                      geometry={nodes["typeMesh_1_(1)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004469, -0.241131, 0.239087]}
                      rotation={[Math.PI / 5, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_2_(1)"
                      geometry={nodes["typeMesh_2_(1)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.005421, -0.335588, 0.051762]}
                      rotation={[1.256637, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_3_(1)"
                      geometry={nodes["typeMesh_3_(1)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.008129, -0.294208, -0.179115]}
                      rotation={[1.884956, 0, 0]}
                    />
                    <mesh
                      name="typeMesh_4_(1)"
                      geometry={nodes["typeMesh_4_(1)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.00564, -0.152872, -0.303211]}
                      rotation={[2.513274, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_5_(1)"
                      geometry={nodes["typeMesh_5_(1)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004981, 0.05462, -0.335158]}
                      rotation={[Math.PI, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_6_(1)"
                      geometry={nodes["typeMesh_6_(1)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004762, 0.241545, -0.238786]}
                      rotation={[-2.513274, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_7_(1)"
                      geometry={nodes["typeMesh_7_(1)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.006885, 0.33561, -0.051692]}
                      rotation={[-1.884956, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_8_(1)"
                      geometry={nodes["typeMesh_8_(1)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004689, 0.301898, 0.155447]}
                      rotation={[-1.256637, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_9_(1)"
                      geometry={nodes["typeMesh_9_(1)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.005201, 0.153286, 0.302909]}
                      rotation={[-Math.PI / 5, 0, 0]}
                      scale={0.674685}
                    />
                  </group>
                  <mesh
                    name="pCylinder1_(1)"
                    geometry={nodes["pCylinder1_(1)"].geometry}
                    material={materials.shrdScrt_phong_2}
                  />
                  <mesh
                    name="pCylinder2_(1)"
                    geometry={nodes["pCylinder2_(1)"].geometry}
                    material={materials.shrdScrt_phong_2}
                    rotation={[0, 0, Math.PI / 2]}
                    scale={[32.340168, 13.574957, 32.340168]}
                  />
                </group>
              </group>
            </group>
          </group>
          <group name="shared_secret_grp2" position={[-0.6, 0, 0]}>
            <group
              name="cc_dice_1_(2)"
              position={[-0.047744, 0.050856, 0.034773]}
              rotation={[0.402104, 0, 0]}
              scale={2.71371}
            >
              <group
                name="dice1_(4)"
                position={[-0.000001, 0, -0.000001]}
                rotation={[0, -1.24621, 0]}
              >
                <mesh
                  name="dice1_9"
                  geometry={nodes.dice1_9.geometry}
                  material={materials.shrdScrt_phong_1}
                />
                <mesh
                  name="dice1_10"
                  geometry={nodes.dice1_10.geometry}
                  material={materials.shrdScrt_phong_2}
                />
              </group>
            </group>
            <group
              name="cc_dice_2_(2)"
              position={[-0.024995, -0.051167, 0.034773]}
              rotation={[0.402104, 0, 0]}
              scale={2.71371}
            >
              <group
                name="dice1_(5)"
                position={[0.000001, 0, -0.000001]}
                rotation={[0, 1.309157, 0]}
              >
                <mesh
                  name="dice1_11"
                  geometry={nodes.dice1_11.geometry}
                  material={materials.shrdScrt_phong_1}
                />
                <mesh
                  name="dice1_12"
                  geometry={nodes.dice1_12.geometry}
                  material={materials.shrdScrt_phong_2}
                />
              </group>
            </group>
            <mesh
              name="base_secret_share1_(2)"
              geometry={nodes["base_secret_share1_(2)"].geometry}
              material={materials.shrdScrt_phong_5}
              position={[0, 0, 0.000794]}
            />
            <group
              name="share_card_(4)"
              position={[0.119553, 0.117223, 0.005333]}
              scale={0.649257}
            >
              <group name="numbers_(2)">
                <mesh
                  name="shareCard_number_plane1_(2)"
                  geometry={nodes["shareCard_number_plane1_(2)"].geometry}
                  material={materials.shrdScrt_number_material_1}
                  position={[0, 0, -0.004118]}
                />
              </group>
              <mesh
                name="share_card_(5)"
                geometry={nodes["share_card_(5)"].geometry}
                material={materials.shrdScrt_phong_7}
              />
              <mesh
                name="shareCard_number_plane_(2)"
                geometry={nodes["shareCard_number_plane_(2)"].geometry}
                material={materials.shrdScrt_number_material_0}
              />
            </group>
            <group
              name="offset_transforms_equation_spinner_grp_(2)"
              position={[0.057702, 0.002389, 0.042131]}
              scale={0.151222}
            >
              <group name="equation_spinner_grp_(2)">
                <group
                  name="group1_(2)"
                  position={[0, 0.03846, 0]}
                  rotation={[-1.928532, 0, 0]}
                >
                  <group name="group12_(2)">
                    <mesh
                      name="typeMesh_0_(2)"
                      geometry={nodes["typeMesh_0_(2)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004615, -0.054547, 0.335158]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_1_(2)"
                      geometry={nodes["typeMesh_1_(2)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004469, -0.241131, 0.239087]}
                      rotation={[Math.PI / 5, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_2_(2)"
                      geometry={nodes["typeMesh_2_(2)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.005421, -0.335588, 0.051762]}
                      rotation={[1.256637, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_3_(2)"
                      geometry={nodes["typeMesh_3_(2)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.008129, -0.294208, -0.179115]}
                      rotation={[1.884956, 0, 0]}
                    />
                    <mesh
                      name="typeMesh_4_(2)"
                      geometry={nodes["typeMesh_4_(2)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.00564, -0.152872, -0.303211]}
                      rotation={[2.513274, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_5_(2)"
                      geometry={nodes["typeMesh_5_(2)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004981, 0.05462, -0.335158]}
                      rotation={[Math.PI, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_6_(2)"
                      geometry={nodes["typeMesh_6_(2)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004762, 0.241545, -0.238786]}
                      rotation={[-2.513274, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_7_(2)"
                      geometry={nodes["typeMesh_7_(2)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.006885, 0.33561, -0.051692]}
                      rotation={[-1.884956, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_8_(2)"
                      geometry={nodes["typeMesh_8_(2)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004689, 0.301898, 0.155447]}
                      rotation={[-1.256637, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_9_(2)"
                      geometry={nodes["typeMesh_9_(2)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.005201, 0.153286, 0.302909]}
                      rotation={[-Math.PI / 5, 0, 0]}
                      scale={0.674685}
                    />
                  </group>
                  <mesh
                    name="pCylinder1_(2)"
                    geometry={nodes["pCylinder1_(2)"].geometry}
                    material={materials.shrdScrt_phong_2}
                  />
                  <mesh
                    name="pCylinder2_(2)"
                    geometry={nodes["pCylinder2_(2)"].geometry}
                    material={materials.shrdScrt_phong_2}
                    rotation={[0, 0, Math.PI / 2]}
                    scale={[32.340168, 13.574957, 32.340168]}
                  />
                </group>
              </group>
            </group>
          </group>
          <group name="shared_secret_grp1" position={[-0.3, 0, 0]}>
            <group
              name="cc_dice_1_(3)"
              position={[-0.047744, 0.050856, 0.034773]}
              rotation={[0.402104, 0, 0]}
              scale={2.71371}
            >
              <group
                name="dice1_(6)"
                position={[-0.000001, 0, -0.000001]}
                rotation={[0, -1.24621, 0]}
              >
                <mesh
                  name="dice1_13"
                  geometry={nodes.dice1_13.geometry}
                  material={materials.shrdScrt_phong_1}
                />
                <mesh
                  name="dice1_14"
                  geometry={nodes.dice1_14.geometry}
                  material={materials.shrdScrt_phong_2}
                />
              </group>
            </group>
            <group
              name="cc_dice_2_(3)"
              position={[-0.024995, -0.051167, 0.034773]}
              rotation={[0.402104, 0, 0]}
              scale={2.71371}
            >
              <group
                name="dice1_(7)"
                position={[0.000001, 0, -0.000001]}
                rotation={[0, 1.309157, 0]}
              >
                <mesh
                  name="dice1_15"
                  geometry={nodes.dice1_15.geometry}
                  material={materials.shrdScrt_phong_1}
                />
                <mesh
                  name="dice1_16"
                  geometry={nodes.dice1_16.geometry}
                  material={materials.shrdScrt_phong_2}
                />
              </group>
            </group>
            <mesh
              name="base_secret_share1_(3)"
              geometry={nodes["base_secret_share1_(3)"].geometry}
              material={materials.shrdScrt_phong_5}
              position={[0, 0, 0.000794]}
            />
            <group
              name="share_card_(6)"
              position={[0.119553, 0.117223, 0.005333]}
              scale={0.649257}
            >
              <group name="numbers_(3)">
                <mesh
                  name="shareCard_number_plane1_(3)"
                  geometry={nodes["shareCard_number_plane1_(3)"].geometry}
                  material={materials.shrdScrt_number_material_1}
                  position={[0, 0, -0.004118]}
                />
              </group>
              <mesh
                name="share_card_(7)"
                geometry={nodes["share_card_(7)"].geometry}
                material={materials.shrdScrt_phong_7}
              />
              <mesh
                name="shareCard_number_plane_(3)"
                geometry={nodes["shareCard_number_plane_(3)"].geometry}
                material={materials.shrdScrt_number_material_0}
              />
            </group>
            <group
              name="offset_transforms_equation_spinner_grp_(3)"
              position={[0.057702, 0.002389, 0.042131]}
              scale={0.151222}
            >
              <group name="equation_spinner_grp_(3)">
                <group
                  name="group1_(3)"
                  position={[0, 0.03846, 0]}
                  rotation={[-1.928532, 0, 0]}
                >
                  <group name="group12_(3)">
                    <mesh
                      name="typeMesh_0_(3)"
                      geometry={nodes["typeMesh_0_(3)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004615, -0.054547, 0.335158]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_1_(3)"
                      geometry={nodes["typeMesh_1_(3)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004469, -0.241131, 0.239087]}
                      rotation={[Math.PI / 5, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_2_(3)"
                      geometry={nodes["typeMesh_2_(3)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.005421, -0.335588, 0.051762]}
                      rotation={[1.256637, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_3_(3)"
                      geometry={nodes["typeMesh_3_(3)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.008129, -0.294208, -0.179115]}
                      rotation={[1.884956, 0, 0]}
                    />
                    <mesh
                      name="typeMesh_4_(3)"
                      geometry={nodes["typeMesh_4_(3)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.00564, -0.152872, -0.303211]}
                      rotation={[2.513274, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_5_(3)"
                      geometry={nodes["typeMesh_5_(3)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004981, 0.05462, -0.335158]}
                      rotation={[Math.PI, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_6_(3)"
                      geometry={nodes["typeMesh_6_(3)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004762, 0.241545, -0.238786]}
                      rotation={[-2.513274, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_7_(3)"
                      geometry={nodes["typeMesh_7_(3)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.006885, 0.33561, -0.051692]}
                      rotation={[-1.884956, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_8_(3)"
                      geometry={nodes["typeMesh_8_(3)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004689, 0.301898, 0.155447]}
                      rotation={[-1.256637, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_9_(3)"
                      geometry={nodes["typeMesh_9_(3)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.005201, 0.153286, 0.302909]}
                      rotation={[-Math.PI / 5, 0, 0]}
                      scale={0.674685}
                    />
                  </group>
                  <mesh
                    name="pCylinder1_(3)"
                    geometry={nodes["pCylinder1_(3)"].geometry}
                    material={materials.shrdScrt_phong_2}
                  />
                  <mesh
                    name="pCylinder2_(3)"
                    geometry={nodes["pCylinder2_(3)"].geometry}
                    material={materials.shrdScrt_phong_2}
                    rotation={[0, 0, Math.PI / 2]}
                    scale={[32.340168, 13.574957, 32.340168]}
                  />
                </group>
              </group>
            </group>
          </group>
          <group name="shared_secret_grp">
            <group
              name="cc_dice_1_(4)"
              position={[-0.047744, 0.050856, 0.034773]}
              rotation={[0.402104, 0, 0]}
              scale={2.71371}
            >
              <group
                name="dice1_(8)"
                position={[-0.000001, 0, -0.000001]}
                rotation={[0, -1.24621, 0]}
              >
                <mesh
                  name="dice1_17"
                  geometry={nodes.dice1_17.geometry}
                  material={materials.shrdScrt_phong_1}
                />
                <mesh
                  name="dice1_18"
                  geometry={nodes.dice1_18.geometry}
                  material={materials.shrdScrt_phong_2}
                />
              </group>
            </group>
            <group
              name="cc_dice_2_(4)"
              position={[-0.024995, -0.051167, 0.034773]}
              rotation={[0.402104, 0, 0]}
              scale={2.71371}
            >
              <group
                name="dice1_(9)"
                position={[0.000001, 0, -0.000001]}
                rotation={[0, 1.309157, 0]}
              >
                <mesh
                  name="dice1_19"
                  geometry={nodes.dice1_19.geometry}
                  material={materials.shrdScrt_phong_1}
                />
                <mesh
                  name="dice1_20"
                  geometry={nodes.dice1_20.geometry}
                  material={materials.shrdScrt_phong_2}
                />
              </group>
            </group>
            <mesh
              name="base_secret_share1_(4)"
              geometry={nodes["base_secret_share1_(4)"].geometry}
              material={materials.shrdScrt_phong_5}
              position={[0, 0, 0.000794]}
            />
            <group
              name="share_card_(8)"
              position={[0.119553, 0.117223, 0.005333]}
              scale={0.649257}
            >
              <group name="numbers_(4)">
                <mesh
                  name="shareCard_number_plane1_(4)"
                  geometry={nodes["shareCard_number_plane1_(4)"].geometry}
                  material={materials.shrdScrt_number_material_1}
                  position={[0, 0, -0.004118]}
                />
              </group>
              <mesh
                name="share_card_(9)"
                geometry={nodes["share_card_(9)"].geometry}
                material={materials.shrdScrt_phong_7}
              />
              <mesh
                name="shareCard_number_plane_(4)"
                geometry={nodes["shareCard_number_plane_(4)"].geometry}
                material={materials.shrdScrt_number_material_1}
              />
            </group>
            <group
              name="offset_transforms_equation_spinner_grp_(4)"
              position={[0.057702, 0.002389, 0.042131]}
              scale={0.151222}
            >
              <group name="equation_spinner_grp_(4)">
                <group
                  name="group1_(4)"
                  position={[0, 0.03846, 0]}
                  rotation={[-1.928532, 0, 0]}
                >
                  <group name="group12_(4)">
                    <mesh
                      name="typeMesh_0_(4)"
                      geometry={nodes["typeMesh_0_(4)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004615, -0.054547, 0.335158]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_1_(4)"
                      geometry={nodes["typeMesh_1_(4)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004469, -0.241131, 0.239087]}
                      rotation={[Math.PI / 5, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_2_(4)"
                      geometry={nodes["typeMesh_2_(4)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.005421, -0.335588, 0.051762]}
                      rotation={[1.256637, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_3_(4)"
                      geometry={nodes["typeMesh_3_(4)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.008129, -0.294208, -0.179115]}
                      rotation={[1.884956, 0, 0]}
                    />
                    <mesh
                      name="typeMesh_4_(4)"
                      geometry={nodes["typeMesh_4_(4)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.00564, -0.152872, -0.303211]}
                      rotation={[2.513274, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_5_(4)"
                      geometry={nodes["typeMesh_5_(4)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004981, 0.05462, -0.335158]}
                      rotation={[Math.PI, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_6_(4)"
                      geometry={nodes["typeMesh_6_(4)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004762, 0.241545, -0.238786]}
                      rotation={[-2.513274, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_7_(4)"
                      geometry={nodes["typeMesh_7_(4)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.006885, 0.33561, -0.051692]}
                      rotation={[-1.884956, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_8_(4)"
                      geometry={nodes["typeMesh_8_(4)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004689, 0.301898, 0.155447]}
                      rotation={[-1.256637, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_9_(4)"
                      geometry={nodes["typeMesh_9_(4)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.005201, 0.153286, 0.302909]}
                      rotation={[-Math.PI / 5, 0, 0]}
                      scale={0.674685}
                    />
                  </group>
                  <mesh
                    name="pCylinder1_(4)"
                    geometry={nodes["pCylinder1_(4)"].geometry}
                    material={materials.shrdScrt_phong_2}
                  />
                  <mesh
                    name="pCylinder2_(4)"
                    geometry={nodes["pCylinder2_(4)"].geometry}
                    material={materials.shrdScrt_phong_2}
                    rotation={[0, 0, Math.PI / 2]}
                    scale={[32.340168, 13.574957, 32.340168]}
                  />
                </group>
              </group>
            </group>
          </group>
          <group name="shared_secret_grp5" position={[0.307437, 0, 0]}>
            <group
              name="cc_dice_1_(5)"
              position={[-0.047744, 0.050856, 0.034773]}
              rotation={[0.402104, 0, 0]}
              scale={2.71371}
            >
              <group
                name="dice1_(10)"
                position={[-0.000001, 0, -0.000001]}
                rotation={[0, -1.24621, 0]}
              >
                <mesh
                  name="dice1_21"
                  geometry={nodes.dice1_21.geometry}
                  material={materials.shrdScrt_phong_1}
                />
                <mesh
                  name="dice1_22"
                  geometry={nodes.dice1_22.geometry}
                  material={materials.shrdScrt_phong_2}
                />
              </group>
            </group>
            <group
              name="cc_dice_2_(5)"
              position={[-0.024995, -0.051167, 0.034773]}
              rotation={[0.402104, 0, 0]}
              scale={2.71371}
            >
              <group
                name="dice1_(11)"
                position={[0.000001, 0, -0.000001]}
                rotation={[0, 1.309157, 0]}
              >
                <mesh
                  name="dice1_23"
                  geometry={nodes.dice1_23.geometry}
                  material={materials.shrdScrt_phong_1}
                />
                <mesh
                  name="dice1_24"
                  geometry={nodes.dice1_24.geometry}
                  material={materials.shrdScrt_phong_2}
                />
              </group>
            </group>
            <mesh
              name="base_secret_share1_(5)"
              geometry={nodes["base_secret_share1_(5)"].geometry}
              material={materials.shrdScrt_phong_5}
              position={[0, 0, 0.000794]}
            />
            <group
              name="share_card_(10)"
              position={[0.119553, 0.117223, 0.005333]}
              scale={0.649257}
            >
              <group name="numbers_(5)">
                <mesh
                  name="shareCard_number_plane1_(5)"
                  geometry={nodes["shareCard_number_plane1_(5)"].geometry}
                  material={materials.shrdScrt_number_material_1}
                  position={[0, 0, -0.004118]}
                />
              </group>
              <mesh
                name="share_card_(11)"
                geometry={nodes["share_card_(11)"].geometry}
                material={materials.shrdScrt_phong_7}
              />
              <mesh
                name="shareCard_number_plane_(5)"
                geometry={nodes["shareCard_number_plane_(5)"].geometry}
                material={materials.shrdScrt_number_material_0}
              />
            </group>
            <group
              name="offset_transforms_equation_spinner_grp_(5)"
              position={[0.057702, 0.002389, 0.042131]}
              scale={0.151222}
            >
              <group name="equation_spinner_grp_(5)">
                <group
                  name="group1_(5)"
                  position={[0, 0.03846, 0]}
                  rotation={[-1.928532, 0, 0]}
                >
                  <group name="group12_(5)">
                    <mesh
                      name="typeMesh_0_(5)"
                      geometry={nodes["typeMesh_0_(5)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004615, -0.054547, 0.335158]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_1_(5)"
                      geometry={nodes["typeMesh_1_(5)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004469, -0.241131, 0.239087]}
                      rotation={[Math.PI / 5, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_2_(5)"
                      geometry={nodes["typeMesh_2_(5)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.005421, -0.335588, 0.051762]}
                      rotation={[1.256637, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_3_(5)"
                      geometry={nodes["typeMesh_3_(5)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.008129, -0.294208, -0.179115]}
                      rotation={[1.884956, 0, 0]}
                    />
                    <mesh
                      name="typeMesh_4_(5)"
                      geometry={nodes["typeMesh_4_(5)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.00564, -0.152872, -0.303211]}
                      rotation={[2.513274, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_5_(5)"
                      geometry={nodes["typeMesh_5_(5)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004981, 0.05462, -0.335158]}
                      rotation={[Math.PI, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_6_(5)"
                      geometry={nodes["typeMesh_6_(5)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004762, 0.241545, -0.238786]}
                      rotation={[-2.513274, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_7_(5)"
                      geometry={nodes["typeMesh_7_(5)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.006885, 0.33561, -0.051692]}
                      rotation={[-1.884956, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_8_(5)"
                      geometry={nodes["typeMesh_8_(5)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004689, 0.301898, 0.155447]}
                      rotation={[-1.256637, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_9_(5)"
                      geometry={nodes["typeMesh_9_(5)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.005201, 0.153286, 0.302909]}
                      rotation={[-Math.PI / 5, 0, 0]}
                      scale={0.674685}
                    />
                  </group>
                  <mesh
                    name="pCylinder1_(5)"
                    geometry={nodes["pCylinder1_(5)"].geometry}
                    material={materials.shrdScrt_phong_2}
                  />
                  <mesh
                    name="pCylinder2_(5)"
                    geometry={nodes["pCylinder2_(5)"].geometry}
                    material={materials.shrdScrt_phong_2}
                    rotation={[0, 0, Math.PI / 2]}
                    scale={[32.340168, 13.574957, 32.340168]}
                  />
                </group>
              </group>
            </group>
          </group>
          <group name="shared_secret_grp6" position={[0.607437, 0, 0]}>
            <group
              name="cc_dice_1_(6)"
              position={[-0.047744, 0.050856, 0.034773]}
              rotation={[0.402104, 0, 0]}
              scale={2.71371}
            >
              <group
                name="dice1_(12)"
                position={[-0.000001, 0, -0.000001]}
                rotation={[0, -1.24621, 0]}
              >
                <mesh
                  name="dice1_25"
                  geometry={nodes.dice1_25.geometry}
                  material={materials.shrdScrt_phong_1}
                />
                <mesh
                  name="dice1_26"
                  geometry={nodes.dice1_26.geometry}
                  material={materials.shrdScrt_phong_2}
                />
              </group>
            </group>
            <group
              name="cc_dice_2_(6)"
              position={[-0.024995, -0.051167, 0.034773]}
              rotation={[0.402104, 0, 0]}
              scale={2.71371}
            >
              <group
                name="dice1_(13)"
                position={[0.000001, 0, -0.000001]}
                rotation={[0, 1.309157, 0]}
              >
                <mesh
                  name="dice1_27"
                  geometry={nodes.dice1_27.geometry}
                  material={materials.shrdScrt_phong_1}
                />
                <mesh
                  name="dice1_28"
                  geometry={nodes.dice1_28.geometry}
                  material={materials.shrdScrt_phong_2}
                />
              </group>
            </group>
            <mesh
              name="base_secret_share1_(6)"
              geometry={nodes["base_secret_share1_(6)"].geometry}
              material={materials.shrdScrt_phong_5}
              position={[0, 0, 0.000794]}
            />
            <group
              name="share_card_(12)"
              position={[0.119553, 0.117223, 0.005333]}
              scale={0.649257}
            >
              <group name="numbers_(6)">
                <mesh
                  name="shareCard_number_plane1_(6)"
                  geometry={nodes["shareCard_number_plane1_(6)"].geometry}
                  material={materials.shrdScrt_number_material_1}
                  position={[0, 0, -0.004118]}
                />
              </group>
              <mesh
                name="share_card_(13)"
                geometry={nodes["share_card_(13)"].geometry}
                material={materials.shrdScrt_phong_7}
              />
              <mesh
                name="shareCard_number_plane_(6)"
                geometry={nodes["shareCard_number_plane_(6)"].geometry}
                material={materials.shrdScrt_number_material_1}
              />
            </group>
            <group
              name="offset_transforms_equation_spinner_grp_(6)"
              position={[0.057702, 0.002389, 0.042131]}
              scale={0.151222}
            >
              <group name="equation_spinner_grp_(6)">
                <group
                  name="group1_(6)"
                  position={[0, 0.03846, 0]}
                  rotation={[-1.928532, 0, 0]}
                >
                  <group name="group12_(6)">
                    <mesh
                      name="typeMesh_0_(6)"
                      geometry={nodes["typeMesh_0_(6)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004615, -0.054547, 0.335158]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_1_(6)"
                      geometry={nodes["typeMesh_1_(6)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004469, -0.241131, 0.239087]}
                      rotation={[Math.PI / 5, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_2_(6)"
                      geometry={nodes["typeMesh_2_(6)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.005421, -0.335588, 0.051762]}
                      rotation={[1.256637, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_3_(6)"
                      geometry={nodes["typeMesh_3_(6)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.008129, -0.294208, -0.179115]}
                      rotation={[1.884956, 0, 0]}
                    />
                    <mesh
                      name="typeMesh_4_(6)"
                      geometry={nodes["typeMesh_4_(6)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.00564, -0.152872, -0.303211]}
                      rotation={[2.513274, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_5_(6)"
                      geometry={nodes["typeMesh_5_(6)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004981, 0.05462, -0.335158]}
                      rotation={[Math.PI, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_6_(6)"
                      geometry={nodes["typeMesh_6_(6)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004762, 0.241545, -0.238786]}
                      rotation={[-2.513274, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_7_(6)"
                      geometry={nodes["typeMesh_7_(6)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.006885, 0.33561, -0.051692]}
                      rotation={[-1.884956, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_8_(6)"
                      geometry={nodes["typeMesh_8_(6)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004689, 0.301898, 0.155447]}
                      rotation={[-1.256637, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_9_(6)"
                      geometry={nodes["typeMesh_9_(6)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.005201, 0.153286, 0.302909]}
                      rotation={[-Math.PI / 5, 0, 0]}
                      scale={0.674685}
                    />
                  </group>
                  <mesh
                    name="pCylinder1_(6)"
                    geometry={nodes["pCylinder1_(6)"].geometry}
                    material={materials.shrdScrt_phong_2}
                  />
                  <mesh
                    name="pCylinder2_(6)"
                    geometry={nodes["pCylinder2_(6)"].geometry}
                    material={materials.shrdScrt_phong_2}
                    rotation={[0, 0, Math.PI / 2]}
                    scale={[32.340168, 13.574957, 32.340168]}
                  />
                </group>
              </group>
            </group>
          </group>
          <group name="shared_secret_grp7" position={[0.907437, 0, 0]}>
            <group
              name="cc_dice_1_(7)"
              position={[-0.047744, 0.050856, 0.034773]}
              rotation={[0.402104, 0, 0]}
              scale={2.71371}
            >
              <group
                name="dice1_(14)"
                position={[-0.000001, 0, -0.000001]}
                rotation={[0, -1.24621, 0]}
              >
                <mesh
                  name="dice1_29"
                  geometry={nodes.dice1_29.geometry}
                  material={materials.shrdScrt_phong_1}
                />
                <mesh
                  name="dice1_30"
                  geometry={nodes.dice1_30.geometry}
                  material={materials.shrdScrt_phong_2}
                />
              </group>
            </group>
            <group
              name="cc_dice_2_(7)"
              position={[-0.024995, -0.051167, 0.034773]}
              rotation={[0.402104, 0, 0]}
              scale={2.71371}
            >
              <group
                name="dice1_(15)"
                position={[0.000001, 0, -0.000001]}
                rotation={[0, 1.309157, 0]}
              >
                <mesh
                  name="dice1_31"
                  geometry={nodes.dice1_31.geometry}
                  material={materials.shrdScrt_phong_1}
                />
                <mesh
                  name="dice1_32"
                  geometry={nodes.dice1_32.geometry}
                  material={materials.shrdScrt_phong_2}
                />
              </group>
            </group>
            <mesh
              name="base_secret_share1_(7)"
              geometry={nodes["base_secret_share1_(7)"].geometry}
              material={materials.shrdScrt_phong_5}
              position={[0, 0, 0.000794]}
            />
            <group
              name="share_card_(14)"
              position={[0.119553, 0.117223, 0.005333]}
              scale={0.649257}
            >
              <group name="numbers_(7)">
                <mesh
                  name="shareCard_number_plane1_(7)"
                  geometry={nodes["shareCard_number_plane1_(7)"].geometry}
                  material={materials.shrdScrt_number_material_1}
                  position={[0, 0, -0.004118]}
                />
              </group>
              <mesh
                name="share_card_(15)"
                geometry={nodes["share_card_(15)"].geometry}
                material={materials.shrdScrt_phong_7}
              />
              <mesh
                name="shareCard_number_plane_(7)"
                geometry={nodes["shareCard_number_plane_(7)"].geometry}
                material={materials.shrdScrt_number_material_1}
              />
            </group>
            <group
              name="offset_transforms_equation_spinner_grp_(7)"
              position={[0.057702, 0.002389, 0.042131]}
              scale={0.151222}
            >
              <group name="equation_spinner_grp_(7)">
                <group
                  name="group1_(7)"
                  position={[0, 0.03846, 0]}
                  rotation={[-1.928532, 0, 0]}
                >
                  <group name="group12_(7)">
                    <mesh
                      name="typeMesh_0_(7)"
                      geometry={nodes["typeMesh_0_(7)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004615, -0.054547, 0.335158]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_1_(7)"
                      geometry={nodes["typeMesh_1_(7)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004469, -0.241131, 0.239087]}
                      rotation={[Math.PI / 5, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_2_(7)"
                      geometry={nodes["typeMesh_2_(7)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.005421, -0.335588, 0.051762]}
                      rotation={[1.256637, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_3_(7)"
                      geometry={nodes["typeMesh_3_(7)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.008129, -0.294208, -0.179115]}
                      rotation={[1.884956, 0, 0]}
                    />
                    <mesh
                      name="typeMesh_4_(7)"
                      geometry={nodes["typeMesh_4_(7)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.00564, -0.152872, -0.303211]}
                      rotation={[2.513274, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_5_(7)"
                      geometry={nodes["typeMesh_5_(7)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004981, 0.05462, -0.335158]}
                      rotation={[Math.PI, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_6_(7)"
                      geometry={nodes["typeMesh_6_(7)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004762, 0.241545, -0.238786]}
                      rotation={[-2.513274, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_7_(7)"
                      geometry={nodes["typeMesh_7_(7)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.006885, 0.33561, -0.051692]}
                      rotation={[-1.884956, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_8_(7)"
                      geometry={nodes["typeMesh_8_(7)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004689, 0.301898, 0.155447]}
                      rotation={[-1.256637, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_9_(7)"
                      geometry={nodes["typeMesh_9_(7)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.005201, 0.153286, 0.302909]}
                      rotation={[-Math.PI / 5, 0, 0]}
                      scale={0.674685}
                    />
                  </group>
                  <mesh
                    name="pCylinder1_(7)"
                    geometry={nodes["pCylinder1_(7)"].geometry}
                    material={materials.shrdScrt_phong_2}
                  />
                  <mesh
                    name="pCylinder2_(7)"
                    geometry={nodes["pCylinder2_(7)"].geometry}
                    material={materials.shrdScrt_phong_2}
                    rotation={[0, 0, Math.PI / 2]}
                    scale={[32.340168, 13.574957, 32.340168]}
                  />
                </group>
              </group>
            </group>
          </group>
          <group name="shared_secret_grp8" position={[1.207437, 0, 0]}>
            <group
              name="cc_dice_1_(8)"
              position={[-0.047744, 0.050856, 0.034773]}
              rotation={[0.402104, 0, 0]}
              scale={2.71371}
            >
              <group
                name="dice1_(16)"
                position={[-0.000001, 0, -0.000001]}
                rotation={[0, -1.24621, 0]}
              >
                <mesh
                  name="dice1_33"
                  geometry={nodes.dice1_33.geometry}
                  material={materials.shrdScrt_phong_1}
                />
                <mesh
                  name="dice1_34"
                  geometry={nodes.dice1_34.geometry}
                  material={materials.shrdScrt_phong_2}
                />
              </group>
            </group>
            <group
              name="cc_dice_2_(8)"
              position={[-0.024995, -0.051167, 0.034773]}
              rotation={[0.402104, 0, 0]}
              scale={2.71371}
            >
              <group
                name="dice1_(17)"
                position={[0.000001, 0, -0.000001]}
                rotation={[0, 1.309157, 0]}
              >
                <mesh
                  name="dice1_35"
                  geometry={nodes.dice1_35.geometry}
                  material={materials.shrdScrt_phong_1}
                />
                <mesh
                  name="dice1_36"
                  geometry={nodes.dice1_36.geometry}
                  material={materials.shrdScrt_phong_2}
                />
              </group>
            </group>
            <mesh
              name="base_secret_share1_(8)"
              geometry={nodes["base_secret_share1_(8)"].geometry}
              material={materials.shrdScrt_phong_5}
              position={[0, 0, 0.000794]}
            />
            <group
              name="share_card_(16)"
              position={[0.119553, 0.117223, 0.005333]}
              scale={0.649257}
            >
              <group name="numbers_(8)">
                <mesh
                  name="shareCard_number_plane1_(8)"
                  geometry={nodes["shareCard_number_plane1_(8)"].geometry}
                  material={materials.shrdScrt_number_material_1}
                  position={[0, 0, -0.004118]}
                />
              </group>
              <mesh
                name="share_card_(17)"
                geometry={nodes["share_card_(17)"].geometry}
                material={materials.shrdScrt_phong_7}
              />
              <mesh
                name="shareCard_number_plane_(8)"
                geometry={nodes["shareCard_number_plane_(8)"].geometry}
                material={materials.shrdScrt_number_material_0}
              />
            </group>
            <group
              name="offset_transforms_equation_spinner_grp_(8)"
              position={[0.057702, 0.002389, 0.042131]}
              scale={0.151222}
            >
              <group name="equation_spinner_grp_(8)">
                <group
                  name="group1_(8)"
                  position={[0, 0.03846, 0]}
                  rotation={[-1.928532, 0, 0]}
                >
                  <group name="group12_(8)">
                    <mesh
                      name="typeMesh_0_(8)"
                      geometry={nodes["typeMesh_0_(8)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004615, -0.054547, 0.335158]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_1_(8)"
                      geometry={nodes["typeMesh_1_(8)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004469, -0.241131, 0.239087]}
                      rotation={[Math.PI / 5, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_2_(8)"
                      geometry={nodes["typeMesh_2_(8)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.005421, -0.335588, 0.051762]}
                      rotation={[1.256637, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_3_(8)"
                      geometry={nodes["typeMesh_3_(8)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.008129, -0.294208, -0.179115]}
                      rotation={[1.884956, 0, 0]}
                    />
                    <mesh
                      name="typeMesh_4_(8)"
                      geometry={nodes["typeMesh_4_(8)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.00564, -0.152872, -0.303211]}
                      rotation={[2.513274, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_5_(8)"
                      geometry={nodes["typeMesh_5_(8)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004981, 0.05462, -0.335158]}
                      rotation={[Math.PI, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_6_(8)"
                      geometry={nodes["typeMesh_6_(8)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004762, 0.241545, -0.238786]}
                      rotation={[-2.513274, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_7_(8)"
                      geometry={nodes["typeMesh_7_(8)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.006885, 0.33561, -0.051692]}
                      rotation={[-1.884956, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_8_(8)"
                      geometry={nodes["typeMesh_8_(8)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.004689, 0.301898, 0.155447]}
                      rotation={[-1.256637, 0, 0]}
                      scale={0.674685}
                    />
                    <mesh
                      name="typeMesh_9_(8)"
                      geometry={nodes["typeMesh_9_(8)"].geometry}
                      material={materials.shrdScrt_phong_1}
                      position={[-0.005201, 0.153286, 0.302909]}
                      rotation={[-Math.PI / 5, 0, 0]}
                      scale={0.674685}
                    />
                  </group>
                  <mesh
                    name="pCylinder1_(8)"
                    geometry={nodes["pCylinder1_(8)"].geometry}
                    material={materials.shrdScrt_phong_2}
                  />
                  <mesh
                    name="pCylinder2_(8)"
                    geometry={nodes["pCylinder2_(8)"].geometry}
                    material={materials.shrdScrt_phong_2}
                    rotation={[0, 0, Math.PI / 2]}
                    scale={[32.340168, 13.574957, 32.340168]}
                  />
                </group>
              </group>
            </group>
          </group>
        </group>
      </mesh>
    </group>
  );
}

