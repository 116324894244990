import React from "react";
import styled from "styled-components";
import Fireworks from "../common/Fireworks";

const Holder = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.75em 1.5em;
  border-radius: 3em;
  background: white;
  font-size: 16px;
  text-decoration: none;

  .chevron {
    display: block;
    margin-left: 0.75rem;
    width: 0.75rem;
    flex-shrink: 0;
    transition: 0.3s ease;
  }

  &:hover {
    text-decoration: none;

    .chevron {
      transform: translateX(0.25rem);
    }
  }
`;

const Text = styled.span`
  flex-grow: 1;
  font-size: 0.75em;
`;

const Heading = styled.span`
  display: block;
  font-size: 1.5em;
  font-weight: 700;
  letter-spacing: -0.025em;
`;

function FinalCelebration() {
  return (
    <Holder>
      <Text>
        Congratulations!
        <Heading>You have viewed the final section</Heading>
      </Text>
      <Fireworks show={true} />
    </Holder>
  );
}

export default FinalCelebration;

