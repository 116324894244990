import React from 'react';
import useTrigger from "../../hooks/useTrigger";
import Label from "../Label";
import {text} from "../../data/multiplication-data/text";
import {somethingVerySmall} from "../../helpers/scrollHelpers";

const keyframes = [
  text[11].adjustedIn,
  text[12].adjustedIn,
  text[13].adjustedIn - somethingVerySmall,
  text[14].adjustedIn,
  text[15].adjustedIn,
  text[16].adjustedIn,
]

const labelProps = {
  labelWidth: 150,
  targetHeight: 110,
  targetWidth: 150,
  labelPosition: null,
  hideOnMobileAndTablet: true,
}

const positions = {
  pos1: [-0.17, 0.19, 0],
  pos2: [-0.14, 0.11, 0],
  pos3: [0.16, 0.185, 0],
  pos4: [0.12, 0.11, 0],
}

function CalculationLabelGroup() {
  const showEquation1Helpers = useTrigger(keyframes[0], keyframes[1] - somethingVerySmall)
  const showEquation2Helpers = useTrigger(keyframes[1] - somethingVerySmall, keyframes[2] - somethingVerySmall)
  const showEquation3Helpers = useTrigger(keyframes[2], keyframes[3] - somethingVerySmall)

  return (
    <group
      position={[0.71, 1.4, 0.39]}
      rotation={[0, Math.PI * 0.34, 0]}
    >
      <Label
        show={showEquation1Helpers}
        {...labelProps}
        position={positions.pos1}/>
      <Label
        show={showEquation1Helpers}
        {...labelProps}
        position={positions.pos2}/>


      <Label
        show={showEquation2Helpers}
        {...labelProps}
        position={positions.pos2}/>

      <Label
        show={showEquation2Helpers}
        {...labelProps}
        position={positions.pos4}/>


      <Label
        show={showEquation3Helpers}
        {...labelProps}
        position={positions.pos1}/>

      <Label
        show={showEquation3Helpers}
        {...labelProps}
        position={positions.pos3}/>
    </group>
  )
}

export default CalculationLabelGroup;