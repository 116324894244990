/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";

export default function Spinner({ nodes, materials }) {
  return (
    <group>
      <group name="equation_spinner_grp">
        <group name="group1">
          <group name="group12">
            <mesh
              name="typeMesh_0"
              geometry={nodes.typeMesh_0.geometry}
              material={materials.typeBlinn}
              position={[-0.008129, -0.079434, 0.335158]}
            />
            <mesh
              name="typeMesh_1"
              geometry={nodes.typeMesh_1.geometry}
              material={materials.typeBlinn}
              position={[-0.008129, -0.261264, 0.224459]}
              rotation={[Math.PI / 5, 0, 0]}
            />
            <mesh
              name="typeMesh_2"
              geometry={nodes.typeMesh_2.geometry}
              material={materials.typeBlinn}
              position={[-0.008129, -0.343301, 0.028024]}
              rotation={[1.256637, 0, 0]}
            />
            <mesh
              name="typeMesh_3"
              geometry={nodes.typeMesh_3.geometry}
              material={materials.typeBlinn}
              position={[-0.008129, -0.294208, -0.179115]}
              rotation={[1.884956, 0, 0]}
            />
            <mesh
              name="typeMesh_4"
              geometry={nodes.typeMesh_4.geometry}
              material={materials.typeBlinn}
              position={[-0.008129, -0.132738, -0.317839]}
              rotation={[2.513274, 0, 0]}
            />
            <mesh
              name="typeMesh_5"
              geometry={nodes.typeMesh_5.geometry}
              material={materials.typeBlinn}
              position={[-0.008129, 0.079434, -0.335158]}
              rotation={[Math.PI, 0, 0]}
            />
            <mesh
              name="typeMesh_6"
              geometry={nodes.typeMesh_6.geometry}
              material={materials.typeBlinn}
              position={[-0.008129, 0.261264, -0.224459]}
              rotation={[-2.513274, 0, 0]}
            />
            <mesh
              name="typeMesh_7"
              geometry={nodes.typeMesh_7.geometry}
              material={materials.typeBlinn}
              position={[-0.008129, 0.343301, -0.028024]}
              rotation={[-1.884956, 0, 0]}
            />
            <mesh
              name="typeMesh_8"
              geometry={nodes.typeMesh_8.geometry}
              material={materials.typeBlinn}
              position={[-0.008129, 0.294208, 0.179115]}
              rotation={[-1.256637, 0, 0]}
            />
            <mesh
              name="typeMesh_9"
              geometry={nodes.typeMesh_9.geometry}
              material={materials.typeBlinn}
              position={[-0.008129, 0.132738, 0.317839]}
              rotation={[-Math.PI / 5, 0, 0]}
            />
          </group>
          <mesh
            name="pCylinder1"
            geometry={nodes.pCylinder1.geometry}
            material={materials.phong1}
          />
          <mesh
            name="pCylinder2"
            geometry={nodes.pCylinder2.geometry}
            material={materials.phong1}
            rotation={[0, 0, Math.PI / 2]}
            scale={[32.340168, 13.574957, 32.340168]}
          />
        </group>
      </group>
    </group>
  );
}

