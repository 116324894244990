import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { useBoundStore } from "../../stores/useBoundStore";

const Holder = styled.div`
  position: fixed;
  top: 7rem;
  left: 0.5rem;
  z-index: 1000;

  @media (${(props) => props.theme.breakpoints.lg}) {
    top: auto;
    left: auto;
    right: 0.5rem;
    bottom: 5rem;
  }

  button {
    position: relative;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    transition: 0.25s ease;
    color: white;

    .tip {
      position: absolute;
      bottom: calc(100% + 8px);
      right: 0;
      color: white;
      background: var(--purple);
      white-space: nowrap;
      border-radius: 0.25rem;
      padding: 0.25rem 0.5rem;
      font-size: 0.75rem;
      opacity: 0;
      visibility: hidden;
    }

    svg {
      width: 100%;
      height: 100%;

      path {
        transition: 0.3s ease;
      }

      &.off {
        .speaker-path {
          transform: translateX(6px);
        }

        .sound-path {
          opacity: 0;
        }
      }
    }

    &:hover {
      color: #b9ffca;

      .tip {
        @media (${(props) => props.theme.breakpoints.md}) {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
`;

const AudioPlayer = (props) => {
  const { currentIndex, tracks } = props;

  const audioEnabled = useBoundStore((state) => state.audioEnabled);
  const setAudioEnabled = useBoundStore((state) => state.setAudioEnabled);

  const [currentTrack, setCurrentTrack] = useState(null);
  const ref = useRef();

  useEffect(() => {
    if (audioEnabled) {
      setCurrentTrack(tracks[currentIndex] || null);
    }
  }, [audioEnabled, currentIndex, tracks]);

  useEffect(() => {
    const player = ref?.current;

    if (player && currentTrack) {
      player.pause();

      if (audioEnabled) {
        const playPromise = player.play();

        // avoiding DOM exception errors
        if (playPromise !== undefined) {
          playPromise
            .then(function () {
              // Automatic playback started!
            })
            .catch(function (error) {
              // Automatic playback failed.
            });
        }
      }
    }
  }, [currentTrack, audioEnabled, ref]);

  return (
    <Holder className="hideForLoading">
      <button type="button" onClick={() => setAudioEnabled(!audioEnabled)}>
        <span className="tip">
          {audioEnabled ? "Turn off audio" : "Turn on audio"}
        </span>
        <svg
          className={audioEnabled ? "on" : "off"}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52 52"
        >
          <circle cx="25.668" cy="26.251" r="25.668" fill="currentColor" />

          <path
            className="sound-path"
            d="M28.057 21.258a6.242 6.242 0 0 1 2.432 4.95 6.317 6.317 0 0 1-2.548 5.036M31.912 16.541a12.134 12.134 0 0 1 4.728 9.624c0 3.916-1.95 7.582-4.955 9.795"
            stroke="#7980FA"
            strokeWidth="3.861"
            strokeMiterlimit="10"
          />

          <path
            className="speaker-path"
            d="m17.226 21.657 7.437-5.833v20.853l-7.437-5.833v-9.187ZM11.621 21.557h4.439v9.388h-4.439z"
            fill="#7980FA"
          />
        </svg>
      </button>

      <audio ref={ref} src={currentTrack ? currentTrack?.file : ""} />
    </Holder>
  );
};

AudioPlayer.propTypes = {
  currentIndex: PropTypes.number.isRequired,
  tracks: PropTypes.array.isRequired,
};

export default AudioPlayer;

