import React from "react";
import useTrigger from "../../hooks/useTrigger";
import HelpersHolder from "../shared-models/holders/HelpersHolder";
import {text} from "../../data/addition-data/text";

const HelperTableGroup = () => {
  const showHelpers = useTrigger(text[8].adjustedIn);

  return (
    <>

      <group>
        <HelpersHolder
          position={[0.8, 0, 0.69]}
          rotation={[0, -Math.PI * 0.65, 0]}
          show={showHelpers}
          popsIn={text[9].adjustedIn}
          lookDownAndUp={text[10].adjustedIn}
          color="green"
        />
    
      </group>

      <HelpersHolder
        position={[0, 0, -1.25]}
        rotation={[0, 0, 0]}
        show={showHelpers}
        popsIn={text[9].adjustedIn}
        lookDownAndUp={text[10].adjustedIn}
        shrug={text[11].adjustedIn}
        shrugEnd={text[12].adjustedIn}
        color="blue"
      />

      <HelpersHolder
        position={[-0.8, 0, 0.69]}
        rotation={[0, 2.5, 0]}
        show={showHelpers}
        popsIn={text[9].adjustedIn}
        lookDownAndUp={text[10].adjustedIn}
        color="pink"
      />




    
    </>
  );
};

export default HelperTableGroup;

