import React from 'react'

const IpaEndIcon3 = ({nodes, materials}) => {
  return (
    <group>
    <group name="null1">
      <group name="Icon_Ipa_3_Browser" position={[0, -1.073975, 0.141722]}>
        <group name="Browser_grp" position={[-6.789606, 1.04689, -0.037502]} rotation={[0.006111, -0.156931, 0.017319]}>
          <group name="lock" position={[22.386381, -3.382846, 0.141221]}>
            <mesh name="lock2" geometry={nodes.lock2.geometry} material={materials.phong_4} position={[0.000891, -0.018501, -0.000329]} />
            <mesh name="lock1" geometry={nodes.lock1.geometry} material={materials.phong_4} position={[-0.000609, 0.00817, -0.006911]}>
              <mesh name="pCylinder3" geometry={nodes.pCylinder3.geometry} material={materials.phong_8} position={[0.005643, 1.001712, 0.030814]} />
            </mesh>
          </group>
          <mesh name="pasted__browser_page_4" geometry={nodes.pasted__browser_page_4.geometry} material={materials.phong_1} />
          <mesh name="pasted__browser_buttons_4" geometry={nodes.pasted__browser_buttons_4.geometry} material={materials.phong_1} position={[-2.317569, 0, 0]} />
          <mesh name="pasted__browser_navbar_4" geometry={nodes.pasted__browser_navbar_4.geometry} material={materials.phong_3} />
          <mesh name="pasted__browser_buttons_5" geometry={nodes.pasted__browser_buttons_5.geometry} material={materials.phong_1} position={[-1.621416, 0, 0]} />
        </group>
        <group name="Base_2">
          <mesh name="pDisc2" geometry={nodes.pDisc2.geometry} material={materials.phong_3} position={[0, 1.073975, -0.141722]} />
        </group>
      </group>
    </group>
  </group>
  )
}

export default IpaEndIcon3