/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";

export default function GlobalUser({ nodes, materials }) {
  return (
    <group>
       <mesh name="char_helper_mesh" geometry={nodes.char_helper_mesh.geometry} material={materials['Rig:standardSurface2']} />
    </group>
  );
}

