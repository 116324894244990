import Audio0 from "../../assets/audio/intro/0.mp3";
import Audio1 from "../../assets/audio/intro/1.mp3";
import Audio2 from "../../assets/audio/intro/2.mp3";
import Audio3 from "../../assets/audio/intro/3.mp3";
import Audio4 from "../../assets/audio/intro/4.mp3";

export const audio = [
  {
    file: Audio0,
  },
  {
    file: Audio1,
  },
  {
    file: Audio2,
  },
  {
    file: Audio3,
  },
  {
    file: Audio4,
  },
];

