import React from "react";
import styled from "styled-components";
import { ReactComponent as PlayIcon } from "../../assets/svg/play-icon.inline.svg";

const Holder = styled.div`
  h2 {
    font-size: 18px;
    margin-bottom: 1rem;

    span {
      font-weight: 400;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin: 0;
  }
`;

const ResourceCard = styled.li`
  padding: 16px 24px;
  border-radius: 16px;
  background: white;
  font-size: 16px;

  &.mobile {
    padding: 0 0 1rem;
    border-bottom: 1px solid var(--grey);
    border-radius: 0;

    &:last-of-type {
      border: none;
    }
  }

  h3 {
    font-size: 1em;
    margin-bottom: 0.75em;
  }

  .button {
    display: inline-block;
    min-width: 20ch;
    padding: 0.5em 1em;
    border-radius: 0.25em;
    border: 1px solid var(--blue);
    background: var(--blue);
    color: white;
    font-size: 0.875em;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;

    &:hover {
      background: var(--blue);
      text-decoration: underline;
    }
  }
`;

const ImageWrapper = styled.div`
  img {
    max-width: 100%;
  }

  position: relative;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: white;
    width: 20%;
  }


`;

function AdditionalResources({ isMobile, section }) {
  return (
    <Holder>
      {!isMobile && (
        <h2>
          {section.heading} <span>additional resources:</span>
        </h2>
      )}

      <ul>
        {section.resources.map((item) => (
          <ResourceCard className={isMobile && "mobile"}>
            <h3>{item.heading}</h3>

            <a
              href={item.link?.url}
              target="_blank"
              rel="noopener noreferrer"
              className={!item.link?.image && "button"}
            >
              {item.link?.image ? (
                <ImageWrapper>
                  <PlayIcon />
                  <img src={item.link.image} alt={item.link?.label} />
                </ImageWrapper>              ) : (
                <>{item.link?.label}</>
              )}
            </a>
          </ResourceCard>
        ))}
      </ul>
    </Holder>
  );
}

export default AdditionalResources;

