import React from "react";
import DeepDiveTwo from "../ModalContent/DeepDiveTwo";

const myText = [
  {
    text: "To calculate total counts and sums, IPA adds up secret-shared numbers from a lot of people.",
  },
  { text: "Now lets give an example of how that works" },
  {
    text: "We want to count the number of people in this room who made a purchase.",
  },
  { text: "We want to keep each person’s information secret." },
  {
    text: "If a person made a purchase, they contribute '1' to the total count.",
  },
  { text: "If a person did not make a purchase, they contribute '0'." },
  {
    text: "We can help them keep their contribution private by secret-sharing it.",
  },
  {
    text: "If you’re not familiar with secret-sharing, it was covered in the previous section of this explainer.",
    link: "/secret",
    linkTitle: "View secret sharing explainer",
  },
  {
    text: "Now, we can just add all of these secret-sharings up, to get the total number of purchases people made.",
  },
  { text: "We need 3 “helpers” to run this computation." },
  { text: "Everyone in the room puts their three secret-shares on the table." },
  { text: "The helpers won’t ever learn secret information." },
  { text: "They will only see 2 of the 3 secret-shares." },
  {
    text: "A helper could try to guess what the 3rd share is but they won't know if their guess was right or wrong.",
    modal: <DeepDiveTwo />,
  },
  { text: "Each helper adds up the stack on their right." },
  { text: "They turn the dial to the number on the first share." },
  { text: "Then add the next number..." },
  { text: "And continue for all the shares." },
  {
    text: "At the end they are left with a number that represents their total count.",
  },
  { text: "Now it’s time to reveal that total count!" },
  { text: "The three helpers each announce their total sum." },
  {
    text: "Adding these three numbers together on a dial tells us that there were 6 people in the room who made a purchase.",
  },
  {
    text: (
      <>
        <p>
          This is just a total. Nobody knows <b>which</b> people made a
          purchase, they only learned the total. This helps respect everyone's
          privacy.
        </p>
      </>
    ),
  },
  { text: "You have seen how addition on secret-shared numbers works.", celebration: true, },
  {
    text: "Addition is great, but we can only use it for simple problems. If we want to compute more complex things then we need addition and multiplication.",
  },
];

export const interval = 1 / myText.length;

export const text = myText.map((item, index) => {
  return {
    in: index * interval,
    adjustedIn: (index + 1) * interval, // adjustedIn accounts for the opening screen having no text pop-up.
    sceneAnchor: index,
    ...item,
  };
});
