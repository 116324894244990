import React from "react";
import useTrigger from "../../hooks/useTrigger";
import MultiplicationTotalHolder from "./holders/MultiplicationTotalHolder";
import {text} from "../../data/multiplication-data/text";
import {somethingVerySmall} from "../../helpers/scrollHelpers";

const FinalCountGroup = () => {
  const show = useTrigger(
    text[22].adjustedIn - somethingVerySmall
  );
  return (
    <group visible={show} position={[0, 3.5, 0]}>
       <MultiplicationTotalHolder position={[0, 0.25, 1]} />
    </group>
  );
};

export default FinalCountGroup;

