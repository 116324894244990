import {text, interval} from "./text";

const halfInterval = interval / 2;
const thirdInterval = interval / 3;

export const keyframes = [
  // start looking over room
  { time: 0, position: [0, 15, 15], mobilePosition: [0, 15, 25], lookAt: [0, 1, 0],shakeIntensity: 0.05 },
  { time: text[1].adjustedIn, position: [0, 15, 15], mobilePosition: [0, 15, 25], lookAt: [0, 1, 0],shakeIntensity: 0.05 },
  { time: text[2].adjustedIn, position: [0, 5, 15], mobilePosition: [0, 5, 25], lookAt: [0, 1, 0],shakeIntensity: 0.05 },
  // zoom in on a users
  { time: text[3].adjustedIn, position: [-1.5, 1.7, 2.5], mobilePosition: [-1.5, 1.7, 4.5], lookAt: [-2, 1.7, -1], shakeIntensity: 0.04 },
  // zoom in on pineapple icon 0
  { time: text[4].adjustedIn - thirdInterval, position: [-2.3, 1.7, 1.4], lookAt: [-2.4, 1.7, -1], shakeIntensity: 0.04 },
  { time: text[4].adjustedIn, position: [-2.1, 1.7, 1.4], lookAt: [-2.4, 1.7, -1], shakeIntensity: 0.04 },
  // zoom in on pineapple icon 1
  { time: text[5].adjustedIn - thirdInterval, position: [-1.5, 1.7, 1.4], lookAt: [-1.6, 1.7, -1], shakeIntensity: 0.04 },
  { time: text[5].adjustedIn, position: [-1.3, 1.7, 1.4], lookAt: [-1.6, 1.7, -1], shakeIntensity: 0.04 },
  // back to users
  { time: text[6].adjustedIn, position: [-1.5, 1.7, 2], mobilePosition: [-1.5, 1.7, 4.5], lookAt: [-2, 1.5, -1], shakeIntensity: 0.05 },
  { time: text[7].adjustedIn, position: [-1.5, 1.7, 2], mobilePosition: [-1.5, 1.7, 4.5], lookAt: [-2, 1.5, -1], shakeIntensity: 0.04 },
  // Look up slightly to catch all shares breaking
  { time: text[8].adjustedIn - halfInterval, position: [-1, 2.2, 3], mobilePosition: [-0.75, 3.2, 7], lookAt: [-2, 1.5, -1], shakeIntensity: 0.05 },
  { time: text[8].adjustedIn, position: [-1, 2.2, 3], mobilePosition: [-0.75, 3.2, 7], lookAt: [-2, 1.5, -1], shakeIntensity: 0.05 },
  // zoom out to see everyone number break
  { time: text[9].adjustedIn, position: [1.5, 3, 4], mobilePosition: [2.5, 3, 7], lookAt: [-0.25, 1.5, 0], shakeIntensity: 0.05 },
  { time: text[10].adjustedIn, position: [1.5, 3, 4], mobilePosition: [2.2, 4, 8], lookAt: [-0.25, 1.5, 0], shakeIntensity: 0.05 },
  // resolve on shrug helper
  { time: text[11].adjustedIn, position: [-0.2, 3, 1], mobilePosition: [0, 3.7, 2.2], lookAt: [0, 2, 0.1], shakeIntensity: 0.02 },
  { time: text[12].adjustedIn, position: [-0.2, 3, 1], mobilePosition: [0, 3.7, 2.2], lookAt: [0, 2, 0.1], shakeIntensity: 0.02 },
  // rotate to shrug POV
  { time: text[13].adjustedIn, position: [0, 2.55, -2], mobilePosition: [0, 3, -2.7], lookAt: [0, 1, -0.1], shakeIntensity: 0.02 },
  // Focus on right hand stack
  { time: text[14].adjustedIn, position: [0, 1.8, -1.3], mobilePosition: [0.075, 2, -1.5], lookAt: [-0.15, 0.9, -0.2], shakeIntensity: 0.001 },
  { time: text[18].adjustedIn, position: [0, 1.8, -1.3], mobilePosition: [0.075, 2, -1.5], lookAt: [-0.15, 0.9, -0.2], shakeIntensity: 0.001 },
  // Pull back to table
  { time: text[19].adjustedIn, position: [0, 3, 6], mobilePosition: [0, 3, 8], lookAt: [0, 1, 0], shakeIntensity: 0.2 },
  // zoom out to room
  { time: text[21].adjustedIn, position: [0, 4, 11], mobilePosition: [0, 5, 22.5], lookAt: [0, 2, 0], shakeIntensity: 0.4 },
  { time: text[24].adjustedIn, position: [0, 4, 11], mobilePosition: [0, 5, 22.5], lookAt: [0, 2, 0], shakeIntensity: 0.4 },
  // end scene zoomed out
  { time: 1, position: [0, 8, 10], mobilePosition: [0, 8, 18.5], lookAt: [0, 2, 0], shakeIntensity: 0.4 },
];

