import React from 'react';
import useTrigger from "../../hooks/useTrigger";
import DiceHolder from "../shared-models/holders/DiceHolder";
import {text} from "../../data/secret-data/text";
import {somethingVerySmall} from "../../helpers/scrollHelpers";

// import {Box} from "@react-three/drei";

function DiceGroupThree() {
  const show = useTrigger(text[29].adjustedIn, text[31].adjustedIn - somethingVerySmall);

  return (
    <group
      position={[0.256, 0.425, 4.95]}
      rotation={[0, Math.PI / 5, 0]}
      visible={show}>

      <DiceHolder
        position={[-0.06, 0, 0]}
        in={text[27].adjustedIn}
        roll5={text[29].adjustedIn}
      />
      <DiceHolder
        position={[-0.02, 0, 0]}
        in={text[27].adjustedIn}
        roll2={text[29].adjustedIn}
      />
      <DiceHolder
        position={[0.02, 0, 0]}
        in={text[27].adjustedIn}
        roll8={text[29].adjustedIn}
      />
      <DiceHolder
        position={[0.06, 0, 0]}
        in={text[27].adjustedIn}
        roll5={text[29].adjustedIn}
      />

    </group>
  )
}

export default DiceGroupThree;