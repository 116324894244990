import React, {useMemo, useRef} from "react";
import {useGLTF} from "@react-three/drei";
import Share from "../models/Share";
import PropTypes from "prop-types";
import useNewMaterials from "../../../hooks/useNewMaterials";
export default function ShareHolder(props) {
  const group = useRef();
  const {nodes, materials} = useGLTF(
    "/GlobalModels/share-card-transformed.glb"
  );

  const specificMaterials = useMemo(() => [
    {name: 'number_material_0', replaceWith: `number_material_${props.number}`, setMaterialColor: 0xffffff}
  ], [props.number]);
  const newMaterials = useNewMaterials(materials, {
    baseMaterial: props.color,
    specificMaterials: specificMaterials,
  });

  if(props.debug) {
    console.log(`materials`, materials)
    console.log(`newMaterials`, newMaterials)
  }

  return (
    <group
      ref={group}
      position={props.position}
      dispose={null}
      visible={props.show}
      scale={props.scale}
    >
      <Share materials={newMaterials} nodes={nodes} />
    </group>
  );
}

ShareHolder.propTypes = {
  debug: PropTypes.bool,
  show: PropTypes.bool,
  number: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]).isRequired,
  color: PropTypes.string,
};

ShareHolder.defaultProps = {
  debug: false,
  show: true,
  number: 0,
  color: "primary",
};

useGLTF.preload("/GlobalModels/share-card-transformed.glb");

