import React from "react";
import MultiplicationRoomGroup from "./MultiplicationRoomGroup";
import MultiplicationSpeechBubblesGroup from "./MultiplicationSpeechBubblesGroup";
import FinalCountGroup from "./FinalCountGroup";
import HeroUserPair from "./HeroUserPair";
import HelpersGroup from "./HelpersGroup";
import NonMatchingUserPair from "./NonMatchingUserPair";
import CalculationLabelGroup from "./CalculationLabelGroup";
import StackingSharesGroup from "./StackingSharesGroup";
import CalculatorHolder from "./holders/CalculatorHolder";

function MultiplicationScene() {


  return (
    <group>
      <MultiplicationRoomGroup />
      <HeroUserPair />
      <HelpersGroup />
      <CalculatorHolder />
      <CalculationLabelGroup />
      <NonMatchingUserPair />
      <MultiplicationSpeechBubblesGroup />
      <FinalCountGroup />
      <StackingSharesGroup />
    </group>
  );
}

export default MultiplicationScene;

