import {text, interval} from "./text";

const halfInterval = interval / 2;
const thirdInterval = interval / 3;
export const keyframes = [
  { time: 0, position: [0, 15, 20], mobilePosition: [0, 20, 20], lookAt: [0,0,0], shakeIntensity: 0.02 },
  { time: text[0].adjustedIn, position: [0, 15, 20], mobilePosition: [0, 20, 20], lookAt: [0,0,0], shakeIntensity: 0.02 },
  { time: text[1].adjustedIn, position: [0, 8, 15], mobilePosition: [0, 8, 15], lookAt: [0,0,0], shakeIntensity: 0.02 },
  // zoom to pair
  { time: text[2].adjustedIn, position: [-1.5, 1.7, 2], mobilePosition: [-1.5, 1.7, 5], lookAt: [-2, 1.7, -1], shakeIntensity: 0.02 },
  { time: text[7].adjustedIn, position: [-1.5, 1.7, 2], mobilePosition: [-1.5, 1.7, 5], lookAt: [-2, 1.7, -1], shakeIntensity: 0.03 },
  // pull back to table
  { time: text[8].adjustedIn, position: [0, 4, 4], mobilePosition: [0.5, 7, 12], lookAt: [-0.5, 1.5, 0], shakeIntensity: 0.04 },
  { time: text[8].adjustedIn + thirdInterval, position: [0, 5, 3], lookAt: [0, 1.5, 0], shakeIntensity: 0.04 },
  // rotate to helper POV
  { time: text[9].adjustedIn - thirdInterval, position: [3, 6, 1.65], lookAt: [0, 1, 0], shakeIntensity: 0.03 },

  // focus on calculator
  { time: text[9].adjustedIn, position: [1.25, 2.1, 0.7], mobilePosition: [1.8, 3.3, 0.85], lookAt: [0.1, 0.8, 0], shakeIntensity: 0.000001 },
  { time: text[15].adjustedIn, position: [1.25, 2.1, 0.7], mobilePosition: [1.8, 3.3, 0.85], lookAt: [0.1, 0.8, 0], shakeIntensity: 0.000001 },

  // pull back to show shares on heads of helpers
  { time: text[16].adjustedIn, position: [0, 4.5, 5.8], mobilePosition: [0, 5, 10.5], lookAt: [0, 1, 0], shakeIntensity: 0.04 },
  { time: text[17].adjustedIn, position: [0, 4.5, 5.8], mobilePosition: [0, 5, 10.5], lookAt: [0, 1, 0], shakeIntensity: 0.04 },

  // focus on a match pair
  { time: text[17].adjustedIn + halfInterval, position: [-1.5, 1.5, 2], mobilePosition: [-1.5, 1.5, 6], lookAt: [-2.1, 1.5, -1], shakeIntensity: 0.05 },
  { time: text[18].adjustedIn, position: [-1.5, 1.7, 2], mobilePosition: [-1.5, 1.7, 7], lookAt: [-2.1, 1.7, -1], shakeIntensity: 0.05 },
  { time: text[18].adjustedIn + thirdInterval, position: [-1.5, 1.7, 2], mobilePosition: [-1.5, 1.7, 7], lookAt: [-2, 1.7, -1], shakeIntensity: 0.05 },

  // focus on a non-match pair
  { time: text[19].adjustedIn - thirdInterval, position: [0, 1.5, -2], mobilePosition: [0, 3.5, 3],lookAt: [0, 1.5, -5], shakeIntensity: 0.19 },
  { time: text[19].adjustedIn, position: [0, 1.7, -2], mobilePosition: [0, 3.8, 3], lookAt: [0, 1.7, -5], shakeIntensity: 0.19 },

  // Zoom back to room
  { time: text[20].adjustedIn, position: [0, 6, 11], mobilePosition: [0, 6, 10], lookAt: [0, 1, 0], shakeIntensity: 0.24 },
  { time: text[21].adjustedIn, position: [0, 4, 6], mobilePosition: [0, 6, 11], lookAt: [0, 2, 0], shakeIntensity: 0.24},
  // Pan up to conclusion
  { time: text[22].adjustedIn, position: [0, 4, 11], mobilePosition: [0, 5, 22.5], lookAt: [0, 2, 0], shakeIntensity: 0.26 },
  { time: text[25].adjustedIn, position: [0, 4, 11], mobilePosition: [0, 5, 22.5], lookAt: [0, 2, 0], shakeIntensity: 0.26 },
];

