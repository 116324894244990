import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import styled from "styled-components";
import { ReactComponent as FireworkSvg } from "../assets/svg/firework.inline.svg";

const Holder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 999999;
`;

const Inner = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 9999999;
  transform-origin: 50% 50%;
  top: ${(props) =>
    props.mobilePercentageTop
      ? props.mobilePercentageTop
      : props.percentageTop};
  left: ${(props) =>
    props.mobilePercentageLeft
      ? props.mobilePercentageLeft
      : props.percentageLeft};
  svg {
    path {
      stroke: ${(props) => props.color};
    }
  }

  @media (${(props) => props.theme.breakpoints.md}) {
    top: ${(props) => props.percentageTop};
    left: ${(props) => props.percentageLeft};
  }
`;

const FireworkHolder = ({
  color,
  delay,
  percentageLeft,
  percentageTop,
  initialScale,
  duration,
  mobilePercentageLeft,
  mobilePercentageTop,
}) => {
  const el = useRef();

  useEffect(() => {
    let tl = gsap.timeline({
      id: "celebration",
      delay: delay,
    });
    const ctx = gsap.context(() => {
      tl.from("#firework", {
        scale: 0,
        transformOrigin: "50% 50%",
        ease: "power4.out",
        duration: duration,
      })
        .from(
          "[id^='spark']",
          {
            autoAlpha: 0,
            transformOrigin: "50% 50%",
            stagger: {
              each: 0.02,
              randomize: true,
            },
          },
          0
        )
        .to("[id^='spark']", {
          scale: 0,
          autoAlpha: 0,
          transformOrigin: "50% 50%",
          stagger: {
            each: 0.02,
            randomize: true,
          },
        })
        .to(
          "#firework",
          {
            scale: 0,
            autoAlpha: 0,
            transformOrigin: "50% 50%",
            ease: "power1.out",
            duration: 1.3,
          },
          0.3
        );
    }, el);
    return () => ctx.revert();
  }, [delay, initialScale, duration]);

  return (
    <Holder id="celebration" ref={el}>
      <Inner
        percentageLeft={percentageLeft}
        percentageTop={percentageTop}
        mobilePercentageLeft={mobilePercentageLeft}
        mobilePercentageTop={mobilePercentageTop}
        style={{
          transform: `scale(${initialScale})`,
        }}
        color={color}
      >
        <FireworkSvg />
      </Inner>
    </Holder>
  );
};

export default FireworkHolder;

