/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef} from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import useTriggerAnimation from "../../../hooks/useTriggerAnimation";
import useSetFirstAnimationFrame from "../../../hooks/useSetFirstAnimationFrame";
import PropTypes from "prop-types";
import useNewMaterials from "../../../hooks/useNewMaterials";
import PurchaseIcon from "../models/Purchase";
import TickHolder from "./TickHolder";
import CrossHolder from "./CrossHolder";

export default function PurchaseHolder({ ...props }) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(
    "/GlobalModels/purchase-transformed.glb"
  );

  const newMaterials = useNewMaterials(materials, {
    baseMaterial: props.color,
  });
  const { actions } = useAnimations(animations, group);


  useSetFirstAnimationFrame('enter', actions)
  useTriggerAnimation(props.in, "enter", actions, null, false, 1.5);
  useTriggerAnimation(props.spin0, "spin_to_0", actions, null, false, 1.5);
  useTriggerAnimation(props.spin1, "spin_to_1", actions, null, false, 1.5);

  return (
    <group
      ref={group}
      position={props.position}
      rotation={props.rotation}
      dispose={null}
      visible={props.show}
      scale={props.scale}
    >
      <PurchaseIcon nodes={nodes} materials={newMaterials} />
      <group visible={props.enter} position={[1.5, 1.5, 0.5]}>
        <TickHolder show={props.spin1 !== null} scale={20} />
        <CrossHolder show={props.spin0 !== null} position={[0.4, 0.5, 0]} scale={20} />
      </group>
    </group>
  );
}

PurchaseHolder.propTypes = {
  show: PropTypes.bool,
  scale: PropTypes.number,
  position: PropTypes.array,
  rotation: PropTypes.array,
  in: PropTypes.number,
  spin0: PropTypes.number,
  spin1: PropTypes.number,
  color: PropTypes.string,
}

PurchaseHolder.defaultProps = {
  show: true,
  scale: 1,
  position: [0, 0, 0],
  rotation: [0, 0, 0],
  in: null,
  spin0: null,
  spin1: null,
  color: 'yellow1'
}

useGLTF.preload("/GlobalModels/purchase-transformed.glb");

