import React from "react";
import styled from "styled-components";

const Holder = styled.div`
  @media screen and (orientation: landscape) {
    display: block;
    position: fixed;
    z-index: 100000000;
    width: 100vw;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  }

  @media (${(props) => props.theme.breakpoints.md}) {
    display: none;
  }
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 2rem;
`;

const BlockScreen = () => {
  return (
    <Holder>
      <Inner>Please view in Portrait mode</Inner>
    </Holder>
  );
};

export default BlockScreen;

