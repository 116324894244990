export const secretShares = [
  [
    // Shares for 0
    [8, 3, 9],
    [5, 6, 9],
    [4, 5, 1]
  ],
  [
    // Shares for 1
    [7, 6, 8],
    [4, 8, 9],
    [2, 9, 0]
  ]

];