/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";

export default function PurchaseIcon({ nodes, materials }) {
  return (
    <group>
      <group>
        <group name="Grp_Purchase">
          <group name="purchase" rotation={[0, 0.746835, 0]} scale={0}>
            <mesh
              name="purchase_mesh"
              geometry={nodes.purchase_mesh.geometry}
              material={materials.icon_shader}
              position={[-1.492166, -1.978325, -0.294918]}
              scale={1.989555}
            />
          </group>
          <group name="number_0">
            <mesh
              name="number_0_(1)"
              geometry={nodes["number_0_(1)"].geometry}
              material={materials.numbers_shader}
            />
          </group>
          <group
            name="number_1"
            position={[0.073032, 0.517307, 0]}
            rotation={[0, -0.694551, 0]}
            scale={0}
          >
            <mesh
              name="number_1_(1)"
              geometry={nodes["number_1_(1)"].geometry}
              material={materials.numbers_shader}
            />
          </group>
          <group name="purchase_icon_top">
            <mesh
              name="purchase_mesh_3"
              geometry={nodes.purchase_mesh_3.geometry}
              material={materials.phong3}
              position={[-2.822044, 2.665206, -0.114225]}
              scale={0.72276}
            />
          </group>
        </group>
      </group>
    </group>
  );
}

