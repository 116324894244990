/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useMemo, useRef} from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

import useTriggerAnimation from "../../../hooks/useTriggerAnimation";
import SpeechBubble from "../models/SpeechBubble";
import PropTypes from "prop-types";
import useSetFirstAnimationFrame from "../../../hooks/useSetFirstAnimationFrame";
import useNewMaterials from "../../../hooks/useNewMaterials";

export default function SpeechBubbleHolder({ ...props }) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(
    "/GlobalModels/speech-bubble-transformed.glb"
  );
  const specificMaterials = useMemo(() => [
    {name: 'number_material_0', replaceWith: `number_material_${props.number}`, setMaterialColor: 0xffffff},
  ], [props.number]);
  const newMaterials = useNewMaterials(materials, {
    baseMaterial: props.color,
    specificMaterials: specificMaterials,
  })

  const { actions } = useAnimations(animations, group);

  useSetFirstAnimationFrame("enter", actions);
  useTriggerAnimation(props.in, "enter", actions);
  useTriggerAnimation(props.out, "leave", actions);

  return (
    <group
      ref={group}
      position={props.position}
      dispose={null}
      visible={props.show}
      scale={props.scale}
    >
      <SpeechBubble nodes={nodes} materials={newMaterials} />
    </group>
  );
}

SpeechBubbleHolder.propTypes = {
  number: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]),
  in: PropTypes.number,
  out: PropTypes.number,
  color: PropTypes.string,
};

SpeechBubbleHolder.defaultProps = {
  number: 0,
  in: null,
  out: null,
  color: 'grey3'
};

useGLTF.preload("/GlobalModels/speech-bubble-transformed.glb");

