import React from 'react'

const Hands = ({nodes, materials}) => {
  return (
    <group>
        <group name="char_arms1" position={[0, -0.044566, 0]} rotation={[-Math.PI, 0, -Math.PI]}>
          <group name="group1" position={[0, 0.00295, 0]} rotation={[0, 0.235585, 0]}>
            <mesh name="polySurface2" geometry={nodes.polySurface2.geometry} material={materials.standardSurface2} />
          </group>
          <group name="group2" rotation={[0, -0.206122, 0]}>
            <mesh name="polySurface1" geometry={nodes.polySurface1.geometry} material={materials.standardSurface2} />
          </group>
        </group>
      </group>
  )
}

export default Hands