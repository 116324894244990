import React from 'react';
import {useBoundStore} from "../stores/useBoundStore";
import TemplateScene from "../components/template/TemplateScene";
import {text} from "../data/template-data/text";
import {keyframes} from "../data/template-data/camera";
import AnimatedPageHolder from "../components/common/AnimatedPageHolder";

const timelineId = "template-timeline";
const progressName = "templateProgress";

function Template() {
  const {
    templateProgress,
    setTemplateProgress,
    templateTextIndex,
    setTemplateTextIndex
  } = useBoundStore();

  // useEffect(() => {
  //   console.log("templateProgress", templateProgress);
  //   console.log("templateTextIndex", templateTextIndex);
  // }, [templateProgress, templateTextIndex]);

  return (
    <AnimatedPageHolder
      setProgress={setTemplateProgress}
      setTextIndex={setTemplateTextIndex}
      text={text}
      keyframes={keyframes}
      progressName={progressName}
      progress={templateProgress}
      currentTextIndex={templateTextIndex}
      introText={
        <>
          <h1>Template</h1>
          <p>IPA is a new way of measuring ads where companies don’t need to learn about which apps and websites
            individual users visited. This explainer shows...
          </p>
        </>
      }
      cameraKeyframes={keyframes}
      pageIndex={2}
      textContent={text}
      uniqueTimelineId={timelineId}
    >
      <TemplateScene />
    </AnimatedPageHolder>
  )
}

export default Template;