import React from "react";
import styled from "styled-components";

const Holder = styled.div`
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 1;
  backdrop-filter: blur(5px);
`;

const Inner = styled.div`
  position: relative;
  max-width: 90%;

  display: flex;
  flex-direction: column;
  
  padding: 3rem 2rem 1.5rem;

  background-color: var(--white);
  color: #003a71;
  border-radius: 2rem;
  
  text-align: center;



  @media ( ${(props) => props.theme.breakpoints.md} ) {
    max-width: 30rem;
    padding: 3rem 3rem 1.5rem;
  }
  
  h1, p {
    margin: 0;
  }

  p {
    &.directions {
      font-weight: bold;
    }

    .keyboard-arrows {
      display: none;
      @media ( ${(props) => props.theme.breakpoints.lg} ) {
        display: inline;
      }

    }


    svg {
      transform: rotate(180deg);
      width: 1.4rem;
      align-self: center;

      .cls-1 {
        fill: var(--purple);
      }

    }

`;

const Content = styled.div`
  display: flex;
  align-items: stretch;

  h1 {
    width: 100%;
    ${(props) => props.theme.fluidType(5)};
    margin-bottom: 1.5rem;
  }

  h2,
  .intro-label {
    ${(props) => props.theme.fluidType(-2)};
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;
    margin-bottom: 2rem;
  }

  p {
    ${(props) => props.theme.fluidType(-2)};
    margin-bottom: 2rem;


    &:last-child {
      margin-bottom: 3rem;
    }

    @media (${(props) => props.theme.breakpoints.md}) {
      ${(props) => props.theme.fluidType(-1)};
      line-height: 1.333;

      &:last-child {
        margin-bottom: 2.5rem;
      }
    }
  }
`;

const LeftBorder = styled.div`
  position: relative;
  width: 3.2rem;
  // height: 100%;
  flex-shrink: 0;

  border-left: 1.333rem solid var(--purple);
  border-top: 1.333rem solid var(--purple);
  border-bottom: 1.333rem solid var(--purple);
`;

const RightBorder = styled.div`
  position: relative;
  width: 3.2rem;
  // height: 100%;
  flex-shrink: 0;

  border-right: 1.333rem solid var(--purple);
  border-top: 1.333rem solid var(--purple);
  border-bottom: 1.333rem solid var(--purple);
`;

const ChildrenWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Hint = styled.div`
  margin-top 1.6rem;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  p {
    ${(props) => props.theme.fluidType(-2)};
    line-height: 1.333;
  }
`;

const ArrowsHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5px;
`;

const ArrowButton = styled.div`
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid var(--navy);
  border-radius: 3px;

  &:first-child > div {
    transform: rotate(-45deg);
    margin-top: 2px;
  }

  &:last-child > div {
    transform: rotate(135deg);
    margin-top: -2px;
  }
`;

const Arrow = styled.div`
  width: 8px;
  height: 8px;
  border-top: 2px solid var(--navy);
  border-right: 2px solid var(--navy);
`;

const Intro = (props) => {
  return (
    <Holder id="intro-panel" className="hideForLoading">
      <Inner>
        <Content>
          <LeftBorder className="intro-panel-border ip-left"></LeftBorder>
          <ChildrenWrapper>{props.children}</ChildrenWrapper>
          <RightBorder className="intro-panel-border ip-right"></RightBorder>
        </Content>
        <Hint>
          <ArrowsHolder>
            <ArrowButton>
              <Arrow />
            </ArrowButton>

            <ArrowButton>
              <Arrow />
            </ArrowButton>
          </ArrowsHolder>
          <p>Use your keyboard arrows or the UI navigation to begin</p>
        </Hint>
      </Inner>
    </Holder>
  );
};

export default Intro;

