import React from 'react';
import DeepDiveImage from '../../assets/images/deep-dive-one.png';
import styled from "styled-components";

const Quote = styled.p`
    padding: 10px;
    border-left: 6px solid #ccc;
    margin: 10px 0;
    &::before {
        content: open-quote;
        font-size: 4em;
        line-height: 0.1em;
        margin-right: 0.25em;
        vertical-align: -0.4em;
    }
`;



function DeepDiveOne() {
  return (
    <div>
      <p>Most ads take people from one app/website to another. Advertisers want to know how many people 
        made a purchase on their app/website after seeing an ad someplace else.</p>
      <p>So how do current ads systems calculate this count? Today, here’s how most ads systems work:</p>
        <img src={DeepDiveImage} alt="Diagram of the Ad Attribution and Reporting Process" />
      <p>Businesses who buy ads send data about the purchases made on their app/website to the business that 
        shows the ads. The data they send today usually includes “linkable identifiers”, which is data that 
        can be used to match up individual user journeys across apps/websites. 3rd party cookies and mobile 
        advertising IDs are some examples of “linkable identifiers” that are used today.</p>
      <p>The business who showed the ads checks each purchase to see if that person previously was shown an ad. 
        If they were, this purchase is “attributed” to that ad the person previously saw. These “attributed” purchases 
        are tallied up to get the total count.</p>
      <p>But doing things this way means that the business that shows the ads can learn more than just the total count. 
        Along the way, they also learn a lot of extra information about which specific people made purchases on which 
        apps/websites, including for people who didn’t even see an ad for that app/website.</p>
      <p>Many businesses that show ads offer people various controls to limit what information they keep, and how they 
        use it. But an interesting thought experiment is to ask the question:</p>
      <Quote>Is it possible to build an ads system that <b>only</b> calculates the total count? Even if businesses <b>tried to learn 
        more</b>? Can we <b>limit the amount of trust</b> we need to place in the operator of this system?</Quote>
      <p>We considered this thought experiment, and we came up with a design that achieves these goals. It’s called 
        “Interoperable Private Attribution” (IPA). Continue advancing through this explainer to learn how it works!</p>
    </div>
  );
}

export default DeepDiveOne;
