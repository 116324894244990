import React from "react";
import styled from "styled-components";
import FireworkHolder from "../FireworkHolder";
import PropTypes from "prop-types";

const Holder = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1000;
  top: 0;
  left: 0;
`;

function Fireworks(props) {
  if (!props.show) {
    return null;
  }
  return (
    <Holder>
      <FireworkHolder
        color="#EA67CF"
        delay="0"
        percentageLeft="5%"
        percentageTop="50%"
        initialScale="1.4"
        duration="0.8"
      />
      <FireworkHolder
        color="#7980FA"
        delay="0.2"
        percentageLeft="-5%"
        percentageTop="-60%"
        initialScale="0.5"
        duration="1"
      />
      <FireworkHolder
        color="#B9FFCA"
        delay="0.8"
        percentageLeft="65%"
        percentageTop="-30%"
        initialScale="0.9"
        duration="1"
      />
      <FireworkHolder
        color="#7980FA"
        delay="0.5"
        percentageLeft="75%"
        percentageTop="40%"
        initialScale="0.7"
        duration="0.7"
      />
      <FireworkHolder
        color="#7980FA"
        delay="0.5"
        percentageLeft="35%"
        percentageTop="60%"
        mobilePercentageTop="35%"
        initialScale="0.4"
        duration="0.4"
      />
    </Holder>
  );
}

Fireworks.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default Fireworks;

