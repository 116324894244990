import React from 'react';
import TableHolder from "../shared-models/holders/TableHolder";
import HelpersHolder from "../shared-models/holders/HelpersHolder";
import useTrigger from "../../hooks/useTrigger";
import SpeechBubbleHolder from "../shared-models/holders/SpeechBubbleHolder";
import LockboxPileHolder from './holders/LockboxPileHolder';
import Label from "../Label";
import {text} from "../../data/ipa-data/text";
import {somethingVerySmall} from "../../helpers/scrollHelpers";

function IpaHelperGroup() {
  const show = useTrigger(text[9].adjustedIn, text[16].adjustedIn - somethingVerySmall);
  const showLabel = useTrigger(text[15].adjustedIn, text[16].adjustedIn - somethingVerySmall);

  return (
    <group position={[0,-2.4,0]} scale={0.2} visible={show}>
      <TableHolder position={[0, -0.2, 0]} shadowPosition={0.2}/>

      <LockboxPileHolder 
        scale={1.5}
        position={[0, 0.8, 0.5]}
        land={text[12].adjustedIn}
        out={text[14].adjustedIn}
        color="blue"
        />
      <LockboxPileHolder
        scale={1.5}
        position={[0.3, 0.8, -0.2]}
        land={text[12].adjustedIn}
        out={text[14].adjustedIn}
        color="green"
      />
      <LockboxPileHolder
        scale={1.5}
        position={[-0.3, 0.8, -0.2]}
        land={text[12].adjustedIn}
        out={text[14].adjustedIn}
        color="pink"
      />

      <HelpersHolder
        position={[0.8, 0, 0.8]}
        rotation={[0, -Math.PI * 0.65, 0]}
        show={show}
        popsIn={text[12].adjustedIn}
        color="green"
      />
      <HelpersHolder
        position={[0, 0, -1]}
        rotation={[0, 0, 0]}
        show={show}
        popsIn={text[12].adjustedIn}
        color="pink"
      />
      <HelpersHolder
        position={[-0.8, 0, 0.69]}
        rotation={[0, 2.5, 0]}
        show={show}
        popsIn={text[12].adjustedIn}
        color="blue"
      />
      <SpeechBubbleHolder
        number={9}
        position={[0, 2, 0]}
        show={show}
        scale={16}
        in={text[15].adjustedIn - somethingVerySmall}
        color="blue2"
      />

      <Label
        position={[0, 2.2, 0]}
        labelPosition="bottom"
        show={showLabel}
        fullWidthLabel={true}
        targetWidth={200}
        targetHeight={200}
        text="9 pizzas sold due to the ads!" />
    </group>
  )
}

export default IpaHelperGroup;