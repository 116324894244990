import React, {useMemo, useRef} from "react";
import { useAnimations, useGLTF } from "@react-three/drei";
import PropTypes from "prop-types";
import useTriggerAnimation from "../../../hooks/useTriggerAnimation";
import useSetFirstAnimationFrame from "../../../hooks/useSetFirstAnimationFrame";
import IpaEndIcon3 from "../models/IpaEndIcon3";
import useNewMaterials from "../../../hooks/useNewMaterials";

export default function IpaEndIconThreeHolder({ ...props }) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(
    "/SceneIPA/ipa-end-icon-3-transformed.glb"
  );
  const specificMaterials = useMemo(() => [
    {name: 'phong_4', color: 'pink1' },
    {name: 'phong_1', color: 'pink2' },
  ], [])
  const newMaterials = useNewMaterials(materials, {
    baseMaterial: 'pink4',
    specificMaterials: specificMaterials,
  });
  const { actions } = useAnimations(animations, group);

  useSetFirstAnimationFrame('appear', actions);
  useTriggerAnimation(props.in, "appear", actions);

  return (
    <group
      ref={group}
      position={props.position}
      dispose={null}
      visible={props.show}
      scale={props.scale}
    >
      <IpaEndIcon3 materials={newMaterials} nodes={nodes} />
    </group>
  );
}

IpaEndIconThreeHolder.propTypes = {
  show: PropTypes.bool,
  in: PropTypes.number,
  scale: PropTypes.number,
  position: PropTypes.array,
};

IpaEndIconThreeHolder.defaultProps = {
  show: true,
  scale: null,
  position: null,
};

useGLTF.preload("/SceneIPA/ipa-end-icon-3-transformed.glb");

