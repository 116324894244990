import React from 'react';
import useTrigger from "../../hooks/useTrigger";
import DiceHolder from "../shared-models/holders/DiceHolder";
import {text} from "../../data/secret-data/text";

function DiceGroupOne() {
  const show = useTrigger(text[8].adjustedIn, text[17].adjustedIn);
  const showDice1 = useTrigger(text[8].adjustedIn, text[17].adjustedIn);

  return (
    <group position={[0.25, 0.4325, 4.98]} rotation={[0, Math.PI / 5, 0]} visible={show}>

      <DiceHolder
        scale={1.2}
        show={showDice1}
        in={text[9].adjustedIn}
        roll6={text[10].adjustedIn}
      />

    </group>
  )
}

export default DiceGroupOne;