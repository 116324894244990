import React, { useState } from "react";
import styled from "styled-components";
import NavigationLink from "./NavigationLink";
import { ReactComponent as ChevronDown } from "../../assets/svg/chevron-down.inline.svg";

const Card = styled.div`
  padding: 8px;
  border-radius: 16px;
  background: white;
`;

const CardHeader = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 52px 1fr 112px 168px;
  grid-gap: 16px;
  align-items: center;

  h2 {
    font-size: 18px;
  }

  a {
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
  }

  .description-toggle {
    position: relative;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 600;

    &:hover {
      background: transparent;
      text-decoration: underline;
    }

    svg {
      flex-shrink: 0;
      width: 12px;
      transition: 0.3s ease;
    }

    &.expanded {
      svg {
        transform: rotate(180deg);
      }
    }
  }
`;

const CardIcon = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  padding: 12px;
  border-radius: 12px;
  background: var(--green);

  svg {
    width: 100%;
    height: 100%;
  }
`;

const CardBody = styled.div`
  max-height: 0;
  overflow: hidden;
  font-size: 14px;
  transition: 0.3s ease-in-out;

  > p {
    margin: 0;
    padding: 16px 0 8px 72px;
  }

  &.expanded {
    max-height: 10em;
  }
`;

function HomeMenuCard({ description, heading, Icon, path }) {
  const [isExpanded, setIsExpanded] = useState(null);

  return (
    <Card key={path}>
      <CardHeader>
        <CardIcon>
          <Icon />
        </CardIcon>

        <h2>{heading}</h2>
        <button
          className={`description-toggle ${isExpanded && "expanded"}`}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          Description
          <ChevronDown />
        </button>
        <NavigationLink path={path} />
      </CardHeader>

      <CardBody className={isExpanded && "expanded"}>
        <p>{description}</p>
      </CardBody>
    </Card>
  );
}

export default HomeMenuCard;

