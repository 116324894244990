import React from 'react';
import useTrigger from "../../hooks/useTrigger";
import {text} from "../../data/secret-data/text";
import ShareHolder from "../shared-models/holders/ShareHolder";
import {Shadow} from "@react-three/drei";
import {config, useSpring, animated} from "@react-spring/three";
import {somethingVerySmall} from "../../helpers/scrollHelpers";

function SharesGroupOne() {
  const show = useTrigger(text[22].adjustedIn, text[38].adjustedIn);
  const showShadows = useTrigger(text[23].adjustedIn - somethingVerySmall, text[26].adjustedIn - somethingVerySmall);
  const moveUp = useTrigger(text[28].adjustedIn - somethingVerySmall);

  const { groupPosition } = useSpring({
    groupPosition: moveUp ? [0.266, 0.47, 4.96] : [0.266, 0.425, 4.96],
    config: config.slow
  })

  return (
      <animated.group
        position={groupPosition}
        rotation={[0, Math.PI / 5, 0]}
        visible={show}>
        <ShareHolder scale={0.2} position={[-0.06, 0, 0]} number={6} color={'green1'}/>
        <ShareHolder scale={0.2} position={[-0.02, 0, 0]} number={3} color={'green1'}/>
        <ShareHolder scale={0.2} position={[0.02, 0, 0]} number={7} color={'green1'}/>
        <ShareHolder scale={0.2} position={[0.06, 0, 0]} number={1} color={'green1'}/>
        <group visible={showShadows}>
          <Shadow color="purple" position={[-0.06,-0.035,0]} opacity={0.1} scale={[0.045, 0.06, 0]}/>
          <Shadow color="purple" position={[-0.02,-0.035,0]} opacity={0.1} scale={[0.045, 0.06, 0]}/>
          <Shadow color="purple" position={[0.02,-0.035,0]} opacity={0.1} scale={[0.045, 0.06, 0]}/>
          <Shadow color="purple" position={[0.06,-0.035,0]} opacity={0.1} scale={[0.045, 0.06, 0]}/>
        </group>
      </animated.group>
  )
}

export default SharesGroupOne;