import React from "react";
import BikeLockHolder from "./holders/BikeLockHolder";
import CityHolder from "../shared-models/holders/CityHolder";
import BikeHolder from "./holders/BikeHolder";
import DiceGroupOne from "./DiceGroupOne";
import LabelsGroup from "./LabelsGroup";
import BuildingsHolder from "../shared-models/holders/BuildingsHolder";
import DiceGroupTwo from "./DiceGroupTwo";
import SharesGroupOne from "./SharesGroupOne";
import DiceGroupThree from "./DiceGroupThree";
import SharesGroupTwo from "./SharesGroupTwo";
import IconGroup from "./IconGroup";
import ReferenceNumbersGroup from "./ReferenceNumbersGroup";
import useTrigger from "../../hooks/useTrigger";
import {text} from "../../data/secret-data/text";
import {somethingVerySmall} from "../../helpers/scrollHelpers";

// import MaterialsReference from "./holders/MaterialsReference";

function SecretScene() {
  const hide = useTrigger(text[23].adjustedIn - somethingVerySmall, text[26].adjustedIn - somethingVerySmall);

  return (
    <group>
      <CityHolder position={[0, 0, 4.9]} hide={hide}/>
      <BuildingsHolder position={[0, 0, 4.9]} hide={hide}/>
      <BikeHolder/>
      <BikeLockHolder/>
      <DiceGroupOne/>
      <DiceGroupTwo/>
      <SharesGroupOne/>
      <DiceGroupThree/>
      <SharesGroupTwo/>
      <LabelsGroup/>
      <IconGroup/>
      <ReferenceNumbersGroup/>
      {/*<MaterialsReference/>*/}
    </group>
  );
}

export default SecretScene;

