import React, {Suspense} from 'react';
import PropTypes from "prop-types";
import {Loader} from "@react-three/drei";
import {Canvas} from "@react-three/fiber";
import Rig from "./Rig";
import {Perf} from "r3f-perf";

function CustomCanvas(props) {
  return (
    <>
      <Canvas
        camera={{
          far: 300,
          near: 0.01,
          fov: 30,
          position: props.cameraKeyframes[0].position,
          rotation: props.cameraKeyframes[0].rotation,
        }}
        flat={true}
      >
        <Suspense fallback={null}>
          {props.debug && <Perf position="top-left" />}
          <ambientLight intensity={1} />
          <Rig cameraKeyframes={props.cameraKeyframes} progressName={props.progressName} />
          {props.children}
        </Suspense>
      </Canvas>
      <Loader
        containerStyles={{
          background: '#e2cffc',
        }}
        innerStyles={{
          background: '#ffffff',
        }}
        barStyles={{
          background: '#003a71',
        }}
        dataStyles={{
          color: '#003a71',
        }}
      />
    </>
  )
}

CustomCanvas.propTypes = {
  cameraKeyframes: PropTypes.array.isRequired,
  progressName: PropTypes.string.isRequired,
  debug: PropTypes.bool,
};

export default CustomCanvas;