import React, {useRef} from 'react'
import {IconDiceHolder} from "./holders/IconDiceHolder";
import {IconSpinnerHolder} from "./holders/IconSpinnerHolder";
import useTrigger from "../../hooks/useTrigger";
import {text} from "../../data/intro-data/text";
import { IconBikeLockHolder } from './holders/IconBikeLockHolder';



const gap = 2;
const xColumns = 11;
const yRows = 5;

const PixelVoidGroup = () => {

  const group = useRef()

  const hide = useTrigger(text[4].in)


  return (
    <group ref={group} position={[-10, 6, -1]} visible={!hide}>
      {Array.from({length: yRows}).map((_, yi) =>
        <group key={yi} position={[0, yi * gap, 0]}>
          {Array.from({length: xColumns}).map((_, xi) => {
            // return the IconDiceHolder first, then the IconPineappleHolder, then the IconSpinnerHolder and then repeat that pattern for the rest of the rows
            if (xi % 3 === yi % 3 - 1 || xi % 3 === 2 + yi % 3) {
              return (
                <IconDiceHolder
                  key={xi}
                  scale={25}
                  position={[xi * gap, 0, 0,]}
                />
              )
            } else if (xi % 3 === yi % 3) {
              return (
                <IconBikeLockHolder
                  key={xi}
                  scale={25}
                  hide={yi === 2 && xi === 5}
                  position={[xi * gap, 0, 0,]}
                />
              )
            } else if (xi % 3 === (yi + 1) % 3) {
              return (
                <IconSpinnerHolder
                  key={xi}
                  scale={25}
                  position={[xi * gap, 0, 0,]}
                />
              )
            } else {
              return null
            }

          })}
        </group>
      )}
    </group>
  )
}

export default PixelVoidGroup