const myText = [
  { text: "In our proposal, each device makes up a random number." },
  { text: "This number is stored on the device, and never leaves." },
  {
    text: "This number is never shared with apps or websites. It's not even shared with the company that makes the device.",
  },
  { text: "But when people see an ad..." },
  { text: "Or make a purchase..." },
  {
    text: '...their browser or device can use a technique called "Secret Sharing" to split the number into three random looking pieces. This helps keep the number secret. In the next section, we will explain "Secret Sharing" in a lot more detail.',
  },
  {
    text: 'To do any kind of processing on "Secret Shared" data like this, we will need three "helper parties". Encryption is used to make sure each "helper party" can only decrypt two of the three pieces.',
  },
  {
    text: (
      <p>
        Apps and Websites won't be able to decrypt <b>any</b> of the pieces.
      </p>
    ),
  },
  {
    text: 'Since the browser or device uses a different "secret-sharing" for every app/website, these encrypted values cannot be used to track people across apps/websites.',
  },
  {
    text: "IPA can help advertisers count the total number of purchases that happened as a result of an ad campaign.",
  },
  {
    text: 'One website sends the "helper parties" encrypted reports for all the people that were shown ads.',
  },
  {
    text: 'And one website sends the "helper parties" encrypted reports for all the people that made a purchase.',
  },
  {
    text: 'The first thing the "helper parties" will do is decrypt all the pieces they can. Each "helper party" never learns any of the random device IDs because they can only decrypt two out of three pieces of each. We will talk more about this in the next section on "Secret Sharing".',
  },
  {
    text: 'With the help of the three "helper parties", we can perform calculations on secret-shared data. This kind of processing is known as "Secure Multiparty Computation" or MPC.',
  },
  {
    text: 'The IPA protocol requires the helpers to add and multiply a lot of "secret-shared" numbers together. In the next few sections, we will explain how both "addition" and "multiplication" on secret-shared numbers works.',
  },
  {
    text: "At the end of the IPA protocol, the helpers have a count of how many of those purchases came from people who previously saw the ad.",
  },
  {
    text: "That way, we can get an accurate total count, without learning what each person did.",
    celebration: true,
  },
  { text: "And the helpers don’t learn anything either.", celebration: true },
  {
    text: "And businesses don't need to learn about what apps / websites specific people visited.", celebration: true
  },
  {
    text: 'This is a high-level description of how IPA works. We left out a lot of the details. Next, you can continue on to the next section about "Secret Sharing", or follow the links to "Additional Resources" that will go into IPA in more detail.'
  },
];

export const interval = 1 / myText.length;

export const text = myText.map((item, index) => {
  return {
    in: index * interval,
    adjustedIn: (index + 1) * interval, // adjustedIn accounts for the opening screen having no text pop-up.
    sceneAnchor: index,
    ...item,
  };
});
