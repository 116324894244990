import React from "react";

export default function PineappleTotal({ nodes, materials }) {
  return (
    <group>
        <group name="pineapple_total_adition" position={[0.223914, -7.314021, 0]}>
          <group name="card_4">
            <mesh name="speech_bubble_number_plane4" geometry={nodes.speech_bubble_number_plane4.geometry} material={materials.number_material_4} position={[-8.95026, 6.91773, 0.143315]} scale={1.004572} />
            <mesh name="card1" geometry={nodes.card1.geometry} material={materials.phong_cards} position={[-11.931136, 6.981863, 0]} scale={[313.38916, 313.38916, 46.837257]} />
          </group>
          <group name="card_9">
            <mesh name="speech_bubble_number_plane9" geometry={nodes.speech_bubble_number_plane9.geometry} material={materials.number_material_9} position={[-3.763133, 6.91773, 0.143315]} scale={1.004572} />
            <mesh name="card2" geometry={nodes.card2.geometry} material={materials.phong_cards} position={[-6.748762, 6.981863, 0]} scale={[313.38916, 313.38916, 46.837257]} />
          </group>
          <group name="card_5">
            <mesh name="speech_bubble_number_plane3" geometry={nodes.speech_bubble_number_plane3.geometry} material={materials.number_material_3} position={[1.843067, 6.91773, 0.143315]} scale={1.004572} />
            <mesh name="card3" geometry={nodes.card3.geometry} material={materials.phong_cards} position={[-1.176238, 6.981863, 0]} scale={[313.38916, 313.38916, 46.837257]} />
          </group>
          <group name="card_6">
            <mesh name="card4" geometry={nodes.card4.geometry} material={materials.phong_cards} position={[5.28655, 6.981863, 0]} scale={[313.38916, 313.38916, 46.837257]} />
            <mesh name="speech_bubble_number_plane6" geometry={nodes.speech_bubble_number_plane6.geometry} material={materials.number_material_1} position={[7.657447, 6.917058, 0.143315]} scale={1.004572} />
            <mesh name="speech_bubble_number_plane10" geometry={nodes.speech_bubble_number_plane10.geometry} material={materials.number_material_6} position={[8.951507, 6.917058, 0.143315]} scale={1.004572} />
          </group>
          <group name="Transforms_equation_spinner_grp" position={[11.321382, 7.314021, 0]} scale={6.762186}>
            <group name="equation_spinner_grp" rotation={[2.513274, 0, 0]}>
              <group name="group1">
                <group name="group12">
                  <mesh name="typeMesh_0" geometry={nodes.typeMesh_0.geometry} material={materials.typeBlinn} position={[-0.008129, -0.079434, 0.335158]} />
                  <mesh name="typeMesh_1" geometry={nodes.typeMesh_1.geometry} material={materials.typeBlinn} position={[-0.008129, -0.261264, 0.224459]} rotation={[Math.PI / 5, 0, 0]} />
                  <mesh name="typeMesh_2" geometry={nodes.typeMesh_2.geometry} material={materials.typeBlinn} position={[-0.008129, -0.343301, 0.028024]} rotation={[1.256637, 0, 0]} />
                  <mesh name="typeMesh_3" geometry={nodes.typeMesh_3.geometry} material={materials.typeBlinn} position={[-0.008129, -0.294208, -0.179115]} rotation={[1.884956, 0, 0]} />
                  <mesh name="typeMesh_4" geometry={nodes.typeMesh_4.geometry} material={materials.typeBlinn} position={[-0.008129, -0.132738, -0.317839]} rotation={[2.513274, 0, 0]} />
                  <mesh name="typeMesh_5" geometry={nodes.typeMesh_5.geometry} material={materials.typeBlinn} position={[-0.008129, 0.079434, -0.335158]} rotation={[Math.PI, 0, 0]} />
                  <mesh name="typeMesh_6" geometry={nodes.typeMesh_6.geometry} material={materials.typeBlinn} position={[-0.008129, 0.261264, -0.224459]} rotation={[-2.513274, 0, 0]} />
                  <mesh name="typeMesh_7" geometry={nodes.typeMesh_7.geometry} material={materials.typeBlinn} position={[-0.008129, 0.343301, -0.028024]} rotation={[-1.884956, 0, 0]} />
                  <mesh name="typeMesh_8" geometry={nodes.typeMesh_8.geometry} material={materials.typeBlinn} position={[-0.008129, 0.294208, 0.179115]} rotation={[-1.256637, 0, 0]} />
                  <mesh name="typeMesh_9" geometry={nodes.typeMesh_9.geometry} material={materials.typeBlinn} position={[-0.008129, 0.132738, 0.317839]} rotation={[-Math.PI / 5, 0, 0]} />
                </group>
                <mesh name="pCylinder1" geometry={nodes.pCylinder1.geometry} material={materials.phong1} />
                <mesh name="pCylinder2" geometry={nodes.pCylinder2.geometry} material={materials.phong1} rotation={[0, 0, Math.PI / 2]} scale={[32.340168, 13.574957, 32.340168]} />
              </group>
            </group>
          </group>
          <mesh name="plus_icon" geometry={nodes.plus_icon.geometry} material={materials.phong2_plus_and_equal_signs} />
          <mesh name="plus_icon_2" geometry={nodes.plus_icon_2.geometry} material={materials.phong2_plus_and_equal_signs} />
          <mesh name="equal_sign_mesh1" geometry={nodes.equal_sign_mesh1.geometry} material={materials.phong2_plus_and_equal_signs} />
          <mesh name="equal_sign_mesh2" geometry={nodes.equal_sign_mesh2.geometry} material={materials.phong2_plus_and_equal_signs} position={[6.352749, 0, 0]} />
        </group>
      </group>
  );
}

