/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useEffect, useMemo, useRef, useState} from "react";
import {useGLTF} from "@react-three/drei";
import PropTypes from "prop-types";
import Spinner from "../models/Spinner";
import * as THREE from 'three'
import {useFrame} from '@react-three/fiber';
import useNewMaterials from "../../../hooks/useNewMaterials";


export default function SpinnerHolder({...props}) {
  const group = useRef();
  const {nodes, materials} = useGLTF(
    "/GlobalModels/spinner-transformed.glb"
  );

  const specificMaterials = useMemo(() => [
    {name: 'typeBlinn', replaceWith: `typeBlinn`, setMaterialColor: 0xffffff}
  ], []);
  const newMaterials = useNewMaterials(materials, {
    baseMaterial: props.color,
    specificMaterials: specificMaterials,
  });

  const [spinnerRotation, setSpinnerRotation] = useState(0);
  const rotationIncrement = 2 * Math.PI / 10;

  useEffect(() => {
    if (props.multipleMoves && props.multipleMoves.length > 0) {
      let totalRotation = 0;
      props.multipleMoves.forEach(move => {
        if (move.start) {
          totalRotation += move.direction ? -rotationIncrement * move.numberOfTicks : rotationIncrement * move.numberOfTicks;
        }
      });
      setSpinnerRotation(totalRotation);

    } else if (props.spin) {
      if (props.direction) {
        setSpinnerRotation(-rotationIncrement * props.numberOfTicks)
      } else {
        setSpinnerRotation(rotationIncrement * props.numberOfTicks)
      }
    } else {
      setSpinnerRotation(0)
    }
  }, [props.spin, props.numberOfTicks, props.direction, spinnerRotation, rotationIncrement, props.multipleMoves]);


  useFrame((state, delta) => {
    group.current.rotation.x = THREE.MathUtils.lerp(group.current.rotation.x, spinnerRotation, delta * 3)
  });


  return (
    <group
      ref={group}
      position={props.position}
      dispose={null}
      visible={props.show}
      scale={props.scale}
    >
      <Spinner nodes={nodes} materials={newMaterials} />
    </group>
  );
}

SpinnerHolder.propTypes = {
  show: PropTypes.bool,
  spin: PropTypes.bool,
  numberOfTicks: PropTypes.number,
  direction: PropTypes.bool.isRequired,
  scale: PropTypes.number,
  position: PropTypes.array,
  multipleMoves: PropTypes.array,
  color: PropTypes.string,
};

SpinnerHolder.defaultProps = {
  show: true,
  spin: false,
  numberOfTicks: null,
  direction: false,
  scale: null,
  position: [0,0,0],
  color: 'blue4',
};

useGLTF.preload("/GlobalModels/spinner-transformed.glb");

