/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React from "react";

export default function Dice({materials, nodes}) {
  return (
    <group>
        <group name="nurbsCircle1" rotation={[0.577704, 0, 0]}>
          <group name="dice1">
            <mesh name="dice1_1" geometry={nodes.dice1_1.geometry} material={materials.numbers_material} />
            <mesh name="dice1_2" geometry={nodes.dice1_2.geometry} material={materials.aiStandardSurface1} />
          </group>
        </group>
      </group>
  );
}

