import React from 'react';
import useTrigger from "../../hooks/useTrigger";
import Label from "../Label";
import {text} from "../../data/secret-data/text";
import {somethingVerySmall} from "../../helpers/scrollHelpers";
import useIsMobile from '../../hooks/useIsMobile';

const fullCodeProps = {
  labelPosition: 'top',
  labelWidth: 150,
  targetHeight: 130,
  targetWidth: 400,
}

const singleDigitCodeProps = {
  labelPosition: 'bottom',
  fullWidthLabel: true,
  targetHeight: 100,
  targetWidth: 100,
}

const setProps = {
  labelPosition: 'left',
  labelWidth: 150,
  targetHeight: 110,
  targetWidth: 620,
}

const cardSetProps = {
  labelPosition: 'top',
  labelWidth: 150,
  targetHeight: 150,
  targetWidth: 620,
  mobileSize: 0.55,
}

function LabelsGroup() {
  const showSecureCode = useTrigger(text[2].adjustedIn - somethingVerySmall, text[4].adjustedIn)
  const showRandomCode = useTrigger(text[4].adjustedIn, text[6].adjustedIn)
  const showSecureCode2 = useTrigger(text[7].adjustedIn, text[8].adjustedIn - somethingVerySmall)
  const showFocus = useTrigger(text[8].adjustedIn, text[9].adjustedIn)
  const showRandomNumber = useTrigger(text[12].adjustedIn - somethingVerySmall, text[13].adjustedIn - somethingVerySmall)
  const showSecretShare = useTrigger(text[13].adjustedIn, text[16].adjustedIn - somethingVerySmall)
  const showSecureCode3 = useTrigger(text[17].adjustedIn - somethingVerySmall, text[18].adjustedIn)

  const showSet1 = useTrigger(text[19].adjustedIn + somethingVerySmall, text[20].adjustedIn)
  const showSecureCode4 = useTrigger(text[21].adjustedIn, text[22].adjustedIn)

  const showSet1B = useTrigger(text[25].adjustedIn, text[26].adjustedIn)
  const showSet1and2 = useTrigger(text[26].adjustedIn, text[28].adjustedIn - somethingVerySmall)
  const secondShowSet1and2 = useTrigger(text[32].adjustedIn, text[37].adjustedIn - somethingVerySmall)
  const showSet3 = useTrigger(text[33].adjustedIn - somethingVerySmall, text[37].adjustedIn - somethingVerySmall)
  const set1Tick = useTrigger(text[35].adjustedIn, text[36].adjustedIn)
  const set2Cross = useTrigger(text[35].adjustedIn, text[36].adjustedIn - somethingVerySmall)
  const set2Tick = useTrigger(text[36].adjustedIn - somethingVerySmall, text[36].adjustedIn)
  const set3Tick = useTrigger(text[35].adjustedIn, text[36].adjustedIn)
  const showSecretShare1 = useTrigger(text[37].adjustedIn, text[37].adjustedIn + somethingVerySmall)
  const showSecretShare2 = useTrigger(text[37].adjustedIn, text[37].adjustedIn + somethingVerySmall)
  const showSecretShare3 = useTrigger(text[37].adjustedIn, text[37].adjustedIn + somethingVerySmall)

  const {isDesktop} = useIsMobile();
  const secretShareOnePosition = isDesktop ? [0.175, 0.442, 4.90] : [0.175, 0.435, 4.903];

  return (
    <group>

      <group position={[0.215, 0.363, 4.9]} >
        <Label
          show={showSecureCode}
          {...fullCodeProps}
          text="This is a secure code" />
        <Label
          show={showRandomCode}
          {...fullCodeProps}
          text="This is a random number" />
        <Label
          show={showSecureCode2}
          {...fullCodeProps}
          text="We return to the secure code" />
        <Label show={showSecureCode4} {...fullCodeProps} lock={true}/>
        <Label show={showSet1and2} {...fullCodeProps} labelPosition='left' text="Set 2"  tick={true}/>
        <Label show={showSet3} {...fullCodeProps} text="Set 3" labelPosition='bottom' tick={set3Tick}/>
        <Label show={showSecretShare3} {...fullCodeProps} text="Secret Share #3" labelPosition='bottom' tick={set3Tick}/>
      </group>

      <group position={[0.175, 0.361, 4.9]}>
        <Label show={showFocus} {...singleDigitCodeProps} />
        <Label show={showRandomNumber} {...singleDigitCodeProps} text="Random number" />
        <Label show={showSecretShare} {...singleDigitCodeProps} text="Secret share 2" />
        <Label show={showSecureCode3} {...singleDigitCodeProps} tick={true}/>
      </group>

      <group position={secretShareOnePosition}>
        <Label show={showSecretShare} {...singleDigitCodeProps} text="Secret share 1" />
        <Label show={showRandomNumber} {...singleDigitCodeProps} text="Random number" />
      </group>

      <group position={[0.256, 0.425, 4.95]}
             rotation={[0, Math.PI / 5, 0]}>
        <Label show={showSet1} {...setProps} text="Set 1" />
        <Label show={showSet1B} {...cardSetProps} text="Set 1" />
        <Label show={showSet1and2} {...cardSetProps} text="Set 1" tick={true}/>
        <Label show={secondShowSet1and2} {...cardSetProps} text="Set 2" labelPosition='bottom' tick={set2Tick} cross={set2Cross}/>
        <Label show={showSecretShare2} {...cardSetProps} text="Secret Share #2" labelPosition='bottom' tick={set2Tick}/>
      </group>

      <group position={[0.256, 0.472, 4.95]}
             rotation={[0, Math.PI / 5, 0]}>
        <Label show={secondShowSet1and2} {...cardSetProps} text="Set 1" tick={set1Tick}/>
        <Label show={showSecretShare1} {...cardSetProps} text="Secret Share #1" tick={set1Tick}/>

      </group>

    </group>
  )
}

export default LabelsGroup;