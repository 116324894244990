/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import {useGLTF, useAnimations, Shadow} from '@react-three/drei'
import City from "../models/City";
import useTriggerAnimation from "../../../hooks/useTriggerAnimation";
import useNewMaterials from "../../../hooks/useNewMaterials";
import PropTypes from "prop-types";
import {text} from "../../../data/secret-data/text";

export default function CityHolder( {...props} ) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/GlobalModels/city-transformed.glb')
  const { actions } = useAnimations(animations, group)
  const newMaterials = useNewMaterials(materials)

  const cityLoopIn =  text[0].adjustedIn
  const cityLoopOut =  text[11].adjustedIn

  // loop animation
  useTriggerAnimation(cityLoopIn, 'city-life-loop', actions, cityLoopOut, true)
  
  return (
    <group 
      ref={group} 
      dispose={null}
      {...props}  
      visible={!(props.hide)}
      position={props.position}
    >
      <City materials={newMaterials} nodes={nodes}/>

      <Shadow color="purple" position={[-2.7,0,-0.1]} opacity={0.15} scale={2}/>
      <Shadow color="purple" position={[2.5,0,-0.1]} opacity={0.15} scale={2}/>
      <Shadow color="purple" position={[-1.2,0,1.4]} opacity={0.15} scale={1.5}/>

      <Shadow color="purple" position={[0.64,0,1]} opacity={0.1} scale={0.2}/>
      <Shadow color="purple" position={[1.16,0,1]} opacity={0.1} scale={0.2}/>

      <Shadow color="purple" position={[-0.35,0,-0.89]} opacity={0.1} scale={0.2}/>
      <Shadow color="purple" position={[0.1,0,-0.89]} opacity={0.1} scale={0.2}/>

      <Shadow color="purple" position={[-1.6,0,-0.6]} opacity={0.15} scale={[1.2, 0.75, 0]}/>
      <Shadow color="purple" position={[-1.6,0,-1.6]} opacity={0.15} scale={[1.2, 0.75, 0]}/>

      <Shadow color="purple" position={[1.5,0,1.7]} opacity={0.1} scale={[2.5, 0.5, 1]}/>
      <Shadow color="purple" position={[2.8,0,1.9]} rotation={[0,0.1,0]} opacity={0.1} scale={[2.5, 0.5, 1]}/>
      <Shadow color="purple" position={[4,0,2.1]} opacity={0.1} scale={[2.5, 0.5, 1]}/>

      <Shadow color="purple" position={[-10,0,-10]} opacity={0.15} scale={20}/>
      <Shadow color="purple" position={[10,0,-10]} opacity={0.15} scale={20}/>
    </group>
  )
}

CityHolder.propTypes = {
    hide: PropTypes.bool,
    position: PropTypes.array,
};

CityHolder.defaultProps = {
    hide: false,
    position: [],
};

useGLTF.preload('/GlobalModels/city-transformed.glb')
