import React from 'react'

const AdditionCube = ({nodes, materials}) => {
  return (

    <group>
      <group name="letter_p">
        <group name="icon_addition_p" position={[2.870079, -0.094736, 40.87821]} scale={0}>
          <mesh name="ipa_building_block_secret_adding2" geometry={nodes.ipa_building_block_secret_adding2.geometry} material={materials.phong_7} position={[1.534036, -920.9381, -91.26264]} />
          <mesh name="ipa_building_block_secret_adding5" geometry={nodes.ipa_building_block_secret_adding5.geometry} material={materials.phong_7} position={[0, -921.9219, -91.26264]} />
          <mesh name="ipa_building_block_secret_adding3" geometry={nodes.ipa_building_block_secret_adding3.geometry} material={materials.phong_7} position={[0, -920.0035, -91.26264]} />
          <mesh name="ipa_building_block_secret_adding4" geometry={nodes.ipa_building_block_secret_adding4.geometry} material={materials.phong_7} position={[-1.717521, -920.9381, -91.26264]} />
          <mesh name="ipa_building_block_secret_adding1" geometry={nodes.ipa_building_block_secret_adding1.geometry} material={materials.phong_7} position={[0, -920.9381, -91.26264]} />
        </group>
        <group name="cubies">
          <group name="letter_p_pieces">
            <mesh name="expanding_pCube11" geometry={nodes.expanding_pCube11.geometry} material={materials.phong_5} scale={0.901978} />
            <mesh name="pCube6" geometry={nodes.pCube6.geometry} material={materials.phong_5} />
            <mesh name="pCube7" geometry={nodes.pCube7.geometry} material={materials.phong_5} />
            <mesh name="pCube10" geometry={nodes.pCube10.geometry} material={materials.phong_5} />
            <mesh name="pCube12" geometry={nodes.pCube12.geometry} material={materials.phong_5} />
            <mesh name="pCube13" geometry={nodes.pCube13.geometry} material={materials.phong_5} />
            <mesh name="pCube15" geometry={nodes.pCube15.geometry} material={materials.phong_5} />
            <mesh name="pCube16" geometry={nodes.pCube16.geometry} material={materials.phong_5} />
            <mesh name="pCube17" geometry={nodes.pCube17.geometry} material={materials.phong_5} />
            <mesh name="pCube18" geometry={nodes.pCube18.geometry} material={materials.phong_5} />
            <mesh name="pCube77" geometry={nodes.pCube77.geometry} material={materials.phong_5} />
          </group>
          <group name="cube_p">
            <mesh name="pCube56" geometry={nodes.pCube56.geometry} material={materials.phong_5}>
              <mesh name="pCube71" geometry={nodes.pCube71.geometry} material={materials.phong_5}>
                <mesh name="pCube67" geometry={nodes.pCube67.geometry} material={materials.phong_5} />
              </mesh>
            </mesh>
            <mesh name="pCube60" geometry={nodes.pCube60.geometry} material={materials.phong_5} />
            <mesh name="pCube61" geometry={nodes.pCube61.geometry} material={materials.phong_5} />
            <mesh name="pCube62" geometry={nodes.pCube62.geometry} material={materials.phong_5} />
            <mesh name="pCube63" geometry={nodes.pCube63.geometry} material={materials.phong_5} />
            <mesh name="pCube64" geometry={nodes.pCube64.geometry} material={materials.phong_5} />
            <mesh name="pCube65" geometry={nodes.pCube65.geometry} material={materials.phong_5} />
            <mesh name="pCube66" geometry={nodes.pCube66.geometry} material={materials.phong_5} />
            <mesh name="pCube68" geometry={nodes.pCube68.geometry} material={materials.phong_5} />
            <mesh name="pCube75" geometry={nodes.pCube75.geometry} material={materials.phong_5}>
              <mesh name="pCube59" geometry={nodes.pCube59.geometry} material={materials.phong_5}>
                <mesh name="pCube69" geometry={nodes.pCube69.geometry} material={materials.phong_5} />
              </mesh>
            </mesh>
            <mesh name="pCube76" geometry={nodes.pCube76.geometry} material={materials.phong_5} />
          </group>
        </group>
      </group>
      <group name="grp_pineapple" position={[2.029809, 0, 42.51995]} scale={578.1012}>
        <group name="leafs">
          <group name="leaf_grp">
            <mesh name="leaf1" geometry={nodes.leaf1.geometry} material={materials.blinn3} position={[0.011424, 0.04604, 0]} scale={0} />
          </group>
          <group name="leaf_grp1" rotation={[0, Math.PI / 3, 0]}>
            <mesh name="leaf2" geometry={nodes.leaf2.geometry} material={materials.blinn3} position={[0.011424, 0.04604, 0]} scale={0} />
          </group>
          <group name="leaf_grp2" rotation={[-Math.PI, Math.PI / 3, -Math.PI]}>
            <mesh name="leaf3" geometry={nodes.leaf3.geometry} material={materials.blinn3} position={[0.011424, 0.04604, 0]} scale={0} />
          </group>
          <group name="leaf_grp3" rotation={[-Math.PI, 0, -Math.PI]}>
            <mesh name="leaf4" geometry={nodes.leaf4.geometry} material={materials.blinn3} position={[0.011424, 0.04604, 0]} scale={0} />
          </group>
          <group name="leaf_grp4" rotation={[-Math.PI, -Math.PI / 3, -Math.PI]}>
            <mesh name="leaf5" geometry={nodes.leaf5.geometry} material={materials.blinn3} position={[0.011424, 0.04604, 0]} scale={0} />
          </group>
          <group name="leaf_grp5" rotation={[0, -Math.PI / 3, 0]}>
            <mesh name="leaf6" geometry={nodes.leaf6.geometry} material={materials.blinn3} position={[0.011424, 0.04604, 0]} scale={0} />
          </group>
        </group>
        <group name="group11">
          <mesh name="pineapple_cube121" geometry={nodes.pineapple_cube121.geometry} material={materials.pasted__pineapple_material_orange} position={[-0.401796, -0.512438, 0.389491]} rotation={[0.389837, -0.330262, 0.13246]} scale={0} />
          <mesh name="pineapple_cube100" geometry={nodes.pineapple_cube100.geometry} material={materials.pasted__pineapple_material_orange} position={[-0.687387, -2.034596, -0.18085]} rotation={[-1.115335, 0.102574, 0.206081]} scale={0} />
          <mesh name="pineapple_cube118" geometry={nodes.pineapple_cube118.geometry} material={materials.pasted__pineapple_material_orange} position={[0.159827, -1.580169, -0.27238]} rotation={[1.267174, 0.108261, -0.332094]} scale={0} />
          <mesh name="pineapple_cube130" geometry={nodes.pineapple_cube130.geometry} material={materials.pasted__pineapple_material_orange} position={[-1.442555, 0.174598, -0.351974]} rotation={[-0.367338, -1.427192, -0.363884]} scale={0} />
          <mesh name="pineapple_cube110" geometry={nodes.pineapple_cube110.geometry} material={materials.pasted__pineapple_material_orange} position={[-1.372128, -0.940748, 0.002185]} rotation={[2.249102, 1.116275, 0.839682]} scale={0} />
          <mesh name="pineapple_cube115" geometry={nodes.pineapple_cube115.geometry} material={materials.pasted__pineapple_material_orange} position={[0.272979, 1.255455, -0.094189]} rotation={[0.233996, 0.722216, -0.156285]} scale={0} />
          <mesh name="pineapple_cube108" geometry={nodes.pineapple_cube108.geometry} material={materials.pasted__pineapple_material_orange} position={[-0.286793, -0.83479, 0.057286]} rotation={[1.442386, -0.77502, 1.38832]} scale={0} />
          <mesh name="pineapple_cube129" geometry={nodes.pineapple_cube129.geometry} material={materials.pasted__pineapple_material_orange} position={[-1.654952, 1.198641, 0.354095]} rotation={[0.453115, -1.062891, 0.402242]} scale={0} />
          <mesh name="pineapple_cube128" geometry={nodes.pineapple_cube128.geometry} material={materials.pasted__pineapple_material_orange} position={[-0.91326, 1.706189, -0.26289]} rotation={[-1.798242, -0.01946, 0.083876]} scale={0} />
          <mesh name="pineapple_cube114" geometry={nodes.pineapple_cube114.geometry} material={materials.pasted__pineapple_material_orange} position={[-1.280807, -2.145131, -0.349738]} rotation={[-2.01633, -0.056668, 0.118046]} scale={0} />
          <mesh name="pineapple_cube126" geometry={nodes.pineapple_cube126.geometry} material={materials.pasted__pineapple_material_orange} position={[-1.953535, -1.599295, 0.223943]} rotation={[-1.568619, -0.006145, -1.23024]} scale={0} />
          <mesh name="pineapple_cube98" geometry={nodes.pineapple_cube98.geometry} material={materials.pasted__pineapple_material_orange} position={[0.403577, -1.102221, 0.018592]} rotation={[0.559028, -0.631759, 0.353901]} scale={0} />
          <mesh name="pineapple_cube106" geometry={nodes.pineapple_cube106.geometry} material={materials.pasted__pineapple_material_orange} position={[-1.86682, -1.75391, 0.299649]} rotation={[1.627043, -0.52811, -1.459519]} scale={0} />
          <mesh name="pineapple_cube117" geometry={nodes.pineapple_cube117.geometry} material={materials.pasted__pineapple_material_orange} position={[1.044886, 1.26954, -0.351144]} rotation={[1.412196, -1.027738, 1.386105]} scale={0} />
          <mesh name="pineapple_cube105" geometry={nodes.pineapple_cube105.geometry} material={materials.pasted__pineapple_material_orange} position={[0.660551, -2.102707, 0.037277]} rotation={[-1.678301, 0.556995, -1.369415]} scale={0} />
          <mesh name="pineapple_cube109" geometry={nodes.pineapple_cube109.geometry} material={materials.pasted__pineapple_material_orange} position={[0.837918, 1.597285, 0.11565]} rotation={[-1.815099, 0.250043, -0.781702]} scale={0} />
          <mesh name="pineapple_cube99" geometry={nodes.pineapple_cube99.geometry} material={materials.pasted__pineapple_material_orange} position={[0.581935, 1.722375, 0.195628]} rotation={[2.775693, -0.21725, -0.0824]} scale={0} />
          <mesh name="pineapple_cube107" geometry={nodes.pineapple_cube107.geometry} material={materials.pasted__pineapple_material_orange} position={[1.244626, 0.036565, 0.250354]} rotation={[2.734817, -0.499624, -0.203538]} scale={0} />
          <mesh name="pineapple_cube97" geometry={nodes.pineapple_cube97.geometry} material={materials.pasted__pineapple_material_orange} position={[0.389597, 0.627614, -0.393844]} rotation={[2.266302, 1.290262, 0.855609]} scale={0} />
          <mesh name="pineapple_cube123" geometry={nodes.pineapple_cube123.geometry} material={materials.pasted__pineapple_material_orange} position={[0.282791, -1.913377, -0.289081]} rotation={[-2.339365, -1.059852, 0.734001]} scale={0} />
          <mesh name="pineapple_cube116" geometry={nodes.pineapple_cube116.geometry} material={materials.pasted__pineapple_material_orange} position={[2.043502, -1.441155, 0.405471]} rotation={[0.169294, 0.189938, -0.03226]} scale={0} />
          <mesh name="pineapple_cube120" geometry={nodes.pineapple_cube120.geometry} material={materials.pasted__pineapple_material_orange} position={[-1.278109, -1.468529, -0.039283]} rotation={[-1.504254, 0.465091, 1.423292]} scale={0} />
          <mesh name="pineapple_cube112" geometry={nodes.pineapple_cube112.geometry} material={materials.pasted__pineapple_material_orange} position={[0.79861, -0.871887, -0.062449]} rotation={[-0.892726, -0.005568, -0.006912]} scale={0} />
          <mesh name="pineapple_cube103" geometry={nodes.pineapple_cube103.geometry} material={materials.pasted__pineapple_material_orange} position={[-0.13203, 1.430764, -0.082469]} rotation={[-1.415228, -1.110008, -1.397455]} scale={0} />
          <mesh name="pineapple_cube102" geometry={nodes.pineapple_cube102.geometry} material={materials.pasted__pineapple_material_orange} position={[-0.963226, -0.674023, 0.071247]} rotation={[-2.476856, 0.059421, -0.046509]} scale={0} />
          <mesh name="pineapple_cube125" geometry={nodes.pineapple_cube125.geometry} material={materials.pasted__pineapple_material_orange} position={[-1.241774, 1.918459, 0.296]} rotation={[-1.42062, -0.075147, -0.460566]} scale={0} />
          <mesh name="pineapple_cube127" geometry={nodes.pineapple_cube127.geometry} material={materials.pasted__pineapple_material_orange} position={[-0.164705, 1.114099, 0.152581]} rotation={[-0.926351, -1.08085, -0.865334]} scale={0} />
          <mesh name="pineapple_cube18" geometry={nodes.pineapple_cube18.geometry} material={materials.pasted__pineapple_material_orange} position={[0.90886, -0.538208, 0.130103]} rotation={[1.006336, -0.121226, 0.188714]} scale={0} />
          <mesh name="pineapple_cube19" geometry={nodes.pineapple_cube19.geometry} material={materials.pasted__pineapple_material_orange} position={[0.665792, 1.031777, 0.066029]} rotation={[-1.680492, 0.022886, -0.204857]} scale={0} />
          <mesh name="pineapple_cube54" geometry={nodes.pineapple_cube54.geometry} material={materials.pasted__pineapple_material_orange} position={[0.709742, -0.226363, 0.128112]} rotation={[0.141513, 0.419179, -0.05792]} scale={0} />
          <mesh name="pineapple_cube16" geometry={nodes.pineapple_cube16.geometry} material={materials.pasted__pineapple_material_orange} position={[0.114143, 1.930462, -0.136967]} rotation={[3.006934, -0.755999, -0.092674]} scale={0} />
          <mesh name="pineapple_cube25" geometry={nodes.pineapple_cube25.geometry} material={materials.pasted__pineapple_material_orange} position={[0.638436, -1.872112, 0.0204]} rotation={[-0.934434, -0.497353, -0.573337]} scale={0} />
          <mesh name="pineapple_cube17" geometry={nodes.pineapple_cube17.geometry} material={materials.pasted__pineapple_material_orange} position={[-1.675916, 2.321718, 0.182448]} rotation={[2.471563, -0.455236, -0.335208]} scale={0} />
          <mesh name="pineapple_cube21" geometry={nodes.pineapple_cube21.geometry} material={materials.pasted__pineapple_material_orange} position={[0.945284, 1.131824, -0.363181]} rotation={[1.976076, 0.193732, 0.421804]} scale={0} />
          <mesh name="pineapple_cube22" geometry={nodes.pineapple_cube22.geometry} material={materials.pasted__pineapple_material_orange} position={[1.590828, 1.800456, -0.133391]} rotation={[-1.665581, 0.341286, -1.294041]} scale={0} />
          <mesh name="pineapple_cube29" geometry={nodes.pineapple_cube29.geometry} material={materials.pasted__pineapple_material_orange} position={[0.774232, 2.177367, -0.278006]} rotation={[0.557398, -0.6422, 0.357322]} scale={0} />
          <mesh name="pineapple_cube59" geometry={nodes.pineapple_cube59.geometry} material={materials.pasted__pineapple_material_orange} position={[-0.272856, 0.311516, 0.218893]} rotation={[-1.243176, 0.135214, 0.377599]} scale={0} />
          <mesh name="pineapple_cube63" geometry={nodes.pineapple_cube63.geometry} material={materials.pasted__pineapple_material_orange} position={[-1.289979, -1.570792, 0.115856]} rotation={[1.482755, 0.285084, -1.266672]} scale={0} />
          <mesh name="pineapple_cube55" geometry={nodes.pineapple_cube55.geometry} material={materials.pasted__pineapple_material_orange} position={[0.374006, 1.117658, -0.402905]} rotation={[-1.611815, 1.176523, -1.526374]} scale={0} />
          <mesh name="pineapple_cube45" geometry={nodes.pineapple_cube45.geometry} material={materials.pasted__pineapple_material_orange} position={[1.2092, -0.031277, 0.229578]} rotation={[1.88212, -0.723184, -1.11821]} scale={0} />
          <mesh name="pineapple_cube50" geometry={nodes.pineapple_cube50.geometry} material={materials.pasted__pineapple_material_orange} position={[1.201809, 2.246245, -0.16606]} rotation={[-2.831774, -0.282428, 0.08898]} scale={0} />
          <mesh name="pineapple_cube65" geometry={nodes.pineapple_cube65.geometry} material={materials.pasted__pineapple_material_orange} position={[0.760744, 1.566772, 0.234864]} rotation={[1.37407, -0.473765, 1.15894]} scale={0} />
          <mesh name="pineapple_cube61" geometry={nodes.pineapple_cube61.geometry} material={materials.pasted__pineapple_material_orange} position={[1.38, 2.331296, 0.082009]} rotation={[3.10407, -0.70136, -0.024218]} scale={0} />
          <mesh name="pineapple_cube56" geometry={nodes.pineapple_cube56.geometry} material={materials.pasted__pineapple_material_orange} position={[0.12794, 1.097733, -0.24947]} rotation={[2.66644, 0.073576, 0.0378]} scale={0} />
          <mesh name="pineapple_cube44" geometry={nodes.pineapple_cube44.geometry} material={materials.pasted__pineapple_material_orange} position={[-0.402896, -1.783309, 0.148866]} rotation={[-2.115, 0.264667, -0.408007]} scale={0} />
          <mesh name="pineapple_cube40" geometry={nodes.pineapple_cube40.geometry} material={materials.pasted__pineapple_material_orange} position={[-1.618561, -1.328401, -0.316474]} rotation={[2.152877, -0.67517, -0.759596]} scale={0} />
          <mesh name="pineapple_cube20" geometry={nodes.pineapple_cube20.geometry} material={materials.pasted__pineapple_material_orange} position={[1.050778, -2.240855, 0.058311]} rotation={[-0.201952, -1.059142, -0.176662]} scale={0} />
          <mesh name="pineapple_cube24" geometry={nodes.pineapple_cube24.geometry} material={materials.pasted__pineapple_material_orange} position={[-1.365557, 1.789747, 0.058019]} rotation={[2.503739, 0.770295, 0.476477]} scale={0} />
          <mesh name="pineapple_cube60" geometry={nodes.pineapple_cube60.geometry} material={materials.pasted__pineapple_material_orange} position={[1.861291, 0.231797, 0.370805]} rotation={[-0.817312, -0.205451, -0.21413]} scale={0} />
          <mesh name="pineapple_cube38" geometry={nodes.pineapple_cube38.geometry} material={materials.pasted__pineapple_material_orange} position={[-1.852466, -1.060891, -0.264751]} rotation={[1.57478, -0.002058, -0.476781]} scale={0} />
          <mesh name="pineapple_cube31" geometry={nodes.pineapple_cube31.geometry} material={materials.pasted__pineapple_material_orange} position={[-0.922745, 0.602482, -0.144568]} rotation={[2.285246, 1.045794, 0.78426]} scale={0} />
          <mesh name="pineapple_cube39" geometry={nodes.pineapple_cube39.geometry} material={materials.pasted__pineapple_material_orange} position={[-0.286139, -0.628419, 0.046105]} rotation={[0.604913, 0.171264, -0.117289]} scale={0} />
          <mesh name="pineapple_cube58" geometry={nodes.pineapple_cube58.geometry} material={materials.pasted__pineapple_material_orange} position={[-1.268015, -2.178776, -0.071799]} rotation={[-1.710596, -0.960814, 1.400775]} scale={0} />
          <mesh name="pineapple_cube49" geometry={nodes.pineapple_cube49.geometry} material={materials.pasted__pineapple_material_orange} position={[1.045823, -1.018124, 0.23192]} rotation={[-1.172506, -0.36274, -0.700583]} scale={0} />
          <mesh name="pineapple_cube66" geometry={nodes.pineapple_cube66.geometry} material={materials.pasted__pineapple_material_orange} position={[-1.207495, 1.756801, -0.050208]} rotation={[-0.692144, 0.879723, 0.568429]} scale={0} />
          <mesh name="pineapple_cube57" geometry={nodes.pineapple_cube57.geometry} material={materials.pasted__pineapple_material_orange} position={[-0.821996, -1.992529, 0.170429]} rotation={[2.957058, 0.632009, 0.109828]} scale={0} />
          <mesh name="pineapple_cube62" geometry={nodes.pineapple_cube62.geometry} material={materials.pasted__pineapple_material_orange} position={[-1.420447, 1.148929, -0.378394]} rotation={[0.705556, 0.074478, -0.0633]} scale={0} />
          <mesh name="pineapple_cube81" geometry={nodes.pineapple_cube81.geometry} material={materials.pasted__pineapple_material_orange} position={[1.176417, -0.829694, 0.013526]} rotation={[-1.571674, -0.021204, 1.529429]} scale={0} />
          <mesh name="pineapple_cube52" geometry={nodes.pineapple_cube52.geometry} material={materials.pasted__pineapple_material_orange} position={[0.530049, -1.838831, -0.201]} rotation={[0.643285, -0.177644, 0.131707]} scale={0} />
          <mesh name="pineapple_cube92" geometry={nodes.pineapple_cube92.geometry} material={materials.pasted__pineapple_material_orange} position={[0.092033, 1.154999, -0.332789]} rotation={[2.216926, -0.1558, -0.202932]} scale={0} />
          <mesh name="pineapple_cube48" geometry={nodes.pineapple_cube48.geometry} material={materials.pasted__pineapple_material_orange} position={[-1.158801, 0.460329, 0.350093]} rotation={[1.037567, -0.050725, 0.085688]} scale={0} />
          <mesh name="pineapple_cube74" geometry={nodes.pineapple_cube74.geometry} material={materials.pasted__pineapple_material_orange} position={[-1.089208, 2.150644, 0.057007]} rotation={[1.589464, 0.21034, 1.481613]} scale={0} />
          <mesh name="pineapple_cube71" geometry={nodes.pineapple_cube71.geometry} material={materials.pasted__pineapple_material_orange} position={[-0.141953, 0.750096, 0.212983]} rotation={[1.648688, -0.134241, -1.042849]} scale={0} />
          <mesh name="pineapple_cube80" geometry={nodes.pineapple_cube80.geometry} material={materials.pasted__pineapple_material_orange} position={[0.695108, 0.905698, -0.191888]} rotation={[-0.949194, 0.066134, 0.091996]} scale={0} />
          <mesh name="pineapple_cube85" geometry={nodes.pineapple_cube85.geometry} material={materials.pasted__pineapple_material_orange} position={[1.052777, 0.897858, -0.328917]} rotation={[-1.895801, 1.215731, -1.2258]} scale={0} />
          <mesh name="pineapple_cube53" geometry={nodes.pineapple_cube53.geometry} material={materials.pasted__pineapple_material_orange} position={[-0.60225, -2.14895, 0.335857]} rotation={[2.217706, 1.016378, 0.844417]} scale={0} />
          <mesh name="pineapple_cube64" geometry={nodes.pineapple_cube64.geometry} material={materials.pasted__pineapple_material_orange} position={[1.875389, 1.489562, -0.027818]} rotation={[-1.652894, 0.489828, -1.397655]} scale={0} />
          <mesh name="pineapple_cube68" geometry={nodes.pineapple_cube68.geometry} material={materials.pasted__pineapple_material_orange} position={[0.480185, -1.188459, -0.248338]} rotation={[2.630304, -0.632266, -0.32016]} scale={0} />
          <mesh name="pineapple_cube91" geometry={nodes.pineapple_cube91.geometry} material={materials.pasted__pineapple_material_orange} position={[-1.575183, 2.331473, -0.035898]} rotation={[1.278938, -0.603931, 1.08418]} scale={0} />
          <mesh name="pineapple_cube46" geometry={nodes.pineapple_cube46.geometry} material={materials.pasted__pineapple_material_orange} position={[-0.708766, -1.496387, -0.030975]} rotation={[0.029476, -0.603001, 0.016719]} scale={0} />
          <mesh name="pineapple_cube51" geometry={nodes.pineapple_cube51.geometry} material={materials.pasted__pineapple_material_orange} position={[0.824094, -2.117039, 0.376728]} rotation={[-0.630227, 0.063962, 0.046592]} scale={0} />
          <mesh name="pineapple_cube69" geometry={nodes.pineapple_cube69.geometry} material={materials.pasted__pineapple_material_orange} position={[0.137894, -0.25489, -0.002871]} rotation={[-0.372341, -1.086252, -0.33275]} scale={0} />
          <mesh name="pineapple_cube79" geometry={nodes.pineapple_cube79.geometry} material={materials.pasted__pineapple_material_orange} position={[-0.68345, 1.518014, 0.064147]} rotation={[1.231366, 0.870127, -1.138075]} scale={0} />
          <mesh name="pineapple_cube83" geometry={nodes.pineapple_cube83.geometry} material={materials.pasted__pineapple_material_orange} position={[-1.304094, -1.308124, -0.291819]} rotation={[0.189718, -0.138852, 0.026572]} scale={0} />
          <mesh name="pineapple_cube76" geometry={nodes.pineapple_cube76.geometry} material={materials.pasted__pineapple_material_orange} position={[0.886747, 2.137292, 0.217223]} rotation={[-1.560488, -0.162215, -1.507056]} scale={0} />
          <mesh name="pineapple_cube47" geometry={nodes.pineapple_cube47.geometry} material={materials.pasted__pineapple_material_orange} position={[1.709715, -0.303501, 0.280696]} rotation={[-1.859757, -0.614416, 1.094682]} scale={0} />
          <mesh name="pineapple_cube67" geometry={nodes.pineapple_cube67.geometry} material={materials.pasted__pineapple_material_orange} position={[1.26034, 1.433333, -0.007041]} rotation={[-2.095325, 0.070362, -0.120916]} scale={0} />
          <mesh name="pineapple_cube30" geometry={nodes.pineapple_cube30.geometry} material={materials.pasted__pineapple_material_orange} position={[-0.125355, -1.291567, -0.318489]} rotation={[1.79547, -0.320844, -0.943716]} scale={0} />
          <mesh name="pineapple_cube33" geometry={nodes.pineapple_cube33.geometry} material={materials.pasted__pineapple_material_orange} position={[-0.565641, -0.481254, 0.111644]} rotation={[1.504232, 0.272156, -1.327706]} scale={0} />
          <mesh name="pineapple_cube13" geometry={nodes.pineapple_cube13.geometry} material={materials.pasted__pineapple_material_orange} position={[-1.629815, 2.090304, -0.262942]} rotation={[0.015042, 0.880191, -0.011596]} scale={0} />
          <mesh name="pineapple_cube5" geometry={nodes.pineapple_cube5.geometry} material={materials.pasted__pineapple_material_orange} position={[1.869651, -0.030039, -0.02779]} rotation={[-0.943551, -0.407401, -0.50022]} scale={0} />
          <mesh name="pineapple_cube28" geometry={nodes.pineapple_cube28.geometry} material={materials.pasted__pineapple_material_orange} position={[-0.58552, -0.465552, 0.140102]} rotation={[1.533755, 0.030204, -0.683774]} scale={0} />
          <mesh name="pineapple_cube11" geometry={nodes.pineapple_cube11.geometry} material={materials.pasted__pineapple_material_orange} position={[-1.640806, -1.042433, 0.351208]} rotation={[-1.25987, -0.142309, -0.415641]} scale={0} />
          <mesh name="pineapple_cube10" geometry={nodes.pineapple_cube10.geometry} material={materials.pasted__pineapple_material_orange} position={[1.591131, -0.558119, -0.30406]} rotation={[-2.360301, -0.303812, 0.288438]} scale={0} />
          <mesh name="pineapple_cube37" geometry={nodes.pineapple_cube37.geometry} material={materials.pasted__pineapple_material_orange} position={[1.060814, 0.092947, 0.017987]} rotation={[-1.315088, 0.006442, 0.024638]} scale={0} />
          <mesh name="pineapple_cube32" geometry={nodes.pineapple_cube32.geometry} material={materials.pasted__pineapple_material_orange} position={[-1.283746, -0.936176, -0.298192]} rotation={[2.491411, 0.583748, 0.396904]} scale={0} />
          <mesh name="pineapple_cube9" geometry={nodes.pineapple_cube9.geometry} material={materials.pasted__pineapple_material_orange} position={[-1.977604, 0.585754, -0.327544]} rotation={[0.360178, 0.140539, -0.052705]} scale={0} />
          <mesh name="pineapple_cube3" geometry={nodes.pineapple_cube3.geometry} material={materials.pasted__pineapple_material_orange} position={[-1.502822, 1.615479, 0.207404]} rotation={[1.402734, -0.496132, 1.228419]} scale={0} />
          <mesh name="pineapple_cube7" geometry={nodes.pineapple_cube7.geometry} material={materials.pasted__pineapple_material_orange} position={[-1.819764, -2.169488, -0.312662]} rotation={[-2.229934, -0.815325, 0.75428]} scale={0} />
          <mesh name="pineapple_cube1" geometry={nodes.pineapple_cube1.geometry} material={materials.pasted__pineapple_material_orange} position={[0.541223, 0.019577, -0.00433]} rotation={[2.27495, 0.647725, 0.617633]} scale={0} />
          <mesh name="pineapple_cube14" geometry={nodes.pineapple_cube14.geometry} material={materials.pasted__pineapple_material_orange} position={[-2.045966, -2.00888, 0.235954]} rotation={[-1.18009, 0.937971, 1.098554]} scale={0} />
          <mesh name="pineapple_cube36" geometry={nodes.pineapple_cube36.geometry} material={materials.pasted__pineapple_material_orange} position={[0.06817, -1.296975, -0.398827]} rotation={[-2.199764, 0.846623, -0.799966]} scale={0} />
          <mesh name="pineapple_cube41" geometry={nodes.pineapple_cube41.geometry} material={materials.pasted__pineapple_material_orange} position={[-1.834539, 1.404108, 0.351196]} rotation={[-1.648777, 0.008024, -0.102324]} scale={0} />
          <mesh name="pineapple_cube15" geometry={nodes.pineapple_cube15.geometry} material={materials.pasted__pineapple_material_orange} position={[0.259097, -1.956468, -0.124647]} rotation={[-2.642847, 0.759338, -0.35875]} scale={0} />
          <mesh name="pineapple_cube34" geometry={nodes.pineapple_cube34.geometry} material={materials.pasted__pineapple_material_orange} position={[1.034936, 2.105819, -0.326685]} rotation={[2.194716, -0.282156, -0.369066]} scale={0} />
          <mesh name="pineapple_cube27" geometry={nodes.pineapple_cube27.geometry} material={materials.pasted__pineapple_material_orange} position={[-0.201202, -1.262568, 0.21721]} rotation={[1.448648, 0.564064, -1.345091]} scale={0} />
          <mesh name="pineapple_cube23" geometry={nodes.pineapple_cube23.geometry} material={materials.pasted__pineapple_material_orange} position={[1.776433, -0.636122, 0.292091]} rotation={[-1.139713, -0.122295, -0.259266]} scale={0} />
          <mesh name="pineapple_cube6" geometry={nodes.pineapple_cube6.geometry} material={materials.pasted__pineapple_material_orange} position={[0.334861, 1.062421, -0.288707]} rotation={[1.078653, -0.184684, 0.329988]} scale={0} />
          <mesh name="pineapple_cube4" geometry={nodes.pineapple_cube4.geometry} material={materials.pasted__pineapple_material_orange} position={[1.492082, -0.124129, -0.144852]} rotation={[-1.890027, -1.129395, 1.220314]} scale={0} />
          <mesh name="pineapple_cube12" geometry={nodes.pineapple_cube12.geometry} material={materials.pasted__pineapple_material_orange} position={[0.593158, 1.853943, 0.164962]} rotation={[-0.501927, 0.165714, 0.090284]} scale={0} />
          <mesh name="pineapple_cube42" geometry={nodes.pineapple_cube42.geometry} material={materials.pasted__pineapple_material_orange} position={[0.273133, -0.515904, -0.221403]} rotation={[-0.029054, 0.417394, 0.011781]} scale={0} />
          <mesh name="pineapple_cube43" geometry={nodes.pineapple_cube43.geometry} material={materials.pasted__pineapple_material_orange} position={[1.254017, -0.154938, 0.109663]} rotation={[-1.106985, 1.192574, 1.077021]} scale={0} />
          <mesh name="pineapple_cube35" geometry={nodes.pineapple_cube35.geometry} material={materials.pasted__pineapple_material_orange} position={[-0.471698, -1.822417, 0.16977]} rotation={[1.415488, -0.286799, 1.065287]} scale={0} />
          <mesh name="pineapple_cube2" geometry={nodes.pineapple_cube2.geometry} material={materials.pasted__pineapple_material_orange} position={[0.904137, -1.810773, 0.357895]} rotation={[1.540566, 0.077403, -1.198014]} scale={0} />
          <mesh name="pineapple_cube26" geometry={nodes.pineapple_cube26.geometry} material={materials.pasted__pineapple_material_orange} position={[1.482471, -0.443125, 0.197441]} rotation={[3.001859, 0.449366, 0.061022]} scale={0} />
          <mesh name="pineapple_cube8" geometry={nodes.pineapple_cube8.geometry} material={materials.pasted__pineapple_material_orange} position={[-1.496055, 1.388284, 0.019048]} rotation={[-2.024189, 0.59, -0.851528]} scale={0} />
          <mesh name="pineapple_cube72" geometry={nodes.pineapple_cube72.geometry} material={materials.pasted__pineapple_material_orange} position={[-0.964575, -0.844278, 0.169238]} rotation={[1.52976, -0.397688, 1.465173]} scale={0} />
          <mesh name="pineapple_cube101" geometry={nodes.pineapple_cube101.geometry} material={materials.pasted__pineapple_material_orange} position={[-1.078539, -0.224089, 0.189287]} rotation={[2.901111, 0.518531, 0.120942]} scale={0} />
          <mesh name="pineapple_cube95" geometry={nodes.pineapple_cube95.geometry} material={materials.pasted__pineapple_material_orange} position={[1.571659, 1.539974, -0.065114]} rotation={[1.635548, -0.225139, -1.288118]} scale={0} />
          <mesh name="pineapple_cube122" geometry={nodes.pineapple_cube122.geometry} material={materials.pasted__pineapple_material_orange} position={[-0.143954, 1.19351, 0.0576]} rotation={[1.708061, 0.332382, 1.170333]} scale={0} />
          <mesh name="pineapple_cube86" geometry={nodes.pineapple_cube86.geometry} material={materials.pasted__pineapple_material_orange} position={[-1.439125, 2.205553, 0.284922]} rotation={[1.502117, -0.613702, 1.451915]} scale={0} />
          <mesh name="pineapple_cube75" geometry={nodes.pineapple_cube75.geometry} material={materials.pasted__pineapple_material_orange} position={[0.928414, 0.493665, 0.378253]} rotation={[0.685813, -0.409991, 0.315302]} scale={0} />
          <mesh name="pineapple_cube90" geometry={nodes.pineapple_cube90.geometry} material={materials.pasted__pineapple_material_orange} position={[-0.777502, 1.791611, -0.327408]} rotation={[0.956062, 1.01746, -0.878136]} scale={0} />
          <mesh name="pineapple_cube77" geometry={nodes.pineapple_cube77.geometry} material={materials.pasted__pineapple_material_orange} position={[0.548228, 1.10668, -0.301794]} rotation={[-0.568595, -0.59392, -0.343417]} scale={0} />
          <mesh name="pineapple_cube82" geometry={nodes.pineapple_cube82.geometry} material={materials.pasted__pineapple_material_orange} position={[1.559653, 0.995225, -0.34885]} rotation={[1.545543, -0.00817, 0.312828]} scale={0} />
          <mesh name="pineapple_cube113" geometry={nodes.pineapple_cube113.geometry} material={materials.pasted__pineapple_material_orange} position={[1.426544, -0.090094, -0.049332]} rotation={[1.882801, 0.521275, 0.996048]} scale={0} />
          <mesh name="pineapple_cube119" geometry={nodes.pineapple_cube119.geometry} material={materials.pasted__pineapple_material_orange} position={[-1.325082, 1.13254, -0.223573]} rotation={[1.273999, -0.163831, 0.48993]} scale={0} />
          <mesh name="pineapple_cube104" geometry={nodes.pineapple_cube104.geometry} material={materials.pasted__pineapple_material_orange} position={[1.013013, -1.087577, -0.251783]} rotation={[-1.552089, 0.250636, 1.495504]} scale={0} />
          <mesh name="pineapple_cube89" geometry={nodes.pineapple_cube89.geometry} material={materials.pasted__pineapple_material_orange} position={[-1.1973, 1.965787, -0.088118]} rotation={[1.64906, 0.012961, 0.163789]} scale={0} />
          <mesh name="pineapple_cube88" geometry={nodes.pineapple_cube88.geometry} material={materials.pasted__pineapple_material_orange} position={[-0.943164, -0.510109, -0.060184]} rotation={[-1.272024, -0.069066, -0.220428]} scale={0} />
          <mesh name="pineapple_cube73" geometry={nodes.pineapple_cube73.geometry} material={materials.pasted__pineapple_material_orange} position={[0.636179, -0.724689, 0.353688]} rotation={[1.819466, 0.102743, 0.383871]} scale={0} />
          <mesh name="pineapple_cube87" geometry={nodes.pineapple_cube87.geometry} material={materials.pasted__pineapple_material_orange} position={[0.657831, 1.335109, 0.335249]} rotation={[2.039847, 0.306224, 0.536635]} scale={0} />
          <mesh name="pineapple_cube93" geometry={nodes.pineapple_cube93.geometry} material={materials.pasted__pineapple_material_orange} position={[-0.739736, 0.468062, -0.123211]} rotation={[3.059479, 0.941918, 0.066456]} scale={0} />
          <mesh name="pineapple_cube78" geometry={nodes.pineapple_cube78.geometry} material={materials.pasted__pineapple_material_orange} position={[-0.535129, 0.897814, -0.021145]} rotation={[-1.518622, 0.918203, 1.505163]} scale={0} />
          <mesh name="pineapple_cube96" geometry={nodes.pineapple_cube96.geometry} material={materials.pasted__pineapple_material_orange} position={[0.146084, 1.324812, 0.296947]} rotation={[-2.427778, 0.993572, -0.627861]} scale={0} />
          <mesh name="pineapple_cube84" geometry={nodes.pineapple_cube84.geometry} material={materials.pasted__pineapple_material_orange} position={[-0.071755, -1.247279, -0.382916]} rotation={[2.552361, -0.870721, -0.472585]} scale={0} />
          <mesh name="pineapple_cube70" geometry={nodes.pineapple_cube70.geometry} material={materials.pasted__pineapple_material_orange} position={[-0.065074, -0.381409, 0.069419]} rotation={[-1.667973, -0.17313, 1.055836]} scale={0} />
          <mesh name="pineapple_cube111" geometry={nodes.pineapple_cube111.geometry} material={materials.pasted__pineapple_material_orange} position={[0.181527, 2.225952, 0.291183]} rotation={[-1.022751, -1.084366, -0.966466]} scale={0} />
          <mesh name="pineapple_cube124" geometry={nodes.pineapple_cube124.geometry} material={materials.pasted__pineapple_material_orange} position={[1.443813, 0.876641, -0.047913]} rotation={[-1.122014, 0.0316, 0.065515]} scale={0} />
          <mesh name="pineapple_cube94" geometry={nodes.pineapple_cube94.geometry} material={materials.pasted__pineapple_material_orange} position={[-1.03371, 0.147426, -0.164654]} rotation={[-1.64752, -0.262231, 1.282514]} scale={0} />
        </group>
      </group>
    </group>
    
  )
}

export default AdditionCube