import React, {useMemo, useRef} from "react";
import { useAnimations, useGLTF } from "@react-three/drei";
import PropTypes from "prop-types";
import useTriggerAnimation from "../../../hooks/useTriggerAnimation";
import useSetFirstAnimationFrame from "../../../hooks/useSetFirstAnimationFrame";
import LockboxPile from "../models/LockboxPile";
import useNewMaterials from "../../../hooks/useNewMaterials";

export default function LockboxPileHolder({ ...props }) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(
    "/SceneIPA/lockbox-pile-transformed.glb"
  );
  const specificMaterials = useMemo(() => [
    {name: 'phong_7', color: 'blue2' },
    {name: 'number_material_7', replaceWith: 'number_material_7', setMaterialColor: 0xffffff },
  ], [])
  const newMaterials = useNewMaterials(materials, {
    baseMaterial: `${props.color}3`,
    specificMaterials: specificMaterials,
  });
  const { actions } = useAnimations(animations, group);

  useSetFirstAnimationFrame('lockboxes-land', actions);
  useTriggerAnimation(props.land, "lockboxes-land", actions, null, false, 1.5);
  useTriggerAnimation(props.out, "lockboxes-disappear", actions, null, false, 1);

  return (
    <group
      ref={group}
      position={props.position}
      dispose={null}
      visible={props.show}
      scale={props.scale}
    >
      <LockboxPile materials={newMaterials} nodes={nodes} />
    </group>
  );
}

LockboxPileHolder.propTypes = {
  show: PropTypes.bool,
  land: PropTypes.number,
  out: PropTypes.number,
  scale: PropTypes.number,
  position: PropTypes.array,  
  color: PropTypes.string,

};

LockboxPileHolder.defaultProps = {
  show: true,
  land: null,
  out: null,
  scale: null,
  position: null,
  color: "grey",
};

useGLTF.preload("/SceneIPA/lockbox-pile-transformed.glb");

