import React, {useMemo, useRef} from "react";
import { useAnimations, useGLTF } from "@react-three/drei";
import PropTypes from "prop-types";
import useTriggerAnimation from "../../../hooks/useTriggerAnimation";
import useSetFirstAnimationFrame from "../../../hooks/useSetFirstAnimationFrame";
import NewsSilo from "../models/NewsSilo";
import useNewMaterials from "../../../hooks/useNewMaterials";

export default function NewsSiloHolder({ ...props }) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(
    "/SceneIPA/website-data-silo-news-site-transformed.glb"
  );
  const specificMaterials = useMemo(() => [
    {name: 'phong_1', color: 'blue4' },
    {name: 'phong_7', color: 'blue4' },
    {name: 'phong_3', color: 'pink4' },
  ], [])
  const newMaterials = useNewMaterials(materials, {
    specificMaterials: specificMaterials,
  });
  const { actions } = useAnimations(animations, group);

  useSetFirstAnimationFrame('silo-rotation', actions);
  useTriggerAnimation(props.spin, "silo-rotation", actions, null, false, 1.5);
  useTriggerAnimation(props.drop, "lock-boxes-drop", actions, null, false, 1.5);

  return (
    <group
      ref={group}
      position={props.position}
      dispose={null}
      visible={props.show}
      scale={props.scale}
    >
      <NewsSilo materials={newMaterials} nodes={nodes} />
    </group>
  );
}

NewsSiloHolder.propTypes = {
  show: PropTypes.bool,
  spinEnd: PropTypes.number,
  spin: PropTypes.number,
  drop: PropTypes.number,
  scale: PropTypes.number,
  position: PropTypes.array,
};

NewsSiloHolder.defaultProps = {
  show: true,
  spin: null,
  spinEnd: null,
  drop: null,
  scale: null,
  position: null,
};

useGLTF.preload("/SceneIPA/website-data-silo-news-site-transformed.glb");

