import React from "react";
import TableHolder from "../shared-models/holders/TableHolder";
import PairsWithSharesGroup from "./PairsWithSharesGroup";

const MultiplicationRoomGroup = () => {
  return (
    <>
      <TableHolder position={[0, 0, 0]} />
      <PairsWithSharesGroup />
    </>
  );
};

export default MultiplicationRoomGroup;

