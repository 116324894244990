import {text} from "./text";

export const keyframes = [

  //Looking at title
  { time: 0, position: [0, 10, 10], lookAt: [0, 10, 0,], shakeIntensity: 0.5 },
  //Pulls away to browser
  { time: text[0].adjustedIn, position: [0, 10, 20], mobilePosition: [0, 10, 24], lookAt: [0, 10, 0], shakeIntensity: 0.5 },
  { time: text[2].adjustedIn, position: [0, 10, 20], mobilePosition: [0, 10, 24], lookAt: [0, 10, 0], shakeIntensity: 0.5 },
  //Spans down to IPA
  { time: text[3].adjustedIn, position: [0, 2, 15], mobilePosition: [0, 2, 24], lookAt: [0, 0, 0], shakeIntensity: 0.5},
  { time: text[4].adjustedIn, position: [0, 2, 15], mobilePosition: [0, 2, 24], lookAt: [0, 0, 0], shakeIntensity: 0.5},
];
